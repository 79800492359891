import React, { useEffect, useState } from 'react';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import { reviewTableHeadings } from 'pages/analytics/common/consts';
import AnalyticsEmojiData from 'pages/analytics/common/AnalyticsEmojiData/AnalyticsEmojiData';
import {
    useFetchCoursesPopularQuery,
    useFetchGeneralAnalyticsQuery,
} from 'api/services/AnalyticsService/AnalyticsService';
import { useSelector } from 'react-redux';
import EmptyPlaceholders from 'pages/analytics/common/EmptyPlaceholders/EmptyPlaceholders';
import { useTranslation } from 'react-i18next';

const Review = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [page, setPage] = useState(1);
    const { data } = useFetchGeneralAnalyticsQuery(
        { spaceId: userSpace?.id },
        {
            skip: !userSpace,
        }
    );
    const bodyReviews = useFetchCoursesPopularQuery({
        spaceId: userSpace?.id,
        page: page - 1,
    });
    useEffect(() => {
        bodyReviews.refetch();
    }, [page]);
    return (
        <div>
            <AnalyticsEmojiData data={data || {}} />
            {(bodyReviews?.data?.items || []).length > 0 ? (
                <AnalyticsTable
                    headings={reviewTableHeadings}
                    body={bodyReviews?.data?.items || []}
                    withImgColumn
                    total={bodyReviews?.data?.total}
                    withoutBorder
                    onChangePage={setPage}
                />
            ) : (
                <EmptyPlaceholders
                    title={t('ANALYTICS_EMPTY_TITLE')}
                    description={t('ANALYTICS_EMPTY_REVIEW_DESC')}
                />
            )}
        </div>
    );
};

export default Review;
