const params = {
    spaceBetween: 48,
    slidesPerView: 4,
    slidesPerGroup: 4,
    virtual: true,
    grabCursor: true,
    breakpoints: {
        0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
        },
        425: {
            slidesPerView: 2,
            slidesPerGroup: 2,
        },
        1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
        },
        1440: {
            slidesPerView: 4,
            slidesPerGroup: 4,
        },
    },
};

export { params };
