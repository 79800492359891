import { CustomDropdownIndicator } from 'components/custom-react-select/CustomDropdownIndicator';
import { CustomValueContainer } from 'components/custom-react-select/CustomValueContainer';
import { CustomClearIndicator } from 'components/custom-react-select/CustomClearIndicator';
import { CustomMultiValue } from 'components/custom-react-select/CustomMultiValue';
import { CustomControl } from 'components/custom-react-select/CustomControl';

const baseStyles = ({ isError }) => {
    return {
        container: (base) => ({
            ...base,
            width: '100%',
        }),
        input: (base) => ({
            ...base,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '434px',
        }),
        control: (provided, state) => {
            return {
                ...provided,
                paddingLeft: '16px',
                paddingBottom: '12px',
                border: 0,
                borderBottom: `1px solid ${
                    isError && !state.selectProps.inputValue
                        ? '#ff2f24'
                        : '#EDEFF0'
                }`,
                borderRadius: 0,
                boxShadow: 'none',
                '&:hover, &:focus, &:active': {
                    borderColor:
                        isError && !state.selectProps.inputValue
                            ? '#ff2f24'
                            : '#061822',
                    cursor: 'text',
                },
            };
        },
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            fontSize: '15px',
            top: '3.5px',
            margin: '-4px',
            overflow: 'visible',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            top:
                state.isFocused ||
                state.isSelected ||
                state.selectProps.inputValue
                    ? -25
                    : 0,
            transition: 'top 0.1s, font-size 0.1s',
            fontSize:
                (state.isFocused ||
                    state.isSelected ||
                    state.selectProps.inputValue) &&
                13,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            color:
                state.isFocused ||
                state.isSelected ||
                state.selectProps.inputValue
                    ? '#637D8B'
                    : '#a8afb2',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            width: '56px',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 24px rgba(56, 166, 226, 0.08)',
            borderRadius: '12px',
        }),
        option: (provided) => ({
            ...provided,
            paddingTop: '11px',
            paddingBottom: '11px',
            paddingLeft: '16px',
            paddingRight: '16px',
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: '140%',
            color: '#445259',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#F7F9FA',
                color: '#061822',
            },
        }),
    };
};

const baseComponents = {
    Control: CustomControl,
    DropdownIndicator: CustomDropdownIndicator,
    ValueContainer: CustomValueContainer,
    ClearIndicator: CustomClearIndicator,
    MultiValue: CustomMultiValue,
    LoadingIndicator: null,
};

export { baseStyles, baseComponents };
