import React, { useEffect, useState } from 'react';
import {
    Heading,
    Modal,
    List,
    Checkbox,
    Typography,
    Icon,
    Button,
} from '@akhter-studios/classlytics-front-ui-components';
import './PublishModal.scss';

import { useTranslation } from 'react-i18next';
import {
    useFetchPublishItemsQuery,
    usePublishCourseWithMaterialsMutation,
} from 'api/services';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PublishedModal from 'components/courses/PublishedModal/PublishedModal';
import {PREVIEW_STATUS, usePreview} from "components/layout/PreviewContext";

const PublishModal = ({ isOpen, setIsOpen }) => {
    const { t } = useTranslation('common');
    const [publishedModalOpen, setPublishedModalOpen] = useState(false);
    const [publishedMaterials, setPublishedMaterials] = useState({
        published: [],
        unpublished: [],
        deleted: [],
    });

    const preview = usePreview();

    const { userSpace } = useSelector((state) => state.space);
    const { courseId } = useParams();
    const { data: publishInfo, refetch } = useFetchPublishItemsQuery({
        spaceId: userSpace?.id,
        courseId,
    }, {skip: preview !== PREVIEW_STATUS.draft || !isOpen});

    useEffect(() => {
        if (preview === PREVIEW_STATUS.draft && isOpen) {
            refetch();
        }
    }, [isOpen]);
    const [publishCourse] = usePublishCourseWithMaterialsMutation();
    const onPublish = async () => {
        const { data: publishResult } = await publishCourse({
            spaceId: userSpace?.id,
            courseId,
            body: selectedPublishItems,
        });
        setPublishedMaterials(publishResult);
        setPublishedModalOpen(true);
        setIsOpen(false);
        checkAllItems();
    };
    const [selectedPublishItems, setSelectedPublishItems] = useState({
        courseId: null,
        materials: [],
    });
    const onMainCheck = () => {
        if (selectedPublishItems.courseId) {
            setSelectedPublishItems({
                ...selectedPublishItems,
                courseId: null,
            });
        } else {
            setSelectedPublishItems({ ...selectedPublishItems, courseId });
        }
    };
    const onItemCheck = (id) => {
        if (selectedPublishItems.materials.includes(id)) {
            setSelectedPublishItems({
                ...selectedPublishItems,
                materials: selectedPublishItems.materials.filter(
                    (item) => item !== id
                ),
            });
        } else {
            setSelectedPublishItems({
                ...selectedPublishItems,
                materials: [...selectedPublishItems.materials, id],
            });
        }
    };
    const checkAllItems = () => {
        if (
            selectedPublishItems.courseId &&
            selectedPublishItems.materials.length ===
                publishInfo?.materials.length
        ) {
            setSelectedPublishItems({ courseId: null, materials: [] });
        } else {
            setSelectedPublishItems({
                courseId,
                materials: publishInfo?.materials.map((item) => item.id),
            });
        }
    };
    return (
        <>
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className={'publish-modal'}
            >
                <Heading variant={'h2'}>{t('PUBLISH_QUESTIONNAIRE')}</Heading>
                <div className={'publish-items'}>
                    <li className={'publish-item publish-item--all'}>
                        <div
                            className={
                                'publish-item__controls publish-item__controls--main'
                            }
                            onClick={checkAllItems}
                        >
                            <Checkbox
                                checked={
                                    !!selectedPublishItems.courseId &&
                                    selectedPublishItems.materials.length >=
                                        publishInfo?.materials.length
                                }
                                onChange={checkAllItems}
                            />
                            <Typography
                                variant={'body4'}
                                className={
                                    'publish-item__name publish-item__name--all'
                                }
                            >
                                {selectedPublishItems.courseId &&
                                selectedPublishItems.materials.length >=
                                    publishInfo?.materials.length
                                    ? t('PUBLISH_RESET_ALL')
                                    : t('PUBLISH_CHOOSE_ALL')}
                            </Typography>
                        </div>
                        <Typography variant={'body4'}>
                            {t('PUBLISH_SELECTED_ITEMS', {
                                count:
                                    selectedPublishItems.materials.length +
                                    !!selectedPublishItems.courseId,
                                from: publishInfo?.materials.length + 1,
                            })}
                        </Typography>
                    </li>
                    <List
                        className={'publish-list'}
                        items={publishInfo?.materials || []}
                        firstItem={
                            <div className={'publish-item'}>
                                <div
                                    className={'publish-item__controls'}
                                    onClick={onMainCheck}
                                >
                                    <Checkbox
                                        checked={
                                            !!selectedPublishItems?.courseId
                                        }
                                        onChange={onMainCheck}
                                    />
                                    <Typography
                                        variant={'body3'}
                                        className={'publish-item__name'}
                                    >
                                        {t('PUBLISH_MAIN_ITEM')}
                                    </Typography>
                                </div>
                                <Icon name={'link'} />
                            </div>
                        }
                        renderItem={({ id, name }) => {
                            return (
                                <div className={'publish-item'} key={id}>
                                    <div
                                        className={'publish-item__controls'}
                                        onClick={() => onItemCheck(id)}
                                    >
                                        <Checkbox
                                            checked={selectedPublishItems?.materials.includes(
                                                id
                                            )}
                                            onChange={() => onItemCheck(id)}
                                        />
                                        <Typography
                                            variant={'body3'}
                                            className={'publish-item__name'}
                                        >
                                            {name}
                                        </Typography>
                                    </div>
                                    <Icon name={'link'} />
                                </div>
                            );
                        }}
                    />
                </div>
                <div className={'publish-buttons'}>
                    <Button
                        variant={'primary'}
                        className={'publish-buttons__button'}
                        size={'small'}
                        disabled={
                            !selectedPublishItems.courseId &&
                            !selectedPublishItems.materials.length
                        }
                        onClick={onPublish}
                    >
                        {t('PUBLISH_THESE_ONES')}
                    </Button>
                    <Button
                        onClick={() => setIsOpen(false)}
                        className={'publish-buttons__button'}
                        variant={'secondary'}
                        size={'small'}
                    >
                        {t('PUBLISH_CANCEL')}
                    </Button>
                </div>
            </Modal>
            <PublishedModal
                setIsOpen={setPublishedModalOpen}
                isOpen={publishedModalOpen}
                materials={publishedMaterials}
            />
        </>
    );
};

export default PublishModal;
