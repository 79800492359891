import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyFetchCoursesQuery } from 'api/services';
import {
    Icon,
    Typography,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import CoursesItem from '../CoursesItem';
import CoursesEmpty from '../CoursesEmpty';
import InfiniteScroll from 'react-infinite-scroll-component';
import './CoursesList.scss';
import { useAction } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';
import { onlyCenterMinHeight } from 'utils/functions/helpers';
import FoundCourses from 'pages/courses/CoursesList/FoundCourses';

const CoursesList = ({
    page,
    setPage,
    isLoading,
    onCreateCourse,
    onShowDeleteModal,
}) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const { courses: coursesList } = useSelector((state) => state.courses);
    const { search } = useSelector((state) => state.foundCourses);
    const { setCourses } = useAction();
    const [trigger, { isSuccess, isFetching }] = useLazyFetchCoursesQuery();
    const [searchParams] = useSearchParams();
    const status = useMemo(
        () => searchParams.get('scope') || 'active',
        [searchParams]
    );
    const pageSize = 15;
    useEffect(() => {
        if (coursesList?.results?.length === 3) {
            loadMoreCourses();
        }
    }, [coursesList?.results]);
    const loadMoreCourses = async () => {
        if (!coursesList.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            spaceId: userSpace?.id,
            status,
            page: page + 1,
            page_size: pageSize,
        });
        const modifiedResults = [...coursesList.results, ...data.results];
        setCourses({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        setCourses({
            results: [],
            hasMore: false,
            count: 0,
        });
        userSpace &&
            trigger({
                spaceId: userSpace?.id,
                status,
                page,
                page_size: pageSize,
            });
    }, [userSpace, status]);

    if (isSuccess && coursesList?.results.length === 0 && !isFetching) {
        return (
            <CoursesEmpty
                isLoading={isLoading}
                onCreateCourse={onCreateCourse}
            />
        );
    }

    if (isFetching && coursesList.results.length === 0) {
        return (
            <div className={'layout-main'}>
                <div style={onlyCenterMinHeight(60)}>
                    {t('GENERAL_LOADING')}
                </div>
            </div>
        );
    }

    return coursesList ? (
        <>
            {search.length === 0 ? (
                <InfiniteScroll
                    className={'courses-list'}
                    dataLength={coursesList.results.length || 0}
                    next={loadMoreCourses}
                    hasMore={coursesList.hasMore}
                    loader={null}
                >
                    {coursesList.results.map((course) => (
                        <CoursesItem
                            {...course}
                            key={course.id}
                            status={status}
                            onShowDeleteModal={() =>
                                onShowDeleteModal(course.id)
                            }
                        />
                    ))}
                    {isSuccess ? (
                        <div className={'courses-list__last-wrapper'}>
                            <div
                                className={'courses-list__last'}
                                onClick={onCreateCourse}
                            >
                                <Icon name={'select-object-add-plus'} />
                                <Typography variant={'body3'}>
                                    {t('COURSES_NEW_CREATE')}
                                </Typography>
                            </div>
                        </div>
                    ) : null}
                </InfiniteScroll>
            ) : (
                <FoundCourses
                    isSuccess={isSuccess}
                    status={status}
                    onShowDeleteModal={onShowDeleteModal}
                    onCreateCourse={onCreateCourse}
                />
            )}
        </>
    ) : null;
};

export default CoursesList;
