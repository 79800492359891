import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actions } from './consts';
import {
    Checkbox,
    Avatar,
    Typography,
    Icon,
    Dropdown,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './MentorItem.scss';
import { useWindowSize } from 'helpers/hooks';
import { useMeQuery } from 'api/services';
import { useSelector } from 'react-redux';

const MentorItem = (props) => {
    const {
        id,
        user,
        role,
        access,
        isSelected = false,
        onSelectedMentor,
        onRemove,
    } = props;
    const { avatar, email, firstName, lastName, isActive } = user;
    const { t } = useTranslation('common');
    const { width } = useWindowSize();
    const [isShow, setIsShow] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const { data: profile } = useMeQuery(
        { id: userSpace?.id },
        { skip: !userSpace }
    );
    const onShow = (e) => {
        e.stopPropagation();
        setIsShow((prev) => !prev);
    };
    const onSelected = () => {
        if (width < 769) {
            return;
        } else {
            onSelectedMentor({
                target: { checked: !isSelected, value: id },
            });
        }
    };
    return (
        <div
            className={classNames('mentor-item', {
                selected: isSelected,
                current: profile?.id === user?.id,
            })}
            onClick={onSelected}
        >
            <Checkbox
                className={'mentor-item__checkbox'}
                value={id}
                checked={isSelected}
                onChange={onSelectedMentor}
            />
            {!isActive ? (
                <div className={'mentor-item__circle'} />
            ) : (
                <Avatar
                    className={'mentor-item__avatar'}
                    src={avatar}
                    alt={'avatar'}
                    variant={'rounded'}
                    avatarText={`${firstName?.charAt(0).toUpperCase() || ''}${
                        lastName?.charAt(0).toUpperCase() || ''
                    }`}
                />
            )}
            <div className={'mentor-item__content'}>
                <div>
                    <Typography
                        className={'mentor-item__name'}
                        variant={'body4'}
                    >
                        {firstName} {lastName}
                    </Typography>
                    <div className={'mentor-item__block'}>
                        <Typography
                            className={'mentor-item__email'}
                            variant={'body4'}
                        >
                            {email}
                        </Typography>
                        {!isActive ? (
                            <div className={'mentor-item__invited'}>
                                <Typography variant={'body5'}>
                                    {t('ADMINS_INVITED')}
                                </Typography>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={'mentor-item__access'}>
                    <Typography variant={'body4'}>
                        {t('ADMINS_ACCESS_TO_COURSE')}
                    </Typography>
                    <Typography variant={'body4'}>{access}</Typography>
                </div>
            </div>
            <Dropdown
                className={'mentor-item__dropdown'}
                isShow={isShow}
                setIsShow={setIsShow}
            >
                <Dropdown.Header>
                    <div className={'mentor-item__select'} onClick={onShow}>
                        <Typography variant={'body4'}>{role}</Typography>
                        <Icon name={`arrow-${isShow ? 'up' : 'down'}`} />
                    </div>
                </Dropdown.Header>
                <Dropdown.Body>
                    <List
                        className={'mentor-item__actions'}
                        items={actions({
                            id,
                            role,
                            onRemove,
                        })}
                        renderItem={({
                            id,
                            text,
                            border,
                            disabled,
                            onClick,
                        }) => (
                            <li
                                key={id}
                                className={classNames('mentor-item__action', {
                                    border,
                                    disabled,
                                })}
                                onClick={onClick}
                            >
                                <Typography variant={'body4'}>
                                    {t(text)}
                                </Typography>
                            </li>
                        )}
                    />
                </Dropdown.Body>
            </Dropdown>
            {/*<Icon className={'mentor-item__arrow'} name={'arrow-right'} />*/}
        </div>
    );
};

export default MentorItem;
