import { Icon } from '@akhter-studios/classlytics-front-ui-components';
import { components } from 'react-select';
import './CustomDropdownIndicator.scss';

const { DropdownIndicator } = components;

const CustomDropdownIndicator = (props) => {
    const { onNext, iconName, menuIsOpen } = props.selectProps;
    return (
        <DropdownIndicator {...props}>
            <Icon
                className={'custom-dropdown-indicator'}
                name={
                    iconName ? iconName : menuIsOpen ? 'arrow-up' : 'arrow-down'
                }
                onClick={onNext}
            />
        </DropdownIndicator>
    );
};

export default CustomDropdownIndicator;
