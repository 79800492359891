import { useLayoutEffect, useState } from 'react';
import { debounce } from 'utils/functions/helpers';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useLayoutEffect(() => {
        const updateSize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', debounce(updateSize, 250));
        // updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return isMobile;
};

export default useIsMobile;
