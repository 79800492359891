import { useTranslation } from 'react-i18next';
import { tabs } from './consts';
import {
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import './CreateCourseSettingsMenu.scss';

const CreateCourseSettingsMenu = ({ courseId }) => {
    const { search: locationSearch } = useLocation();
    const { t } = useTranslation('common');
    return (
        <nav className={'create-course-settings-menu'}>
            <List
                className={'create-course-settings-menu__list'}
                items={tabs({ courseId })}
                renderItem={({ id, icon, text, search, to, isDivider }) => (
                    <li
                        className={classNames(
                            'create-course-settings-menu__item',
                            {
                                divider: isDivider,
                            }
                        )}
                        key={id}
                    >
                        <Link
                            className={classNames(
                                'create-course-settings-menu__link',
                                {
                                    active:
                                        search ===
                                        (locationSearch.length > 0
                                            ? locationSearch
                                            : '?scope=aboutCourse'),
                                }
                            )}
                            to={to}
                        >
                            <Icon name={icon} />
                            <Typography
                                className={'create-course-settings-menu__text'}
                                variant={'body4'}
                            >
                                {t(text)}
                            </Typography>
                        </Link>
                    </li>
                )}
            />
        </nav>
    );
};

export default CreateCourseSettingsMenu;
