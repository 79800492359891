import React from 'react';
import {
    Heading,
    Icon,
    Typography,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
import './index.scss';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

const CourseManagement = ({ onPreviewClick, onSettingsClick }) => {
    const { t } = useTranslation('common');
    return (
        <div className={'course-management'}>
            <Heading variant={'h4'} className={'course-management__title'}>
                {t('COURSE_MANAGEMENT_TITLE')}
            </Heading>
            <List
                items={[
                    {
                        id: nanoid(),
                        key: 'preview',
                        iconName: 'folder',
                        name: t('COURSE_MANAGEMENT_PREVIEW'),
                        onClick: onPreviewClick,
                    },
                    {
                        id: nanoid(),
                        key: 'settings',
                        iconName: 'lesson',
                        name: t('COURSE_MANAGEMENT_SETTINGS'),
                        onClick: onSettingsClick,
                    },
                ]}
                renderItem={(action) =>
                    !action.disabled ? (
                        <li
                            className={classNames('coursesDropdown__item', {
                                divider: action.isDivider,
                            })}
                            key={action.id}
                            onClick={action.onClick}
                        >
                            <Icon
                                className={'coursesDropdown__icon'}
                                name={action.iconName}
                            />
                            <Typography
                                className={'coursesDropdown__name'}
                                variant={'body4'}
                            >
                                {t(action.name)}
                            </Typography>
                        </li>
                    ) : null
                }
            />
        </div>
    );
};

export default CourseManagement;
