import React from 'react';
import { Icon } from 'components/common';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

const BasePlaceholder = ({ onUploadFile }) => {
    const { t } = useTranslation('common');
    return (
        <div className="knowledge-base-placeholder">
            <div className="knowledge-base-placeholder__title">
                {t('NO_DOCUMENTS_YET')}
            </div>
            <div className="knowledge-base-placeholder__desc">
                {t('BASE_DESCRIPTION')}
            </div>
            <div className="knowledge-base__actions-item">
                <label htmlFor="upload-file">
                    <span>{t('UPLOAD_FILE')}</span>
                    <input
                        onChange={(e) =>
                            onUploadFile(e.target.files, null, nanoid())
                        }
                        type="file"
                        style={{ display: 'none' }}
                        name="upload-file"
                        id="upload-file"
                    />
                </label>
            </div>
        </div>
    );
};

export default BasePlaceholder;
