import React, { useRef } from 'react';
import {
    Checkbox,
    Radio,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import { testStateField } from 'utils/functions/helpers';
import { Icon } from 'components/common';
import useAutoSizeTextarea from 'helpers/hooks/useAutoSizeTextarea';

const BlockField = ({
    type,
    x,
    isStudent,
    index,
    item,
    roleKey,
    handleChangeField,
    isReadOnly,
    handleChangeLabel,
    childIndex,
    onChangeDebounceLabel,
    removeField,
}) => {
    const refTextarea = useRef(null);
    useAutoSizeTextarea(refTextarea.current, x.label);
    return (
        <div className="block-item__fields-wrap">
            <div className="d-flex w-100" style={{ alignItems: 'center' }}>
                {!isReadOnly && !isStudent ? (
                    <span
                        className="column-drag-handle"
                        style={{
                            float: 'left',
                            padding: '0 10px',
                            color: '#637D8B',
                            cursor: 'move',
                        }}
                    >
                        &#x2630;
                    </span>
                ) : null}
                {type === 'single-choice' ? (
                    <Radio
                        className={classNames(testStateField(x, isStudent))}
                        disabled={isReadOnly}
                        onChange={(e) =>
                            handleChangeField(x, index, item.type, item)
                        }
                        id={x.id}
                        checked={x[roleKey]}
                    />
                ) : (
                    <Checkbox
                        className={classNames(testStateField(x, isStudent))}
                        disabled={isReadOnly}
                        onChange={(e) =>
                            handleChangeField(x, index, item.type, item)
                        }
                        checked={x[roleKey]}
                        id={x.id}
                    />
                )}
                <textarea
                    ref={refTextarea}
                    disabled={isStudent || isReadOnly}
                    value={x.label}
                    className={'block-item__answer'}
                    placeholder={x.placeholder}
                    onChange={({ target: { value } }) => {
                        if (value.length < 128) {
                            handleChangeLabel({
                                value,
                                childIndex,
                                index,
                            });
                            onChangeDebounceLabel({
                                value,
                                childIndex,
                                index,
                            });
                        }
                    }}
                />
            </div>
            {!isStudent && !isReadOnly && (
                <div
                    className="block-item__fields-actions"
                    onClick={() => removeField(x.id, index)}
                >
                    <Icon name="trash" />
                </div>
            )}
        </div>
    );
};

export default BlockField;
