import {
    Button,
    Heading,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './SettingsCard.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React from 'react';
const SettingsCard = ({
    className,
    children,
    title,
    description,
    isDownloadBtn = true,
    isRemoveBtn = true,
    isError,
    errorText,
    onUpload,
    onRemove,
}) => {
    const { t } = useTranslation('common');

    return (
        <div className={classNames('settings-card', className)}>
            <Heading className={'settings-card__title'} variant={'h4'}>
                {title}
            </Heading>
            <Typography className={'settings-card__desc'} variant={'body4'}>
                {description}
            </Typography>
            <div className={classNames('settings-card__content', { isError })}>
                <div className={'settings-card__children'}>{children}</div>
                <div className={'settings-card__buttons'}>
                    {isDownloadBtn ? (
                        <Button
                            variant={'secondary'}
                            size={'small'}
                            onClick={onUpload}
                        >
                            {t('UPLOAD')}
                        </Button>
                    ) : null}
                    {isRemoveBtn ? (
                        <Button
                            className={'tertiary'}
                            size={'small'}
                            onClick={onRemove}
                        >
                            {t('FILE_REMOVE')}
                        </Button>
                    ) : null}
                </div>
            </div>
            {errorText && (
                <Typography
                    className={'settings-card__errorText'}
                    variant={'body4'}
                >
                    {errorText}
                </Typography>
            )}
        </div>
    );
};

export default SettingsCard;
