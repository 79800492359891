import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import './DefaultMultiValue.scss';
import { useTranslation } from 'react-i18next';

const DefaultMultiValue = (props) => {
    const { index, data, getValue } = props;
    const maxToShow = 3;
    const count = getValue().length - maxToShow;
    const { t } = useTranslation('common');

    return index < maxToShow ? (
        <Heading className={'default-multi-value__text'} variant={'h5'}>
            {data.label},
        </Heading>
    ) : index === maxToShow ? (
        <Heading variant={'h5'}>{t('COURSE_SELECTED', { count })}</Heading>
    ) : null;
};

export default DefaultMultiValue;
