import { baseApi } from 'api/xhr';
import {
    CREATE_SPACE_URL,
    FETCH_SPACES_URL,
    FETCH_SETTINGS_BY_DOMAIN_URL,
} from 'utils/consts';

const spaceApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: ['DeleteSpaceImage'],
});
export const spaceApi = spaceApiWithTags.injectEndpoints({
    endpoints: (build) => ({
        fetchSpaces: build.query({
            query: (params) => ({
                url: FETCH_SPACES_URL,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    spaces: baseQueryReturnValue.results,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
            keepUnusedDataFor: 5,
        }),
        fetchSettingsByDomain: build.query({
            query: (originalDomain) => ({
                url: `${FETCH_SETTINGS_BY_DOMAIN_URL}${originalDomain}/`,
            }),
        }),
        fetchSpace: build.query({
            query: ({ domain }) => ({
                url: `${FETCH_SPACES_URL}${domain}/`,
            }),
            providesTags: ['DeleteSpaceImage'],
        }),
        createSpace: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: CREATE_SPACE_URL,
                body,
            }),
        }),
        updateSpace: build.mutation({
            query: ({ id, ...body }) => ({
                method: 'PATCH',
                url: `/learning-space/${id}/`,
                body,
            }),
        }),
        updateSpaceAssets: build.mutation({
            query: ({ id, formData }) => ({
                method: 'PATCH',
                url: `/learning-space/${id}/`,
                body: formData,
            }),
        }),
        deleteSpaceImage: build.mutation({
            query: ({ id, ...params }) => ({
                method: 'DELETE',
                url: `/learning-space/${id}/delete-space-image/`,
                params,
            }),
            invalidatesTags: (result, error, arg) => {
                if (arg.image_type === 'logo') return ['DeleteSpaceImage'];
            },
        }),
    }),
});

export const {
    useFetchSpacesQuery,
    useLazyFetchSettingsByDomainQuery,
    useLazyFetchSpacesQuery,
    useLazyFetchSpaceQuery,
    useCreateSpaceMutation,
    useUpdateSpaceMutation,
    useUpdateSpaceAssetsMutation,
    useDeleteSpaceImageMutation,
} = spaceApi;
