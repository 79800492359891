import HeaderCompany from 'components/common/Header/HeaderCompany';
import './MenuHeader.scss';
import { useSelector } from 'react-redux';

const MenuHeader = () => {
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);

    return (
        <header
            className={'header menu-header'}
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <HeaderCompany className={'menu-header__company'} />
        </header>
    );
};

export default MenuHeader;
