import React from 'react';
import { useSelector } from 'react-redux';
import {
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import CoursesItem from 'pages/courses/CoursesItem';
import { useTranslation } from 'react-i18next';

const FoundCourses = ({
    status,
    onShowDeleteModal,
    isSuccess,
    onCreateCourse,
}) => {
    const { t } = useTranslation('common');
    const { courses: foundCourses } = useSelector(
        (state) => state.foundCourses
    );

    return (
        <List
            className={'courses-list'}
            items={foundCourses}
            renderItem={(course) => (
                <CoursesItem
                    {...course}
                    key={course.id}
                    status={status}
                    onShowDeleteModal={() => onShowDeleteModal(course.id)}
                />
            )}
            lastItem={
                isSuccess ? (
                    <div className={'courses-list__last-wrapper'}>
                        <div
                            className={'courses-list__last'}
                            onClick={onCreateCourse}
                        >
                            <Icon name={'select-object-add-plus'} />
                            <Typography variant={'body3'}>
                                {t('COURSES_NEW_CREATE')}
                            </Typography>
                        </div>
                    </div>
                ) : null
            }
        />
    );
};

export default FoundCourses;
