import React, { useEffect } from 'react';
import useOutsideClick from 'helpers/hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';

const List = ({ data, handleClick, open, setOpen, title }) => {
    const ref = useOutsideClick(() => setOpen(false));
    const { t } = useTranslation('common');
    const handleClickItem = (handler, type) => {
        if (handler) {
            handler();
            setOpen(false);
        } else {
            handleClick(type);
        }
    };
    return (
        <div className="create-test-list" ref={ref}>
            <div className="create-test-list__title">{title}</div>
            <ul>
                {data.map(({ type, title, icon, handler, isShow }) => (
                    <React.Fragment key={type}>
                        {isShow && (
                            <li onClick={() => handleClickItem(handler, type)}>
                                {icon}
                                <span>{t(title)}</span>
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default List;
