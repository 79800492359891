import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFetchCourseMutation, useFetchCourseTagsQuery } from 'api/services';
import CreateCourseSettingsAboutCourseForm from './CreateCourseSettingsAboutCourseForm';
const CreateCourseSettingsAboutCourse = () => {
    const { courseId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { course } = useSelector((state) => state.course);
    const { data: tags } = useFetchCourseTagsQuery(
        { spaceId: userSpace?.id, course_id: courseId },
        { skip: !userSpace }
    );
    const [fetchCourse] = useFetchCourseMutation();

    useEffect(() => {
        if (courseId) {
            fetchCourse({
                spaceId: userSpace?.id,
                courseId,
            });
        }
    }, [courseId]);
    return (
        <CreateCourseSettingsAboutCourseForm
            complexity={course.complexity}
            tags={tags}
        />
    );
};

export default CreateCourseSettingsAboutCourse;
