export const clearValueFromSwitch = (switches, handler) => {
    const { time, percent, limit } = switches;
    if (!time) {
        return handler('duration');
    }
    if (!percent) {
        return handler('minAcceptance');
    }
    if (!limit) {
        return handler('limit');
    }
};
