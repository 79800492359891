import { createSlice } from '@reduxjs/toolkit';
import { courseApi, courseInjectionApi } from 'api/services';

const initialState = {
    lesson: {
        blocks: null,
        courseId: null,
        draft: null,
        id: null,
        materialId: null,
        moduleId: null,
        name: null,
        status: null,
    },
    dataUpdated: false,
};
export const lessonSlice = createSlice({
    name: 'lesson',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                courseApi.endpoints.getLesson.matchFulfilled,
                (state, { payload }) => {
                    Object.keys(payload).forEach((key) => {
                        state.lesson[key] = payload[key];
                    });
                    state.dataUpdated = false;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.updateCourseLesson.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            );
    },
});

const lessonActions = lessonSlice.actions;
export { lessonActions };

export default lessonSlice.reducer;
