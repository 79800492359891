const COMPANY_NAME_REGEX = /^[а-яА-Яa-zA-Z0-9-‑\s]+$/g;
const DOMAIN_REGEX = /(?!^\d+$)^.+$/g;
const DOMAIN_START_END_CHARACTERS_REGEX =
    /^((?!-)[a-zA-Z0-9-]{0,63})+[a-zA-Z0-9]$/;
const ONLY_LATIN = /.*[^a-zA-Z-\d]+.*/gi;
const NAME_REGEX =
    /^[\sa-zA-Zа-яА-яёЁ\u04D8\u04D9\u04B0\u04B1\u0406\u0456\u04A2\u04A3\u0492\u0493\u04AE\u04AF\u049A\u049B\u04E8\u04E9\u04BA\u04BBàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-]+$/u;
const PASSWORD_REGEX = /^[^\s]+$/g;
const PASSWORD_VALID_REGEX =
    /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}/;

const PHONE_NUMBER_REGEX = /^\+?77(\d{9})$/;

export {
    COMPANY_NAME_REGEX,
    DOMAIN_REGEX,
    DOMAIN_START_END_CHARACTERS_REGEX,
    ONLY_LATIN,
    NAME_REGEX,
    PASSWORD_REGEX,
    PASSWORD_VALID_REGEX,
    PHONE_NUMBER_REGEX
};
