import { baseApi } from 'api/xhr';

export const userGroupApi = baseApi.injectEndpoints({
    tagTypes: ['Groups', 'Group', 'GroupCourses', 'GroupStudents'],
    endpoints: (build) => ({
        fetchUserGroups: build.query({
            query: ({ id, ...params }) => ({
                url: `/user-part/${id}/groups/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
        }),
        fetchUserSearchGroups: build.query({
            query: (params) => ({
                url: `/search/groups/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
        }),
        fetchUserGroup: build.query({
            query: ({ spaceId, groupId }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Group', 'GroupStudents', 'GroupCourses'],
        }),
        addUserGroup: build.mutation({
            query: ({ spaceId, lang, ...body }) => ({
                url: `/user-part/${spaceId}/groups/`,
                method: 'POST',
                body,
                params: { lang },
            }),
            invalidatesTags: ['Groups'],
        }),
        removeUserGroup: build.mutation({
            query: ({ spaceId, groupId }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/`,
                method: 'DELETE',
            }),
        }),
        fetchUserGroupStudents: build.query({
            query: (params) => ({
                url: `/search/users/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                const newRes = baseQueryReturnValue.results.map((r) => ({
                    ...r,
                    user: {
                        id: r.profile.id,
                        avatar: r.avatar,
                        email: r.email,
                        firstName: r.firstName,
                        lastName: r.lastName,
                        isActive: r.isActive,
                    },
                }));
                return {
                    results: newRes,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
            keepUnusedDataFor: 5,
            providesTags: ['GroupStudents'],
        }),
        fetchUserGroupManageStudents: build.query({
            query: ({ spaceId, groupId, ...params }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/students/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
            keepUnusedDataFor: 5,
            providesTags: ['GroupStudents'],
        }),
        fetchUserGroupCourses: build.query({
            query: (params) => ({
                url: `/search/courses/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
            providesTags: ['GroupCourses'],
        }),
        removeUserGroupStudents: build.mutation({
            query: ({ spaceId, groupId, ...body }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/remove-student/`,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 5,
            invalidatesTags: ['GroupStudents'],
        }),
        removeUserGroupCourses: build.mutation({
            query: ({ spaceId, groupId, ...body }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/remove-courses/`,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 5,
            invalidatesTags: ['GroupCourses'],
        }),
        updateUserGroup: build.mutation({
            query: ({ spaceId, groupId, ...body }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Group'],
        }),
        addUserGroupStudents: build.mutation({
            query: ({ spaceId, groupId, ...body }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/add-student/`,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 5,
            invalidatesTags: ['GroupStudents'],
        }),
        addUserGroupCourses: build.mutation({
            query: ({ spaceId, groupId, ...body }) => ({
                url: `/user-part/${spaceId}/groups/${groupId}/add-courses/`,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 5,
            invalidatesTags: ['GroupCourses'],
        }),
    }),
});

export const {
    useFetchUserGroupsQuery,
    useLazyFetchUserGroupsQuery,
    useFetchUserSearchGroupsQuery,
    useLazyFetchUserSearchGroupsQuery,
    useFetchUserGroupQuery,
    useAddUserGroupMutation,
    useRemoveUserGroupMutation,
    useFetchUserGroupStudentsQuery,
    useLazyFetchUserGroupStudentsQuery,
    useFetchUserGroupCoursesQuery,
    useLazyFetchUserGroupCoursesQuery,
    useRemoveUserGroupStudentsMutation,
    useRemoveUserGroupCoursesMutation,
    useUpdateUserGroupMutation,
    useAddUserGroupStudentsMutation,
    useAddUserGroupCoursesMutation,
    useFetchUserGroupManageStudentsQuery,
    useLazyFetchUserGroupManageStudentsQuery,
} = userGroupApi;
