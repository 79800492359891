import React, { useEffect } from 'react';
import { useAction } from 'helpers/hooks';
import { getSessionStorageData } from 'utils/functions';

const CountDownTimer = ({ minSecs, disabled }) => {
    const times = sessionStorage.getItem('testTime');
    console.log({ times });
    const { hours = 0, minutes = 0, seconds = 60 } = minSecs;
    const { setCheckTimeIsOver } = useAction();
    const [[hrs, mins, secs], setTime] = React.useState([
        hours,
        minutes,
        seconds,
    ]);
    let timerId = null;
    useEffect(() => {
        if (times) {
            const splitedTimes = times.split(' ');
            setTime([hrs, Number(splitedTimes[0]), Number(splitedTimes[1])]);
        }
    }, []);
    const tick = () => {
        if (hrs === 0 && mins === 0 && secs === 1) {
            setCheckTimeIsOver(true);
            sessionStorage.removeItem('testTime');
            reset();
        } else if (mins === 0 && secs === 0) {
            // setTime([hrs - 1, 59, 59]);
            clearInterval(timerId);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };

    const reset = () =>
        setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

    React.useEffect(() => {
        if (mins === 0 && secs === 0) {
            clearInterval(timerId);
        } else {
            if (!disabled) {
                timerId = setInterval(() => {
                    sessionStorage.setItem('testTime', `${mins} ${secs}`);
                    tick();
                }, 1000);
            }
        }
        return () => clearInterval(timerId);
    });

    return (
        <div>
            <p style={{ color: minutes === 0 ? '#F55C45' : '' }}>
                {mins === 0 && secs === 0
                    ? 'Время теста истекло'
                    : `${mins.toString().padStart(2, '0')}:${secs
                          .toString()
                          .padStart(2, '0')}`}
            </p>
        </div>
    );
};

export default CountDownTimer;
