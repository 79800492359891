import { nanoid } from 'nanoid';

const actions = ({ id, role, onRemove }) => [
    {
        id: nanoid(),
        text: role,
        border: true,
        disabled: true,
    },
    {
        id: nanoid(),
        text: 'ADMINS_REMOVE',
        onClick: () => onRemove(id),
    },
];

export { actions };
