import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';
import { ScrollToTop } from 'components/feature';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AppRoutes />
        </BrowserRouter>
    );
};

export default AppRouter;
