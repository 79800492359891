import React, { useState } from 'react';
import { Icon } from 'components/common';
import DialogModalCustom from 'components/common/DialogModalCustom/DialogModalCustom';
import CourseContent from 'pages/courses/CoursesDetailed/CourseContent';
import { useTranslation } from 'react-i18next';
import './index.scss';

const CoursesStudentContent = ({ materials, contentOpen, setContentOpen }) => {
    const { t } = useTranslation('common');

    return (
        <>
            <div
                className="course-content-button block-interface"
                onClick={setContentOpen}
            >
                <span className={'course-content-button__text'}>
                    {t('COURSE_CONTENT')}
                </span>
                <Icon name="course-content" />
            </div>
            <DialogModalCustom
                className={'courses-detailed-content__wrapper'}
                open={contentOpen}
                setOpen={setContentOpen}
            >
                <div className="courses-detailed-content__title">
                    {t('COURSE_CONTENT')}
                </div>
                <CourseContent data={materials} />
            </DialogModalCustom>
        </>
    );
};

export default CoursesStudentContent;
