import React from 'react';
import {
    Button,
    Heading,
    Modal,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './WarnModal.scss';
import useIsMobile from 'helpers/hooks/useIsMobile';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import { useTranslation } from 'react-i18next';

const WarnModal = ({ isOpen, setIsOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('common');
    const isMobile = useIsMobile();
    const getContent = () => (
        <>
            <Heading className={'warn-modal__title'} variant={'h4'}>
                {t('WARN_UNSAVED_TITLE')}
            </Heading>
            <Typography className={'warn-modal__desc'} variant={'body3'}>
                {t('WARN_UNSAVED_DESC')}
            </Typography>
            <div className={'warn-modal__groupButtons'}>
                <Button
                    variant={'secondary'}
                    size={'small'}
                    onClick={onConfirm}
                >
                    {t('WARN_UNSAVED_SAVE')}
                </Button>
                <Button variant={'secondary'} size={'small'} onClick={onClose}>
                    {t('WARN_UNSAVED_DOESNT_SAVE')}
                </Button>
            </div>
        </>
    );
    return isMobile ? (
        <BottomSheetCustom open={isOpen} setOpen={setIsOpen} minHeight={203}>
            {getContent()}
        </BottomSheetCustom>
    ) : (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} className={'warn-modal'}>
            {getContent()}
        </Modal>
    );
};

export default WarnModal;
