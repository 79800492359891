import React from 'react';
import {
    Dropdown,
    Heading,
    Icon,
    IconButton,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './CDropdown.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { useFindDropdownPosition } from 'helpers/hooks';

const CDropdown = ({
    icon,
    show,
    setShow,
    onShowMoreClick,
    items,
    bottomSheetTitle = '',
    bottomSheetMinHeight,
}) => {
    const { t } = useTranslation('common');
    const isMobile = useIsMobile();
    const { dropdownPosition, findDropdownPosition } =
        useFindDropdownPosition();
    return isMobile ? (
        <>
            <IconButton
                className={'coursesDropdown__iconButton'}
                onClick={onShowMoreClick}
            >
                <Icon name={icon} />
            </IconButton>
            <BottomSheetCustom
                open={show}
                setOpen={setShow}
                minHeight={bottomSheetMinHeight}
            >
                {bottomSheetTitle ? (
                    <Heading
                        className={'coursesDropdown__title'}
                        variant={'h4'}
                    >
                        {bottomSheetTitle}
                    </Heading>
                ) : null}
                <List
                    className={'coursesDropdown__list'}
                    items={items}
                    renderItem={(action) =>
                        !action.disabled ? (
                            <li
                                className={classNames('coursesDropdown__item', {
                                    divider: action.isDivider,
                                })}
                                key={action.id}
                                onClick={action.onClick}
                            >
                                <Icon
                                    className={'coursesDropdown__icon'}
                                    name={action.iconName}
                                />
                                <Typography
                                    className={'coursesDropdown__name'}
                                    variant={'body4'}
                                >
                                    {t(action.name)}
                                </Typography>
                            </li>
                        ) : null
                    }
                />
            </BottomSheetCustom>
        </>
    ) : (
        <Dropdown
            className={'coursesDropdown'}
            isShow={show}
            setIsShow={setShow}
        >
            <Dropdown.Header className={'coursesDropdown__header'}>
                <IconButton
                    className={'coursesDropdown__iconButton'}
                    onClick={(e) => {
                        findDropdownPosition(
                            (e.clientY * 100) / window.innerHeight
                        );
                        onShowMoreClick();
                    }}
                >
                    <Icon name={icon} />
                </IconButton>
            </Dropdown.Header>
            <Dropdown.Body
                className={classNames(
                    'coursesDropdown__body',
                    dropdownPosition
                )}
            >
                <List
                    className={'coursesDropdown__list'}
                    items={items}
                    renderItem={(action) =>
                        !action.disabled ? (
                            <li
                                className={classNames('coursesDropdown__item', {
                                    divider: action.isDivider,
                                })}
                                key={action.id}
                                onClick={action.onClick}
                            >
                                <Icon
                                    className={'coursesDropdown__icon'}
                                    name={action.iconName}
                                />
                                <Typography
                                    className={'coursesDropdown__name'}
                                    variant={'body4'}
                                >
                                    {t(action.name)}
                                </Typography>
                            </li>
                        ) : null
                    }
                />
            </Dropdown.Body>
        </Dropdown>
    );
};

export default CDropdown;
