import MenuProfile from 'pages/menu/MenuProfile';
import MenuList from 'pages/menu/MenuList';

const Menu = () => {
    return (
        <article className={'menu layout-main'}>
            <MenuProfile />
            <MenuList />
        </article>
    );
};

export default Menu;
