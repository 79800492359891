import { axiosRequest } from 'api/xhr';
import axios, { CancelToken } from 'axios';

export const uploadExcelFile = ({
    spaceId,
    id,
    file,
    setPercent,
    cancelRef,
}) => {
    return axiosRequest.post(
        `/course-space/${spaceId}/questions/${id}/upload/questions/`,
        file,
        {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );

                setPercent(percentCompleted);
            },
            cancelToken: new CancelToken(
                (cancel) => (cancelRef.current = cancel)
            ),
            // cancelToken: new CancelToken((cancel) => uploadCancel(cancel)),
        }
    );
};

export const getDetailTestApi = (spaceId, testId) => {
    return axiosRequest
        .get(`/course-space/${spaceId}/tests/${testId}`)
        .then((result) => result);
};

export const addQuestionApi = (data, spaceId) => {
    return axiosRequest.post(`/course-space/${spaceId}/questions/`, data);
};

export const removeQuestionApi = (spaceId, id) => {
    return axiosRequest.delete(`/course-space/${spaceId}/questions/${id}`);
};

export const changeBlockInfoApi = (spaceId, id, data) => {
    return axiosRequest.patch(
        `/course-space/${spaceId}/questions/${id}/`,
        data
    );
};

export const addFieldApi = (spaceId, data) => {
    return axiosRequest.post(`/course-space/${spaceId}/options/`, data);
};

export const changeFieldApi = (spaceId, id, data) => {
    delete data['explanation'];
    return axiosRequest.patch(`/course-space/${spaceId}/options/${id}/`, data);
};

export const moveDndApi = (spaceId, data) => {
    return axiosRequest.put(`/course-space/${spaceId}/tests/move/`, {
        criterion: data,
    });
};

export const changeTypeBlockApi = (data, spaceId, id) => {
    return axiosRequest.patch(`/course-space/${spaceId}/questions/${id}/`, {
        ...data,
    });
};

export const duplicateBlockApi = (data, spaceId, duplicate) => {
    return axiosRequest.post(
        `/course-space/${spaceId}/questions/${data.id}/duplicate-question/?id=${data.id}&marker='${duplicate}'`
    );
};

export const removeFieldApi = (spaceId, id) => {
    return axiosRequest.delete(`/course-space/${spaceId}/options/${id}/`);
};

export const updateTestApi = (spaceId, id, data) => {
    Object.keys(data).forEach((x) => (data[x] === null ? delete data[x] : x));
    return axiosRequest.patch(`/course-space/${spaceId}/tests/${id}/`, {
        ...data,
    });
};

export const removeTestApi = (spaceId, id) => {
    return axiosRequest.delete(`/course-space/${spaceId}/tests/${id}`);
};

export const fetchTestStudent = (spaceId, id, retest) => {
    const isRetest = retest ? `?retest=${Boolean(retest)}` : '';
    return axiosRequest.get(`/students/${spaceId}/tests/${id}/${isRetest}`);
};

export const studentTesting = ({ spaceId, test_id, body }) => {
    return axiosRequest.post(`/students/${spaceId}/tests/${test_id}/testing/`, {
        result: body,
    });
};

export const saveSettingTestApi = ({ spaceId, id, body }) => {
    return axiosRequest.patch(`/course-space/${spaceId}/tests/${id}/`, body);
};
