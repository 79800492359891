import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
} from '@mui/material';
import Icon from '../../../Icon/Icon';
import './index.scss';

const KnowledgeList = ({ knowledge, onAddKnowledge }) => {
    return (
        <List>
            {knowledge.map((k) => {
                const {
                    id,
                    type,
                    expansion,
                    name,
                    files,
                    isActive = false,
                } = k;
                return (
                    <ListItem
                        key={id}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginBottom: '16px',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '0 14px',
                                '&:hover': {
                                    backgroundColor: '#F7F7F7',
                                    borderRadius: '12px',
                                },
                            }}
                            onClick={(e) => onAddKnowledge(e, k)}
                        >
                            <Stack direction={'row'} width={'100%'}>
                                <ListItemIcon>
                                    <Icon name={type} />
                                </ListItemIcon>
                                <Box>
                                    {type === 'folder' ? (
                                        <>
                                            <ListItemText
                                                className={
                                                    'knowledge-list__knowledge-name'
                                                }
                                            >
                                                {expansion.length > 0
                                                    ? expansion
                                                    : 'Папка'}
                                            </ListItemText>
                                            <ListItemText
                                                className={
                                                    'knowledge-list__knowledge-name'
                                                }
                                            >
                                                {name}
                                            </ListItemText>
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText
                                                className={
                                                    'knowledge-list__knowledge-name'
                                                }
                                            >
                                                {name}
                                            </ListItemText>
                                            <ListItemText>
                                                {expansion}
                                            </ListItemText>
                                        </>
                                    )}
                                </Box>
                            </Stack>
                            {type === 'folder' && (
                                <ListItemIcon>
                                    <Icon name={'arrow-down'} />
                                </ListItemIcon>
                            )}
                        </Box>
                        {isActive && type === 'folder' && (
                            <List style={{ width: '100%', paddingLeft: 16 }}>
                                {files.map((file) => (
                                    <ListItem
                                        key={file.id}
                                        onClick={(e) => onAddKnowledge(e, file)}
                                    >
                                        <Stack direction={'row'} width={'100%'}>
                                            <ListItemIcon>
                                                <Icon name={'file'} />
                                            </ListItemIcon>
                                            <Box>
                                                <ListItemText
                                                    className={
                                                        'knowledge-list__knowledge-name'
                                                    }
                                                >
                                                    {file.name}
                                                </ListItemText>
                                                <ListItemText>
                                                    {file.expansion}
                                                </ListItemText>
                                            </Box>
                                        </Stack>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </ListItem>
                );
            })}
        </List>
    );
};

export default KnowledgeList;
