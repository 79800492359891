import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
    spaceApi,
    courseApi,
    courseInjectionApi,
    authApi,
    profileApi,
    studentCourseApi,
    userStudentApi,
    userMentorApi,
    userGroupApi,
    userApi,
} from 'api/services';
import rootReducer from 'store/reducers';
import { searchCourseApi } from 'api/services/SearchCourseService/SearchCourseService';
import { notificationApi } from 'api/services/NotificationService/NotificationsService';
import { analyticsApi } from 'api/services/AnalyticsService/AnalyticsService';

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            authApi.middleware,
            spaceApi.middleware,
            courseApi.middleware,
            courseInjectionApi.middleware,
            searchCourseApi.middleware,
            profileApi.middleware,
            studentCourseApi.middleware,
            userStudentApi.middleware,
            userMentorApi.middleware,
            userGroupApi.middleware,
            userApi.middleware,
            notificationApi.middleware,
            analyticsApi.middleware
        ),
});

setupListeners(store.dispatch);
