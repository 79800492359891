import './SettingsColorsMainBackgroundSample.scss';
import {
    Heading,
    Icon,
    IconButton,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import Logo from 'assets/img/png/space.png';
import Avatar from 'assets/img/png/avatar.png';
import ArrowBack from 'components/ArrowBack/ArrowBack';
import { useTranslation } from 'react-i18next';
import { pickTextColorBasedOnBgColor } from 'utils/functions';
const SettingsColorsMainBackgroundSample = ({
    backgroundColor = '#ffffff',
}) => {
    const { t } = useTranslation('common');
    return (
        <div
            className={'settings-colors-main-background-sample'}
            style={{ backgroundColor }}
        >
            <IconButton
                className={'settings-colors-main-background-sample__icon'}
            >
                <ArrowBack />
            </IconButton>
            <div className={'settings-colors-main-background-sample__left'}>
                <Icon name={'menu'} />
                <img
                    className={'settings-colors-main-background-sample__logo'}
                    src={Logo}
                    alt={'Classlytics'}
                />
                <Typography
                    className={'settings-colors-main-background-sample__title'}
                    variant={'body4'}
                    style={{
                        color: pickTextColorBasedOnBgColor(backgroundColor),
                    }}
                >
                    Ernst & Young
                </Typography>
                <Icon
                    className={'settings-colors-main-background-sample__arrow'}
                    name={'arrow-down'}
                />
            </div>
            <Heading
                className={'settings-colors-main-background-sample__text'}
                variant={'h4'}
                style={{
                    color: pickTextColorBasedOnBgColor(backgroundColor),
                }}
            >
                {t('SETTINGS_BACKGROUND_MENU_COLOR_TITLE')}
            </Heading>
            <div className={'settings-colors-main-background-sample__right'}>
                <IconButton disabled={true}>
                    <Icon name={'eye'} />
                </IconButton>
                <Icon name={'chat'} />
                <Icon name={'notification'} />
                <img src={Avatar} alt="avatar" />
                <Icon name={'arrow-down'} />
            </div>
            <IconButton
                className={'settings-colors-main-background-sample__icon'}
            >
                <Icon name={'edit'} />
            </IconButton>
        </div>
    );
};

export default SettingsColorsMainBackgroundSample;
