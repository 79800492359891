import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
const firebaseConfig = {
    apiKey: 'AIzaSyBvauL38FqmNVKcx-VNqVUtQxg3XdtfIH8',
    authDomain: 'new-web-project-2380e.firebaseapp.com',
    databaseURL:
        'https://new-web-project-2380e-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'new-web-project-2380e',
    storageBucket: 'new-web-project-2380e.appspot.com',
    messagingSenderId: '506114786142',
    appId: '1:506114786142:web:51e72167504de7d4c045cf',
    measurementId: 'G-YZ0271N4GB',
};
export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        return window.navigator.serviceWorker
            .getRegistration('/firebase-push-notification-scope')
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register(
                    '/firebase-messaging-sw.js',
                    {
                        scope: '/firebase-push-notification-scope',
                    }
                );
            });
    }
    throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
    getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
        getToken(messaging, {
            vapidKey:
                'BNr03xrwPNCX-987aSzmH05utav8UcfPHuZQEvCtoOrcNZluUi-aOqcN6YK-Qc5RVPzb2ete-QQAF6Nf2RcLF5k',
            serviceWorkerRegistration,
        })
    );

export const onForegroundMessage = () => {
    return new Promise((resolve) =>
        onMessage(messaging, (payload) => resolve(payload))
    );
};
