import { useState } from 'react';
import { actions } from './consts';
import {
    Checkbox,
    Dropdown,
    Heading,
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import CourseImg from 'assets/img/png/img.png';
import classNames from 'classnames';
import './StudentDetailCourseItem.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'helpers/hooks';

const StudentDetailCourseItem = (props) => {
    const { id, image, name, isSelected, onSelected, onRemove } = props;
    const { t } = useTranslation('common');
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState(false);

    const onNavigateToCourse = () => {
        navigate(`/content/courses/${id}`);
    };
    const onShow = (e) => {
        e.stopPropagation();
        setIsShow((prev) => !prev);
    };
    const onContainerSelected = () => {
        if (width < 769) {
            onNavigateToCourse();
        } else {
            onSelected({
                target: { checked: !isSelected, value: id },
            });
        }
    };

    return (
        <div
            className={classNames('student-course-detail-item', {
                selected: isSelected,
            })}
            onClick={onContainerSelected}
        >
            <Checkbox
                className={'student-course-detail-item__checkbox'}
                value={id}
                checked={isSelected}
                onChange={onSelected}
            />
            <img
                className={'student-course-detail-item__img'}
                src={image?.length > 0 ? image : CourseImg}
                alt={name}
                onClick={onNavigateToCourse}
            />
            <div className={'student-course-detail-item__content'}>
                <Heading
                    className={'student-course-detail-item__name'}
                    variant={'h5'}
                >
                    {name}
                </Heading>
                <Typography
                    className={'student-course-detail-item__info'}
                    variant={'body4'}
                >
                    {t('PASSES')}
                    {/*Проходит: 12% • 12 сен. в 11:00*/}
                </Typography>
            </div>
            <Dropdown
                className={'student-course-detail-item__dropdown'}
                isShow={isShow}
                setIsShow={setIsShow}
            >
                <Dropdown.Header>
                    <Icon name={'more'} onClick={onShow} />
                </Dropdown.Header>
                <Dropdown.Body>
                    <List
                        className={'student-course-detail-item__actions'}
                        items={actions({ id, onNavigateToCourse, onRemove })}
                        renderItem={({ id, text, border, onClick }) => (
                            <li
                                className={classNames(
                                    'student-course-detail-item__action',
                                    { border }
                                )}
                                key={id}
                                onClick={onClick}
                            >
                                <Typography variant={'body4'}>
                                    {t(text)}
                                </Typography>
                            </li>
                        )}
                    />
                </Dropdown.Body>
            </Dropdown>
        </div>
    );
};

export default StudentDetailCourseItem;
