import React from 'react';
import defaultAvatar from 'assets/img/png/img.png';
import useFormatDate from 'helpers/hooks/useFormatDate';
import DetailCourseStatisticsItem from 'pages/analytics/views/courses/DetailCourses/DetailCourseStatisticsItem';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@akhter-studios/classlytics-front-ui-components';
import { formatDate } from 'utils/functions/helpers';

const StudentDetailProfile = ({ data, courseQty, groupQty }) => {
    const { t } = useTranslation('common');
    return (
        <div className="analytics-detail-course__profile student-detail-profile">
            <div className="analytics-detail-course__profile-wrap">
                <div className="analytics-detail-course__profile-info j-c-b">
                    <div className="d-flex">
                        <Avatar
                            className={'analytics-detail-course__profile-img'}
                            src={data.avatar}
                            variant={'rounded'}
                            avatarText={`${
                                data.first_name?.charAt(0).toUpperCase() || ''
                            }${data.last_name?.charAt(0).toUpperCase() || ''}`}
                        />
                        <div className="analytics-detail-course__profile-text">
                            <div className="analytics-detail-course__profile-title">
                                {data.first_name + ' ' + data.last_name}
                            </div>
                            <div className="analytics-detail-course__profile-date d-flex">
                                <span>{data.email}</span>
                            </div>
                            <div className="analytics-detail-course__profile-date d-flex">
                                <span>{t('ANALYTICS_JOINED_SPACE')}</span>
                                <div>
                                    {formatDate(data.created_at, 'DD-MM-YYYY')}
                                </div>
                            </div>
                            <div className="analytics-detail-course__profile-date d-flex">
                                <span>{t('ANALYTICS_WAS_ONLINE')}</span>
                                <div>
                                    {formatDate(data.online_date, 'DD-MM-YYYY')}
                                </div>
                            </div>
                            <div className="analytics-detail-course__profile-statistics d-flex desktop-hidden">
                                <DetailCourseStatisticsItem
                                    title={t('INVITED_COURSES')}
                                    value={courseQty || '-'}
                                />
                                <DetailCourseStatisticsItem
                                    title={t('GROUPS')}
                                    value={groupQty || '-'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="analytics-detail-course__profile-statistics d-flex mobile-hidden">
                        <DetailCourseStatisticsItem
                            title={t('INVITED_COURSES')}
                            value={courseQty || '-'}
                        />
                        <DetailCourseStatisticsItem
                            title={t('GROUPS')}
                            value={groupQty || '-'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentDetailProfile;
