import React from 'react';
import DetailCoursesStudentsMobile from 'pages/analytics/views/courses/DetailCourses/views/students/DetailCoursesStudentsMobile';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@akhter-studios/classlytics-front-ui-components';

const AnalyticsTableMobile = ({
    body,
    headings,
    handler,
    courseDetailStudents,
    isStudent,
    testMobileTable,
}) => {
    const { t } = useTranslation('common');
    if (courseDetailStudents)
        return (
            <DetailCoursesStudentsMobile
                handler={handler}
                headings={headings}
                body={body}
                isStudent={isStudent}
            />
        );
    return (
        <section
            className={classNames('analytics-table-mobile p-20-x', {
                isStudent,
                testMobileTable,
            })}
        >
            <div className="analytics-table-mobile__list">
                {body.map((x) => {
                    return (
                        <div
                            className="analytics-table-mobile__item"
                            onClick={() =>
                                testMobileTable && handler && handler(x)
                            }
                        >
                            {!testMobileTable && (
                                <div
                                    className="analytics-table-mobile__item-name d-flex"
                                    onClick={() => handler && handler(x)}
                                >
                                    {isStudent ? (
                                        <Avatar
                                            className={
                                                'analytics-table-mobile__item-avatar'
                                            }
                                            src={x.img || x.image}
                                            variant={'rounded'}
                                            avatarText={`${
                                                x.firstName
                                                    ?.charAt(0)
                                                    .toUpperCase() || ''
                                            }${
                                                x.lastName
                                                    ?.charAt(0)
                                                    .toUpperCase() || ''
                                            }`}
                                        />
                                    ) : (
                                        (x.img || x.image) && (
                                            <img
                                                src={x.img || x.image}
                                                alt=""
                                            />
                                        )
                                    )}
                                    <div>{x.name}</div>
                                </div>
                            )}
                            <div className="analytics-table-mobile__headings">
                                {Object.keys(headings).map((el, idx) => {
                                    return (
                                        <>
                                            {testMobileTable ? (
                                                <div>
                                                    <span>
                                                        {t(headings[el])}
                                                    </span>
                                                    <div>{x[el]}</div>
                                                </div>
                                            ) : (
                                                idx !== 0 && (
                                                    <div>
                                                        <span>
                                                            {t(headings[el])}
                                                        </span>
                                                        <div>{x[el]}</div>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default AnalyticsTableMobile;
