const options = [
    { label: 'EY', value: 'EY' },
    { label: 'Inter', value: 'Inter' },
    { label: 'Roboto', value: 'Roboto' },
    { label: 'Lora', value: 'Lora' },
    { label: 'Lyon text', value: 'Lyon' },
    { label: 'Roboto mono', value: 'RobotoMono' },
    { label: 'IBM plex mono', value: 'IBM' },
];

const styles = {
    container: (base) => ({
        ...base,
        width: '100%',
    }),
    control: (provided, state) => {
        return {
            ...provided,
            paddingLeft: '16px',
            paddingBottom: '8px',
            paddingRight: '8px',
            paddingTop: '8px',
            border: 0,
            borderBottom: `1px solid ${
                !state.selectProps.inputValue && '#EDEFF0'
            }`,
            borderRadius: 0,
            boxShadow: 'none',
            '&:hover, &:focus, &:active': {
                borderColor: !state.selectProps.inputValue && '#061822',
                cursor: 'text',
            },
        };
    },
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        color: '#a8afb2',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    menuList: (provided) => ({
        ...provided,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 24px rgba(56, 166, 226, 0.08)',
        borderRadius: '12px',
    }),
    option: (provided) => ({
        ...provided,
        paddingTop: '11px',
        paddingBottom: '11px',
        paddingLeft: '16px',
        paddingRight: '16px',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '140%',
        color: '#445259',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F7F9FA',
            color: '#061822',
        },
    }),
};

export { styles, options };
