import React from 'react';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import {
    Button,
    Heading,
    Icon,
} from '@akhter-studios/classlytics-front-ui-components';
import AvatarEditor from 'react-avatar-editor';
import {
    buttons,
    ranges,
} from 'pages/courses/Course/create-course/CreateCourseCoverModal/consts';
import './CreateCourseCoverBottomSheet.scss';
import { useWindowSize } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';

const CreateCourseCoverBottomSheet = (props) => {
    const { t } = useTranslation('common');
    const {
        open,
        setOpen,
        setEditorRef,
        coverPreview,
        cover,
        value,
        onValueChange,
        onUploadOpen,
        onSave,
        isLoading,
    } = props;

    const { width } = useWindowSize();
    return (
        <BottomSheetCustom
            open={open}
            setOpen={setOpen}
            minHeight={width < 576 ? 430 : 500}
        >
            <div className={'create-course-cover-bottom-sheet'}>
                <div className={'create-course-cover-bottom-sheet__heading'}>
                    <Heading
                        className={'create-course-cover-bottom-sheet__title'}
                        variant={'h4'}
                    >
                        Редактирование обложки
                    </Heading>
                    <Icon name={'close'} onClick={() => setOpen(false)} />
                </div>
                <div className={'create-course-cover-bottom-sheet__cropper'}>
                    <AvatarEditor
                        ref={setEditorRef}
                        image={coverPreview ?? cover}
                        width={width < 576 ? 343 : 500}
                        height={width < 576 ? 193 : 250}
                        border={12}
                        color={[255, 255, 255, 0.6]}
                        scale={value.scale}
                        rotate={value.rotate}
                        crossOrigin={'anonymous'}
                    />
                </div>
                <div className={'create-course-cover-modal__ranges'}>
                    {ranges.map(({ id, text, name, min, max, step }) => (
                        <div
                            key={id}
                            className={'create-course-cover-modal__range'}
                        >
                            <div>
                                <p>{t(text)}</p>
                                <p>{value[name]}</p>
                            </div>
                            <input
                                type={'range'}
                                name={name}
                                min={min}
                                max={max}
                                step={step}
                                value={value[name]}
                                onChange={onValueChange}
                            />
                        </div>
                    ))}
                </div>
                <div className={'create-course-cover-modal__buttons'}>
                    {buttons({ onUploadOpen, onSave, isLoading }).map(
                        ({ id, variant, text, onClick, isLoading = false }) => (
                            <Button
                                key={id}
                                variant={variant}
                                size={'small'}
                                loading={isLoading}
                                onClick={onClick}
                            >
                                {t(text)}
                            </Button>
                        )
                    )}
                </div>
            </div>
        </BottomSheetCustom>
    );
};

export default CreateCourseCoverBottomSheet;
