import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyFetchSpacesQuery } from 'api/services';
import { authRequest } from 'api/xhr';
import {
    CONTENT_COURSES_ROUTE,
    CREATE_SPACE_ROUTE,
    GENERATE_TOKEN_URL,
} from 'utils/consts';
import { findDomainToRedirect } from 'utils/functions';
import { pageSize, scrollHeight, maxLengthOfScroll } from './consts';
import { Button, List } from '@akhter-studios/classlytics-front-ui-components';
import { CircularProgress } from '@mui/material';
import { SpaceCard } from '../../views';
import InfiniteScroll from 'react-infinite-scroll-component';
import './SpaceList.scss';
import { useTranslation } from 'react-i18next';
import { isEyacademycca } from 'utils/functions/findDomainToRedirect';

const SpaceList = () => {
    const { t } = useTranslation('common');
    const { token, refreshToken } = useSelector((state) => state.auth);
    const [trigger] = useLazyFetchSpacesQuery();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [spaceList, setSpaceList] = useState(null);
    const isEY = useMemo(() => isEyacademycca(), []);
    const onNavigateToCreateSpace = () => navigate(CREATE_SPACE_ROUTE);
    const onNavigate = async (domain) => {
        const { token: generateToken } = await authRequest.post(
            GENERATE_TOKEN_URL,
            { token, domain }
        );
        const oriDomain = findDomainToRedirect();
        if (generateToken.length > 0) {
            window.location.href = `${window.location.protocol}//${domain}.${oriDomain}${CONTENT_COURSES_ROUTE}?oauth=${generateToken}&refresh_token=${refreshToken}`;
        }
    };

    const loadMoreSpaces = async () => {
        if (!spaceList?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({ page: page + 1, page_size: pageSize });
        const modifiedResults = [...spaceList.spaces, ...data.spaces];
        setSpaceList({ spaces: modifiedResults, hasMore: data.hasMore });
    };

    useEffect(
        () =>
            trigger({ page, page_size: pageSize }).then(({ data }) =>
                setSpaceList(data)
            ),
        []
    );

    if (!spaceList) {
        return null;
    }
    return (
        <div className={'spaces-list'}>
            {spaceList.spaces.length > maxLengthOfScroll ? (
                <InfiniteScroll
                    className={'spaces-list__scroller'}
                    dataLength={spaceList.spaces.length}
                    next={loadMoreSpaces}
                    hasMore={spaceList.hasMore}
                    loader={
                        <div className={'spaces-list__progress'}>
                            <CircularProgress color="inherit" size={25} />
                        </div>
                    }
                    height={scrollHeight}
                >
                    {spaceList.spaces.map(
                        ({
                            id,
                            companyName,
                            role,
                            logo,
                            courseQty,
                            domain,
                        }) => (
                            <SpaceCard
                                key={id}
                                companyName={companyName}
                                role={role}
                                logo={logo}
                                courseQty={courseQty}
                                onClick={() => onNavigate(domain)}
                            />
                        )
                    )}
                </InfiniteScroll>
            ) : (
                <List
                    items={spaceList.spaces}
                    renderItem={({
                        id,
                        companyName,
                        role,
                        logo,
                        courseQty,
                        domain,
                    }) => (
                        <SpaceCard
                            key={id}
                            companyName={companyName}
                            role={role}
                            logo={logo}
                            courseQty={courseQty}
                            onClick={() => onNavigate(domain)}
                        />
                    )}
                />
            )}

            {!(isEY && spaceList?.spaces?.length) && (
                <Button
                    className={'spaces-list__btn'}
                    onClick={onNavigateToCreateSpace}
                >
                    {t('NEW_SPACE')}
                </Button>
            )}
        </div>
    );
};

export default SpaceList;
