import { useEffect, useMemo, useState } from 'react';
import { useLazyFetchSpacesQuery } from 'api/services';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authRequest } from 'api/xhr';
import {
    CONTENT_COURSES_ROUTE,
    CREATE_SPACE_ROUTE,
    GENERATE_TOKEN_URL,
} from 'utils/consts';
import {
    Icon,
    Typography,
    List,
    Button,
    Dropdown,
} from '@akhter-studios/classlytics-front-ui-components';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import Logo from 'assets/img/svg/default-logo.svg';
import './HeaderCompany.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    findDomainToRedirect,
    pickTextColorBasedOnBgColor,
} from 'utils/functions';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { isEyacademycca } from 'utils/functions/findDomainToRedirect';

const HeaderCompany = ({ className }) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState(false);
    const { token, refreshToken } = useSelector((state) => state.auth);
    const { userSpace } = useSelector((state) => state.space);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const [trigger] = useLazyFetchSpacesQuery();
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const [spaceList, setSpaceList] = useState(null);
    const onNavigateToCreateSpace = () => navigate(CREATE_SPACE_ROUTE);
    const onShow = () => setIsShow((prev) => !prev);
    const onChangeSpace = async (domain) => {
        const newDomain = domain.toString().toLowerCase();
        const { token: generateToken } = await authRequest.post(
            GENERATE_TOKEN_URL,
            { token, domain }
        );
        if (generateToken.length > 0) {
            window.location.href = `${
                window.location.protocol
            }//${newDomain}.${findDomainToRedirect()}${CONTENT_COURSES_ROUTE}?oauth=${generateToken}&refresh_token=${refreshToken}`;
        }
    };
    const isEY = useMemo(() => isEyacademycca(), []);
    const { profile } = useSelector((state) => state.profile);

    const loadMoreSpaces = async () => {
        if (!spaceList?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({ page: page + 1, page_size: pageSize });
        const modifiedResults = [...spaceList.spaces, ...data.spaces];
        setSpaceList({ spaces: modifiedResults, hasMore: data.hasMore });
    };

    useEffect(
        () =>
            userSpace &&
            trigger({ page, page_size: pageSize }).then(({ data }) =>
                setSpaceList(data)
            ),
        [userSpace]
    );
    return (
        <Dropdown
            className={classNames('header-company', className)}
            isShow={isShow}
            setIsShow={setIsShow}
        >
            <Dropdown.Header>
                <div className={'header-company__header'} onClick={onShow}>
                    <img
                        className={'header-company__img'}
                        src={userSpace?.logo ?? Logo}
                        alt={'Classlytics'}
                    />
                    <Typography
                        className={'header-company__title'}
                        variant={'body4'}
                        style={{
                            color: pickTextColorBasedOnBgColor(
                                menuBackgroundColor
                            ),
                        }}
                    >
                        {userSpace?.companyName}
                    </Typography>
                    <Icon
                        className={'header-company__icon'}
                        name={isShow ? 'arrow-up' : 'arrow-down'}
                    />
                </div>
            </Dropdown.Header>
            <Dropdown.Body className={'header-company__body'}>
                <Typography
                    className={'header-company__name'}
                    variant={'body4'}
                >
                    {t('LEARNING_SPACE')}
                </Typography>
                {/*<Divider className={"header-company__divider"}/>*/}
                {/*<Typography className={"header-company__name"} variant={"body4"}>*/}
                {/*    Админ панель*/}
                {/*</Typography>*/}
                {spaceList ? (
                    <>
                        {spaceList.spaces.length > 5 ? (
                            <InfiniteScroll
                                className={'header-company__spaces'}
                                dataLength={spaceList.spaces.length}
                                next={loadMoreSpaces}
                                hasMore={spaceList.hasMore}
                                loader={
                                    <div className={'header-company__progress'}>
                                        <CircularProgress
                                            color="inherit"
                                            size={15}
                                        />
                                    </div>
                                }
                                height={250}
                            >
                                {spaceList.spaces.map(
                                    ({ id, logo, companyName, domain }) => (
                                        <li
                                            key={id}
                                            className={classNames(
                                                'header-company__space',
                                                { picked: id === userSpace?.id }
                                            )}
                                            onClick={() =>
                                                onChangeSpace(domain)
                                            }
                                        >
                                            <Avatar
                                                className="space-img"
                                                src={logo}
                                                sx={{
                                                    bgcolor: deepOrange[500],
                                                }}
                                            >
                                                {companyName.slice(0, 1)}
                                            </Avatar>

                                            <Typography
                                                className={'space-name'}
                                                variant={'body3'}
                                            >
                                                {companyName}
                                            </Typography>
                                        </li>
                                    )
                                )}
                            </InfiniteScroll>
                        ) : (
                            <List
                                className={'header-company__spaces'}
                                items={spaceList.spaces}
                                renderItem={({
                                    id,
                                    logo,
                                    companyName,
                                    domain,
                                }) => (
                                    <li
                                        key={id}
                                        className={classNames(
                                            'header-company__space',
                                            { picked: id === userSpace?.id }
                                        )}
                                        onClick={() => onChangeSpace(domain)}
                                    >
                                        <img
                                            className={'space-img'}
                                            src={logo ?? Logo}
                                            alt={companyName}
                                        />
                                        <Typography
                                            className={'space-name'}
                                            variant={'body3'}
                                        >
                                            {companyName}
                                        </Typography>
                                    </li>
                                )}
                            />
                        )}
                    </>
                ) : null}
                {!(isEY && profile?.role === 'student') && (
                    <Button
                        className={'header-company__btn'}
                        size={'small'}
                        fullWidth
                        onClick={onNavigateToCreateSpace}
                    >
                        {t('NEW_SPACE')}
                    </Button>
                )}
            </Dropdown.Body>
        </Dropdown>
    );
};

export default HeaderCompany;
