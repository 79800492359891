import { Outlet } from 'react-router-dom';
import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';

const Invite = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <Heading className={'auth-title'} variant={'h1'}>
                {t('AUTH_INVITE_TITLE')}
            </Heading>
            <Outlet />
        </>
    );
};

export default Invite;
