import { useState } from 'react';
import SettingsItem from 'pages/settings/common/SettingsItem';
import { DEFAULT_COLOR } from 'pages/settings/consts';
import { useTranslation } from 'react-i18next';
import ColorValue from 'pages/settings/common/ColorValue';
import SettingsColorsModalWrapper from 'pages/settings/common/SettingsColors/SettingsColorsModalWrapper';
import SettingsColorsMainBackgroundSample from 'pages/settings/common/SettingsColors/SettingsColorsMainBackgroundMenu/SettingsColorsMainBackgroundSample';
import { useAction } from 'helpers/hooks';
import { useSelector } from 'react-redux';
import { useUpdateSpaceMutation } from 'api/services';

const SettingsColorsMainBackgroundMenu = () => {
    const { t } = useTranslation('common');
    const [isOpen, setIsOpen] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const [backgroundColorPreview, setBackgroundColorPreview] = useState(
        menuBackgroundColor || DEFAULT_COLOR
    );
    const { setMenuBackgroundColor } = useAction();
    const [updateSpace] = useUpdateSpaceMutation();
    const onChangeColor = (e) => setBackgroundColorPreview(e.target.value);
    const onApplyColor = async () => {
        setIsOpen(false);
        setMenuBackgroundColor(backgroundColorPreview);
        await updateSpace({
            id: userSpace.id,
            menu_background_color: backgroundColorPreview,
        });
    };
    const onResetColor = async () => {
        setMenuBackgroundColor(DEFAULT_COLOR);
        setBackgroundColorPreview(DEFAULT_COLOR);
        await updateSpace({
            id: userSpace.id,
            menu_background_color: DEFAULT_COLOR,
        });
    };
    const onOpen = () => setIsOpen((prev) => !prev);
    return (
        <>
            <SettingsItem
                title={t('SETTINGS_BACKGROUND_MENU_COLOR_TITLE')}
                description={t('SETTINGS_BACKGROUND_MENU_COLOR_DESC')}
                btnSecondaryText={t('CHANGE')}
                onReset={onResetColor}
                onApply={onOpen}
            >
                <ColorValue color={menuBackgroundColor} />
            </SettingsItem>
            <SettingsColorsModalWrapper
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={t('SETTINGS_BACKGROUND_MENU_COLOR_TITLE')}
                color={backgroundColorPreview}
                onApply={onApplyColor}
                onReset={onResetColor}
                onChange={onChangeColor}
                sampleComponent={
                    <SettingsColorsMainBackgroundSample
                        backgroundColor={backgroundColorPreview}
                    />
                }
            />
        </>
    );
};

export default SettingsColorsMainBackgroundMenu;
