import React, { useEffect, useMemo, useState } from 'react';
import { Editor, Sidebar } from 'components/common';
import { useParams } from 'react-router-dom';
import {
    useFetchLessonQuery,
    useGetLessonMutation,
    useUpdateCourseLessonMutation,
} from 'api/services';
import useEditor from 'helpers/hooks/useEditor';
import { useSavingStatus } from 'components/layout/SavingStatusContext';
import { useSelector } from 'react-redux';
import { getEditorJsTools, getTools } from 'components/common/Editor/constants';
import { TextareaAutosize } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { onlyCenterMinHeight } from 'utils/functions/helpers';
import { useDebounce } from 'helpers/hooks';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import classnames from 'classnames';

const LessonCreate = ({ fetchStudentURL, readOnlyStudent }) => {
    const previewStatus = usePreview();
    const { t } = useTranslation('common');
    const { changeStatus } = useSavingStatus();
    const { lessonId } = useParams();
    const { courseId } = useParams();
    const [updateLesson] = useUpdateCourseLessonMutation();
    const { userSpace } = useSelector((state) => state.space);
    const { lesson, dataUpdated } = useSelector((state) => state.lesson);
    const sameLesson = useMemo(
        () => lessonId === lesson?.id,
        [lessonId, lesson?.id]
    );
    const [fetchLesson, { isLoading }] = useGetLessonMutation();

    const [lessonName, setLessonName] = useState('');

    const debouncedLessonName = useDebounce(lessonName, 700);

    useEffect(() => {
        if ((!sameLesson && userSpace) || dataUpdated) {
            fetchLesson({
                spaceId: userSpace?.id,
                lessonId: lessonId,
                fetchStudentURL,
            });
        }
    }, []);
    useEffect(() => {
        if (lesson?.name !== lessonName) {
            if (debouncedLessonName) {
                updateLesson({
                    spaceId: userSpace?.id,
                    lessonId: lessonId,
                    data: {
                        name: debouncedLessonName,
                    },
                });
            }
        }
    }, [debouncedLessonName]);
    const onUpdate = async (savedData) => {
        if (fetchStudentURL) return;
        changeStatus(t('GENERAL_SAVING'));
        try {
            await updateLesson({
                spaceId: userSpace?.id,
                lessonId: lessonId,
                data: {
                    name: lessonName,
                    blocks: savedData.blocks,
                },
            });
        } catch (e) {
            console.error(e);
        } finally {
            changeStatus(null);
        }
    };

    useEffect(() => {
        if (lesson?.name) {
            setLessonName(lesson.name);
        }
    }, [lesson?.name]);

    const { childRef, onEditorChange, outerSave } = useEditor({
        isDisabled: Boolean(fetchStudentURL),
    });
    if (isLoading || !sameLesson) {
        return (
            <div className={'layout-main'}>
                <div style={onlyCenterMinHeight(60)}>
                    {t('GENERAL_LOADING')}
                </div>
            </div>
        );
    }
    return (
        <>
            <div className={'layout-main'}>
                <div
                    className={classnames('lesson draft', {
                        'lesson--draft': previewStatus === PREVIEW_STATUS.draft,
                    })}
                >
                    <div className={'lesson__content'}>
                        <TextareaAutosize
                            value={lessonName}
                            onChange={(e) => setLessonName(e.target.value)}
                            name="lesson-title"
                            id="lesson-title"
                            rows="1"
                            disabled={readOnlyStudent}
                            className={'lesson__title'}
                            placeholder={t('LESSON_NAME')}
                            maxLength={200}
                        />
                        <Editor
                            courseId={courseId}
                            tools={getTools(
                                [
                                    'embedPDF',
                                    'embedDOC',
                                    'header',
                                    'table',
                                    'strikethrough',
                                    'embed',
                                    'list',
                                    'linkTool',
                                    'code',
                                    'inlineCode',
                                    'quote',
                                    'underline',
                                    'delimiter',
                                    'customMarker',
                                    'Color',
                                    'image',
                                    'attaches',
                                    'knowledge',
                                    'video',
                                    'toggleList',
                                    'audio',
                                ],
                                getEditorJsTools(t, userSpace?.id, courseId)
                            )}
                            readOnly={readOnlyStudent}
                            isDisabled={fetchStudentURL}
                            ref={childRef}
                            initialData={lesson}
                            onChange={onEditorChange}
                            onSave={onUpdate}
                            outsideSave={outerSave}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LessonCreate;
