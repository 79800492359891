import React, { memo, useRef, useState } from 'react';

import {
    DefaultInput,
    Radio,
    Checkbox,
} from '@akhter-studios/classlytics-front-ui-components';
import './BlockItem.scss';
import { Icon } from 'components/common';
import AddTest from 'pages/course-test/common/AddTest/AddTest';
import { Container, Draggable } from 'react-smooth-dnd';
import List from 'pages/course-test/views/List/List';
import { blockSettingsData } from 'pages/course-test/functions';
import classNames from 'classnames';
import { testStateField } from 'utils/functions/helpers';
import ResultBlockTest from 'pages/course-test/views/StudentTest/ResultBlockTest';
import useAutoSizeTextarea from 'helpers/hooks/useAutoSizeTextarea';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import BlockField from 'pages/course-test/views/BlockItem/BlockField/BlockField';
import { useTranslation } from 'react-i18next';

const BlockItem = ({
    type,
    variants,
    onDrop,
    addField,
    addDescription,
    index,
    id,
    duplicateBlock,
    item,
    onChangeBlockInfo,
    handleChangeField,
    handleChangeLabel,
    deleteBlock,
    spaceId,
    onChangeDebounce,
    onChangeDebounceLabel,
    changeTypeBlock,
    removeField,
    isStudent,
    isCorrect,
    isStudentReadOnly,
}) => {
    const previewStatus = usePreview();
    const [openSetting, setOpenSetting] = useState(false);
    const refs = {
        title: useRef(null),
        description: useRef(null),
    };
    const handleChange = (e) => {
        onChangeBlockInfo(e, index);
        onChangeDebounce(e, index);
    };
    const { t } = useTranslation('common');
    useAutoSizeTextarea(refs.title.current, item.question);
    useAutoSizeTextarea(refs.description.current, item.description);
    const roleKey = isStudent ? 'choice' : 'correctChoice';
    return (
        <div className="block-item" key={`${id}${index}`}>
            <div className="block-item-top__wrapper">
                <div className="block-item-input__wrapper">
                    <textarea
                        ref={refs.title}
                        disabled={
                            isStudent || previewStatus !== PREVIEW_STATUS.draft
                        }
                        placeholder={t('MENTOR_TEST_ADD_QUESTION_NAME')}
                        value={item.question}
                        className={classNames({
                            isDuplicate: item.isDuplicate,
                        })}
                        name="question"
                        onChange={(e) =>
                            e.target.value.length < 150 && handleChange(e)
                        }
                    />

                    {item.existDescription && (
                        <textarea
                            className="block-item-description"
                            ref={refs.description}
                            placeholder={t('ENTER_COURSE_DESCRIPTION')}
                            disabled={
                                isStudent ||
                                previewStatus !== PREVIEW_STATUS.draft
                            }
                            value={item.description}
                            name="description"
                            onChange={handleChange}
                        />
                    )}
                </div>
                {!isStudent && previewStatus === PREVIEW_STATUS.draft && (
                    <div className="block-item-field__right">
                        <div className="block-item__icons">
                            <Icon name="plus" handler={() => addField(id)} />
                            <Icon
                                name="more"
                                handler={() => setOpenSetting(true)}
                            />
                        </div>
                        {openSetting && (
                            <List
                                data={blockSettingsData({
                                    duplicateBlock,
                                    addDescription,
                                    deleteBlock,
                                    item,
                                    spaceId,
                                    changeTypeBlock,
                                    index,
                                })}
                                setOpen={setOpenSetting}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className="block-item__fields">
                <Container
                    lockAxis="y"
                    dragHandleSelector=".column-drag-handle"
                    nonDragAreaSelector={
                        isStudent ? '.block-item__fields-wrap' : ''
                    }
                    onDrop={(res) =>
                        previewStatus === PREVIEW_STATUS.draft
                            ? onDrop(res, { variants, id })
                            : {}
                    }
                >
                    {variants?.map((x, childIndex) => {
                        return (
                            <Draggable key={x.id}>
                                <BlockField
                                    type={type}
                                    x={x}
                                    isStudent={isStudent}
                                    index={index}
                                    item={item}
                                    roleKey={roleKey}
                                    handleChangeField={handleChangeField}
                                    isReadOnly={
                                        isStudentReadOnly ||
                                        (isStudent
                                            ? false
                                            : previewStatus !==
                                              PREVIEW_STATUS.draft)
                                    }
                                    handleChangeLabel={handleChangeLabel}
                                    childIndex={childIndex}
                                    onChangeDebounceLabel={
                                        onChangeDebounceLabel
                                    }
                                    removeField={removeField}
                                />
                            </Draggable>
                        );
                    })}
                </Container>
            </div>
            {!isStudent && previewStatus === PREVIEW_STATUS.draft && (
                <div className="block-item__action">
                    <AddTest
                        title={t('MENTOR_TEST_ADD_QUESTION_ANSWER')}
                        handler={() => addField(id)}
                    />
                </div>
            )}
            {isStudent && item.hasOwnProperty('isCorrect') && (
                <ResultBlockTest isCorrect={isCorrect} />
            )}
        </div>
    );
};

export default memo(BlockItem);
