const AUTH_ROUTE = '/auth';
const AUTH_LOGIN_ROUTE = '/auth/login';
const AUTH_REGISTER_ROUTE = '/auth/register';
const AUTH_FORGOT_ROUTE = '/auth/forgot';
const AUTH_VERIFICATION_ROUTE = '/auth/verification';
const AUTH_INVITE_ROUTE = '/auth/invite';
const AUTH_CONFIRM_ROUTE = '/auth/confirm';
const AUTH_DONE_ROUTE = '/auth/done';
const LOGIN_ROUTE = '/login';
const REGISTER_ROUTE = '/register/*';
const VERIFICATION_ROUTE = '/verification';
const FORGOT_ROUTE = '/forgot';
const PASSWORD_ROUTE = '/password';
const CONFIRM_ROUTE = '/confirm';
const DONE_ROUTE = '/done';
const CONTENT_ROUTE = '/content';
const PREVIEW = '/preview';
const MAIN_ROUTE = '/main';
const BASE_ROUTE = '/documents';
const CREATE_COURSE_ROUTE = '/courses/create';
const ABOUT_COMPANY_ROUTE = '/about-company';
const CONFIRMATION_ROUTE = '/confirmation';
const INVITE_ROUTE = '/invite/*';
const CONTENT_KNOWLEDGE_BASE_ROUTE = '/content/knowledge/base';
const CONTENT_MAIN_ROUTE = '/content/main';
const SPACES_ROUTE = '/spaces';
const CREATE_SPACE_ROUTE = '/spaces/create';
const COURSES_ROUTE = '/courses';
const CONTENT_COURSES_ROUTE = '/content/courses';
const LESSON = '/lesson/:id';
const LESSON_STUDENT = '/lesson/:id/space/:spaceId';
const FAVORITES_ROUTE = '/favorites';
const CONTENT_FAVORITES_ROUTE = '/content/favorites';
const CALENDAR_ROUTE = '/calendar';
const CONTENT_CALENDAR_ROUTE = '/content/calendar';
const ANALYTICS_ROUTE = '/analytics';
const CONTENT_ANALYTICS_ROUTE = '/content/analytics';
const DOCUMENTS_ROUTE = '/documents';
const CONTENT_DOCUMENTS_ROUTE = '/content/knowledge/base/';
const SETTINGS_ROUTE = '/settings';
const CONTENT_SETTINGS_ROUTE = '/content/settings';
const CHAT_ROUTE = '/chat';
const CONTENT_CHAT_ROUTE = '/content/chat';
const NOTIFICATION_ROUTE = '/notification';
const CONTENT_NOTIFICATION_ROUTE = '/content/notification';
const PROFILE_ROUTE = '/profile';
const CONTENT_PROFILE_ROUTE = '/content/profile';
const USERS_ROUTE = '/users';
const CONTENT_USERS_ROUTE = '/content/users';
const USER_DETAILS_ROUTE = '/users/:id';
const CONTENT_USER_DETAILS_ROUTE = '/content/users/:id';
const CONTENT_COURSES_DETAILED_ROUTE = '/courses/detailed/:spaceId/:id';
const CONTENT_COURSES_TEST_STUDENT = '/student/test/:testId/space/:spaceId';

export {
    AUTH_ROUTE,
    AUTH_LOGIN_ROUTE,
    PASSWORD_ROUTE,
    LOGIN_ROUTE,
    REGISTER_ROUTE,
    VERIFICATION_ROUTE,
    FORGOT_ROUTE,
    CONTENT_ROUTE,
    PREVIEW,
    BASE_ROUTE,
    CREATE_COURSE_ROUTE,
    AUTH_REGISTER_ROUTE,
    AUTH_FORGOT_ROUTE,
    AUTH_VERIFICATION_ROUTE,
    ABOUT_COMPANY_ROUTE,
    CONFIRMATION_ROUTE,
    INVITE_ROUTE,
    AUTH_INVITE_ROUTE,
    CONTENT_KNOWLEDGE_BASE_ROUTE,
    CONTENT_MAIN_ROUTE,
    MAIN_ROUTE,
    SPACES_ROUTE,
    CONFIRM_ROUTE,
    AUTH_CONFIRM_ROUTE,
    DONE_ROUTE,
    AUTH_DONE_ROUTE,
    COURSES_ROUTE,
    CONTENT_COURSES_ROUTE,
    FAVORITES_ROUTE,
    CONTENT_FAVORITES_ROUTE,
    ANALYTICS_ROUTE,
    CONTENT_ANALYTICS_ROUTE,
    DOCUMENTS_ROUTE,
    CONTENT_DOCUMENTS_ROUTE,
    CALENDAR_ROUTE,
    CONTENT_CALENDAR_ROUTE,
    SETTINGS_ROUTE,
    CONTENT_SETTINGS_ROUTE,
    CHAT_ROUTE,
    CONTENT_CHAT_ROUTE,
    NOTIFICATION_ROUTE,
    CONTENT_NOTIFICATION_ROUTE,
    PROFILE_ROUTE,
    CONTENT_PROFILE_ROUTE,
    USERS_ROUTE,
    CONTENT_USERS_ROUTE,
    LESSON,
    LESSON_STUDENT,
    CONTENT_COURSES_DETAILED_ROUTE,
    CONTENT_COURSES_TEST_STUDENT,
    CREATE_SPACE_ROUTE,
    USER_DETAILS_ROUTE,
    CONTENT_USER_DETAILS_ROUTE,
};
