import { useEffect, useState } from 'react';

const useAutosizeTextArea = (textAreaRef, value) => {
    const [rerender, setRerenderer] = useState(false);
    useEffect(() => {
        setRerenderer(true);
        if (textAreaRef) {
            textAreaRef.style.height = `0px`;
            const scrollHeight = textAreaRef.scrollHeight;

            textAreaRef.style.height = scrollHeight + 'px';
        }
    }, [textAreaRef, value, rerender]);
};

export default useAutosizeTextArea;
