import React from 'react';
import {
    Dropdown,
    Icon,
    List,
    Search,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { actions } from 'pages/users/common/UsersTable/UsersTableFilter/consts';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const UsersFilterSearch = ({
    searchPlaceholder,
    search,
    onSearch,
    isFilter,
    setIsShow,
    selectedAction,
    onShow,
    isShow,
    onFilter,
    totalCount = 0,
    totalPlaceholder,
    otherActions,
}) => {
    const { t } = useTranslation('common');

    return (
        <section className="users-table-filter">
            <div className="d-flex users-table-filter__all">
                {' '}
                <Typography variant={'body4'} style={{ whiteSpace: 'nowrap' }}>
                    <span>{totalPlaceholder || t('TOTAL')}:</span> {totalCount}
                </Typography>
            </div>
            <Search
                placeholder={searchPlaceholder}
                value={search}
                onChange={onSearch}
                fullWidth={true}
            />
            {isFilter && (
                <Dropdown
                    isShow={isShow}
                    setIsShow={setIsShow}
                    className={'users-table-filter__dropdown'}
                >
                    <Dropdown.Header>
                        <div
                            className={'users-table-filter__select'}
                            onClick={onShow}
                        >
                            <Typography variant={'body4'}>
                                {t(selectedAction.text)}
                            </Typography>
                            <Icon name={`arrow-${isShow ? 'up' : 'down'}`} />
                        </div>
                    </Dropdown.Header>
                    <Dropdown.Body className={'dropdown__body'}>
                        <List
                            className={'dropdown__list'}
                            items={otherActions || actions}
                            renderItem={({ id, text, border, type }) => (
                                <li
                                    key={id}
                                    className={classNames('dropdown__item', {
                                        border,
                                    })}
                                    onClick={() => onFilter({ type, text })}
                                >
                                    <Typography variant={'body4'}>
                                        {t(text)}
                                    </Typography>
                                </li>
                            )}
                        />
                    </Dropdown.Body>
                </Dropdown>
            )}
        </section>
    );
};

export default UsersFilterSearch;
