import React from 'react';
import { formatBytes } from 'helpers/functions';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/functions/helpers';

const AboutFileContent = ({ aboutFile }) => {
    const { t } = useTranslation('common');
    if (!aboutFile) return <div>{t('GENERAL_LOADING')}</div>;
    return (
        <div className="about-file-content">
            <h5>{t('BASE_MATERIAL_INFO_TITLE')}</h5>
            <ul className="about-file-content__list">
                <li>
                    <span>{t('BASE_MATERIAL_CREATION_DATE')}</span>
                    <div>{formatDate(aboutFile.created)}</div>
                </li>
                <li>
                    <span>{t('BASE_MATERIAL_AUTHOR')}</span>
                    <div>{aboutFile.author}</div>
                </li>
                {aboutFile.type === 'folder' && (
                    <li>
                        <span>{t('BASE_MATERIAL_CONSISTS')}</span>
                        <div>{aboutFile.qt}</div>
                    </li>
                )}
                <li>
                    <span>{t('BASE_MATERIAL_SIZE')}</span>
                    <div>{formatBytes(aboutFile.size)}</div>
                </li>
            </ul>
        </div>
    );
};

export default AboutFileContent;
