import React, { useMemo } from 'react';
import './PreviewLayout.scss';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { SavingStatusProvider } from 'components/layout/SavingStatusContext';
import {
    PREVIEW_STATUS,
    PreviewProvider,
} from 'components/layout/PreviewContext';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFetchLessonQuery } from 'api/services';

const PreviewLayout = ({ children }) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const { lessonId, testId } = useParams();
    const { data: lesson } = useFetchLessonQuery(
        {
            spaceId: userSpace?.id,
            lessonId: lessonId,
        },
        { skip: !userSpace || !lessonId }
    );
    const [searchParams] = useSearchParams();
    const previewStatusSearchParam = useMemo(
        () => searchParams.get('previewStatus'),
        [searchParams]
    );
    const goBack = () => {
        if (lessonId || testId) {
            let path = `/content/courses${
                previewStatusSearchParam === PREVIEW_STATUS.draft
                    ? '/create'
                    : ''
            }/${lesson?.courseId}`;
            navigate(path);
        } else {
            navigate(-1);
        }
    };
    const navigate = useNavigate();
    return (
        <div className={'preview-layout'}>
            <PreviewProvider previewStatus={PREVIEW_STATUS.preview}>
                <SavingStatusProvider>
                    <header className={'preview-layout__header'}>
                        <Button
                            variant={'secondary'}
                            onClick={goBack}
                            size={'medium'}
                        >
                            {t('RETURN_FROM_PREVIEW')}
                        </Button>
                    </header>
                    <main className={'preview-layout__main'}>{children}</main>
                </SavingStatusProvider>
            </PreviewProvider>
        </div>
    );
};

export default PreviewLayout;
