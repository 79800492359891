import {nanoid} from "nanoid";
import Img1 from "assets/img/png/admin-main/img.png";
import Img2 from "assets/img/png/admin-main/img_1.png";
import Img3 from "assets/img/png/admin-main/img_2.png";
import Img4 from "assets/img/png/admin-main/img_3.png";
import Img5 from "assets/img/png/admin-main/img_4.png";
import Img6 from "assets/img/png/admin-main/img_5.png";
import Img7 from "assets/img/png/admin-main/img_6.png";

const histories = [
    {
        id: nanoid(),
        image: Img1,
        isPreview: true
    },
    {
        id: nanoid(),
        image: Img2,
        isPreview: true
    },
    {
        id: nanoid(),
        image: Img3,
        isPreview: true
    },
    {
        id: nanoid(),
        image: Img4,
        isPreview: false
    },
    {
        id: nanoid(),
        image: Img5,
        isPreview: false
    },
    {
        id: nanoid(),
        image: Img6,
        isPreview: false
    },
    {
        id: nanoid(),
        image: Img7,
        isPreview: false
    },
    {
        id: nanoid(),
        image: Img1,
        isPreview: true
    },
    {
        id: nanoid(),
        image: Img2,
        isPreview: true
    },
    {
        id: nanoid(),
        image: Img3,
        isPreview: true
    },
    {
        id: nanoid(),
        image: Img4,
        isPreview: false
    },
    {
        id: nanoid(),
        image: Img5,
        isPreview: false
    },
    {
        id: nanoid(),
        image: Img6,
        isPreview: false
    },
    {
        id: nanoid(),
        image: Img7,
        isPreview: false
    }
];

export {histories};