import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import {
    Icon,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './MenuItem.scss';

const MenuItem = ({ icon, name, to, isDivider, isHideActive, onClick }) => {
    const { t } = useTranslation('common');

    const onContainerClick = () => {
        onClick && onClick();
    };
    return (
        <NavLink
            to={to}
            onClick={onContainerClick}
            className={({ isActive }) =>
                classNames('menu-item', {
                    active: isActive && !isHideActive,
                    isDivider,
                })
            }
        >
            <Icon name={icon} />
            <Typography className={'menu-item__name'} variant={'body3'}>
                {t(name)}
            </Typography>
        </NavLink>
    );
};

export default MenuItem;
