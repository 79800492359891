const generateYearsBetween = (startYear = 1900, endYear) => {
    const endDate = endYear || new Date().getFullYear();
    let years = [];

    for (let i = startYear; i <= endDate; i++) {
        years.push(startYear);
        startYear++;
    }
    return years;
};

export default generateYearsBetween;
