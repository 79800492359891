import { nanoid } from 'nanoid';

const actions = ({
    id,
    userId,
    isActive,
    onNavigateToUserDetails,
    onRemove,
    onCopy,
    onContainerOpenAddCoursesModal,
}) => [
    {
        id: nanoid(),
        text: 'STUDENTS_ABOUT_USER',
        isShow: true,
        onClick: onNavigateToUserDetails,
    },
    {
        id: nanoid(),
        text: 'STUDENTS_INVITE_TO_COURSE',
        border: isActive,
        isShow: true,
        onClick: () => onContainerOpenAddCoursesModal(),
    },
    {
        id: nanoid(),
        text: 'STUDENTS_COPY_EMAIL',
        border: true,
        isShow: !isActive,
        onClick: onCopy,
    },
    // {
    //     id: nanoid(),
    //     text: "Переотправить приглашение",
    //     border: true,
    //     isShow: !isActive,
    //     onClick: () => onAddCourses(id)
    // },
    {
        id: nanoid(),
        text: 'STUDENTS_REMOVE_USER',
        isShow: true,
        onClick: () => onRemove(userId || id),
    },
];

export { actions };
