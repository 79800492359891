import { createSlice } from '@reduxjs/toolkit';
import { userGroupApi, userStudentApi } from 'api/services';
import { nanoid } from 'nanoid';

const initialState = {
    groups: {
        results: [],
        count: [],
        hasMore: false,
    },
    groupStudents: {
        results: [],
        count: [],
        hasMore: false,
    },
    groupCourses: {
        results: [],
        count: [],
        hasMore: false,
    },
};

export const userGroupSlice = createSlice({
    name: 'userGroup',
    initialState,
    reducers: {
        setUserGroups: (state, { payload }) => {
            state.groups = payload;
        },
        setUserGroupStudents: (state, { payload }) => {
            state.groupStudents = payload;
        },
        setUserGroupCourses: (state, { payload }) => {
            state.groupCourses = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                userGroupApi.endpoints.fetchUserGroups.matchFulfilled,
                (state, { payload }) => {
                    state.groups = payload;
                }
            )
            .addMatcher(
                userGroupApi.endpoints.fetchUserSearchGroups.matchFulfilled,
                (state, { payload }) => {
                    state.groups = payload;
                }
            )
            .addMatcher(
                userGroupApi.endpoints.addUserGroup.matchFulfilled,
                (state, { meta, payload }) => {
                    const { id } = payload;
                    const { title, courses, emails } = meta.arg.originalArgs;
                    state.groups = {
                        count: state.groups.count + 1,
                        hasMore: state.groups.hasMore,
                        results: [
                            {
                                id: id || nanoid(),
                                title,
                                qtyParticipants: emails.length,
                                accessCourses: courses.length,
                            },
                            ...state.groups.results,
                        ],
                    };
                }
            )
            .addMatcher(
                userGroupApi.endpoints.removeUserGroup.matchFulfilled,
                (state, { meta, payload }) => {
                    const { groupId } = meta.arg.originalArgs;
                    state.groups.results = state.groups.results.filter(
                        (student) => student.id !== groupId
                    );
                    state.groups.count -= 1;
                }
            )
            .addMatcher(
                userGroupApi.endpoints.updateUserGroup.matchFulfilled,
                (state, { payload }) => {
                    const { id, title } = payload;
                    state.groups.results = state.groups.results.map((group) => {
                        if (group.id === id) {
                            return {
                                ...group,
                                title,
                            };
                        } else {
                            return group;
                        }
                    });
                }
            )
            .addMatcher(
                userGroupApi.endpoints.addUserGroupStudents.matchFulfilled,
                (state, { meta }) => {
                    const { groupId } = meta.arg.originalArgs;
                    state.groups.results = state.groups.results.map((group) => {
                        if (group.id === groupId) {
                            return {
                                ...group,
                                qtyParticipants: group.qtyParticipants + 1,
                            };
                        } else {
                            return group;
                        }
                    });
                }
            )
            .addMatcher(
                userGroupApi.endpoints.removeUserGroupStudents.matchFulfilled,
                (state, { meta }) => {
                    const { groupId } = meta.arg.originalArgs;
                    state.groups.results = state.groups.results.map((group) => {
                        if (group.id === groupId) {
                            return {
                                ...group,
                                qtyParticipants: group.qtyParticipants - 1,
                            };
                        } else {
                            return group;
                        }
                    });
                }
            )
            .addMatcher(
                userGroupApi.endpoints.fetchUserGroupStudents.matchFulfilled,
                (state, { payload }) => {
                    state.groupStudents = payload;
                }
            )
            .addMatcher(
                userGroupApi.endpoints.removeUserGroupStudents.matchFulfilled,
                (state, { meta }) => {
                    const { users: removeUsers } = meta.arg.originalArgs;
                    state.groupStudents.results =
                        state.groupStudents.results.filter(
                            (student) => !removeUsers.includes(student.user.id)
                        );
                    state.groupStudents.count =
                        state.groupStudents.count - removeUsers.length;
                }
            )
            .addMatcher(
                userStudentApi.endpoints.addUserStudentCourses.matchFulfilled,
                (state, { meta }) => {
                    const { studentId, courses } = meta.arg.originalArgs;
                    state.groupStudents.results =
                        state.groupStudents.results.map((student) => {
                            if (student.user.id === studentId) {
                                student.qtyCourses += courses.length;
                            }
                            return student;
                        });
                }
            )
            .addMatcher(
                userGroupApi.endpoints.fetchUserGroupCourses.matchFulfilled,
                (state, { payload }) => {
                    state.groupCourses = payload;
                }
            )
            .addMatcher(
                userGroupApi.endpoints.removeUserGroupCourses.matchFulfilled,
                (state, { meta }) => {
                    const { courses: removeCourses } = meta.arg.originalArgs;
                    state.groupCourses.results =
                        state.groupCourses.results.filter(
                            (group) => !removeCourses.includes(group.id)
                        );
                    state.groupCourses.count =
                        state.groupCourses.count - removeCourses.length;
                }
            );
    },
});

const userGroupActions = userGroupSlice.actions;
export { userGroupActions };

export default userGroupSlice.reducer;
