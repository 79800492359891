import React from 'react';
import {
    Modal,
    Heading,
    Typography,
    Button,
} from '@akhter-studios/classlytics-front-ui-components';
import './index.scss';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomLottie } from 'components/common';
import animationData from 'assets/lotties/done.json';

const PublishedModal = ({ isOpen, setIsOpen, materials }) => {
    const { t } = useTranslation('common');
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className={'published-modal'}
        >
            <CustomLottie
                className={'published-icon'}
                lottie={JSON.stringify(animationData)}
                width={144}
                height={144}
                loop={true}
            />
            {(!!materials.published.length &&
                !materials.unpublished.length &&
                !materials.deleted.length) ||
            (!materials.published.length &&
                !materials.unpublished.length &&
                !materials.deleted.length) ? (
                <Heading variant={'h2'} className={'published__title'}>
                    {t('PUBLISHED_SUCCESSFULLY')}
                </Heading>
            ) : (
                <div>
                    {!!materials.published.length && (
                        <div className={'published-materials'}>
                            <Typography
                                className={'published-materials__title'}
                                variant={'body3'}
                            >
                                {t('PUBLISHED_PUBLISHED_PAGES')}
                            </Typography>
                            <ul className={'published-materials__items'}>
                                {materials.published.map((item, idx) => (
                                    <li
                                        className={'published-materials__item'}
                                        key={idx}
                                    >
                                        <Typography variant={'body3'}>
                                            {item?.name ||
                                                t('PUBLISHED_NO_NAME')}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {!!materials.unpublished.length && (
                        <div className={'published-materials'}>
                            <Typography
                                className={'published-materials__title'}
                                variant={'body3'}
                            >
                                {t('PUBLISHED_EMPTY_PAGES')}
                            </Typography>
                            <ul className={'published-materials__items'}>
                                {materials.unpublished.map((item, idx) => (
                                    <li
                                        className={'published-materials__item'}
                                        key={idx}
                                    >
                                        <Typography variant={'body3'}>
                                            {item?.name ||
                                                t('PUBLISHED_NO_NAME')}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {!!materials.deleted.length && (
                        <div className={'published-materials'}>
                            <Typography
                                className={'published-materials__title'}
                                variant={'body3'}
                            >
                                {t('PUBLISHED_DELETED_PAGES')}
                            </Typography>
                            <ul className={'published-materials__items'}>
                                {materials.deleted.map((item, idx) => (
                                    <li
                                        className={'published-materials__item'}
                                        key={idx}
                                    >
                                        <Typography variant={'body3'}>
                                            {item?.name ||
                                                t('PUBLISHED_NO_NAME')}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
            <Stack alignItems={'center'}>
                <Button
                    variant={'primary'}
                    size={'small'}
                    onClick={() => setIsOpen(false)}
                >
                    {t('PUBLISHED_OK')}
                </Button>
            </Stack>
        </Modal>
    );
};

export default PublishedModal;
