import React from 'react';
import TestStatisticsList from 'pages/analytics/views/courses/DetailCourses/views/test/TestStatisticsList';
import { useTranslation } from 'react-i18next';

const TestStatistics = ({ data, title }) => {
    const { t } = useTranslation('common');
    return (
        <div className="analytics-test-statistics">
            <div className="analytics-test-statistics__title">{title}</div>
            <div className="analytics-test-statistics__total">
                {t('ANALYTICS_TOTAL_QUESTIONS')} {data?.length}
            </div>
            <TestStatisticsList data={data} />
        </div>
    );
};

export default TestStatistics;
