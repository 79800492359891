import { ErrorHelperText } from 'components/common';
import { components } from 'react-select';
const { Control } = components;

const CustomControl = (props) => {
    const { isError, helperText, inputValue } = props.selectProps;
    return (
        <>
            <Control {...props} />
            {isError && !inputValue ? (
                <ErrorHelperText text={helperText} />
            ) : null}
        </>
    );
};

export default CustomControl;
