const options = (t) => [
    { value: 'beginner', label: t('COURSE_SETTINGS_COMPLEXITY_BEGINNER') },
    { value: 'standard', label: t('COURSE_SETTINGS_COMPLEXITY_STANDARD') },
    { value: 'advanced', label: t('COURSE_SETTINGS_COMPLEXITY_ADVANCED') },
];

const currentComplexityLabel = {
    beginner: 'COURSE_SETTINGS_COMPLEXITY_BEGINNER',
    standard: 'COURSE_SETTINGS_COMPLEXITY_STANDARD',
    advanced: 'COURSE_SETTINGS_COMPLEXITY_ADVANCED',
};

export { options, currentComplexityLabel };
