import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { tabs } from './consts';
import { List } from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './ProfileMenu.scss';
import { ActiveLink } from 'components/common';

const ProfileMenu = () => {
    const { search: locationSearch } = useLocation();
    const { t } = useTranslation('common');
    return (
        <nav className={'profile-menu'}>
            <List
                className={'profile-menu__list'}
                items={tabs}
                renderItem={({ id, icon, text, search, to, isDivider }) => (
                    <li
                        className={classNames('profile-menu__item', {
                            divider: isDivider,
                        })}
                        key={id}
                    >
                        <ActiveLink
                            icon={icon}
                            text={t(text)}
                            active={
                                search ===
                                (locationSearch.length > 0
                                    ? locationSearch
                                    : '?scope=profile')
                            }
                            to={to}
                        />
                    </li>
                )}
            />
        </nav>
    );
};

export default ProfileMenu;
