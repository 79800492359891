export const DOMAINS = [
    { domain: 'classlytics', custom: false },
    { domain: 'eyacademycca', custom: true },
];
export const findAppIdFromDomain = (hostNameArr) =>
    hostNameArr.findIndex((f) => DOMAINS.some((dObj) => dObj.domain === f));

export const isEyacademycca = () => {
    const { host } = window.location;
    return host.split('.').includes('eyacademycca');
};

export const getPathWithOriDomain = () => {
    const { host, protocol, hostname } = window.location;
    const hostNameArr = host.split('.');
    const isLocalHost = hostname.split('.').includes('localhost');
    const isDev = hostname.split('.').includes('akhter');
    if (isLocalHost) {
        const hostName = hostNameArr[hostNameArr.length - 1];
        return `${protocol}//${hostName}`;
    } else if (isDev) {
        const appId = hostNameArr.findIndex((f) => f === 'classlytics');
        const hostName = hostNameArr.slice(appId).join('.');
        return `${protocol}//${hostName}`;
    } else {
        const appId = findAppIdFromDomain(hostNameArr);
        const hostName = hostNameArr.slice(appId).join('.');
        return `${protocol}//app.${hostName}`;
    }
};

const findDomainToRedirect = () => {
    const { host, hostname } = window.location;
    const hostNameArr = hostname.split('.');
    if (hostNameArr.includes('localhost')) {
        return host;
    } else {
        const appId = findAppIdFromDomain(hostNameArr);
        return hostNameArr.slice(appId).join('.');
    }
};

export default findDomainToRedirect;
