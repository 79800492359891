import {
    Button,
    ColorPicker,
    Heading,
    Modal,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './SettingsColorsModalWrapper.scss';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'helpers/hooks/useIsMobile';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
const SettingsColorsModalWrapper = ({
    isLoading = false,
    isOpen,
    setIsOpen,
    title,
    color,
    onChange,
    onApply,
    onReset,
    sampleComponent,
}) => {
    const { t } = useTranslation('common');
    const isMobile = useIsMobile();

    const getContent = () => (
        <>
            <Heading
                className={'settings-colors-modal-wrapper__title'}
                variant={'h2'}
            >
                {title}
            </Heading>
            <div className={'settings-colors-modal-wrapper__content'}>
                <div className={'settings-colors-modal-wrapper__sample'}>
                    <Typography className={'sample__text'} variant={'body2'}>
                        {t('SETTINGS_BACKGROUND_MENU_COLOR_SAMPLE')}
                    </Typography>
                    <div className={'sample__wrapper'}>{sampleComponent}</div>
                </div>
                <div className={'settings-colors-modal-wrapper__sample'}>
                    <Typography className={'sample__text'} variant={'body2'}>
                        {t('SETTINGS_BACKGROUND_MENU_COLOR_SPECIFY_COLOR')}
                    </Typography>
                    <ColorPicker
                        value={color}
                        onChange={onChange}
                        className={'sample__field'}
                    />
                </div>
                <div className={'settings-colors-modal-wrapper__actions'}>
                    <Button
                        variant={'secondary'}
                        size={'small'}
                        onClick={onReset}
                    >
                        {t('RESET')}
                    </Button>
                    <Button
                        loading={isLoading}
                        variant={'primary'}
                        size={'small'}
                        onClick={onApply}
                    >
                        {t('APPLY')}
                    </Button>
                </div>
            </div>
        </>
    );

    return isMobile ? (
        <BottomSheetCustom open={isOpen} setOpen={setIsOpen}>
            {getContent()}
        </BottomSheetCustom>
    ) : (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className={'settings-colors-modal-wrapper'}
        >
            {getContent()}
        </Modal>
    );
};

export default SettingsColorsModalWrapper;
