import { useSelector } from 'react-redux';
import {
    Button,
    Heading,
    Icon,
    Progress,
    Title,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { CustomSwiper } from 'components/common';
import defaultAvatar from 'assets/img/png/img.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import './StudentCoursesSwiper.scss';
import { useTranslation } from 'react-i18next';

const StudentCoursesSwiper = ({ courses, loadMore }) => {
    const navigate = useNavigate();
    const { userSpace } = useSelector((state) => state.space);
    const { t } = useTranslation('common');
    const onViewAll = () => navigate('/content/student-courses/process');
    const onDetailed = (id) =>
        navigate(`/content/courses/detailed/${userSpace?.id}/${id}`);
    return (
        <section className={'student-courses-swiper student'}>
            <CustomSwiper
                title={t('STUDENT_COURSES_IN_PROGRESS')}
                slides={courses || []}
                onViewAll={onViewAll}
                loadMore={loadMore}
                renderSlide={({
                    id,
                    image,
                    isFavorite,
                    name,
                    passed,
                    subjectCount,
                    percentage,
                }) => (
                    <div key={id} className={'student-courses-swiper__item'}>
                        <img
                            className={'item__img'}
                            src={image || defaultAvatar}
                            alt={name}
                            onClick={() => onDetailed(id)}
                        />
                        {/*<div*/}
                        {/*    className={classNames('item__fav', {*/}
                        {/*        isWhite: !isFavorite,*/}
                        {/*    })}*/}
                        {/*>*/}
                        {/*    <Icon*/}
                        {/*        name={*/}
                        {/*            isFavorite ? 'heart-favorite' : 'heart'*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <Heading className={'item__name'} variant={'h5'}>
                            {name || '-'}
                        </Heading>
                        {/*<div className={'item__percentage'}>*/}
                        {/*    <Typography variant={'body4'}>*/}
                        {/*        {subjectCount}*/}
                        {/*    </Typography>*/}
                        {/*    <Title*/}
                        {/*        className={'item__percent'}*/}
                        {/*        variant={'title3'}*/}
                        {/*    >*/}
                        {/*        {percentage}%*/}
                        {/*    </Title>*/}
                        {/*</div>*/}
                        {/*<Progress*/}
                        {/*    className={'item__progress'}*/}
                        {/*    value={percentage}*/}
                        {/*    max={100}*/}
                        {/*/>*/}
                        <Button
                            className={'item__btn'}
                            variant={'secondary'}
                            fullWidth
                            onClick={() => onDetailed(id)}
                        >
                            {passed
                                ? t('STUDENT_COURSE_COMPLETED')
                                : t('STUDENT_COURSE_CONTINUE')}
                        </Button>
                    </div>
                )}
            />
        </section>
    );
};

export default StudentCoursesSwiper;
