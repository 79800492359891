import React from 'react';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { DialogModal } from '@akhter-studios/classlytics-front-ui-components';

const NotificationWrapper = ({ children, isOpen, close }) => {
    const isMobile = useIsMobile();
    const displayContent = () => {
        if (isMobile) {
            return children;
        }
        return (
            <DialogModal setIsOpen={close} isOpen={isOpen}>
                {children}
            </DialogModal>
        );
    };
    return <section className="notifications">{displayContent()}</section>;
};

export default NotificationWrapper;
