import { baseApi } from 'api/xhr';

export const courseInjectionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        fetchCourse: build.mutation({
            query: ({ spaceId, courseId, ...params }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/`,
                method: 'GET',
                params,
            }),
        }),
        fetchCourseName: build.mutation({
            query: ({ spaceId, courseId, ...params }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/`,
                method: 'GET',
                params,
            }),
        }),
        uploadCourseCover: build.mutation({
            query: ({ spaceId, courseId, file }) => {
                const formData = new FormData();
                formData.append('image', file, file.name);
                return {
                    url: `/course-space/${spaceId}/courses/${courseId}/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
        }),
        updateCourse: build.mutation({
            query: ({ spaceId, courseId, ...body }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/`,
                method: 'PATCH',
                body,
            }),
        }),
        updateCourseName: build.mutation({
            query: ({ spaceId, courseId, ...body }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/`,
                method: 'PATCH',
                body,
            }),
        }),
        updateEducationalMaterial: build.mutation({
            query: ({ spaceId, materialId, data }) => ({
                url: `/course-space/${spaceId}/educational-materials/${materialId}/`,
                method: 'PATCH',
                body: data,
            }),
        }),
        removeEducationalMaterial: build.mutation({
            query: ({ spaceId, materialId, data }) => ({
                url: `/course-space/${spaceId}/educational-materials/${materialId}/`,
                method: 'DELETE',
                body: data,
            }),
        }),
        createMaterial: build.mutation({
            query: ({ spaceId, courseId, type, parentId }) => ({
                url: `/course-space/${spaceId}/materials/`,
                method: 'POST',
                body: {
                    courseId,
                    type,
                    parentId,
                },
            }),
        }),
        updateModule: build.mutation({
            query: ({ spaceId, moduleId, ...body }) => ({
                url: `/course-space/${spaceId}/modules/${moduleId}/`,
                method: 'PATCH',
                body,
            }),
        }),
        removeModule: build.mutation({
            query: ({ spaceId, moduleId }) => ({
                url: `/course-space/${spaceId}/modules/${moduleId}/`,
                method: 'DELETE',
            }),
        }),
        updateCourseLesson: build.mutation({
            query: ({ spaceId, lessonId, data }) => ({
                url: `/course-space/${spaceId}/lessons/${lessonId}/`,
                method: 'PATCH',
                body: data,
            }),
        }),
        updateCourseTest: build.mutation({
            query: ({ spaceId, id, data }) => ({
                url: `/course-space/${spaceId}/tests/${id}/`,
                method: 'PATCH',
                body: data,
            }),
        }),
        removeCourseLesson: build.mutation({
            query: ({ spaceId, lessonId }) => ({
                url: `/course-space/${spaceId}/lessons/${lessonId}/`,
                method: 'DELETE',
            }),
        }),
        removeCourseTest: build.mutation({
            query: ({ spaceId, id }) => ({
                url: `/course-space/${spaceId}/tests/${id}/`,
                method: 'DELETE',
            }),
        }),
        moveMaterial: build.mutation({
            query: ({ spaceId, materials }) => ({
                url: `/course-space/${spaceId}/materials/move/`,
                method: 'PUT',
                body: {
                    materials,
                },
            }),
        }),
    }),
});

export const {
    useFetchCourseMutation,
    useFetchCourseNameMutation,
    useUploadCourseCoverMutation,
    useUpdateCourseMutation,
    useUpdateCourseNameMutation,
    useUpdateEducationalMaterialMutation,
    useRemoveEducationalMaterialMutation,
    useCreateMaterialMutation,
    useUpdateModuleMutation,
    useRemoveModuleMutation,
    useUpdateCourseLessonMutation,
    useUpdateCourseTestMutation,
    useRemoveCourseLessonMutation,
    useRemoveCourseTestMutation,
    useMoveMaterialMutation,
} = courseInjectionApi;
