import { nanoid } from 'nanoid';

const actions = ({
    navigate,
    onContainerOpenAddCoursesModal,
    onRemove,
    onContainerOpenAddGroupsModal,
    onNavigateToProfile,
}) => [
    {
        id: nanoid(),
        text: 'PROFILE_VIEW',
        onClick: () => onNavigateToProfile(),
    },
    {
        id: nanoid(),
        text: 'STUDENTS_INVITE_TO_COURSE',
        onClick: () => onContainerOpenAddCoursesModal(),
    },
    {
        id: nanoid(),
        text: 'STUDENTS_INVITE_TO_GROUP',
        border: true,
        onClick: () => onContainerOpenAddGroupsModal(),
    },
    {
        id: nanoid(),
        text: 'STUDENTS_REMOVE_USER',
        onClick: () => onRemove(),
    },
];

export { actions };
