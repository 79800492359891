import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actions } from './consts';
import {
    Search,
    Typography,
    Checkbox,
    Icon,
    Dropdown,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './UsersTableFilter.scss';

const UsersTableFilter = (props) => {
    const {
        count,
        isFilter = false,
        searchPlaceholder,
        onFilter: onParentFilter,
        search,
        onSearch,
        onSelectedAll,
        isChecked,
        isDetailPage = false,
    } = props;
    const [isShow, setIsShow] = useState(false);
    const [selectedAction, setSelectedAction] = useState({
        text: 'USERS_SHOW_ALL',
        type: null,
    });
    const { t } = useTranslation('common');

    const onShow = () => setIsShow((prev) => !prev);
    const onFilter = ({ type, text }) => {
        onParentFilter(type);
        setSelectedAction({ type, text });
        setIsShow(false);
    };
    return (
        <section className={classNames('users-table-filter', { isDetailPage })}>
            <div className={'users-table-filter__all'}>
                <Checkbox
                    value={'all'}
                    checked={isChecked}
                    disabled={count === 0}
                    onChange={onSelectedAll}
                />
                <Typography variant={'body4'}>
                    <span>{t('TOTAL')}:</span> {count || 0}
                </Typography>
            </div>
            <Search
                groupClassName={classNames('users-table-filter__search', {
                    full: !isFilter,
                })}
                placeholder={searchPlaceholder}
                value={search}
                onChange={onSearch}
                fullWidth={!isFilter}
            />
            {isFilter && (
                <Dropdown
                    isShow={isShow}
                    setIsShow={setIsShow}
                    className={'users-table-filter__dropdown'}
                >
                    <Dropdown.Header>
                        <div
                            className={'users-table-filter__select'}
                            onClick={onShow}
                        >
                            <Typography variant={'body4'}>
                                {t(selectedAction.text)}
                            </Typography>
                            <Icon name={`arrow-${isShow ? 'up' : 'down'}`} />
                        </div>
                    </Dropdown.Header>
                    <Dropdown.Body className={'dropdown__body'}>
                        <List
                            className={'dropdown__list'}
                            items={actions}
                            renderItem={({ id, text, border, type }) => (
                                <li
                                    key={id}
                                    className={classNames('dropdown__item', {
                                        border,
                                    })}
                                    onClick={() => onFilter({ type, text })}
                                >
                                    <Typography variant={'body4'}>
                                        {t(text)}
                                    </Typography>
                                </li>
                            )}
                        />
                    </Dropdown.Body>
                </Dropdown>
            )}
        </section>
    );
};

export default UsersTableFilter;
