import React from 'react';
import ReactDOM from 'react-dom';
import { store } from 'store';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { WithTheme } from 'helpers/hoc';
import { App } from 'components/feature';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'styles/index.scss';
import WithTranslations from 'components/layout/WithTranslations/WithTranslations';
import './firebase';
localStorage.removeItem('i18nextLng');

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <WithTranslations>
                    <WithTheme>
                        <App />
                    </WithTheme>
                </WithTranslations>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

const userAgentString = navigator.userAgent;
let chromeAgent = userAgentString.indexOf('Chrome') > -1;
let safariAgent = userAgentString.indexOf('Safari') > -1;
if (chromeAgent && safariAgent) safariAgent = false;

if (!safariAgent) {
    console.log('[not safari]');
    serviceWorkerRegistration.register();
}
