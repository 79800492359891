import React from 'react';
import {
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const UsersHeadingTabs = ({ tabs }) => {
    const { t } = useTranslation('common');
    return (
        <section className={'users-heading'}>
            <List
                className={'users-heading__tabs'}
                items={tabs}
                renderItem={({ id, icon, text, to, search }) => (
                    <li key={id} className={'users-heading__tab'}>
                        <NavLink
                            activeClassName="active"
                            className={classNames('users-heading__link')}
                            to={to}
                        >
                            <Icon name={icon} />
                            <Typography variant={'body4'}>{t(text)}</Typography>
                        </NavLink>
                    </li>
                )}
            />
        </section>
    );
};

export default UsersHeadingTabs;
