import { useState, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAction, useDebounce } from 'helpers/hooks';
import {
    useFetchUserStudentQuery,
    useLazyFetchUserStudentGroupsQuery,
    useRemoveUserStudentGroupsMutation,
} from 'api/services';
import { UsersTableFilter } from 'pages/users/common/UsersTable/UsersTableFilter';
import {
    StudentDetailGroupBottomModal,
    StudentDetailGroupItem,
    UserEmpty,
    UserSearchEmpty,
} from 'pages/users/common';
import { UsersTableList } from 'pages/users/common/UsersTable/UsersTableList';
import './Groups.scss';
import { useTranslation } from 'react-i18next';

const Groups = () => {
    const [isOpen, setIsOpen] = useOutletContext();
    const { id: studentId } = useParams();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const { studentGroups: groups } = useSelector((state) => state.userStudent);
    const { setUserStudentGroups } = useAction();
    const [trigger, { isLoading }] = useLazyFetchUserStudentGroupsQuery();
    const [removeGroup] = useRemoveUserStudentGroupsMutation();
    const { data: student } = useFetchUserStudentQuery(
        { spaceId: userSpace?.id, studentId },
        { skip: !userSpace || !studentId }
    );
    const [page, setPage] = useState(1);
    const pageSize = 15;
    const { t } = useTranslation('common');

    const onSearch = (e) => setSearch(e.target.value);
    const onSelectedGroup = (e) => {
        const { checked, value } = e.target;
        let updatedList = [...selectedGroups];
        if (checked) {
            updatedList = [...updatedList, value];
        } else {
            updatedList = updatedList.filter((p) => p !== value);
        }
        setSelectedGroups(updatedList);
    };
    const onSelectedAllGroups = (e) => {
        const { checked } = e.target;
        const updatedList = groups.results.map((p) => p.id);
        if (checked) {
            setIsCheckedAll(true);
            setSelectedGroups(updatedList);
        } else {
            setIsCheckedAll(false);
            setSelectedGroups([]);
        }
    };
    const onClearSelectedGroups = () => {
        setIsCheckedAll(false);
        setSelectedGroups([]);
    };

    const onRemoveGroup = async (id) =>
        await removeGroup({
            spaceId: userSpace.id,
            userId: student.user.id,
            groups: [id],
        });
    const onRemoveGroups = async () =>
        await removeGroup({
            spaceId: userSpace.id,
            userId: student.user.id,
            groups: selectedGroups,
        }).then(({ data }) => {
            if (data.length === 0) {
                onClearSelectedGroups();
            }
        });

    const loadMoreGroups = async () => {
        if (!groups.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            space_id: userSpace.id,
            student_id: studentId,
            search: debouncedSearch,
            page: page + 1,
            page_size: pageSize,
        });
        const modifiedResults = [...groups.results, ...data.results];
        setUserStudentGroups({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        if (userSpace) {
            setPage(1);
            trigger({
                space_id: userSpace.id,
                student_id: studentId,
                search: debouncedSearch,
                page: 1,
                page_size: pageSize,
            });
        }
    }, [debouncedSearch]);

    useEffect(() => {
        if (userSpace) {
            trigger({
                space_id: userSpace.id,
                student_id: studentId,
                page,
                page_size: pageSize,
            });
        }
    }, [userSpace]);

    const renderTable = () => {
        if (isLoading) {
            return null;
        } else if (search.length > 0 && !groups?.results.length) {
            return <UserSearchEmpty />;
        } else if (groups?.results.length === 0) {
            return (
                <UserEmpty
                    title={t('LIST_EMPTY')}
                    desc={t('ADD_STUDENTS_DESCRIPTION')}
                    btnText={t('ADD_TO_GROUP')}
                    onClick={() => setIsOpen(true)}
                />
            );
        } else {
            return groups?.results.length > 0 ? (
                <UsersTableList
                    count={groups.count}
                    items={groups.results}
                    loadMore={loadMoreGroups}
                    pageSize={pageSize}
                    hasMore={groups.hasMore}
                    renderItem={(course) => (
                        <StudentDetailGroupItem
                            key={course.id}
                            isSelected={selectedGroups.includes(course.id)}
                            onSelected={onSelectedGroup}
                            onRemove={onRemoveGroup}
                            qtyCourses={course.accessCourses}
                            qtyStudents={course.qtyParticipants}
                            {...course}
                        />
                    )}
                />
            ) : null;
        }
    };
    return (
        <div className={'student-detail-groups'}>
            <UsersTableFilter
                count={groups?.count}
                searchPlaceholder={t('USERS_GROUPS_SEARCH')}
                isFilter={false}
                isChecked={isCheckedAll}
                isDetailPage={true}
                search={search}
                onSearch={onSearch}
                onSelectedAll={onSelectedAllGroups}
            />
            {renderTable()}
            {selectedGroups.length > 0 ? (
                <StudentDetailGroupBottomModal
                    count={selectedGroups.length}
                    maxCount={groups.count}
                    onRemove={onRemoveGroups}
                    onClear={onClearSelectedGroups}
                />
            ) : null}
        </div>
    );
};

export default Groups;
