import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Heading,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import CoursesDropdown from '../CoursesDropdown';
import dayjs from 'dayjs';
import CourseImg from 'assets/img/png/img.png';
import classNames from 'classnames';
import './CoursesItem.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CoursesItem = (props) => {
    const {
        id,
        image,
        name,
        modified,
        remained,
        isDraft,
        status,
        onShowDeleteModal,
        draft,
        courseOrder,
    } = props;
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const { i18n } = useTranslation('common');

    const onShow = () => setIsShow((prev) => !prev);
    const onImageLoad = () => setIsLoaded(true);

    const onNavigateToCourse = () => {
        navigate(
            `/content/courses/${status !== 'active' ? 'create/' : ''}${id}`
        );
    };
    return (
        <li key={id} className={'courses-item'}>
            <img
                className={classNames('courses-item__img', {
                    'courses-item__img--loaded': !isLoaded,
                })}
                src={image ?? CourseImg}
                alt={name}
                onClick={onNavigateToCourse}
                onLoad={onImageLoad}
            />
            {isDraft && (
                <span className={'courses-item__draft'}>
                    <Typography variant={'body5'}>Есть черновик</Typography>
                </span>
            )}
            {remained && (
                <span className={'courses-item__draft'}>
                    <Typography variant={'body5'}>{remained} дней</Typography>
                </span>
            )}
            <div className={'courses-item__wrapper'}>
                <div className={'courses-item__content'}>
                    <Heading className={'courses-item__name'} variant={'h5'}>
                        {name ||
                            t('COURSE_NAME_PLACEHOLDER', {
                                number: courseOrder + 1 || '',
                            })}
                    </Heading>
                    <Typography
                        className={'courses-item__date'}
                        variant={'body4'}
                    >
                        {t('UPDATE')}:{' '}
                        {dayjs(modified)
                            .locale(i18n?.language || 'ru')
                            .format(
                                `DD MMM ${
                                    i18n?.language === 'ru' ? '[в]' : '[at]'
                                } h:mm`
                            )}
                    </Typography>
                </div>
                <CoursesDropdown
                    id={id}
                    status={status}
                    isShow={isShow}
                    setIsShow={setIsShow}
                    remaining={!remained}
                    onShow={onShow}
                    draftId={draft?.id}
                    onShowDeleteModal={onShowDeleteModal}
                />
            </div>
        </li>
    );
};

export default CoursesItem;
