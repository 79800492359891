import { createSlice } from '@reduxjs/toolkit';
import { courseInjectionApi } from 'api/services';

const initialState = {
    course: {
        blocks: null,
        complexity: null,
        draft: null,
        educationalMaterials: null,
        id: null,
        image: null,
        materials: null,
        name: null,
        status: null,
    },
    dataUpdated: false,
    editingMaterial: null,
    publishModalOpen: false,
    breadcrumbsName: '',
};

export const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        toggleDataUpdated: (state, { payload }) => {
            state.dataUpdated = payload;
        },
        setEditingMaterial: (state, { payload }) => {
            state.editingMaterial = payload;
        },
        changeCourseName: (state, { payload }) => {
            state.course.name = payload;
        },
        setPublishModalOpen: (state, { payload }) => {
            state.publishModalOpen = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                courseInjectionApi.endpoints.fetchCourse.matchFulfilled,
                (state, { payload }) => {
                    state.course = payload;
                    state.breadcrumbsName = payload.name;
                    state.dataUpdated = false;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.fetchCourseName.matchFulfilled,
                (state, { payload }) => {
                    state.breadcrumbsName = payload.name;
                }
            )
            // .addMatcher(
            //     courseInjectionApi.endpoints.uploadCourseCover.matchFulfilled,
            //     (state, { payload }) => {
            //         state.course.image = payload.image;
            //     }
            // )
            .addMatcher(
                courseInjectionApi.endpoints.updateCourse.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.removeEducationalMaterial
                    .matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.updateCourseName.matchFulfilled,
                (state, { payload }) => {
                    state.dataUpdated = true;
                    state.breadcrumbsName = payload.name;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.updateEducationalMaterial
                    .matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.createMaterial.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.updateModule.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.removeModule.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.updateCourseLesson.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.updateCourseTest.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.removeCourseLesson.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.removeCourseTest.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.moveMaterial.matchFulfilled,
                (state) => {
                    state.dataUpdated = true;
                }
            );
    },
});

const courseActions = courseSlice.actions;
export { courseActions };

export default courseSlice.reducer;
