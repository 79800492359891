import React from 'react';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
const TestInfoModal = ({ data, onClose, ready }) => {
    const { t } = useTranslation('common');
    return (
        <div className="test-info-modal">
            <div className="modal-title">{t('TEST_NEXT_WILL_BE_TIME')}</div>
            <div className="test-info-modal__desc">
                <p>
                    {t('TEST_NEXT_WILL_BE_TIME_DESC', {
                        name: data?.test_name,
                        duration: data?.duration,
                    })}
                </p>
            </div>
            <div className="test-info-modal__btns">
                <Button variant="primary" fullWidth onClick={ready}>
                    {t('TEST_I_AM_READY')}
                </Button>
                <Button
                    onClick={onClose}
                    fullWidth
                    className="test-info-modal__passLater"
                >
                    {t('TEST_I_PASS_LATER')}
                </Button>
            </div>
        </div>
    );
};

export default TestInfoModal;
