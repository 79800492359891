import HeaderMenuIcon from "../HeaderMenuIcon";
import HeaderCompany from "../HeaderCompany";
import HeaderOffline from "../HeaderOffline";
import HeaderBreadcrumbs from "../HeaderBreadcrumbs";
import "./HeaderLogo.scss";

const HeaderLogo = () => {
    return (
        <div className={"header-logo"}>
            <HeaderMenuIcon/>
            <HeaderCompany/>
            {/*<HeaderOffline/>*/}
            {/*<HeaderBreadcrumbs/>*/}
        </div>
    );
};

export default HeaderLogo;
