import { AddCoursesModal, AddGroupsModal } from 'pages/users/common';

const renderModal = (props) => ({
    '': props.isOpen ? <AddCoursesModal {...props} /> : null,
    courses: props.isOpen ? <AddCoursesModal {...props} /> : null,
    groups: props.isOpen ? <AddGroupsModal {...props} /> : null,
});

const renderText = {
    '': 'ADD_COURSE',
    courses: 'ADD_COURSE',
    groups: 'ADD_TO_GROUP',
};

export { renderText, renderModal };
