import { baseApi } from 'api/xhr';

export const userApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        fetchUsers: build.query({
            query: (params) => ({
                url: `users/get-user/`,
                params,
            }),
        }),
        existUser: build.query({
            query: (params) => ({
                url: `users/pre-route/`,
                params,
            }),
        }),
    }),
});

export const { useFetchUsersQuery, useLazyFetchUsersQuery, useExistUserQuery } =
    userApi;
