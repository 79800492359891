import React, { useState } from 'react';
import {
    IconButton,
    Icon,
} from '@akhter-studios/classlytics-front-ui-components';
import { Chip, Collapse, Grid, TextareaAutosize } from '@mui/material';
import './CoursesModule.scss';
import { useRemoveModuleMutation, useUpdateModuleMutation } from 'api/services';
import classnames from 'classnames';
import CoursesContentDropdown from 'pages/courses/CoursesContentDropdown/CoursesContentDropdown';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useTranslation } from 'react-i18next';
import { useMaterialsContext } from 'components/layout/MaterialsContext/MaterialsContext';
import { useDebouncedCallback } from 'use-debounce';
import MaterialOptionsDropdown from 'pages/courses/MaterialOptionsDropdown';
import { useWindowSize } from 'helpers/hooks';

const CoursesModule = ({
    modulesTotal,
    module,
    materials,
    onCreate,
    children,
    idx,
    index,
}) => {
    const previewStatus = usePreview();
    const { width } = useWindowSize();
    const {
        updateMaterialName,
        removeMaterial,
        onModuleDrop,
        toggleModule,
        setModuleOpen,
    } = useMaterialsContext();
    const [moduleName, setModuleName] = useState(module?.name || '');
    const [moduleDescription, setModuleDescription] = useState(
        module?.description || ''
    );
    const [moduleDescriptionOpen, setModuleDescriptionOpen] = useState(
        !!module?.description
    );
    const [createDropdownShow, setCreateDropdownShow] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const [updateModule] = useUpdateModuleMutation();
    const [removeModule] = useRemoveModuleMutation();
    const { t } = useTranslation('common');
    const saveModuleData = (data) => {
        return updateModule({
            spaceId: userSpace?.id,
            moduleId: module.id,
            ...data,
        });
    };
    const handleModuleNameChange = (e) => {
        setModuleName(e.target.value);
        updateMaterialName(module.id, newName(e.target.value));
        moduleNameChangeDebounce();
    };
    const moduleNameChangeDebounce = useDebouncedCallback(
        () => saveModuleData({ name: moduleName }),
        1000
    );
    const onNameBlur = async (e) => {
        await saveModuleData({
            name: newName(e.target.value),
        });
        updateMaterialName(module.id, newName(e.target.value));
        setModuleName(newName(e.target.value));
    };
    const onDescriptionBlur = async () => {
        await saveModuleData({
            description: moduleDescription,
        });
    };
    const newName = (value) => {
        let newValue = idx === modulesTotal ? modulesTotal : idx;
        return value || t('NEW_MODULE', { count: newValue });
    };
    const onNameKeyDown = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.target.blur();
            await saveModuleData({
                name: newName(e.target.value),
            });
        }
    };
    const onDescriptionKeyDown = async (e) => {
        if (
            (e.key === 'Enter' && !e.shiftKey) ||
            (e.key === 'Backspace' && !moduleDescription)
        ) {
            e.target.blur();
            await saveModuleData({
                description: moduleDescription,
            });
            if (e.key === 'Backspace' && !moduleDescription) {
                setModuleDescriptionOpen(false);
            }
        }
    };
    const handleModuleDescriptionChange = (e) => {
        setModuleDescription(e.target.value);
    };
    const handleDropDownClick = (cb) => {
        setCreateDropdownShow(false);
        cb();
        setModuleOpen(module.materialId, true);
    };
    const createDescription = () => {
        if (previewStatus === PREVIEW_STATUS.draft) {
            setModuleDescriptionOpen(true);
        }
    };
    const removeModuleAction = () => {
        removeModule({
            spaceId: userSpace?.id,
            moduleId: module.id,
        });
        removeMaterial(module.materialId, module.parentId, module.type);
    };
    const moveModuleUp = () => {
        onModuleDrop({ removedIndex: index, addedIndex: index - 1 });
    };
    const moveModuleDown = () => {
        onModuleDrop({ removedIndex: index, addedIndex: index + 1 });
    };
    return (
        <>
            <div
                className={classnames('course-module', {
                    'course-module__read-only':
                        previewStatus !== PREVIEW_STATUS.draft,
                })}
            >
                <MaterialOptionsDropdown
                    moverClassnames={'module-move course-module__move'}
                    bottomSheetTitle={t('MATERIAL_OPTIONS')}
                    items={[
                        {
                            id: nanoid(),
                            disabled: index === 0,
                            key: 'move-up',
                            iconName: 'up',
                            name: t('MATERIAL_MOVE_UP'),
                            onClick: moveModuleUp,
                        },
                        {
                            id: nanoid(),
                            disabled: index === modulesTotal - 1,
                            key: 'move-down',
                            iconName: 'down',
                            name: t('MATERIAL_MOVE_DOWN'),
                            onClick: moveModuleDown,
                        },
                        {
                            id: nanoid(),
                            key: 'remove',
                            iconName: 'trash',
                            name: t('MATERIAL_REMOVE'),
                            onClick: removeModuleAction,
                        },
                    ]}
                />
                <Grid container alignItems={'center'}>
                    <Grid item xs={6} md={8}>
                        <div className={'course-module__title--wrapper'}>
                            <TextareaAutosize
                                type="text"
                                className={classnames('course-module__title', {
                                    'course-module__title--disabled':
                                        !materials?.length,
                                })}
                                onBlur={onNameBlur}
                                disabled={
                                    previewStatus !== PREVIEW_STATUS.draft
                                }
                                maxLength={128}
                                value={moduleName}
                                placeholder={t('MODULE_NAME')}
                                onChange={handleModuleNameChange}
                                onKeyDown={onNameKeyDown}
                                autoFocus={!module?.name}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        md={4}
                        alignItems={'center'}
                        className={'icon--no-stroke'}
                        justifyContent={'flex-end'}
                    >
                        {!materials?.length && (
                            <Chip
                                className={'course-module__chip'}
                                label={
                                    width > 400
                                        ? t('EMPTY_MODULE')
                                        : t('EMPTY_MATERIAL_MOBILE')
                                }
                                size={'small'}
                            />
                        )}
                        {previewStatus === PREVIEW_STATUS.draft && (
                            <CoursesContentDropdown
                                show={createDropdownShow}
                                setShow={setCreateDropdownShow}
                                items={[
                                    {
                                        id: nanoid(),
                                        key: 'lesson',
                                        iconName: 'lesson',
                                        name: t('LESSON'),
                                        onClick: () =>
                                            handleDropDownClick(() =>
                                                onCreate(
                                                    'lesson',
                                                    module?.materialId
                                                )
                                            ),
                                    },
                                    {
                                        id: nanoid(),
                                        key: 'test',
                                        iconName: 'test',
                                        name: t('TEST'),
                                        onClick: () =>
                                            handleDropDownClick(() =>
                                                onCreate(
                                                    'test',
                                                    module?.materialId
                                                )
                                            ),
                                    },
                                    {
                                        id: nanoid(),
                                        key: 'description',
                                        iconName: 'description',
                                        name: t('MODULE_DESCRIPTION'),
                                        onClick: () =>
                                            handleDropDownClick(
                                                createDescription
                                            ),
                                    },
                                ]}
                            />
                        )}

                        <IconButton
                            onClick={() => toggleModule(module.materialId)}
                            className={'icon--no-stroke'}
                        >
                            <Icon
                                name={
                                    module?.isOpen
                                        ? 'arrow-down'
                                        : 'arrow-right'
                                }
                            />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse in={module?.isOpen} unmountOnExit>
                    {moduleDescriptionOpen && (
                        <TextareaAutosize
                            value={moduleDescription}
                            onChange={handleModuleDescriptionChange}
                            className={'course-module__description'}
                            placeholder={t('MODULE_DESCRIPTION')}
                            onKeyDown={onDescriptionKeyDown}
                            onBlur={onDescriptionBlur}
                        />
                    )}
                    <div className={'course-module__content'}>{children}</div>
                </Collapse>
            </div>
        </>
    );
};

export default CoursesModule;
