import React from 'react';
import { Icon } from 'components/common';
import { useTranslation } from 'react-i18next';

const UploadFileBtn = ({ setIsOpen }) => {
    const { t } = useTranslation('common');
    return (
        <div className="block-interface uploadFileTest" onClick={setIsOpen}>
            <span>{t('MENTOR_TEST_QUESTION_IMPORT')}</span>
            <Icon name="upload-excel" />
        </div>
    );
};

export default UploadFileBtn;
