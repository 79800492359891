import {Outlet, useLocation} from "react-router-dom";
import {findAuthWrapperLarge} from "./functions";
import classNames from "classnames";
import "./Auth.scss";

const Auth = () => {
    const {pathname} = useLocation();
    return (
        <div className={"auth"}>
            <div className={classNames("auth-wrapper", {"auth-wrapper--large": findAuthWrapperLarge(pathname)})}>
                <Outlet/>
            </div>
        </div>
    );
};

export default Auth;
