import * as yup from 'yup';
import {
    ABOUT_COMPANY_ROUTE,
    AUTH_INVITE_ROUTE,
    AUTH_REGISTER_ROUTE,
    CONFIRMATION_ROUTE,
    COMPANY_NAME_REGEX,
    DOMAIN_REGEX,
    DOMAIN_START_END_CHARACTERS_REGEX,
    NAME_REGEX,
    PASSWORD_REGEX,
    PASSWORD_VALID_REGEX,
    PHONE_NUMBER_REGEX,
} from 'utils/consts';

yup.addMethod(yup.string, 'name', function (message) {
    return this.matches(NAME_REGEX, message);
});

yup.addMethod(yup.string, 'password', function () {
    return this.matches(PASSWORD_REGEX, 'Неправильный пароль');
});
yup.addMethod(yup.string, 'phoneNumber', function () {
    return this.matches(PHONE_NUMBER_REGEX, 'Неправильный номер');
});

const email = (t) =>
    yup
        .string()
        .max(255, t('MAX_VALIDATE', { length: 255 }))
        .email(t('INCORRECT_EMAIL_VALIDATE'))
        .required(t('EMPTY_FIELD_VALIDATE'));

const password = (t) =>
    yup
        .string()
        .password()
        .min(8, t('MIN_VALIDATE', { length: 8 }))
        .max(32, t('MAX_VALIDATE', { length: 32 }))
        .matches(PASSWORD_VALID_REGEX, t('INCORRECT_PASSWORD_VALIDATE'))
        .required(t('EMPTY_FIELD_VALIDATE'));

const second_password = (t) =>
    yup
        .string()
        .password()
        .min(8, t('MIN_VALIDATE', { length: 8 }))
        .max(32, t('MAX_VALIDATE', { length: 32 }))
        .oneOf(
            [yup.ref('password'), null],
            t('INCORRECT_PASSWORD_MATCH_VALIDATE')
        )
        .required(t('EMPTY_FIELD_VALIDATE'));

const first_name = (t) =>
    yup
        .string()
        .required(t('EMPTY_FIELD_VALIDATE'))
        .max(64, t('MAX_VALIDATE', { length: 64 }))
        .name(t('CORRECT_FIRST_NAME_VALIDATE'));

const last_name = (t) =>
    yup
        .string()
        .required(t('EMPTY_FIELD_VALIDATE'))
        .max(64, t('MAX_VALIDATE', { length: 64 }))
        .name(t('CORRECT_LAST_NAME_VALIDATE'));

const emails = yup
    .array()
    .min(1, 'MUSTNT_BE_EMPTY')
    .of(
        yup.object({
            label: yup.string(),
            value: yup.string(),
        })
    );

const courses = yup
    .array()
    .min(1, 'MUSTNT_BE_EMPTY')
    .of(
        yup.object({
            label: yup.string(),
            value: yup.string(),
        })
    );

const groupTitle = yup.string().required('MUSTNT_BE_EMPTY');

const emailSchema = (t) =>
    yup.object().shape({
        email: email(t),
    });

const passwordSchema = (t) =>
    yup.object().shape({
        password: password(t),
        second_password: second_password(t),
    });

const profileMainSchema = (t) =>
    yup.object().shape({
        firstName: first_name(t),
        lastName: last_name(t),
        middleName: yup
            .string()
            .max(64, t('MAX_VALIDATE', { length: 64 }))
            .name(t('CORRECT_MIDDLE_NAME_VALIDATE')),
        email: email(t),
    });
const profilePasswordSchema = (t) =>
    yup.object().shape({
        current_password: yup
            .string()
            .password()
            .required(t('EMPTY_FIELD_VALIDATE')),
        password: yup
            .string()
            .password()
            .min(8, t('MIN_VALIDATE', { length: 8 }))
            .max(32, t('MAX_VALIDATE', { length: 32 }))
            .matches(PASSWORD_VALID_REGEX, t('INCORRECT_PASSWORD_VALIDATE'))
            .notOneOf(
                [yup.ref('current_password'), null],
                t('INCORRECT_NEW_PASSWORD_MATCH_VALIDATE')
            )
            .required(t('EMPTY_FIELD_VALIDATE')),
        second_password: second_password(t),
    });

const loginSchema = (t) =>
    yup.object().shape({
        email: email(t),
        password: yup
            .string()
            .password()
            .min(8, t('MIN_VALIDATE', { length: 8 }))
            .max(32, t('MAX_VALIDATE', { length: 32 }))
            .required(t('EMPTY_FIELD_VALIDATE')),
    });

const blocksSchema = yup.object();

const courseStatusSchema = yup
    .mixed()
    .oneOf(['inactive', 'active', 'archived', 'deleted']);

const courseSchema = yup.object().shape({
    name: yup.string(),
    blocks: blocksSchema,
    image: yup.string(),
    status: courseStatusSchema,
});

const registerSchema = (t) => {
    return {
        [AUTH_REGISTER_ROUTE]: yup.object().shape({
            email: email(t),
            first_name: first_name(t),
            last_name: last_name(t),
        }),
        [`${AUTH_REGISTER_ROUTE}${ABOUT_COMPANY_ROUTE}`]: yup.object().shape({
            company_name: yup
                .string()
                .min(2, t('MIN_VALIDATE', { length: 2 }))
                .max(100, t('MAX_VALIDATE', { length: 100 }))
                .matches(
                    COMPANY_NAME_REGEX,
                    t('INCORRECT_COMPANY_NAME_VALIDATE')
                )
                .required(t('EMPTY_FIELD_VALIDATE')),
            domain: yup
                .string()
                .min(2, t('MIN_VALIDATE', { length: 2 }))
                .max(63, t('MAX_VALIDATE', { length: 63 }))
                .matches(
                    DOMAIN_START_END_CHARACTERS_REGEX,
                    t('INCORRECT_DOMAIN_VALIDATE')
                )
                .matches(DOMAIN_REGEX, t('INCORRECT_DOMAIN_VALIDATE'))
                .required(t('EMPTY_FIELD_VALIDATE')),
        }),
        [`${AUTH_REGISTER_ROUTE}${CONFIRMATION_ROUTE}`]: passwordSchema(t),
    };
};

const inviteSchema = (t) => {
    return {
        [AUTH_INVITE_ROUTE]: yup.object().shape({
            first_name: first_name(t),
            last_name: last_name(t),
        }),
        [`${AUTH_INVITE_ROUTE}${CONFIRMATION_ROUTE}`]: passwordSchema(t),
    };
};

const userStudentInvitationSchema = yup.object({
    emails,
    courses,
});

const userMentorInvitationSchema = yup.object({
    emails,
    type: yup.string().required(),
});

const addGroupSchema = yup.object({
    title: groupTitle,
    emails,
    courses,
});

const changeGroupTitleSchema = yup.object({
    title: groupTitle,
});

const courseSettingsAboutCourseSchema = yup.object({
    complexity: yup.object({
        value: yup.string().required('MUSTNT_BE_EMPTY'),
    }),
    tags: yup
        .array()
        .min(1, 'MUSTNT_BE_EMPTY')
        .of(
            yup.object({
                label: yup.string(),
                value: yup.string(),
            })
        ),
});

export {
    loginSchema,
    registerSchema,
    emailSchema,
    passwordSchema,
    inviteSchema,
    courseSchema,
    userMentorInvitationSchema,
    userStudentInvitationSchema,
    addGroupSchema,
    changeGroupTitleSchema,
    profilePasswordSchema,
    courseSettingsAboutCourseSchema,
    profileMainSchema,
};
