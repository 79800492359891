import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actions } from './consts';
import {
    Checkbox,
    Avatar,
    Typography,
    Dropdown,
    Icon,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import GroupImg from 'assets/img/png/group.png';
import classNames from 'classnames';
import './StudentDetailGroupItem.scss';
import { useWindowSize } from 'helpers/hooks';

const StudentDetailGroupItem = (props) => {
    const {
        id,
        title,
        qtyCourses = 0,
        qtyStudents = 0,
        isSelected,
        onSelected,
        onRemove,
    } = props;
    const { t } = useTranslation('common');
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState(false);

    const onNavigateToGroup = () => {
        navigate(`/content/users/groups/${id}/students`);
    };
    const onShow = (e) => {
        e.stopPropagation();
        setIsShow((prev) => !prev);
    };
    const onCancel = () => setIsShow(false);
    const onContainerSelected = () => {
        if (width < 769) {
            onNavigateToGroup();
        } else {
            onSelected({
                target: { checked: !isSelected, value: id },
            });
        }
    };
    return (
        <div
            className={classNames('student-detail-group-item', {
                selected: isSelected,
            })}
            onClick={onContainerSelected}
        >
            <Checkbox
                className={'student-detail-group-item__checkbox'}
                value={id}
                checked={isSelected}
                onChange={onSelected}
            />
            <Avatar
                className={'student-detail-group-item__img'}
                src={GroupImg}
                alt={title}
                onClick={onNavigateToGroup}
            />
            <div className={'student-detail-group-item__content'}>
                <Typography
                    className={'student-detail-group-item__name'}
                    variant={'body4'}
                >
                    {title}
                </Typography>
                <div className={'student-detail-group-item__qty'}>
                    <Typography
                        className={'student-detail-group-item__text'}
                        variant={'body5'}
                    >
                        {t('INVITED_COURSES')}
                    </Typography>
                    <Typography
                        className={'student-detail-group-item__info'}
                        variant={'body4'}
                    >
                        {qtyCourses}
                    </Typography>
                </div>
                <div className={'student-detail-group-item__qty'}>
                    <Typography
                        className={'student-detail-group-item__text'}
                        variant={'body5'}
                    >
                        {t('ABOUT_STUDENTS')}
                    </Typography>
                    <Typography
                        className={'student-detail-group-item__info'}
                        variant={'body4'}
                    >
                        {qtyStudents}
                    </Typography>
                </div>
            </div>
            <Dropdown
                className={'student-detail-group-item__dropdown'}
                isShow={isShow}
                setIsShow={setIsShow}
            >
                <Dropdown.Header>
                    <Icon name={'more'} onClick={onShow} />
                </Dropdown.Header>
                <Dropdown.Body>
                    <List
                        className={'student-detail-group-item__actions'}
                        items={actions({
                            id,
                            onNavigateToGroup,
                            onRemove,
                            onCancel,
                        })}
                        renderItem={({ id, text, border, onClick }) => (
                            <li
                                className={classNames(
                                    'student-detail-group-item__action',
                                    { border }
                                )}
                                key={id}
                                onClick={onClick}
                            >
                                <Typography variant={'body4'}>
                                    {t(text)}
                                </Typography>
                            </li>
                        )}
                    />
                </Dropdown.Body>
            </Dropdown>
        </div>
    );
};

export default StudentDetailGroupItem;
