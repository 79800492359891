import { baseApi } from 'api/xhr';

export const userMentorApi = baseApi.injectEndpoints({
    tagTypes: ['Mentors'],
    endpoints: (build) => ({
        fetchUserMentors: build.query({
            query: ({ id, ...params }) =>
                console.log({ params }) || {
                    url: `/user-part/${id}/mentors/`,
                    params,
                },
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
        }),
        fetchUserMentor: build.query({
            query: ({ spaceId, userId }) => ({
                url: `/user-part/${spaceId}/mentors/${userId}/`,
            }),
        }),
        invitationUserMentor: build.mutation({
            query: ({ spaceId, lang, ...body }) => ({
                url: `/user-part/${spaceId}/invaitation/`,
                method: 'POST',
                body,
                params: { lang },
            }),
        }),
        removeUserMentor: build.mutation({
            query: ({ id, ...body }) => ({
                url: `/user-part/${id}/mentors/remove-mentor/`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useFetchUserMentorsQuery,
    useLazyFetchUserMentorsQuery,
    useFetchUserMentorQuery,
    useInvitationUserMentorMutation,
    useRemoveUserMentorMutation,
} = userMentorApi;
