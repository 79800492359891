import { nanoid } from 'nanoid';

const items = [
    {
        id: nanoid(),
        icon: 'analytics',
        name: 'ANALYTICS',
        to: '/content/analytics',
    },
    // {
    //     id: nanoid(),
    //     icon: 'calendar',
    //     name: 'CALENDAR',
    //     to: '/content/calendar',
    // },
    {
        id: nanoid(),
        icon: 'user-group',
        name: 'NAVIGATION_USERS',
        to: '/content/users',
    },
    {
        id: nanoid(),
        icon: 'documents',
        name: 'DOCUMENTS',
        to: '/content/knowledge/base',
    },
    {
        id: nanoid(),
        icon: 'settings',
        name: 'SETTING_SPACE',
        to: '/content/settings',
    },
];

const anotherItems = ({ logout }) => [
    {
        id: nanoid(),
        icon: 'security',
        name: 'PROFILE_MENU_SECURITY',
        to: '/content/profile?scope=security',
    },
    {
        id: nanoid(),
        icon: 'notification',
        name: 'NOTIFICATION',
        to: '/content/profile?scope=notification',
    },
    // {
    //     id: nanoid(),
    //     icon: 'settings',
    //     name: 'PROFILE_MENU_SETTINGS',
    //     to: '/content/settings',
    //     isDivider: true,
    // },
    {
        id: nanoid(),
        icon: 'log-in-out',
        name: 'LOGOUT',
        to: '#',
        isHideActive: true,
        onClick: () => logout(),
    },
];

export { items, anotherItems };
