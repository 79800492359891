import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { useUpdateSpaceMutation } from 'api/services';
import { useAction } from 'helpers/hooks';
import ColorValue from 'pages/settings/common/ColorValue';
import SettingsColorsModalWrapper from 'pages/settings/common/SettingsColors/SettingsColorsModalWrapper';
import SettingsItem from 'pages/settings/common/SettingsItem';
import { DEFAULT_COLOR } from 'pages/settings/consts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { pickTextColorBasedOnBgColor } from 'utils/functions';

const SettingsColorsSecondaryButton = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const { colorSecondaryButton } = useSelector((state) => state.settingSpace);
    const [isOpen, setIsOpen] = useState(false);
    const [colorSecondaryButtonPreview, setColorSecondaryButtonPreview] =
        useState(colorSecondaryButton || DEFAULT_COLOR);
    const { setColorSecondaryButton } = useAction();
    const [updateSpace] = useUpdateSpaceMutation();
    const onChangeColor = (e) => setColorSecondaryButtonPreview(e.target.value);
    const onApplyColor = async () => {
        setIsOpen(false);
        setColorSecondaryButton(colorSecondaryButtonPreview);
        await updateSpace({
            id: userSpace.id,
            color_secondary_button: colorSecondaryButtonPreview,
        });
    };
    const onResetColor = async () => {
        setColorSecondaryButton(DEFAULT_COLOR);
        setColorSecondaryButtonPreview(DEFAULT_COLOR);
        await updateSpace({
            id: userSpace.id,
            color_secondary_button: DEFAULT_COLOR,
        });
    };
    const onOpen = () => setIsOpen(true);
    return (
        <>
            <SettingsItem
                title={t('SETTINGS_SECONDARY_BUTTON_TITLE')}
                description={t('SETTINGS_SECONDARY_BUTTON_DESC')}
                btnSecondaryText={t('CHANGE')}
                onReset={onResetColor}
                onApply={onOpen}
            >
                <ColorValue color={colorSecondaryButton} />
            </SettingsItem>
            <SettingsColorsModalWrapper
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={t('SETTINGS_SECONDARY_BUTTON_DESC')}
                color={colorSecondaryButtonPreview}
                onApply={onApplyColor}
                onReset={onResetColor}
                onChange={onChangeColor}
                sampleComponent={
                    <Button
                        size={'large'}
                        variant={'secondary'}
                        style={{
                            maxWidth: 343,
                            backgroundColor: colorSecondaryButtonPreview,
                            color: pickTextColorBasedOnBgColor(
                                colorSecondaryButtonPreview
                            ),
                        }}
                        fullWidth
                    >
                        Normal
                    </Button>
                }
            />
        </>
    );
};

export default SettingsColorsSecondaryButton;
