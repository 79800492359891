import { memo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAction, useWindowSize } from 'helpers/hooks';
import { items, locationPathMenus, mobileItems } from './consts';
import {
    Icon,
    List,
    Typography,
    Divider,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './SidebarMenu.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SidebarMenu = () => {
    const { profile } = useSelector((state) => state.profile);
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const { t } = useTranslation('common');
    const { toggleOpenningSettingsAction } = useAction();

    const onHandler = (handler) => {
        handler && handler();
    };
    return (
        <nav>
            {profile ? (
                <List
                    className={'sidebar-menu'}
                    items={
                        width <= 768
                            ? locationPathMenus[window.location.pathname]?.(
                                  goBack,
                                  () => {},
                                  toggleOpenningSettingsAction
                              ) || mobileItems
                            : items
                    }
                    renderItem={({
                        id,
                        to,
                        icon,
                        handler,
                        name,
                        roles,
                        isDivider,
                    }) =>
                        roles.includes(profile.role) && (
                            <li key={id} className={'sidebar-menu__item'}>
                                {isDivider && (
                                    <Divider
                                        className={'sidebar-menu__divider'}
                                    />
                                )}
                                <NavLink
                                    onClick={() => onHandler(handler)}
                                    to={to ? to : '#'}
                                    className={({ isActive }) =>
                                        classNames('sidebar-menu__link', {
                                            active: isActive,
                                        })
                                    }
                                >
                                    <Icon
                                        className={'sidebar-menu__icon'}
                                        name={icon}
                                    />
                                    {name && (
                                        <Typography
                                            className={'sidebar-menu__text'}
                                            variant={'body3'}
                                        >
                                            {t(name)}
                                        </Typography>
                                    )}
                                </NavLink>
                            </li>
                        )
                    }
                />
            ) : null}
        </nav>
    );
};

export default memo(SidebarMenu);
