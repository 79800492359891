import React, { useState } from 'react';
import StudentDetailFilterSearch from 'pages/analytics/views/students/StudentDetail/StudentDetailFilterSearch';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import { testBody, testHeading } from 'pages/analytics/views/students/consts';
import DialogModalCustom from 'components/common/DialogModalCustom/DialogModalCustom';
import TestStatistic from 'pages/analytics/views/students/StudentDetail/TestStatistic/TestStatistic';
import { useParams, useOutletContext } from 'react-router-dom';
import { useFetchStudentDetailTestQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'helpers/hooks';
import { removeKeysIsEmpty } from 'utils/functions/helpers';

const StudentDetailTest = () => {
    const { t } = useTranslation('common');
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { totalTests } = useOutletContext();
    const [search, setSearch] = useState('');
    const [type, setType] = useState(null);
    const debouncedSearchInput = useDebounce(search, 800);
    const { data } = useFetchStudentDetailTestQuery({
        spaceId: userSpace?.id,
        id,
        ...removeKeysIsEmpty({
            search: debouncedSearchInput,
            passed: type,
        }),
    });
    console.log({
        ...removeKeysIsEmpty({
            search: debouncedSearchInput,
            passed: type,
        }),
    });
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const selectedCourseDetail = (item) => {
        setSelectedCourse(item);
        setIsOpen(!isOpen);
    };
    const onSearch = (e) => setSearch(e.target.value);
    return (
        <div className="study-details test">
            <StudentDetailFilterSearch
                totalPlaceholder={t('SHOWN_TESTS')}
                searchPlaceholder={t('SEARCH_TESTS')}
                isFilter={true}
                isStudent={true}
                totalCount={totalTests}
                onSearch={onSearch}
                setType={setType}
            />
            <AnalyticsTable
                withoutBorder
                handler={selectedCourseDetail}
                isClickable
                headings={testHeading}
                body={testBody(data?.items)}
            />
            <DialogModalCustom
                open={isOpen}
                setOpen={setIsOpen}
                customHeight={window.innerHeight - 30}
            >
                {isOpen && <TestStatistic selectedCourse={selectedCourse} />}
            </DialogModalCustom>
        </div>
    );
};

export default StudentDetailTest;
