import {
    ProfileMain,
    ProfileNotification,
    ProfileSecurity,
    ProfileSettings,
} from 'pages/profile/common';
import { Plug } from 'components/common';

const renderComponent = (t) => ({
    '': <ProfileMain />,
    '?scope=profile': <ProfileMain />,
    '?scope=security': <ProfileSecurity />,
    '?scope=notification': (
        <Plug
            title={t('PLUG_TITLE', { module: t('NOTIFICATION') })}
            description={t('PLUG_DESCRIPTION', {
                module: String(t('NOTIFICATION')).toLowerCase(),
            })}
        />
    ),
    '?scope=settings': (
        <Plug
            title={t('PLUG_TITLE', { module: t('PROFILE_MENU_SETTINGS') })}
            description={t('PLUG_DESCRIPTION', {
                module: String(t('PROFILE_MENU_SETTINGS')).toLowerCase(),
            })}
        />
    ),
    // '?scope=notification': <ProfileNotification />,
    // '?scope=settings': <ProfileSettings />,
});

const months = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
];

export { renderComponent, months };
