import { ToastContainer } from 'react-toastify';
import { AppRouter } from 'components/feature';
import 'react-toastify/dist/ReactToastify.css';
import Notification from 'pages/notification/Notification';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { useGetNotificationsQuery } from 'api/services';
import CustomDomainSettings from 'components/layout/CustomDomainSettings/CustomDomainSettings';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { isEyacademycca } from 'utils/functions/findDomainToRedirect';
import { useMemo } from 'react';

const App = () => {
    const isMobile = useIsMobile();
    useGetNotificationsQuery();
    const { isOpen } = useSelector((state) => state.notification);
    const { t } = useTranslation('common');
    const isEY = useMemo(() => isEyacademycca(), []);
    return (
        <div className="app" onContextMenu={(e) => e.preventDefault()}>
            <Helmet>
                <title>{isEY ? t('HEAD_TITLE_EY') : t('HEAD_TITLE')}</title>
            </Helmet>
            <CustomDomainSettings>
                <AppRouter />
                <ToastContainer autoClose={100} />
                {!isMobile && isOpen && <Notification />}
            </CustomDomainSettings>
        </div>
    );
};

export default App;
