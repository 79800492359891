import { useState, useEffect } from 'react';
import { useAction, useDebounce } from 'helpers/hooks';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useLazyFetchUserSearchGroupsQuery,
    useRemoveUserGroupMutation,
} from 'api/services';
import {
    GroupBottomModal,
    GroupItem,
    UserEmpty,
    UserSearchEmpty,
} from 'pages/users/common';
import { UsersTableList } from 'pages/users/common/UsersTable/UsersTableList';
import { UsersTableFilter } from 'pages/users/common/UsersTable/UsersTableFilter';
import './Groups.scss';

const Groups = () => {
    const [isOpen, setIsOpen] = useOutletContext();
    const [type, setType] = useState(null);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const { userSpace } = useSelector((state) => state.space);
    const { groups } = useSelector((state) => state.userGroup);
    const { setUserGroups } = useAction();
    const [trigger, { isLoading }] = useLazyFetchUserSearchGroupsQuery();
    const [removeGroup] = useRemoveUserGroupMutation();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [page, setPage] = useState(1);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const pageSize = 15;
    const { t } = useTranslation('common');
    const onFilter = (type) => setType(type);
    const onSearch = (e) => setSearch(e.target.value);
    const onSelectedGroup = (e) => {
        const { checked, value } = e.target;
        let updatedList = [...selectedGroups];
        if (checked) {
            updatedList = [...updatedList, value];
        } else {
            updatedList = updatedList.filter((p) => p !== value);
        }
        setSelectedGroups(updatedList);
    };
    const onSelectedAllGroups = (e) => {
        const { checked } = e.target;
        const updatedList = groups.results.map((p) => p.id);
        if (checked) {
            setIsCheckedAll(true);
            setSelectedGroups(updatedList);
        } else {
            setIsCheckedAll(false);
            setSelectedGroups([]);
        }
    };
    const onClearSelectedGroups = () => {
        setIsCheckedAll(false);
        setSelectedGroups([]);
    };

    const onRemoveGroup = async (id) =>
        await removeGroup({ spaceId: userSpace.id, groupId: id });
    // const onRemoveSelectedGroups = async () => await removeGroup({spaceId: userSpace.id, groups: selectedGroups}).then(({data}) => {
    //     if (data.length === 0) {
    //         setGroups({...groups, count: groups.count - selectedGroups.length, results: groups.results.filter(m => !selectedGroups.includes(m.id))});
    //         onClearSelectedGroups();
    //     }
    // });

    const loadMoreGroups = async () => {
        if (!groups.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            space_id: userSpace?.id,
            type,
            search: debouncedSearch,
            page: page + 1,
            page_size: pageSize,
        });
        const modifiedResults = [...groups.results, ...data.results];
        setUserGroups({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        if (userSpace) {
            trigger({
                space_id: userSpace?.id,
                type,
                search: debouncedSearch,
                page,
                page_size: pageSize,
            });
        }
    }, [userSpace]);

    useEffect(() => {
        if (userSpace) {
            setPage(1);
            trigger({
                space_id: userSpace?.id,
                type,
                search: debouncedSearch,
                page: 1,
                page_size: pageSize,
            });
        }
    }, [debouncedSearch, type]);

    const renderTable = () => {
        if (isLoading) {
            return null;
        } else if (search.length > 0 && !groups?.results.length) {
            return <UserSearchEmpty />;
        } else if (groups?.results.length === 0) {
            return (
                <UserEmpty
                    title={t('LIST_EMPTY')}
                    desc={t('ADD_STUDENTS_DESCRIPTION')}
                    btnText={t('CREATE_GROUP')}
                    onClick={() => setIsOpen(true)}
                />
            );
        } else {
            return groups?.results.length > 0 ? (
                <UsersTableList
                    count={groups?.count}
                    pageSize={pageSize}
                    items={groups.results}
                    loadMore={loadMoreGroups}
                    hasMore={groups.hasMore}
                    renderItem={(group) => (
                        <GroupItem
                            key={group.id}
                            isSelected={selectedGroups.includes(group.id)}
                            onSelectedGroup={onSelectedGroup}
                            onRemove={onRemoveGroup}
                            {...group}
                        />
                    )}
                />
            ) : null;
        }
    };
    return (
        <div className={'groups-wrapper'}>
            <div className={'groups'}>
                <UsersTableFilter
                    count={groups?.count}
                    search={search}
                    searchPlaceholder={t('USERS_GROUPS_SEARCH')}
                    isFilter={false}
                    isChecked={isCheckedAll}
                    onFilter={onFilter}
                    onSearch={onSearch}
                    onSelectedAll={onSelectedAllGroups}
                />
                {renderTable()}
            </div>
            {selectedGroups.length > 0 ? (
                <GroupBottomModal
                    count={selectedGroups.length}
                    maxCount={groups.count}
                    onClear={onClearSelectedGroups}
                    // onRemove={onRemoveSelectedGroups}
                />
            ) : null}
        </div>
    );
};

export default Groups;
