import React from 'react';
import defaultImage from 'assets/img/png/img.png';
const CourseImage = ({ image }) => {
    return (
        <div className="courses-detailed-img">
            <img src={image || defaultImage} />
        </div>
    );
};

export default CourseImage;
