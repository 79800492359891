import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { Grid } from '@mui/material';
import { Icon } from 'components/common';
import CourseMaterialItem from 'pages/courses/CourseMaterialItem/CourseMaterialItem';
import { materialOrder } from 'helpers/functions';
import cn from 'classnames';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useMaterialsContext } from 'components/layout/MaterialsContext/MaterialsContext';

const LessonsWithoutModule = ({ index, module, newMaterialId }) => {
    const { onLessonDrop } = useMaterialsContext();
    const previewStatus = usePreview();
    return (
        <Draggable key={`${module.id}-${index}`}>
            <Container
                orientation={'vertical'}
                groupName={'lesson'}
                dragHandleSelector={'.lesson-move'}
                dragClass={'course-material--drag'}
                getChildPayload={(idx) => module.children[idx]}
                onDrop={(res) => onLessonDrop(res, module.id, index)}
            >
                {module.children?.map((material, idx) =>
                    previewStatus === PREVIEW_STATUS.preview &&
                    material.empty ? null : (
                        <Draggable key={`${material.id}-${idx}`}>
                            <Grid
                                container
                                className={cn('course-material', {
                                    'course-material__read-only':
                                        previewStatus !== PREVIEW_STATUS.draft,
                                })}
                                alignItems={'center'}
                            >
                                <div className="lesson-move course-material__move">
                                    <Icon name="move" />
                                </div>
                                <CourseMaterialItem
                                    parentId={material?.parentId}
                                    materialOrder={
                                        materialOrder(
                                            module.children,
                                            material.type,
                                            material.id
                                        ) + 1
                                    }
                                    isEditorOn={material.id === newMaterialId}
                                    key={material.id}
                                    materialId={material.id}
                                    name={material.name}
                                    type={material.type}
                                    empty={material?.empty}
                                />
                            </Grid>
                        </Draggable>
                    )
                )}
            </Container>
        </Draggable>
    );
};

export default LessonsWithoutModule;
