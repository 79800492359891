import React from 'react';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { Icon } from 'components/common';
import { useTranslation } from 'react-i18next';
const BaseFooter = ({
    count,
    clear,
    download,
    handleClickModalInterface,
    removeItems,
}) => {
    const { t } = useTranslation('common');
    return (
        <div className={`knowledge-base__footer`}>
            <div className="j-c-b">
                <div className="knowledge-base__footer-left d-flex">
                    <div className="knowledge-base__footer-selected d-flex">
                        <span>{t('BASE_FOOTER_CHOSEN')}</span>
                        <div>{count}</div>
                    </div>
                    <div className="desktop-hidden" onClick={clear}>
                        <Icon name="base-clear-icon" />
                    </div>
                    <div className="knowledge-base__footer-clear mobile-hidden">
                        <Button variant={'secondary'} onClick={clear}>
                            {t('BASE_FOOTER_CLEAR')}
                        </Button>
                    </div>
                </div>
                <div className="desktop-hidden knowledge-base__footer-right d-flex">
                    <Icon name={'base-download-icon'} handler={download} />
                    <Icon name="base-remove-icon" handler={removeItems} />
                </div>
                <div className="knowledge-base__footer-right d-flex mobile-hidden">
                    {count === 1 && (
                        <Button variant={'secondary'} onClick={download}>
                            <span>{t('BASE_FOOTER_DOWNLOAD')}</span>{' '}
                            <Icon name={'download'} />
                        </Button>
                    )}
                    <Button
                        variant={'secondary'}
                        onClick={() => handleClickModalInterface('send')}
                    >
                        <span>{t('BASE_FOOTER_SEND_TO_COURSE')}</span>{' '}
                        <Icon name={'download'} />
                    </Button>
                    <div
                        className="knowledge-base__footer-remove d-flex"
                        onClick={removeItems}
                    >
                        <div>{t('BASE_FOOTER_REMOVE')}</div>
                        <Icon name="trash" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BaseFooter;
