import React from 'react';
import { Icon } from 'components/common';
import { useTranslation } from 'react-i18next';

const NotificationDetail = ({
    text,
    description,
    image,
    created,
    modified,
    goBack,
}) => {
    const { t } = useTranslation('common');
    return (
        <div className="notifyDetail">
            <div
                className="notifyDetail__back d-flex"
                onClick={() => goBack(null)}
            >
                <Icon name="arrow-back" />
                <span>{t('BACK')}</span>
            </div>
            <h5 className="notifyDetail__text">{text}</h5>
            <div className="notifyDetail__img">
                <img src={image} alt="" />
            </div>
            <div className="notifyDetail__desc">{description}</div>
        </div>
    );
};

export default NotificationDetail;
