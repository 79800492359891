import { Caret } from 'components/common/Editor/tools/utils';

export default class ToggleList {
    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;
        this.config = config;
        this._CSS = {
            block: this.api.styles.block,
            wrapper: 'ce-toggle-list',
            arrow: 'ce-toggle-list__arrow',
            contentWrapper: 'ce-toggle-list__content-wrapper',
            contentHeader: 'ce-toggle-list__content-header',
            content: 'ce-toggle-list__content',
        };
        if (!this.readOnly) {
            this.onKeyUp = this.onKeyUp.bind(this);
        }

        this._headerPlaceholder = 'Заголовок контента';
        this._contentPlaceholder = 'Контент';
        this.data = data;
        this._element = this.drawView();
    }
    static get sanitize() {
        return {
            content: {
                br: true,
            },
            headerContent: {
                br: true,
            },
        };
    }
    static get isReadOnlySupported() {
        return true;
    }

    onHeaderEnter(e) {
        if (e.code !== 'Enter') {
            return;
        }
        e.stopPropagation();
        e.preventDefault();

        const parent = e.target.closest('.' + this._CSS.wrapper);
        const [arrow] = parent.getElementsByClassName(this._CSS.arrow);
        if (arrow.dataset.active === 'true') {
            const [content] = parent.getElementsByClassName(this._CSS.content);
            Caret.focus(content, false);
        } else {
            const index = this.api.blocks.getCurrentBlockIndex() + 1;
            this.api.blocks.insert(
                'toggleList',
                { content: null },
                this.config,
                index,
                true
            );
            this.api.caret.setToBlock(index);
        }
    }
    onKeyUp(e) {
        if (e.code !== 'Backspace' && e.code !== 'Delete') {
            return;
        }

        const { textContent } = this._element;

        if (textContent === '') {
            this._element.innerHTML = '';
        }
    }
    drawView() {
        const wrapper = document.createElement('DIV');
        wrapper.classList.add(this._CSS.wrapper, this._CSS.block);
        const arrow = document.createElement('DIV');
        arrow.dataset.active = 'false';
        arrow.addEventListener('click', function () {
            if (this.dataset.active === 'true') {
                this.dataset.active = 'false';
            } else {
                this.dataset.active = 'true';
            }
        });
        arrow.classList.add(this._CSS.arrow);
        const contentWrapper = document.createElement('DIV');
        contentWrapper.classList.add(this._CSS.contentWrapper);
        const contentHeader = document.createElement('DIV');
        contentHeader.classList.add(this._CSS.contentHeader);
        contentHeader.dataset.placeholder = this.api.i18n.t(
            this._headerPlaceholder
        );
        const content = document.createElement('DIV');
        content.classList.add(this._CSS.content);
        content.innerHTML = this?.data?.content || '';
        contentHeader.innerHTML = this.data?.headerContent || '';
        content.dataset.placeholder = this.api.i18n.t(this._contentPlaceholder);
        if (!this.readOnly) {
            contentHeader.contentEditable = 'true';
            contentHeader.addEventListener('keyup', this.onKeyUp);
            contentHeader.addEventListener('keydown', (e) =>
                this.onHeaderEnter(e)
            );
            content.contentEditable = 'true';
            content.addEventListener('keyup', this.onKeyUp);
        }
        contentWrapper.appendChild(contentHeader);
        contentWrapper.appendChild(content);
        wrapper.appendChild(arrow);
        wrapper.appendChild(contentWrapper);
        return wrapper;
    }
    static get toolbox() {
        return {
            title: 'Toggle List',
            icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99818 1.74219C4.87045 1.74219 3.78892 2.19017 2.9915 2.98759C2.19408 3.78501 1.74609 4.86655 1.74609 5.99427V13.9976C1.74609 16.346 3.64982 18.2497 5.99818 18.2497H14.0015C15.1292 18.2497 16.2108 17.8017 17.0082 17.0043C17.8056 16.2069 18.2536 15.1253 18.2536 13.9976V5.99427C18.2536 3.64591 16.3499 1.74219 14.0015 1.74219H5.99818ZM1.93084 1.92693C3.00956 0.848209 4.47263 0.242188 5.99818 0.242188H14.0015C17.1783 0.242188 19.7536 2.81748 19.7536 5.99427V13.9976C19.7536 15.5232 19.1476 16.9862 18.0688 18.0649C16.9901 19.1437 15.5271 19.7497 14.0015 19.7497H5.99818C2.82139 19.7497 0.246094 17.1744 0.246094 13.9976V5.99427C0.246094 4.46872 0.852115 3.00566 1.93084 1.92693Z" fill="#637D8B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.74805 8C4.74805 7.58579 5.08383 7.25 5.49805 7.25H7.49888C7.80556 7.25 8.08134 7.43671 8.19524 7.72146L10.0075 12.2521H13.5014C13.9156 12.2521 14.2514 12.5879 14.2514 13.0021C14.2514 13.4163 13.9156 13.7521 13.5014 13.7521H9.49971C9.19304 13.7521 8.91725 13.5654 8.80336 13.2806L6.99111 8.75H5.49805C5.08383 8.75 4.74805 8.41421 4.74805 8Z" fill="#637D8B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75049 7.99972C9.75049 7.58551 10.0863 7.24972 10.5005 7.24972H13.5017C13.916 7.24972 14.2517 7.58551 14.2517 7.99972C14.2517 8.41393 13.916 8.74972 13.5017 8.74972H10.5005C10.0863 8.74972 9.75049 8.41393 9.75049 7.99972Z" fill="#637D8B"/>
            </svg>`,
        };
    }
    render() {
        return this._element;
    }
    save(block) {
        return {
            content: block.getElementsByClassName(this._CSS.content)[0]
                .innerHTML,
            headerContent: block.getElementsByClassName(
                this._CSS.contentHeader
            )[0].innerHTML,
        };
    }
}
