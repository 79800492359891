import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
    components: {
        MuiFormControl: {
            defaultProps: {
                variant: "standart",
                fullWidth: true,
            },
        },
        MuiInput: {
            defaultProps: {
                variant: "standart",
                fullWidth: true,
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
                disableRipple: true,
                fullWidth: true,
            },
            styleOverrides: {
                root: () => {
                    return {
                        borderRadius: 30,
                        boxShadow: "none",
                        fontWeight: "600",
                        textTransform: "none",
                        minHeight: "51px",
                        "&:hover": {
                            boxShadow: "none",
                        },
                        "&:disabled": {
                            opacity: "0.6",
                        },
                    };
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#3C86F3",
        },
        secondary: {
            main: "#11cb5f",
        },
    },
});

const WithTheme = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default WithTheme;
