import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { UsersHeading, UsersTitle } from './common';
import './Users.scss';

const Users = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => setIsOpen(true);
    return (
        <article className={'users layout-main'}>
            <UsersTitle onOpen={onOpen} />
            <UsersHeading isOpen={isOpen} setIsOpen={setIsOpen} />
            <Outlet context={[isOpen, setIsOpen]} />
        </article>
    );
};

export default Users;
