import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const ResultBlockTest = ({ isCorrect }) => {
    const { t } = useTranslation('common');
    return (
        <div className="result-block-test">
            <div
                className={cn('result-block-test__line', {
                    error: isCorrect === false,
                    success: isCorrect,
                })}
            />
            <div>
                {isCorrect
                    ? t('STUDENT_TEST_ANSWERED_CORRECT')
                    : t('STUDENT_TEST_ANSWERED_INCORRECT')}
            </div>
        </div>
    );
};

export default ResultBlockTest;
