import SettingsCard from 'pages/settings/common/SettingsCard';
import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import DefaultLogo from 'assets/img/svg/default-logo.svg';
import './SettingsCompanyLogo.scss';
import { useTranslation } from 'react-i18next';
import {
    useDeleteSpaceImageMutation,
    useUpdateSpaceAssetsMutation,
} from 'api/services';
const SettingsCompanyLogo = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [currentLogo, setCurrentLogo] = useState(
        userSpace?.logo ?? DefaultLogo
    );
    const [updateSpaceAssets] = useUpdateSpaceAssetsMutation();
    const [deleteSpaceImage] = useDeleteSpaceImageMutation();
    const handleUpload = async (e) => {
        const file = e.target.files[0];
        const path = URL.createObjectURL(file);
        setCurrentLogo(path);
        const formData = new FormData();
        formData.append('logo', file);

        await updateSpaceAssets({
            id: userSpace.id,
            formData,
        });
    };
    const onUpload = () => {
        document.getElementById('logoUploadBtn').click();
    };

    const onRemove = async () => {
        setCurrentLogo(DefaultLogo);
        await deleteSpaceImage({
            id: userSpace.id,
            image_type: 'logo',
        });
    };
    return (
        <>
            <input
                className={'settings-company-logo__upload'}
                type="file"
                id="logoUploadBtn"
                accept={'image/png, image/svg image/jpg image/jpeg'}
                onChange={handleUpload}
            />
            <SettingsCard
                title={t('SETTINGS_LOGO_TITLE')}
                description={t('SETTINGS_LOGO_DESC')}
                onUpload={onUpload}
                onRemove={onRemove}
            >
                <div className={'settings-company-logo__wrapper'}>
                    <img src={currentLogo} alt={'logo'} />
                    <Heading variant={'h4'}>{userSpace.companyName}</Heading>
                </div>
            </SettingsCard>
        </>
    );
};

export default SettingsCompanyLogo;
