import { useState, useEffect } from 'react';
import { getFirebaseToken, onForegroundMessage } from '../../firebase';
import { useAction } from 'helpers/hooks/index';
import { nanoid } from 'nanoid';

const useNotification = () => {
    const [showNotificationBanner, setShowNotificationBanner] = useState(
        Notification.permission === 'default'
    );

    const [value, setValue] = useState('');
    const { addNotification } = useAction();
    console.log({ permission: Notification.permission });
    useEffect(() => {
        onForegroundMessage()
            .then((payload) => {
                console.log('Received foreground message: ', payload);
                const {
                    notification: { title, body },
                } = payload;

                addNotification({
                    text: title,
                    description: body,
                    id: nanoid(),
                });
                setValue(title);
                showNotification(title, body);
            })
            .catch((err) =>
                console.log(
                    'An error occured while retrieving foreground message. ',
                    err
                )
            )
            .finally(() => {
                setTimeout(() => setValue(''), 100);
            });
    }, [value]);
    const showNotification = (title, body) => {
        const notification = new Notification(title, { body });
    };
    const handleGetFirebaseToken = () => {
        getFirebaseToken()
            .then((firebaseToken) => {
                console.log('Firebase token: ', firebaseToken);
                if (firebaseToken) {
                    setShowNotificationBanner(false);
                }
            })
            .catch((err) =>
                console.error(
                    'An error occured while retrieving firebase token. ',
                    err
                )
            );
    };
    useEffect(() => {
        handleGetFirebaseToken();
    }, []);
};

export default useNotification;
