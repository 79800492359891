import React, { useState } from 'react';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import {
    analyticsDetailsStudentTable,
    analyticsDetailStudentsHeading,
} from 'pages/analytics/views/courses/DetailCourses/consts';
import DetailCourseFilterSearch from 'pages/analytics/views/courses/DetailCourses/DetailCourseFilterSearch';
import { useFetchCourseInfoStudentsQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useParams, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'helpers/hooks';

const DetailCoursesStudents = () => {
    const { t } = useTranslation('common');
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { totalStudents } = useOutletContext();
    const [search, setSearch] = useState('');
    const debouncedSearchInput = useDebounce(search, 800);
    const { data } = useFetchCourseInfoStudentsQuery({
        spaceId: userSpace?.id,
        id,
        search: debouncedSearchInput,
    });
    const onSearch = (e) => setSearch(e.target.value);
    return (
        <div>
            <DetailCourseFilterSearch
                // isFilter={true}
                searchPlaceholder={t('USERS_STUDENTS_SEARCH')}
                totalCount={totalStudents}
                onSearch={onSearch}
            />
            <AnalyticsTable
                withImgColumn={true}
                withoutBorder={true}
                isStudent={true}
                headings={analyticsDetailStudentsHeading}
                body={analyticsDetailsStudentTable({ data: data || [], t })}
                courseDetailStudents={true}
            />
        </div>
    );
};

export default DetailCoursesStudents;
