import React, { useEffect, useMemo } from 'react';
import { useFetchCourseMutation } from 'api/services';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, TextareaAutosize } from '@mui/material';
import CreateCourseCover from 'pages/courses/Course/create-course/CreateCourseCover';
import { getEditorJsTools, getTools } from 'components/common/Editor/constants';
import { Editor } from 'components/common';
import useEditor from 'helpers/hooks/useEditor';
import CreateCourseFiles from 'pages/courses/Course/create-course/CreateCourseFiles/CreateCourseFiles';
import CreateCourseContent from 'pages/courses/Course/create-course/CreateCourseContent/CreateCourseContent';
import './index.scss';
import '../../courses/Course/create-course/CreateCourse.scss';
import { useTranslation } from 'react-i18next';
import { MaterialsContextProvider } from 'components/layout/MaterialsContext/MaterialsContext';

const CoursePreview = () => {
    const { userSpace } = useSelector((state) => state.space);
    const { t } = useTranslation('common');
    const { courseId } = useParams();
    const { course, dataUpdated } = useSelector((state) => state.course);
    const [fetchCourse, { isSuccess }] = useFetchCourseMutation();
    const sameCourse = useMemo(
        () => course?.id === courseId,
        [course?.id, courseId]
    );
    useEffect(() => {
        if (!sameCourse || dataUpdated) {
            fetchCourse({ spaceId: userSpace?.id, courseId });
        }
    }, []);
    const { childRef, outerSave, onEditorChange } = useEditor({});
    return (
        (isSuccess || sameCourse) && (
            <Grid container className={'course-preview'}>
                <Grid item sm={12} md={8}>
                    <CreateCourseCover isActionHide={true} />
                    <div className={'course__title-wrapper'}>
                        <TextareaAutosize
                            name={'name'}
                            className="course__title"
                            type="text"
                            disabled
                            placeholder={'Введите название курса'}
                            value={course?.name}
                        />
                    </div>
                    <div className="course-info course-info--desktop">
                        <h6 className={'course-info__title'}>
                            {t('PREVIEW_ABOUT_COURSE')}
                        </h6>
                        <div className={'course-info__progress'}>
                            <span>{t('PREVIEW_LEVEL')}</span>
                            <b>{course?.complexity || t('PREVIEW_UNKNOWN')}</b>
                        </div>
                        <div className={'course-info__progress'}>
                            <span>{t('PREVIEW_LEARNING_MATERIALS')}</span>
                            <b>{course?.materials.length}</b>
                        </div>
                    </div>
                    <Editor
                        dataUpdated={dataUpdated}
                        readOnly
                        tools={getTools(
                            [
                                'embedPDF',
                                'embedDOC',
                                'header',
                                'strikethrough',
                                'embed',
                                'list',
                                'linkTool',
                                'quote',
                                'underline',
                                'delimiter',
                                'customMarker',
                                'Color',
                                'toggleList',
                                'audio',
                                'image',
                                'table',
                                'video',
                                'attaches',
                            ],
                            getEditorJsTools(t, userSpace?.id, courseId)
                        )}
                        courseId={courseId}
                        ref={childRef}
                        onSave={() => {}}
                        outsideSave={outerSave}
                        initialData={course}
                        onChange={onEditorChange}
                        placeholderOnEmpty={t('ENTER_COURSE_DESCRIPTION')}
                    />
                    <CreateCourseFiles
                        additionalMaterials={course?.educationalMaterials}
                    />
                    <MaterialsContextProvider
                        materials={course.materials}
                        isReadOnly={true}
                    >
                        <CreateCourseContent materials={course?.materials} />
                    </MaterialsContextProvider>
                </Grid>
                <Grid item sm={12} md={4}>
                    <div className="course-info course-info--mobile">
                        <h6 className={'course-info__title'}>
                            {t('PREVIEW_ABOUT_COURSE')}
                        </h6>
                        <div className={'course-info__progress'}>
                            <span>{t('PREVIEW_LEVEL')}</span>
                            <b>{course?.complexity || t('PREVIEW_UNKNOWN')}</b>
                        </div>
                        <div className={'course-info__progress'}>
                            <span>{t('PREVIEW_LEARNING_MATERIALS')}</span>
                            <b>{course?.materials.length}</b>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    );
};

export default CoursePreview;
