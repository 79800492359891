import React from 'react';
import { Icon } from 'components/common';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
const Success = ({
    selectedFiles,
    resetCheckboxes,
    currentItem,
    selectedCourses,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const goToCourse = () => {
        const courseId = Object.keys(selectedCourses)[0];
        navigate(`/content/courses/${courseId}`);
    };
    return (
        <div className="success-modal">
            <Icon name="success-icon" />
            <div className="success-modal__title">
                {t('BASE_FILES_SENT_TO_COURSE_TITLE')}
            </div>
            <div className="success-modal__description">
                <Trans
                    t={t}
                    i18nKey={'BASE_FILES_SENT_TO_COURSE_DESCRIPTION'}
                    count={currentItem ? 1 : Object.keys(selectedFiles).length}
                >
                    Выбранные элементы (
                    {{
                        count: currentItem
                            ? 1
                            : Object.keys(selectedFiles).length,
                    }}
                    } шт.) добавлены в раздел «Дополнительные материалы» курса{' '}
                    <strong>
                        {{
                            name: Object.keys(selectedCourses)
                                .map((x, idx) => selectedCourses[x].name)
                                .join(', '),
                        }}
                    </strong>
                </Trans>
            </div>
            {Object.keys(selectedCourses).length === 1 && (
                <div className="w-100">
                    <Button
                        style={{ margin: '24px auto 0 auto' }}
                        variant={'secondary'}
                        onClick={goToCourse}
                    >
                        {t('NAVIGATE_TO_THE_COURSE')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Success;
