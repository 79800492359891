import { useLocation } from 'react-router-dom';
import { renderComponent } from 'pages/profile/consts';
import { ProfileMenu } from 'pages/profile/common';
import './Profile.scss';
import { useTranslation } from 'react-i18next';

const Profile = () => {
    const { t } = useTranslation('common');
    const { search } = useLocation();

    return (
        <article className={'profile layout-main'}>
            <ProfileMenu />
            <div className={'profile__content'}>
                {renderComponent(t)[search]}
            </div>
        </article>
    );
};

export default Profile;
