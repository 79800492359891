import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import UpdatePasswordSpoiler from './UpdatePasswordSpoiler';
import './ProfileSecurity.scss';
import { useTranslation } from 'react-i18next';

const ProfileSecurity = () => {
    const { t } = useTranslation('common');
    return (
        <div className={'profile-security'}>
            <Heading className={'profile-security__title'} variant={'h1'}>
                {t('PROFILE_MENU_SECURITY')}
            </Heading>
            <UpdatePasswordSpoiler />
        </div>
    );
};

export default ProfileSecurity;
