import React, { useMemo, useState } from 'react';
import { Chip, Grid, TextareaAutosize } from '@mui/material';
import {
    IconButton,
    Icon,
    Heading,
} from '@akhter-studios/classlytics-front-ui-components';
import './CourseMaterialItem.scss';
import { nanoid } from 'nanoid';
import CoursesContentDropdown from 'pages/courses/CoursesContentDropdown/CoursesContentDropdown';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PREVIEW } from 'utils/consts';
import {
    useRemoveCourseLessonMutation,
    useRemoveCourseTestMutation,
    useUpdateCourseLessonMutation,
    useUpdateCourseTestMutation,
} from 'api/services';
import { useSelector } from 'react-redux';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useTranslation } from 'react-i18next';
import { useAction, useWindowSize } from 'helpers/hooks';
import classNames from 'classnames';
import { useMaterialsContext } from 'components/layout/MaterialsContext/MaterialsContext';

const CourseMaterialItem = ({
    materialOrder,
    name,
    type,
    empty,
    materialId,
    isEditorOn = false,
    parentId,
}) => {
    const { courseId } = useParams();
    const previewStatus = usePreview();
    const [searchParams] = useSearchParams();
    const previewStatusSearchParam = useMemo(
        () => searchParams.get('previewStatus'),
        [searchParams]
    );
    const { removeMaterial, updateMaterialName } = useMaterialsContext();
    const { setEditingMaterial } = useAction();
    const [showOptions, setShowOptions] = useState(false);
    const [materialName, setMaterialName] = useState(name || '');
    const { userSpace } = useSelector((state) => state.space);
    const [updateLesson, { isLoading }] = useUpdateCourseLessonMutation();
    const [updateTest] = useUpdateCourseTestMutation();
    const [removeLesson] = useRemoveCourseLessonMutation();
    const [removeTest] = useRemoveCourseTestMutation();
    const navigate = useNavigate();
    const [editorOn, setEditorOn] = useState(name ? false : isEditorOn);
    const { t } = useTranslation('common');
    const onRenameClick = () => {
        setEditingMaterial(materialId);
        setShowOptions(false);
        setEditorOn(true);
        setMaterialName(name || '');
    };
    const newName = (value) => {
        return (
            value ||
            `${
                type === 'lesson' ? t('NEW_LESSON') : t('NEW_TEST')
            } ${materialOrder}`
        );
    };
    const updateMaterial = (newValue) => {
        return type === 'test'
            ? updateTest({
                  spaceId: userSpace?.id,
                  id: materialId,
                  data: {
                      name: newValue,
                  },
              })
            : updateLesson({
                  spaceId: userSpace?.id,
                  lessonId: materialId,
                  data: {
                      name: newValue,
                  },
              });
    };
    const resetNameEdit = async () => {
        await updateMaterial(newName(name));
        setEditingMaterial(null);
        setMaterialName(newName(name));
        updateMaterialName(materialId, newName(materialName));
        setEditorOn(false);
    };
    const saveMaterialEdit = async () => {
        await updateMaterial(newName(materialName));
        setEditingMaterial(null);
        updateMaterialName(materialId, newName(materialName));
        setEditorOn(false);
        setMaterialName('');
    };
    const materialDelete = async () => {
        removeMaterial(materialId, parentId, type);
        type === 'test'
            ? await removeTest({ spaceId: userSpace?.id, id: materialId })
            : await removeLesson({
                  spaceId: userSpace?.id,
                  lessonId: materialId,
              });
        setShowOptions(false);
    };
    const navigateToMaterial = () => {
        if (!editorOn) {
            let url = '';
            if (previewStatus === PREVIEW_STATUS.preview) {
                url += PREVIEW;
            } else {
                url += `/content/courses${
                    previewStatus === PREVIEW_STATUS.draft ? '/create' : ''
                }/${courseId}`;
            }
            url += `/${type}/${materialId}`;
            navigate({
                pathname: url,
                search: previewStatusSearchParam
                    ? `?previewStatus=${previewStatusSearchParam}`
                    : '',
            });
        }
    };

    const { width } = useWindowSize();

    const materialEmpty = () => {
        return width > 400
            ? type === 'test'
                ? t('EMPTY_TEST')
                : t('EMPTY_LESSON')
            : t('EMPTY_MATERIAL_MOBILE');
    };

    const onUpdateMaterialName = async () => {
        if (!name) {
            await updateMaterial(newName(name));
        }
    };
    return (
        <>
            <Grid
                item
                container
                wrap={'nowrap'}
                xs={empty ? 8 : 10}
                alignItems={'center'}
                onClick={async () => {
                    await onUpdateMaterialName();
                    navigateToMaterial();
                }}
                className={'course-material__info'}
            >
                <IconButton className={'course-material__icon icon--no-stroke'}>
                    <Icon name={type} />
                </IconButton>
                {editorOn ? (
                    <TextareaAutosize
                        placeholder={
                            type === 'test'
                                ? t('ENTER_TEST_NAME')
                                : t('ENTER_LESSON_NAME')
                        }
                        className={'course-material__textarea'}
                        maxLength={200}
                        value={materialName}
                        onChange={(e) => setMaterialName(e.target.value)}
                        autoFocus
                    />
                ) : (
                    <Heading
                        className={classNames('course-material__title', {
                            empty,
                        })}
                        variant={'h5'}
                    >
                        {name || newName(name)}
                    </Heading>
                )}
            </Grid>
            <Grid
                item
                container
                alignItems={'center'}
                xs={empty ? 4 : 2}
                justifyContent={'flex-end'}
            >
                {editorOn ? (
                    <>
                        <IconButton onClick={saveMaterialEdit}>
                            <Icon
                                className={'course-material__check'}
                                name={'check'}
                            />
                        </IconButton>
                        <IconButton onClick={resetNameEdit}>
                            <Icon
                                className={'course-material__decline'}
                                name={'decline'}
                            />
                        </IconButton>
                    </>
                ) : (
                    <>
                        {empty && (
                            <Chip
                                className={'course-material__chip'}
                                label={materialEmpty()}
                                size={'small'}
                            />
                        )}
                        {previewStatus === PREVIEW_STATUS.draft && (
                            <CoursesContentDropdown
                                show={showOptions}
                                setShow={setShowOptions}
                                icon={'more'}
                                items={[
                                    {
                                        id: nanoid(),
                                        key: 'lesson',
                                        iconName: 'folder',
                                        name: `${
                                            type === 'lesson'
                                                ? t('GO_TO_LESSON')
                                                : t('GO_TO_TEST')
                                        }`,
                                        onClick: navigateToMaterial,
                                    },
                                    {
                                        id: nanoid(),
                                        key: 'rename',
                                        iconName: 'edit',
                                        name: t('RENAME_MATERIAL'),
                                        onClick: onRenameClick,
                                    },
                                    {
                                        id: nanoid(),
                                        key: 'remove',
                                        iconName: 'trash',
                                        name: t('REMOVE_MATERIAL'),
                                        onClick: materialDelete,
                                    },
                                ]}
                            />
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

export default CourseMaterialItem;
