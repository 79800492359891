import { nanoid } from 'nanoid';

const ranges = [
    {
        id: nanoid(),
        text: 'SETTINGS_SQUARE_MODAL_ZOOM',
        name: 'scale',
        min: 1,
        max: 10,
        step: 0.1,
    },
    {
        id: nanoid(),
        text: 'SETTINGS_SQUARE_MODAL_ROTATE',
        name: 'rotate',
        min: 0,
        max: 360,
        step: 1,
    },
];

const buttons = ({ onUploadOpen, onSave, isLoading }) => [
    {
        id: nanoid(),
        variant: 'secondary',
        text: 'CHANGE_PHOTO',
        onClick: () => onUploadOpen('changeBtn'),
    },
    {
        id: nanoid(),
        variant: 'primary',
        text: 'SAVE',
        isLoading,
        onClick: onSave,
    },
];

export { ranges, buttons };
