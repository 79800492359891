import { Grid } from '@mui/material';
import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import classnames from 'classnames';
import CoursesContentDropdown from 'pages/courses/CoursesContentDropdown/CoursesContentDropdown';
import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import './CreateCourseContent.scss';
import { Container } from 'react-smooth-dnd';
import useMaterialMove from 'helpers/hooks/useMaterialMove';
import { useCreateMaterialMutation } from 'api/services';
import { useParams } from 'react-router-dom';
import LessonsWithoutModule from 'pages/courses/CoursesModules/CoursesModule/components/LessonsWithoutModule';
import { useSelector } from 'react-redux';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useTranslation } from 'react-i18next';
import CoursesModules from 'pages/courses/CoursesModules/CoursesModules';
import { useMaterialsContext } from 'components/layout/MaterialsContext/MaterialsContext';

const CreateCourseContent = ({ materials }) => {
    const { t } = useTranslation('common');

    const { editingMaterial } = useSelector((state) => state.course);

    const previewStatus = usePreview();

    const [newMaterialId, setNewMaterialId] = useState(null);

    const [contentModalShow, setContentModalShow] = useState(false);

    const { userSpace } = useSelector((state) => state.space);
    const { courseId } = useParams();

    const [createMaterial] = useCreateMaterialMutation();

    const onCreate = async (type, parentId) => {
        if (previewStatus !== PREVIEW_STATUS.draft || editingMaterial) {
            return;
        }
        const { data: newMaterial } = await createMaterial({
            spaceId: userSpace?.id,
            courseId,
            type,
            parentId,
        });
        if (newMaterial?.id && type !== 'module') {
            setNewMaterialId(newMaterial?.id);
        }
        addMaterial({ ...newMaterial, empty: true });
        setContentModalShow(false);
    };

    const { allLessons, updateLessons, addMaterial } = useMaterialsContext();

    useEffect(() => {
        updateLessons();
    }, [materials]);
    return (
        <div className={'course-content'}>
            <div className={'course-materials'}>
                <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className={'course-materials__head icon--no-stroke'}
                >
                    <Heading
                        className={classnames('course-materials__title', {
                            'course-materials__title--active': materials.length,
                        })}
                        variant={'h4'}
                    >
                        {t('COURSE_CONTENT')}
                    </Heading>
                    {previewStatus === PREVIEW_STATUS.draft && (
                        <CoursesContentDropdown
                            show={contentModalShow}
                            setShow={setContentModalShow}
                            items={[
                                {
                                    id: nanoid(),
                                    key: 'module',
                                    iconName: 'folder',
                                    name: t('MODULE'),
                                    onClick: () => onCreate('module'),
                                },
                                {
                                    id: nanoid(),
                                    key: 'lesson',
                                    iconName: 'lesson',
                                    name: t('LESSON'),
                                    onClick: () => onCreate('lesson'),
                                },
                                {
                                    id: nanoid(),
                                    key: 'test',
                                    iconName: 'test',
                                    name: t('TEST'),
                                    onClick: () => onCreate('test'),
                                },
                            ]}
                        />
                    )}
                </Grid>
                <div className={'course-materials__content'}>
                    <Container
                        dragHandleSelector=".lesson-move"
                        orientation={'vertical'}
                    >
                        <div className={'course-materials__only-lessons'}>
                            {allLessons
                                ?.filter((module) => module?.contentHolder)
                                .map((module, index) => (
                                    <LessonsWithoutModule
                                        key={module.id}
                                        module={module}
                                        newMaterialId={newMaterialId}
                                        index={index}
                                    />
                                ))}
                        </div>
                        <CoursesModules
                            onCreate={onCreate}
                            newMaterialId={newMaterialId}
                        />
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default CreateCourseContent;
