import React from 'react';
import useIsMobile from 'helpers/hooks/useIsMobile';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import {
    DialogModal,
    Modal,
} from '@akhter-studios/classlytics-front-ui-components';

const DialogModalCustom = ({
    open,
    modal,
    setOpen,
    children,
    customHeight,
    className,
}) => {
    const isMobile = useIsMobile();
    if (isMobile) {
        return (
            <BottomSheetCustom
                open={open}
                setOpen={setOpen}
                minHeight={customHeight ? customHeight - 50 : null}
            >
                {children}
            </BottomSheetCustom>
        );
    }
    if (modal) {
        return (
            <Modal isOpen={open} setIsOpen={setOpen}>
                {children}
            </Modal>
        );
    }
    return (
        <DialogModal
            className={className}
            sx={{ pr: 2 }}
            isOpen={open}
            setIsOpen={setOpen}
        >
            {children}
        </DialogModal>
    );
};

export default DialogModalCustom;
