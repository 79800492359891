import { baseApi } from 'api/xhr';

export const searchApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        searchUsers: build.query({
            query: (params) => ({
                url: `/search/users/`,
                params,
            }),
        }),
        searchCourses: build.query({
            query: (params) => ({
                url: `/search/courses/`,
                params,
            }),
        }),
        searchDataCourses: build.query({
            query: (params) => ({
                url: `/search/courses/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
            keepUnusedDataFor: 5,
        }),
    }),
});

export const {
    useSearchUsersQuery,
    useLazySearchUsersQuery,
    useSearchCoursesQuery,
    useLazySearchCoursesQuery,
    useSearchDataCoursesQuery,
    useLazySearchDataCoursesQuery,
} = searchApi;
