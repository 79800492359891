import { CourseItem } from 'pages/users/common';
import SelectableModal from 'pages/users/common/UsersModalWrapper/SelectableModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLazyFetchUserGroupCoursesQuery } from 'api/services';
import { useState, useEffect } from 'react';
import { useDebounce } from 'helpers/hooks';
import { loadMore } from 'pages/users/functions';

const CoursesModal = ({
    isOpen,
    setIsOpen,
    currentCourses,
    setFieldValue,
    onBack,
}) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [trigger] = useLazyFetchUserGroupCoursesQuery();
    const [courses, setCourses] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState(currentCourses);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const status = 'active';

    const onSelectAll = () => {
        if (isSelectedAll) {
            setIsSelectedAll(false);
            setSelectedCourses([]);
        } else {
            setIsSelectedAll(true);
            setSelectedCourses(
                courses.results.map(({ id, name, image }) => ({
                    value: id,
                    label: name || '',
                    image,
                }))
            );
        }
    };
    const onSearch = (e) => setSearch(e.target.value);
    const onSelect = (e, course) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedCourses([
                ...selectedCourses,
                {
                    value: course.id,
                    label: course.name || '',
                    image: course.image,
                },
            ]);
        } else {
            setSelectedCourses(
                selectedCourses.filter((s) => s.value !== value)
            );
        }
    };

    const onAddCourses = () => {
        setFieldValue('courses', selectedCourses);
        onBack();
    };

    const loadMoreCourses = async () =>
        loadMore({
            items: courses.results,
            hasMore: courses.hasMore,
            requestData: {
                space_id: userSpace.id,
                status,
                page: page + 1,
                page_size: pageSize,
                search: debouncedSearch,
            },
            setItems: setCourses,
            setPage,
            request: trigger,
        });

    useEffect(
        () =>
            userSpace &&
            trigger({
                space_id: userSpace.id,
                status,
                page,
                page_size: pageSize,
            }).then(({ data }) => setCourses(data)),
        [userSpace]
    );
    useEffect(
        () =>
            trigger({
                space_id: userSpace.id,
                status,
                page: 1,
                page_size: pageSize,
                search: debouncedSearch,
            }).then(({ data }) => {
                setPage(1);
                setCourses(data);
            }),
        [debouncedSearch]
    );
    return (
        <SelectableModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('CHOOSE_COURSES')}
            searchPlaceholder={t('SEARCH_COURSES')}
            btnText={t('SAVE')}
            isSearchable={true}
            isFilterable={true}
            isBack={true}
            btnFixed={true}
            btnDisabled={selectedCourses.length === 0}
            isSelectedAll={isSelectedAll}
            hasMore={courses?.hasMore}
            search={search}
            currentCount={courses?.results?.length}
            maxCount={courses?.count}
            items={courses?.results}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onLoadMore={loadMoreCourses}
            onSubmit={onAddCourses}
            onBack={onBack}
            renderItem={(course) => (
                <CourseItem
                    key={course.id}
                    isSelected={selectedCourses
                        .map((c) => c.value)
                        .includes(course.id)}
                    onSelect={onSelect}
                    {...course}
                />
            )}
        />
    );
};

export default CoursesModal;
