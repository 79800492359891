import React from 'react';
import { Icon } from 'components/common';
import { Link, useParams } from 'react-router-dom';
import { moduleTypeUrl } from 'pages/course-test/functions';
import { courseModuleItemState } from 'pages/courses/CoursesDetailed/constants';

const CourseContentItem = ({
    type,
    materialId,
    isPassed,
    isAvailable,
    children,
    name,
    id,
}) => {
    const { spaceId, courseId, id: courseIdOptional } = useParams();
    const iconCheck = courseModuleItemState({ isPassed, isAvailable });
    return (
        <Link
            to={
                iconCheck === 'block'
                    ? '#'
                    : moduleTypeUrl({
                          id,
                          courseId: courseIdOptional || courseId,
                          materialId,
                          type,
                          spaceId,
                      })
            }
            className="courses-detailed-content-icon"
            key={id}
        >
            <div className="d-flex">
                <Icon name={type} />
                <div className="courses-detailed-content-icon-title">
                    {name || '-'}
                </div>
            </div>
            <Icon name={iconCheck} />
        </Link>
    );
};

export default CourseContentItem;
