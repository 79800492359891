import React from 'react';
import './DetailCourses.scss';
import UsersHeadingTabs from 'components/common/UsersHeadingTabs/UsersHeadingTabs';
import { tabs } from 'pages/analytics/views/courses/DetailCourses/consts';
import DetailCourseProfile from 'pages/analytics/views/courses/DetailCourses/DetailCourseProfile';
import { Outlet, useParams } from 'react-router-dom';
import { useFetchDetailCourseQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useSelector } from 'react-redux';

const DetailCourses = () => {
    const { userSpace } = useSelector((state) => state.space);
    const { id } = useParams();
    const { data } = useFetchDetailCourseQuery({ spaceId: userSpace?.id, id });

    return (
        <section className="layout-main analytics-detail-course">
            <div className="analytics-detail-course__wrapper students">
                <DetailCourseProfile
                    data={
                        {
                            ...data?.Course,
                            average_score: data?.average_score,
                            passage_time: data?.passage_time,
                        } || {}
                    }
                />
                <UsersHeadingTabs tabs={tabs()} />
                <Outlet
                    context={{
                        totalLessons: data?.total_lessons,
                        totalStudents: data?.total_students,
                    }}
                />
            </div>
        </section>
    );
};

export default DetailCourses;
