import {
    Checkbox,
    Heading,
} from '@akhter-studios/classlytics-front-ui-components';
import GroupImg from 'assets/img/png/group.png';
import classNames from 'classnames';
import './AddGroupsModalItem.scss';

const AddGroupsModalItem = ({ id, title, isSelected, onSelect }) => {
    const onContainerSelected = () => {
        onSelect({
            target: { checked: !isSelected, value: id },
        });
    };
    return (
        <div
            className={classNames('add-groups-modal-item', {
                selected: isSelected,
            })}
            onClick={onContainerSelected}
        >
            <img
                className={'add-groups-modal-item__img'}
                src={GroupImg}
                alt={title}
            />
            <div className={'add-groups-modal-item__content'}>
                <Heading
                    className={'add-groups-modal-item__name'}
                    variant={'h6'}
                >
                    {title}
                </Heading>
            </div>
            <Checkbox
                value={id}
                checked={isSelected}
                className={'add-groups-modal-item__checkbox'}
                onChange={onSelect}
            />
        </div>
    );
};

export default AddGroupsModalItem;
