import { createSlice } from '@reduxjs/toolkit';
import { courseApi, courseInjectionApi, searchCourseApi } from 'api/services';

export const foundCoursesSlice = createSlice({
    name: 'foundCourses',
    initialState: { search: '', courses: [], currentCourseName: '' },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                searchCourseApi.endpoints.getCourseByName.matchFulfilled,
                (state, { meta, payload }) => {
                    const { search } = meta.arg.originalArgs;
                    state.courses = payload;
                    state.search = search;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.fetchCourse.matchFulfilled,
                (state, { payload }) => {
                    state.currentCourseName = payload.name;
                }
            )
            .addMatcher(
                courseInjectionApi.endpoints.updateCourseName.matchFulfilled,
                (state, { payload }) => {
                    state.currentCourseName = payload.name;
                }
            )
            .addMatcher(
                courseApi.endpoints.createCourse.matchFulfilled,
                (state, { payload }) => {
                    state.currentCourseName = payload.name;
                }
            );
    },
});

export const { setFoundCourses } = foundCoursesSlice.actions;
export default foundCoursesSlice.reducer;
