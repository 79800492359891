import React from 'react';

const DetailCourseStatisticsItem = ({ title, value }) => {
    return (
        <div className="statistics-item">
            <div className="title">{title}</div>
            <div className="value">{value}</div>
        </div>
    );
};

export default DetailCourseStatisticsItem;
