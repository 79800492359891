import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
} from 'react';
import { getEditorI18n, getEditorJsTools } from './constants';
import DragDrop from 'editorjs-drag-drop';
import './Editor.scss';
import { createReactEditorJS } from '@akhter-studios/classlytics-front-editor';
import Undo from 'editorjs-undo';
import { REMOVING_BLOCKS } from 'utils/functions/localStorage';
import { useAction } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Editor = forwardRef(
    (
        {
            tools,
            initialData,
            onSave = () => {},
            outsideSave,
            fetchStudentURL,
            onChange = () => {},
            placeholderOnEmpty = 'EDITOR_PLACEHOLDER_EMPTY',
            readOnly,
            dataUpdated,
            courseId,
        },
        ref
    ) => {
        const { t } = useTranslation('common');
        const { userSpace } = useSelector((state) => state.space);
        const { makeReady } = useAction();
        const rightInitialData = useMemo(
            () => (initialData?.blocks?.length ? initialData : null),
            [initialData]
        );
        const editorCore = React.useRef(null);
        const handleInitialize = React.useCallback((instance) => {
            editorCore.current = instance;
        }, []);
        const handleReady = () => {
            const editor = editorCore.current._editorJS;
            new Undo({
                editor,
                config: {
                    debounceTimer: 300,
                },
            });
            new DragDrop(editor);
            makeReady();
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
            });
        };

        useEffect(() => {
            editorCore?.current?._editorJS?.readOnly?.toggle();
        }, [readOnly]);

        const handleRender = async (data) => {
            if (data?.blocks) {
                await editorCore.current?._editorJS?.isReady;
                await editorCore.current.render(data);
            }
        };
        useEffect(() => {
            if (rightInitialData && !dataUpdated) {
                handleRender(rightInitialData).then();
            }
        }, [rightInitialData]);

        const handleSave = async () => {
            if (readOnly) {
                return;
            }
            try {
                const savedData = await editorCore.current.save();
                onSave(savedData);
                //await removeFiles();
            } catch (e) {
                console.error(e);
            } finally {
                localStorage.removeItem(REMOVING_BLOCKS);
            }
        };
        useImperativeHandle(ref, () => ({
            saveRef() {
                handleSave().then();
            },
        }));
        const ReactEditorJS = createReactEditorJS();
        return (
            <ReactEditorJS
                readOnly={readOnly}
                tools={tools || getEditorJsTools(t, userSpace?.id, courseId)}
                onReady={handleReady}
                onInitialize={handleInitialize}
                defaultValue={null}
                autofocus={true}
                placeholder={readOnly ? '' : t(placeholderOnEmpty)}
                i18n={getEditorI18n(t)}
                data={rightInitialData}
                onChange={onChange}
            />
        );
    }
);

export default Editor;
