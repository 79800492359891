import React from 'react';
import { Icon } from 'components/common';

const DropzonePlaceholder = () => {
    return (
        <div className="dropzone-placeholder">
            <div className="dropzone-placeholder__border">
                <Icon name="upload-dropzone-icon" />
                <div className="dropzone-placeholder__title">
                    Загрузить файл
                </div>
            </div>
        </div>
    );
};

export default DropzonePlaceholder;
