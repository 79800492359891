import { ProfileMenu } from 'pages/profile/common';
import { renderComponent } from 'pages/profile/consts';
import { useLocation } from 'react-router-dom';
import './ProfileDetails.scss';
import { Plug } from 'components/common';
const ProfileDetails = () => {
    const { search } = useLocation();

    return (
        <article className={'profile-details layout-main'}>
            <Plug
                title={'Профиль в разработке'}
                description={
                    'Скорем времени вы сможете просматривать профили других участников'
                }
            />
        </article>
    );
};

export default ProfileDetails;
