import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import CreateBlock from 'pages/course-test/views/CreateBlock';
import BlockItem from 'pages/course-test/views/BlockItem/BlockItem';
import { Container, Draggable } from 'react-smooth-dnd';
import { createTest } from 'pages/course-test/functions';
import { Icon, Sidebar } from 'components/common';
import { useDebouncedCallback } from 'use-debounce';
import SettingTest from 'pages/course-test/views/SettingTest/SettingTest';
import {
    DialogModal,
    DefaultInput,
    Modal,
} from '@akhter-studios/classlytics-front-ui-components';
import SettingTestContent from 'pages/course-test/views/SettingTest/SettingTestContent/SettingTestContent';
import { getDetailTestApi } from 'pages/course-test/course-test-services';
import { useAction } from 'helpers/hooks';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import cn from 'classnames';
import HeaderMentorCourse from 'components/common/Header/HeaderMentorCourse';
import { useTranslation } from 'react-i18next';
import {
    banToNegativeNumber,
    onlyCenterMinHeight,
} from 'utils/functions/helpers';
import UploadFileBtn from 'pages/course-test/views/UploadFile/UploadFileBtn';
import UploadFile from 'pages/course-test/views/UploadFile/UploadFile';
import useAutoSizeTextarea from 'helpers/hooks/useAutoSizeTextarea';

const CreateTest = () => {
    const previewStatus = usePreview();
    const [test, setTest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [uploadFileOpen, setIsUploadFileOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const { toggleDataUpdated } = useAction();
    const [switches, setSwitches] = useState({
        percent: false,
        time: false,
        limit: false,
        random: false,
        consider: false,
    });
    const [settingTest, setSettingTest] = useState({
        duration: null,
        minAcceptance: null,
        limit: null,
        random: false,
        consider: false,
    });
    const { userSpace } = useSelector((state) => state.space);
    const { testId } = useParams();
    const testNameRef = useRef(null);
    const { isOpenSettings } = useSelector((state) => state.test);
    const { toggleOpenningSettingsAction } = useAction();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (userSpace) {
            setIsLoading(true);
            getDetailTestApi(userSpace?.id, testId)
                .then((result) => {
                    setTest(result);
                    setSettingTest({
                        ...settingTest,
                        limit: result.limit || null,
                        random: result.random || false,
                        consider: result.consider || false,
                        duration: result.duration,
                        minAcceptance: result.minAcceptance,
                    });
                    setSwitches({
                        ...switches,
                        percent: Boolean(result.minAcceptance),
                        time: Boolean(result.duration),
                        limit: Boolean(result.limit),
                        random: Boolean(result.random),
                        consider: Boolean(result.consider),
                    });
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false))
                .finally(() => setIsLoading(false));
        }
    }, [userSpace, success]);
    const { t } = useTranslation('common');
    const { ...tests } = createTest({
        test,
        setTest,
        spaceId: userSpace?.id,
        setIsOpen,
        isOpen,
        test_id: testId,
        switches,
        setSwitches,
        settingTest,
        t,
    });

    const onChangeDebounce = useDebouncedCallback((e, index) => {
        tests.onChangeBlockInfo(e, index, true);
    }, 500);

    const onChangeNameTestDebounce = useDebouncedCallback((name, value) => {
        tests.updateTest(name, value);
        toggleDataUpdated(true);
    }, 500);

    const onChangeDebounceLabel = useDebouncedCallback(
        ({ index, value, childIndex }) => {
            tests.handleChangeLabel({ index, value, childIndex }, true);
        },
        500
    );
    const handleChangeNameTest = (name, value) => {
        tests.updateTest(name, value, true);
        onChangeNameTestDebounce(name, value);
    };

    const handleChangeSetting = ({ target: { name, value } }) => {
        setSettingTest({
            ...settingTest,
            [name]: banToNegativeNumber(value, name === 'minAcceptance'),
        });
    };

    const handleResetValueFromSwitch = (name) => {
        setSettingTest((prev) => ({ ...prev, [name]: null }));
    };

    useAutoSizeTextarea(testNameRef.current, test?.name);

    if (isLoading) {
        return (
            <div className={'layout-main'}>
                <div style={onlyCenterMinHeight(60)}>
                    {t('GENERAL_LOADING')}
                </div>
            </div>
        );
    }
    return (
        <>
            {previewStatus !== PREVIEW_STATUS.preview && (
                <>
                    <HeaderMentorCourse />
                    <Sidebar />
                </>
            )}
            <section
                className="section-test__create layout-main"
                id="section-test__create"
            >
                <textarea
                    onChange={(e) => {
                        if (e.target.value.length < 255) {
                            handleChangeNameTest('name', e.target.value);
                        }
                    }}
                    ref={testNameRef}
                    value={test?.name}
                    placeholder={t('TEST_NAME_PLACEHOLDER')}
                    className="test-name test-name-mentor"
                    disabled={previewStatus !== PREVIEW_STATUS.draft}
                />

                <Container
                    dragHandleSelector=".test-move"
                    onDrop={(res) =>
                        previewStatus === PREVIEW_STATUS.draft
                            ? tests.onDrop(res, {})
                            : {}
                    }
                    lockAxis="y"
                >
                    {test?.criterion.map((x, index) => (
                        <Draggable key={`${x.id}-${index}-${x.tempId}`}>
                            <div
                                className={cn('block-wrapper', {
                                    'block-wrapper__read-only':
                                        previewStatus !== PREVIEW_STATUS.draft,
                                })}
                            >
                                <div className="test-move">
                                    <Icon name="move" />
                                </div>
                                <BlockItem
                                    {...tests}
                                    onChangeDebounce={onChangeDebounce}
                                    index={index}
                                    item={x}
                                    spaceId={userSpace?.id}
                                    onChangeDebounceLabel={
                                        onChangeDebounceLabel
                                    }
                                    {...x}
                                />
                            </div>
                        </Draggable>
                    ))}
                </Container>
                {previewStatus === PREVIEW_STATUS.draft && (
                    <CreateBlock
                        tests={test?.criterion || []}
                        addBlock={tests.addBlock}
                    />
                )}
                {previewStatus === PREVIEW_STATUS.draft && (
                    <SettingTest setIsOpen={toggleOpenningSettingsAction} />
                )}
                {previewStatus === PREVIEW_STATUS.draft && (
                    <UploadFileBtn setIsOpen={setIsUploadFileOpen} />
                )}
                <Modal isOpen={uploadFileOpen} setIsOpen={setIsUploadFileOpen}>
                    <UploadFile
                        setSuccess={setSuccess}
                        close={() => setIsUploadFileOpen(false)}
                    />
                </Modal>
                <DialogModal
                    isOpen={isOpenSettings}
                    setIsOpen={toggleOpenningSettingsAction}
                >
                    <SettingTestContent
                        saveTest={tests.saveSettingTest}
                        test={test}
                        handleResetValueFromSwitch={handleResetValueFromSwitch}
                        handleChangeSetting={handleChangeSetting}
                        setTest={setTest}
                        settingTest={settingTest}
                        switches={switches}
                        handleChange={tests.handleChangeSwitch}
                    />
                </DialogModal>
            </section>
        </>
    );
};

export default CreateTest;
