import { tabs } from './consts';
import { Link, useLocation } from 'react-router-dom';
import {
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
import './HeaderSettingsSpaceMenu.scss';
import classNames from 'classnames';

const HeaderSettingsSpaceMenu = ({ setOpen }) => {
    const { t } = useTranslation('common');
    const { search: locationSearch } = useLocation();

    return (
        <List
            className={'header-settings-space-menu__list'}
            items={tabs}
            renderItem={({ id, icon, text, search, to }) => (
                <li className={'header-settings-space-menu__item'} key={id}>
                    <Link
                        className={classNames(
                            'header-settings-space-menu__link',
                            {
                                active:
                                    search ===
                                    (locationSearch.length > 0
                                        ? locationSearch
                                        : '?scope=company'),
                            }
                        )}
                        to={to}
                        onClick={() => setOpen(false)}
                    >
                        <Icon name={icon} />
                        <Typography
                            className={'header-settings-space-menu__text'}
                            variant={'body4'}
                        >
                            {t(text)}
                        </Typography>
                    </Link>
                </li>
            )}
        />
    );
};

export default HeaderSettingsSpaceMenu;
