import {
    monospace,
    sansSerif,
    serif,
} from 'components/feature/GlobalSettingsSpace/consts';

const findFontTypes = (font) => {
    if (sansSerif.includes(font)) {
        return 'sans-serif';
    }

    if (serif.includes(font)) {
        return 'serif';
    }

    if (monospace.includes(font)) {
        return 'monospace';
    }
};

export { findFontTypes };
