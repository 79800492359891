import React, { useCallback, useRef, useState } from 'react';
import { Icon } from 'components/common';
import { useDropzone } from 'react-dropzone';
import excelTemplate from 'assets/files/example.xlsx';
import './UploadFile.scss';
import { uploadExcelFile } from 'pages/course-test/course-test-services';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/functions';
import FileState from 'pages/course-test/views/UploadFile/FileState';
import { useTranslation } from 'react-i18next';
const UploadFile = ({ setSuccess, close }) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const { testId } = useParams();
    const [percent, setPercent] = useState(0);
    const [file, setFile] = useState('');

    const cancelRef = useRef(null);

    const cancelClick = (isClear) => {
        if (!isClear) {
            cancelRef.current();
        }
        setFile('');
        setPercent(0);
    };

    const uploadExcel = (file) => {
        setFile(file);
        const formData = new FormData();
        formData.append('file', file);
        uploadExcelFile({
            spaceId: userSpace?.id,
            id: testId,
            file: formData,
            setPercent,
            cancelRef,
        })
            .then((result) => {
                notify('success', t('MENTOR_TEST_QUESTION_IMPORT_SUCCESS'));
            })
            .catch((err) =>
                notify('error', t('MENTOR_TEST_QUESTION_IMPORT_FAILED'))
            )
            .finally(() => {
                setTimeout(() => setSuccess((prev) => !prev), 500);
                close();
                cancelClick(true);
            });
    };

    const onDrop = useCallback((acceptedFiles) => {
        uploadExcel(acceptedFiles[0]);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });
    return (
        <div className="test-excel">
            <div className="j-c-b test-excel__top-wrapper">
                <div className="test-excel__title">
                    {t('MENTOR_TEST_QUESTION_IMPORT_EXCEL')}
                </div>
                <a
                    download
                    href={excelTemplate}
                    className="test-excel__template d-flex"
                >
                    <Icon name="download" />
                    <div>{t('MENTOR_TEST_QUESTION_DOWNLOAD_TEMPLATE')}</div>
                </a>
            </div>
            <div className="test-excel__desc">
                {t('MENTOR_TEST_QUESTION_DOWNLOAD_TEMPLATE_DESC')}
            </div>
            <div className="test-excel__content">
                {percent > 0 ? (
                    <FileState
                        cancel={cancelClick}
                        file={file}
                        percent={percent}
                    />
                ) : (
                    <div className="test-excel__upload " {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="d-flex">
                            <Icon name="upload-test-excel" />
                            <div className="test-excel__upload-title">
                                {t('MENTOR_TEST_QUESTION_UPLOAD_TEXT')}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadFile;
