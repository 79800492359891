import { useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsCard from 'pages/settings/common/SettingsCard';
import getFaviconEl from 'pages/settings/common/SettingsCompany/SettingsCompanyFavIcon/getFaviconEl';
import defaultFavIcon from 'assets/img/svg/default-logo.svg';
import './SettingsCompanyFavIcon.scss';
import { useTranslation } from 'react-i18next';
import {
    useUpdateSpaceAssetsMutation,
    useDeleteSpaceImageMutation,
} from 'api/services';
const SettingsCompanyFavIcon = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [currentFavIcon, setCurrentFavIcon] = useState(
        userSpace?.favicon || getFaviconEl().href
    );
    const [updateSpaceAssets] = useUpdateSpaceAssetsMutation();
    const [deleteSpaceImage] = useDeleteSpaceImageMutation();
    const handleUpload = async (e) => {
        const file = e.target.files[0];
        const path = URL.createObjectURL(file);
        setCurrentFavIcon(path);
        const favicon = getFaviconEl();
        favicon.href = path;
        const formData = new FormData();
        formData.append('favicon', file);
        await updateSpaceAssets({
            id: userSpace.id,
            formData,
        });
    };
    const onUpload = () => {
        document.getElementById('faviconUploadBtn').click();
    };

    const onRemove = async () => {
        setCurrentFavIcon(defaultFavIcon);
        const favicon = getFaviconEl();
        favicon.href = defaultFavIcon;
        await deleteSpaceImage({
            id: userSpace.id,
            image_type: 'favicon',
        });
    };
    return (
        <>
            <input
                className={'settings-company-fav-icon__upload'}
                type="file"
                id="faviconUploadBtn"
                accept={
                    'image/x-icon image/png, image/svg image/jpg image/jpeg'
                }
                onChange={handleUpload}
            />
            <SettingsCard
                title={t('SETTINGS_FAVICON_TITLE')}
                description={t('SETTINGS_FAVICON_DESC')}
                onUpload={onUpload}
                onRemove={onRemove}
            >
                <div className={'settings-company-fav-icon__tag'}>
                    <span>
                        <img src={currentFavIcon} alt={userSpace.companyName} />
                        <p>{userSpace.companyName}</p>
                    </span>
                </div>
            </SettingsCard>
        </>
    );
};

export default SettingsCompanyFavIcon;
