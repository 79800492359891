import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateSpaceMutation } from 'api/services';

const useUpdateSpaceEffect = ({
    field,
    fieldName,
    fieldRequestName,
    debouncedField,
}) => {
    const { userSpace } = useSelector((state) => state.space);
    const [updateSpace, { isError, error }] = useUpdateSpaceMutation();

    useEffect(() => {
        if (field !== userSpace[fieldName]) {
            updateSpace({
                id: userSpace?.id,
                [fieldRequestName]: debouncedField,
            });
        }
    }, [debouncedField]);

    return { isError, error };
};

export default useUpdateSpaceEffect;
