import axios from 'axios';
import { BASE_URL, BASE_URL_AUTH_SERVICE } from 'configs';
import { store } from 'store';
import i18next from 'i18next';
import { CONTENT_COURSES_ROUTE } from 'utils/consts';

const axiosRequest = axios.create({
    baseURL: BASE_URL,
});

const authRequest = axios.create({
    baseURL: BASE_URL_AUTH_SERVICE,
    withCredentials: false,
});
const getInterceptorRequest = (baseUrl) => {
    baseUrl.interceptors.request.use(
        (request) => {
            request.headers.Authorization = `Bearer ${
                store.getState().auth.token
            }`;
            return request;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    baseUrl.interceptors.request.use((config) => {
        config.headers['Accept-Language'] = i18next.language;
        return config;
    });
};

const getInterceptorResponse = (baseUrl) => {
    baseUrl.interceptors.response.use(
        (response) => {
            return response.data;
        },
        (error) => {
            if (error.response.status === 403) {
                window.location.href = CONTENT_COURSES_ROUTE;
            }
            return Promise.reject(error);
        }
    );
};

getInterceptorResponse(axiosRequest);
getInterceptorRequest(axiosRequest);
getInterceptorResponse(authRequest);
getInterceptorRequest(authRequest);

export { axiosRequest, authRequest };
