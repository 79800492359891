import HeaderLogo from 'components/common/Header/HeaderLogo';
import HeaderBreadcrumbs from 'components/common/Header/HeaderBreadcrumbs';
import HeaderHelper from 'components/common/Header/HeaderHelper';
import HeaderView from 'components/common/Header/HeaderView';
import HeaderSettings from 'components/common/Header/HeaderSettings';
import HeaderUploadCourseButton from 'components/common/Header/HeaderUploadCourseButton';
import HeaderSelectBreadcrumb from 'components/common/Header/HeaderSelectBreadcrumb';
import ArrowBack from 'components/ArrowBack/ArrowBack';
import '../Header.scss';
import './HeaderMentorCourse.scss';
import SavingStatus from 'components/common/Header/SavingStatus';
import { useSavingStatus } from 'components/layout/SavingStatusContext';
import { useSelector } from 'react-redux';
import { usePublishCourseMutation } from 'api/services';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import PublishModal from 'components/courses/PublishModal/PublishModal';
import React, { useState } from 'react';
import { useAction } from 'helpers/hooks';
import DialogModalCustom from 'components/common/DialogModalCustom/DialogModalCustom';
import CourseManagement from 'components/courses/CourseManagement/CourseManagement';
import { Icon } from '@akhter-studios/classlytics-front-ui-components';

const HeaderMentorCourse = () => {
    const { publishModalOpen } = useSelector((state) => state.course);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { setPublishModalOpen } = useAction();
    const { t } = useTranslation('common');
    const previewStatus = usePreview();
    const { status } = useSavingStatus();
    const { course } = useSelector((state) => state.course);
    const navigate = useNavigate();
    const openEditor = async () => {
        navigate(`/content/courses/create/${course?.draft?.id}`);
    };
    const { lessonId, courseId, testId } = useParams();
    const onEyeClick = () => {
        let type = lessonId ? 'lesson' : courseId ? 'courses' : 'test';
        navigate({
            pathname: `/preview/${type}/${lessonId || courseId || testId}`,
            search: `?previewStatus=${previewStatus}`,
        });
    };
    const onSettingsClick = () =>
        navigate(
            `/content/courses/create/${
                course.status === 'active' ? course?.draft?.id : courseId
            }/settings`
        );
    return (
        <header
            className={'header header-mentor-course'}
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <div className={'header-mentor-course__left'}>
                <HeaderLogo />
                <HeaderBreadcrumbs />
                {status && <SavingStatus text={status} />}
                <ArrowBack />
                <HeaderSelectBreadcrumb />
            </div>
            <div className={'header-mentor-course__right'}>
                <div className={'header-mentor-course__helper'}>
                    <HeaderView onEyeClick={onEyeClick} />
                    <HeaderSettings onNavigate={onSettingsClick} />
                    <Icon
                        onClick={() => setDialogOpen(true)}
                        className={'header-mentor-course__more'}
                        name={'dots'}
                    />
                    <HeaderUploadCourseButton
                        icon={
                            previewStatus === PREVIEW_STATUS.active
                                ? 'edit'
                                : 'upload'
                        }
                        onClick={
                            previewStatus === PREVIEW_STATUS.active
                                ? openEditor
                                : () => setPublishModalOpen(true)
                        }
                        buttonText={
                            previewStatus === PREVIEW_STATUS.active
                                ? t('EDIT_COURSE')
                                : t('PUBLISH_COURSE')
                        }
                    />
                </div>
                <HeaderHelper />
                <PublishModal
                    isOpen={publishModalOpen}
                    setIsOpen={setPublishModalOpen}
                />
                <DialogModalCustom
                    customHeight={250}
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                >
                    <CourseManagement
                        onPreviewClick={onEyeClick}
                        onSettingsClick={onSettingsClick}
                    />
                </DialogModalCustom>
            </div>
        </header>
    );
};

export default HeaderMentorCourse;
