import { useUpdateProfileMutation } from 'api/services';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { generateBirthDays, generateBirthYears } from 'pages/profile/functions';
import { months } from 'pages/profile/consts';
import { notify } from 'utils/functions';
import { Button, Input } from '@akhter-studios/classlytics-front-ui-components';
import UploadAvatar from '../UploadAvatar';
import { CustomSelect } from 'components/custom-react-select';
import '../ProfileMain.scss';
import { useSelector } from 'react-redux';
import { profileMainSchema } from 'utils/schemas';
import { PatternFormat } from 'react-number-format';
const MainForm = (props) => {
    const {
        avatar,
        firstName,
        lastName,
        middleName,
        role,
        email,
        phoneNumber,
        birthDay,
        birthMonth,
        birthYear,
    } = props;
    const { userSpace } = useSelector((state) => state.space);
    const [updateProfile, { isLoading }] = useUpdateProfileMutation();
    const { t } = useTranslation('common');
    const onSubmit = async (values) => {
        const {
            avatar,
            firstName,
            lastName,
            middleName,
            email,
            phoneNumber,
            role,
            birthDay,
            birthMonth,
            birthYear,
        } = values;

        const formData = new FormData();
        avatar &&
            typeof avatar === 'object' &&
            formData.append('avatar', avatar, avatar?.name);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('middleName', middleName);
        formData.append('email', email.toLowerCase());
        formData.append('phoneNumber', phoneNumber);
        formData.append('role', role);
        birthDay && formData.append('birthDay', birthDay.value);
        birthMonth && formData.append('birthMonth', birthMonth.value);
        birthYear && formData.append('birthYear', birthYear.value);
        birthDay &&
            birthMonth &&
            birthYear &&
            formData.append(
                'birthDate',
                `${birthYear.value}-${birthMonth.value}-${birthDay.value}`
            );
        const { error } = await updateProfile({
            id: userSpace.id,
            body: formData,
        });

        if (!error) {
            notify('success', t('NOTIFY_SUCCESSFULLY_SAVED'));
        } else if (error.phoneNumber) {
            notify('error', error.phoneNumber[0]);
        } else if (error.error) {
            notify('error', error.error);
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues: {
            avatar,
            firstName,
            lastName,
            middleName,
            role,
            email,
            phoneNumber,
            birthDay: birthDay
                ? {
                      label: birthDay,
                      value: birthDay,
                  }
                : null,
            birthMonth: birthMonth
                ? {
                      label: birthMonth,
                      value: birthMonth,
                  }
                : null,
            birthYear: birthYear
                ? {
                      label: birthYear,
                      value: birthYear,
                  }
                : null,
        },
        validateOnBlur: true,
        validationSchema: profileMainSchema(t),
        onSubmit,
    });
    const onChangeBirth = (selectedOption, name) => {
        setFieldValue(name, selectedOption);
    };
    return (
        <form className={'profile-main'} onSubmit={handleSubmit}>
            <UploadAvatar
                avatar={values.avatar}
                firstName={values.firstName}
                lastName={values.lastName}
                setFieldValue={setFieldValue}
            />
            <div className={'profile-main__fields'}>
                <Input
                    id={'profileFirstName'}
                    type={'text'}
                    name={'firstName'}
                    label={t('PROFILE_NAME')}
                    value={values.firstName}
                    error={!!(touched['firstName'] && errors['firstName'])}
                    helperText={
                        !!(touched['firstName'] && errors['firstName']) &&
                        t(errors['firstName'])
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <div className={'profile-main__group'}>
                    <Input
                        id={'profileLastName'}
                        type={'text'}
                        name={'lastName'}
                        label={t('PROFILE_LAST_NAME')}
                        value={values.lastName}
                        error={!!(touched['lastName'] && errors['lastName'])}
                        helperText={
                            !!(touched['lastName'] && errors['lastName']) &&
                            t(errors['lastName'])
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                    <Input
                        id={'profileMiddleName'}
                        type={'text'}
                        name={'middleName'}
                        label={t('PROFILE_PATRONYMIC')}
                        value={values.middleName}
                        error={
                            !!(touched['middleName'] && errors['middleName'])
                        }
                        helperText={
                            !!(touched['middleName'] && errors['middleName']) &&
                            t(errors['middleName'])
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </div>
                <Input
                    id={'profilePosition'}
                    type={'text'}
                    name={'role'}
                    label={t('PROFILE_POSITION')}
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={true}
                    fullWidth
                />
                <Input
                    id={'profileEmail'}
                    type={'text'}
                    name={'email'}
                    label={t('PROFILE_EMAIL')}
                    value={values.email}
                    error={!!(touched['email'] && errors['email'])}
                    helperText={
                        !!(touched['email'] && errors['email']) &&
                        t(errors['email'])
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
                <PatternFormat
                    id={'profilePhoneNumber'}
                    type={'text'}
                    name={'phoneNumber'}
                    label={t('PROFILE_PHONE_NUMBER')}
                    value={values.phoneNumber}
                    error={!!(touched['phoneNumber'] && errors['phoneNumber'])}
                    helperText={
                        !!(touched['phoneNumber'] && errors['phoneNumber']) &&
                        t(errors['phoneNumber'])
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    // allowEmptyFormatting
                    format="+###############"
                    customInput={Input}
                />
                <div className={'profile-main__group'}>
                    <CustomSelect
                        name={'birthDay'}
                        placeholder={t('PROFILE_BIRTH_DAY')}
                        defaultOptions
                        options={generateBirthDays({
                            month: values.birthMonth?.value,
                            year: values.birthYear?.value,
                        })}
                        value={values.birthDay}
                        onChange={(e) => onChangeBirth(e, 'birthDay')}
                        onBlur={() => {
                            handleBlur({ target: { name: 'birthDay' } });
                        }}
                    />
                    <CustomSelect
                        name={'birthMonth'}
                        placeholder={t('PROFILE_BIRTH_MONTH')}
                        options={months}
                        value={values.birthMonth}
                        onChange={(e) => onChangeBirth(e, 'birthMonth')}
                        onBlur={() => {
                            handleBlur({ target: { name: 'birthMonth' } });
                        }}
                    />
                </div>
                <CustomSelect
                    name={'birthYear'}
                    placeholder={t('PROFILE_BIRTH_YEAR')}
                    options={generateBirthYears()}
                    value={values.birthYear}
                    onChange={(e) => onChangeBirth(e, 'birthYear')}
                    onBlur={() => {
                        handleBlur({ target: { name: 'birthYear' } });
                    }}
                />
            </div>
            <Button
                type={'submit'}
                className={'profile-main__btn'}
                variant={'primary'}
                size={'small'}
                loading={isLoading}
                disabled={isLoading}
                fullWidth
            >
                {t('PROFILE_FORM_SAVE')}
            </Button>
        </form>
    );
};

export default MainForm;
