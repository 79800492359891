import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMeQuery } from 'api/services';
import {
    Avatar,
    Dropdown,
    Icon,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { Link } from 'react-router-dom';
import ProfileMenuList from 'components/common/Header/ProfileMenuList';
import './MenuProfile.scss';

const MenuProfile = () => {
    const { t } = useTranslation('common');
    const [isProfileMenuShow, setIsProfileMenuShow] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const { data: profile } = useMeQuery(
        { id: userSpace?.id },
        { skip: !userSpace }
    );

    const onProfileMenuShow = () => setIsProfileMenuShow((prev) => !prev);
    return profile ? (
        <div className={'menu-profile'}>
            <Avatar
                src={profile.avatar}
                className={'menu-profile__avatar'}
                variant={'rounded'}
                avatarText={`${
                    profile.firstName.charAt(0).toUpperCase() || ''
                }${profile.lastName.charAt(0).toUpperCase() || ''}`}
            />
            <div className={'menu-profile__wrapper'}>
                <div className={'menu-profile__content'}>
                    <Typography
                        className={'menu-profile__name'}
                        variant={'body1'}
                    >
                        {profile.firstName} {profile.lastName}
                    </Typography>
                    <Link to={'/content/profile'}>
                        <Typography
                            className={'menu-profile__link'}
                            variant={'body4'}
                        >
                            {t('GO_TO_PROFILE')}
                        </Typography>
                    </Link>
                </div>
                <Dropdown
                    className={'menu-profile__dropdown'}
                    isShow={isProfileMenuShow}
                    setIsShow={setIsProfileMenuShow}
                >
                    <Dropdown.Header>
                        <Icon
                            name={isProfileMenuShow ? 'arrow-up' : 'arrow-down'}
                            onClick={onProfileMenuShow}
                        />
                    </Dropdown.Header>
                    <Dropdown.Body>
                        <ProfileMenuList setIsShow={setIsProfileMenuShow} />
                    </Dropdown.Body>
                </Dropdown>
            </div>
        </div>
    ) : null;
};

export default MenuProfile;
