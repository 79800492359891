import { useState } from 'react';
import {
    Heading,
    Icon,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import UpdatePasswordForm from '../UpdatePasswordForm';
import './UpdatePasswordSpoiler.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const UpdatePasswordSpoiler = () => {
    const {
        profile: { passwordLastUpdated },
    } = useSelector((state) => state.profile);
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation('common');
    const onOpen = () => setIsOpen((prev) => !prev);
    return (
        <>
            <div className={'update-password-spoiler'} onClick={onOpen}>
                <div>
                    <Heading variant={'h4'}>
                        {t('PROFILE_CHANGE_PASSWORD')}
                    </Heading>
                    {passwordLastUpdated ? (
                        <Typography
                            className={'update-password-spoiler__change'}
                            variant={'body4'}
                        >
                            {t('PROFILE_PASSWORD_UPDATE_DATE', {
                                years: dayjs(passwordLastUpdated)
                                    .locale(i18n?.language || 'ru')
                                    .fromNow(),
                            })}
                        </Typography>
                    ) : null}
                </div>
                <Icon name={`arrow-${isOpen ? 'up' : 'down'}`} />
            </div>
            {isOpen ? <UpdatePasswordForm /> : null}
        </>
    );
};

export default UpdatePasswordSpoiler;
