import React from 'react';
import { reviewsEmojiData } from 'pages/analytics/common/consts';
import './index.scss';
import { useTranslation } from 'react-i18next';
const AnalyticsEmojiData = ({ data }) => {
    const { t } = useTranslation('common');
    return (
        <div className="analytics-emoji d-flex p-20-x">
            {reviewsEmojiData(data).map((x, idx) => {
                return (
                    <div key={idx} className="analytics-emoji__item">
                        <div className="d-flex">
                            <div className="analytics-emoji__item-img">
                                {x.emoji}
                            </div>
                            <div className="analytics-emoji__item-cnt">
                                {x.count}
                            </div>
                        </div>
                        <div className="analytics-emoji__item-title">
                            {t(x.title)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AnalyticsEmojiData;
