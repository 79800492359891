import {Typography} from "@akhter-studios/classlytics-front-ui-components";
import "./AuthDesc.scss";

const AuthDesc = ({text}) => {
    return (
        <Typography className={"auth-desc"} variant={"body3"}>
            {text}
        </Typography>
    );
};

export default AuthDesc;
