import { nanoid } from 'nanoid';

const tabs = ({ courseId }) => [
    {
        id: nanoid(),
        icon: 'courses',
        text: 'ABOUT_COURSE',
        search: '?scope=aboutCourse',
        to: `/content/courses/create/${courseId}/settings?scope=aboutCourse`,
    },
    {
        id: nanoid(),
        icon: 'student',
        text: 'STUDENTS',
        search: '?scope=students',
        to: `/content/courses/create/${courseId}/settings?scope=students`,
    },
    {
        id: nanoid(),
        icon: 'mentor',
        text: 'MENTORS',
        search: '?scope=mentors',
        to: `/content/courses/create/${courseId}/settings?scope=mentors`,
    },
];

export { tabs };
