import { type } from '@testing-library/user-event/dist/type';

export const setLocalStorage = (data, name) => {
    localStorage.setItem(name, JSON.stringify(data));
};

export const getLocalStorageData = (name) => {
    const item = localStorage.getItem(name);
    if (item) {
        return JSON.parse(item);
    }
    return null;
};

export const setSessionStorage = (data, name) => {
    localStorage.setItem(
        name,
        typeof data === 'object' ? JSON.stringify(data) : data
    );
};

export const getSessionStorageData = (name) => {
    const item = localStorage.getItem(name);
    if (item) {
        return JSON.parse(item);
    }
    return null;
};

export const REMOVING_BLOCKS = 'removing_blocks';

export const saveRemovingBlocks = (id) => {
    let removingBlocks = getLocalStorageData(REMOVING_BLOCKS);
    if (!removingBlocks) {
        removingBlocks = [];
    }
    removingBlocks.push(id);
    setLocalStorage(removingBlocks, REMOVING_BLOCKS);
};
