import {
    Heading,
    Typography,
    Button,
} from '@akhter-studios/classlytics-front-ui-components';
import './CoursesEmpty.scss';
import { useTranslation } from 'react-i18next';

const CoursesEmpty = ({ isLoading, onCreateCourse }) => {
    const { t } = useTranslation('common');
    return (
        <article className={'courses-empty'}>
            <Heading className={'courses-empty__title'} variant={'h3'}>
                {t('COURSES_WILL_BE_SHOW')}
            </Heading>
            <Typography className={'courses-empty__desc'} variant={'body3'}>
                {t('COURSES_EMPTY_CREATE')}
            </Typography>
            <Button
                className={'courses-empty__btn'}
                variant={'secondary'}
                size={'small'}
                loading={isLoading}
                disabled={isLoading}
                onClick={onCreateCourse}
            >
                {t('CREATE_COURSE')}
            </Button>
        </article>
    );
};

export default CoursesEmpty;
