import {nanoid} from "nanoid";
import Img from "assets/img/png/admin-main/img_7.png";

const useful = [
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме dad sa dsa dsa da ds"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },  {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
    {
        id: nanoid(),
        image: Img,
        date: "October 4, 2022",
        title: "Мультизаполненеие курса в реал тайме"
    },
];

export {useful};