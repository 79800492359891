import {Heading, Typography, Button} from "@akhter-studios/classlytics-front-ui-components";
import "./UserEmpty.scss";

const UserEmpty = ({title, desc, btnText, onClick}) => {
    return (
        <div className={"user-empty"}>
            <Heading className={"user-empty__title"} variant={"h3"}>{title}</Heading>
            <Typography variant={"body3"} className={"user-empty__desc"}>{desc}</Typography>
            <Button className={"user-empty__btn"} variant={"secondary"} size={"small"} onClick={onClick}>{btnText}</Button>
        </div>
    );
};

export default UserEmpty;
