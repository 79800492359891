import React, { useEffect, useState } from 'react';

const useChangeTab = (fetch) => {
    const [isChanged, setIsChanged] = useState(false);
    useEffect(() => {
        window.addEventListener('focus', focusFn);
        window.addEventListener('blur', blurFn);
        return () => {
            window.removeEventListener('focus', focusFn);
            window.removeEventListener('blur', blurFn);
        };
    }, []);

    const focusFn = () => {
        setIsChanged(false);
        fetch();
    };
    const blurFn = () => {
        setIsChanged(true);
    };
    return isChanged;
};

export default useChangeTab;
