import React from 'react';
import { Search } from '@akhter-studios/classlytics-front-ui-components';
import HeaderLogo from 'components/common/Header/HeaderLogo';
import HeaderBreadcrumbs from 'components/common/Header/HeaderBreadcrumbs';
import HeaderHelper from 'components/common/Header/HeaderHelper';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Header = ({ setSearch }) => {
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const { t } = useTranslation('common');
    return (
        <header
            className="header base-header"
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <HeaderLogo />
            <HeaderBreadcrumbs />
            <div className="header-search w-100 desktop-hidden">
                <Search
                    groupClassName={'header-search'}
                    fullWidth
                    placeholder={t('DOCUMENTS_SEARCH')}
                    onChange={setSearch}
                />
            </div>
            <HeaderHelper />
        </header>
    );
};

export default Header;
