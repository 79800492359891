import React from 'react';
import './CoursesDetailed.scss';
import CoursesInfo from 'pages/courses/CoursesDetailed/CoursesInfo';
import CourseAbout from 'pages/courses/CoursesDetailed/CourseAbout';
import { useFetchCourseDetailQuery } from 'api/services';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Header, Sidebar } from 'components/common';
import useIsMobile from 'helpers/hooks/useIsMobile';
import CourseDetailedHeaderMobile from 'pages/courses/CoursesDetailed/CourseDetailedHeaderMobile';
const CoursesDetailed = () => {
    const { id } = useParams();
    const isMobile = useIsMobile();
    const { userSpace } = useSelector((state) => state.space);
    const { data, refetch, isLoading, isFetching } = useFetchCourseDetailQuery(
        { spaceId: userSpace?.id, id },
        {
            refetchOnFocus: true,
            skip: !userSpace,
            refetchOnMountOrArgChange: true,
        }
    );
    if (isLoading) return <></>;
    return (
        <>
            <Header
                isMobileHeader={
                    isMobile ? (
                        <CourseDetailedHeaderMobile title={data?.name} />
                    ) : null
                }
                hiddenSearch={true}
            />
            <Sidebar />
            {data && (
                <section className="courses-detailed layout-main">
                    <div className="courses-detailed-wrapper">
                        <CoursesInfo
                            refetch={refetch}
                            testId={id}
                            isFetching={isFetching}
                            spaceId={userSpace?.id}
                            startCourse={data?.start}
                            proceed={data?.proceed}
                            isDisabled={data?.available}
                            data={data}
                        />
                        <CourseAbout
                            qtyMaterials={data?.qtyMaterials}
                            qtyPassed={data?.qtyPassed}
                            startCourse={data?.start}
                            proceed={data?.proceed}
                            refetch={refetch}
                            testId={id}
                            isFetching={isFetching}
                            isDisabled={data?.available}
                            spaceId={userSpace?.id}
                        />
                    </div>
                </section>
            )}
        </>
    );
};

export default CoursesDetailed;
