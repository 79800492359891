import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTimer } from 'helpers/hooks';
import { useUserChangeStatusMutation } from 'api/services';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import {
    AUTH_LOGIN_ROUTE,
    CONTENT_COURSES_ROUTE,
    GENERATE_TOKEN_URL,
} from 'utils/consts';
import { findDomainToRedirect, notify } from 'utils/functions';
import { authRequest } from 'api/xhr';
import { useTranslation } from 'react-i18next';

const DoneRedirect = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { time, start } = useTimer({
        seconds: 3,
        sessionKey: 'doneTimer',
    });
    const token = searchParams.get('token');
    const domain = searchParams.get('domain');
    const [userChangeStatus] = useUserChangeStatusMutation();
    const { t } = useTranslation('common');
    const generateToken = async () => {
        try {
            const { token: generateToken } = await authRequest.post(
                GENERATE_TOKEN_URL,
                { token, domain }
            );
            if (generateToken.length > 0) {
                window.location.href = `${
                    window.location.protocol
                }//${domain}.${findDomainToRedirect()}${CONTENT_COURSES_ROUTE}?oauth=${generateToken}`;
            } else {
                notify('error', t('ERROR'));
                navigate(AUTH_LOGIN_ROUTE);
            }
        } catch (e) {
            notify('error', t('ERROR'));
            navigate(AUTH_LOGIN_ROUTE);
        }
    };

    useEffect(() => {
        if (time === 1) {
            generateToken();
        }
    }, [time]);

    useEffect(() => {
        if (token) {
            userChangeStatus({ token });
            start();
        } else {
            notify('error', t('ERROR'));
            navigate(AUTH_LOGIN_ROUTE);
        }
    }, []);
    return (
        <Button className={'done-btn'} onClick={generateToken}>
            {t('AUTH_DONE_BTN', { time: time > 0 ? `${time}...` : null })}
        </Button>
    );
};

export default DoneRedirect;
