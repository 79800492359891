import { createSlice } from '@reduxjs/toolkit';
import { userMentorApi } from 'api/services';

const initialState = {
    mentors: {
        results: [],
        count: [],
        hasMore: false,
    },
};

export const userMentorSlice = createSlice({
    name: 'userMentor',
    initialState,
    reducers: {
        setUserMentors: (state, { payload }) => {
            state.mentors = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                userMentorApi.endpoints.fetchUserMentors.matchFulfilled,
                (state, { payload }) => {
                    state.mentors = payload;
                }
            )
            .addMatcher(
                userMentorApi.endpoints.removeUserMentor.matchFulfilled,
                (state, { meta, payload }) => {
                    const { mentors: removeMentors } = meta.arg.originalArgs;
                    state.mentors.results = state.mentors.results.filter(
                        (student) => !removeMentors.includes(student.id)
                    );
                    state.mentors.count =
                        state.mentors.count - removeMentors.length;
                }
            );
    },
});

const userMentorActions = userMentorSlice.actions;
export { userMentorActions };

export default userMentorSlice.reducer;
