import { Typography } from '@akhter-studios/classlytics-front-ui-components';
import './ErrorHelperText.scss';

const ErrorHelperText = ({ text }) => {
    return text ? (
        <Typography className={'error-helper-text'} variant={'body4'}>
            {text}
        </Typography>
    ) : null;
};

export default ErrorHelperText;
