import { useState } from 'react';

export default function useFindDropdownPosition() {
    const [dropdownPosition, setDropdownPosition] = useState('');

    const findDropdownPosition = (targetPosition) => {
        if (targetPosition <= 33) {
            setDropdownPosition('start');
        } else if (targetPosition > 33 && targetPosition <= 69) {
            setDropdownPosition('middle');
        } else {
            setDropdownPosition('end');
        }
    };

    return {
        dropdownPosition,
        findDropdownPosition,
    };
}
