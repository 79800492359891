import { createSlice } from '@reduxjs/toolkit';
import { spaceApi } from 'api/services';

const initialState = {
    userSpace: null,
};

export const authSlice = createSlice({
    name: 'space',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                spaceApi.endpoints.fetchSpace.matchFulfilled,
                (state, { payload }) => {
                    state.userSpace = payload;
                }
            )
            .addMatcher(
                spaceApi.endpoints.updateSpace.matchFulfilled,
                (state, { payload }) => {
                    state.userSpace = payload;
                }
            )
            .addMatcher(
                spaceApi.endpoints.updateSpaceAssets.matchFulfilled,
                (state, { payload }) => {
                    state.userSpace = payload;
                }
            );
    },
});

export default authSlice.reducer;
