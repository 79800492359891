import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction, useDebounce } from 'helpers/hooks';
import { useOutletContext } from 'react-router-dom';
import {
    useLazyFetchUserSearchStudentsQuery,
    useRemoveUserStudentMutation,
} from 'api/services';
import {
    StudentItem,
    UserSearchEmpty,
    UserEmpty,
    StudentBottomModal,
    AddCoursesModal,
} from 'pages/users/common';
import { UsersTableFilter } from 'pages/users/common/UsersTable/UsersTableFilter';
import { UsersTableList } from 'pages/users/common/UsersTable/UsersTableList';
import classNames from 'classnames';
import './Students.scss';
import { useTranslation } from 'react-i18next';

const Students = () => {
    const [isOpen, setIsOpen] = useOutletContext();
    const [type, setType] = useState(null);
    const [search, setSearch] = useState('');
    const { userSpace } = useSelector((state) => state.space);
    const { students } = useSelector((state) => state.userStudent);
    const [trigger, { isLoading }] = useLazyFetchUserSearchStudentsQuery();
    const [removeStudent] = useRemoveUserStudentMutation();
    const { setUserStudents } = useAction();
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [isOpenAddCoursesModal, setIsOpenAddCoursesModal] = useState(false);
    const debouncedSearch = useDebounce(search, 700);
    const [page, setPage] = useState(1);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const pageSize = 15;
    const { t } = useTranslation('common');

    const onFilter = (type) => setType(type);
    const onSearch = (e) => setSearch(e.target.value);
    const onSelectedStudent = (e) => {
        const { checked, value } = e.target;
        const numberValue = +value;
        let updatedList = [...selectedStudents];

        if (checked) {
            updatedList = [...updatedList, numberValue];
        } else {
            updatedList = updatedList.filter((p) => p !== numberValue);
        }
        setSelectedStudents(updatedList);
    };

    const onSelectedAllStudents = (e) => {
        const { checked } = e.target;
        const updatedList = students.results.map((p) => p.id);
        if (checked) {
            setIsCheckedAll(true);
            setSelectedStudents(updatedList);
        } else {
            setIsCheckedAll(false);
            setSelectedStudents([]);
        }
    };
    const onClearSelectedStudents = () => {
        setIsCheckedAll(false);
        setSelectedStudents([]);
    };
    const onOpenAddCoursesModal = (studentId) => {
        setIsOpenAddCoursesModal(true);
        setSelectedStudentId(studentId);
    };

    const onRemoveStudent = async (id) =>
        await removeStudent({
            spaceId: userSpace.id,
            students: [id],
        });

    const onRemoveSelectedMentors = async () =>
        await removeStudent({
            spaceId: userSpace.id,
            students: selectedStudents,
        }).then(({ data }) => {
            if (data.length === 0) {
                onClearSelectedStudents();
            }
        });

    const loadMoreStudents = async () => {
        if (!students.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            space_id: userSpace?.id,
            type,
            search: debouncedSearch,
            page: page + 1,
            page_size: pageSize,
            target: 'add_group',
            unique: false,
            role: 'student',
        });
        const modifiedResults = [...students.results, ...data.results];
        setUserStudents({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        if (userSpace) {
            trigger({
                space_id: userSpace?.id,
                type,
                search: debouncedSearch,
                page,
                page_size: pageSize,
                target: 'add_group',
                unique: false,
                role: 'student',
            });
        }
    }, [userSpace]);

    useEffect(() => {
        if (userSpace) {
            setPage(1);
            trigger({
                space_id: userSpace?.id,
                type,
                search: debouncedSearch,
                page: 1,
                page_size: pageSize,
                target: 'add_group',
                unique: false,
                role: 'student',
            });
        }
    }, [debouncedSearch, type]);
    const renderTable = () => {
        if (isLoading) {
            return null;
        } else if (search.length > 0 && !students?.results.length) {
            return <UserSearchEmpty />;
        } else if (students?.results.length === 0) {
            return (
                <UserEmpty
                    title={t('LIST_EMPTY')}
                    desc={t('ADD_STUDENTS_DESCRIPTION')}
                    btnText={t('INVITE_STUDENTS')}
                    onClick={() => setIsOpen(true)}
                />
            );
        } else {
            return students?.results.length > 0 ? (
                <UsersTableList
                    count={students?.count}
                    pageSize={pageSize}
                    items={students.results}
                    loadMore={loadMoreStudents}
                    hasMore={students.hasMore}
                    renderItem={(student) => (
                        <StudentItem
                            key={student.id}
                            isSelected={selectedStudents.includes(
                                student.profile.id
                            )}
                            navigateId={student.profile.id}
                            qtyCourses={student.coursesQty || 0}
                            qtyGroups={student.groupsQty || 0}
                            onSelectedStudent={onSelectedStudent}
                            onRemove={onRemoveStudent}
                            onOpenAddCoursesModal={onOpenAddCoursesModal}
                            {...student}
                        />
                    )}
                />
            ) : null;
        }
    };
    return (
        <div className={'students-wrapper'}>
            <div
                className={classNames('students', {
                    shift: selectedStudents.length > 0,
                })}
            >
                <UsersTableFilter
                    count={students?.count}
                    search={search}
                    searchPlaceholder={t('SEARCH_STUDENTS')}
                    isFilter={true}
                    isChecked={isCheckedAll}
                    onFilter={onFilter}
                    onSearch={onSearch}
                    onSelectedAll={onSelectedAllStudents}
                />
                {renderTable()}
            </div>
            {selectedStudents.length > 0 ? (
                <StudentBottomModal
                    count={selectedStudents.length}
                    maxCount={students?.results.length}
                    selectedStudents={selectedStudents}
                    onClear={onClearSelectedStudents}
                    onRemove={onRemoveSelectedMentors}
                />
            ) : null}
            {isOpenAddCoursesModal ? (
                <AddCoursesModal
                    isOpen={isOpenAddCoursesModal}
                    setIsOpen={setIsOpenAddCoursesModal}
                    selectedStudentId={selectedStudentId}
                />
            ) : null}
        </div>
    );
};

export default Students;
