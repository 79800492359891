import React, { useEffect, useState } from 'react';
import './Base.scss';
import FileItem from './FileItem';
import {
    addFolderApi,
    changeFileName,
    createQueryBase,
    fetchKnowledgeBase,
} from './services';
import KnowledgeModals from './KnowledgeModals';

import { useDebouncedCallback, useDebounce } from 'use-debounce';
import { notify } from 'utils/functions/notify';
import BaseTop from 'pages/knowledge/BaseTop';
import BaseAddFolder from 'pages/knowledge/BaseAddFolder';
import { useDropzone } from 'react-dropzone';
import { dndFiles } from 'pages/knowledge/functions';
import { useSelector } from 'react-redux';
import useOnchange from 'helpers/hooks/useOnchange';
import { showAllList, typeFileList } from 'pages/knowledge/constants';
import BaseFooter from 'pages/knowledge/BaseFooter';
import useSaveData from 'helpers/hooks/useSaveData';
import { Modal } from '@akhter-studios/classlytics-front-ui-components';
import AboutFile from 'pages/knowledge/ModalContent/AboutFile/AboutFile';
import BasePlaceholder from 'pages/knowledge/BasePlaceholder';
import DropzonePlaceholder from 'pages/knowledge/DropzonePlaceholder';
import Header from 'pages/knowledge/Header';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import {
    downloadBlob,
    fileExpansionsNewTab,
    onlyCenterMinHeight,
} from 'utils/functions/helpers';
import Placeholders from 'pages/knowledge/Placeholders';
import { Sidebar } from 'components/common';
import { saveAs } from 'file-saver';
import classNames from 'classnames';

const Base = () => {
    const [folderFiles, setFolderFiles] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [interfaceModal, setInterfaceModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    const [item, setItem] = useState(null);
    const [success, setSuccess] = useState(false);
    const [search, setSearch] = useState('');
    const [sorted, setSorted] = useState('');
    const [isParent, setIsParent] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [value] = useDebounce(search, 500);
    const [aboutFile, setAboutFile] = useState(null);
    const [open, setOpen] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const [uploadPercent, setUploadPercent] = useState({});
    const { t } = useTranslation('common');
    const [loader, setLoader] = useState(true);
    const [checkboxesData, handleChangeCheckboxes, resetCheckboxes] =
        useSaveData();

    const { form, handleChangeBlock, resetFields } = useOnchange({
        showType: showAllList[0],
        fileType: typeFileList[0],
    });

    const addFolder = () => {
        const body = {
            name: t('BASE_NEW_FOLDER'),
            file: null,
            learning_space_id: 5,
            folder_id: null,
            type: 'folder',
        };
        addFolderApi(userSpace?.id, body).then((data) => {
            notify('success', t('NOTIFY_SUCCESSFULLY_CREATED'));
            setSuccess(!success);
        });
    };

    useEffect(() => {
        if (userSpace) {
            setLoader(true);
            fetchKnowledgeBase(
                userSpace?.id,
                createQueryBase(
                    form.showType?.value,
                    form.fileType?.value,
                    value
                )
            )
                .then((data) => {
                    setFolderFiles(data.results || []);
                })
                .catch(() => {})
                .finally(() => setLoader(false));
        }
    }, [success, form, value, userSpace]);

    const changeNameFileDebounce = useDebouncedCallback((value, id) => {
        changeFileName(userSpace?.id, id, value)
            .then((result) => {
                notify('success', t('NOTIFY_SUCCESSFULLY_CHANGED'));
            })
            .catch((err) => {
                notify('error', t('NOTIFY_ERROR_WENT_WRONG'));
            });
    }, 500);

    const {
        onDragEndParent,
        onDragEnd,
        removeFileHandler,
        handleClickModalInterface,
        onDragEnterParent,
        onDrop,
        onDragStart,
        onDragLeave,
        onDragOver,
        removeCanceledFile,
        onUploadFile,
        onDropChild,
        onChangeName,
        massDeleteHandler,
        getDetailFile,
    } = dndFiles({
        setCurrentItem,
        setOpen,
        setUploadPercent,
        uploadPercent,
        success,
        setAboutFile,
        setSuccess,
        currentItem,
        spaceId: userSpace?.id,
        folderFiles,
        currentIndex,
        setFolderFiles,
        isParent,
        setIsParent,
        setCurrentIndex,
        setInterfaceModal,
        setTypeModal,
        setItem,
        changeNameFileDebounce,
        resetIsSelected: resetCheckboxes,
        t,
    });

    const onDropFile = async (acceptedFiles) => {
        onUploadFile(acceptedFiles, null, nanoid());
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDropFile,
        noClick: true,
    });
    const selectedFileCnt = Object.keys(checkboxesData).length;

    const downloadFile = async (link, title, item) => {
        const fileItem = checkboxesData[Object.keys(checkboxesData)[0]];
        if (fileExpansionsNewTab[(fileItem || item)?.expansion]) {
            return window.open(link, '_blank');
        } else {
            const file = await fetch(link || fileItem.file);
            let data = await file.blob();
            saveAs(data, title || fileItem.name);
        }
    };
    return (
        <>
            <Header setSearch={setSearch} />
            <Sidebar />
            <section
                className={classNames('knowledge-base layout-main', {
                    'full-modal-mobile': typeModal === 'send',
                    selectedFile: selectedFileCnt > 0,
                })}
            >
                <input {...getInputProps()} />
                <div
                    onDragEnter={onDragEnterParent}
                    onDragEnd={onDragEndParent}
                >
                    <div className="knowledge-base__wrap d-flex">
                        <h1 className="heading users-title__name heading--h1">
                            {t('DOCUMENTS')}
                        </h1>
                        <BaseAddFolder
                            addFolder={addFolder}
                            onUploadFile={onUploadFile}
                        />
                    </div>
                    <div className="container">
                        <BaseTop
                            setLoader={setLoader}
                            form={form}
                            handleChangeBlock={handleChangeBlock}
                            search={search}
                            setSorted={setSorted}
                            setSearch={setSearch}
                        />
                        {loader ? (
                            <div
                                className="knowledge-base-loading"
                                style={onlyCenterMinHeight(60)}
                            >
                                {t('GENERAL_LOADING')}
                            </div>
                        ) : (
                            <>
                                {!folderFiles.length &&
                                (search || form.fileType.value !== 'TEXT') ? (
                                    <div className="knowledge-base-placeholders">
                                        <Placeholders
                                            resetFields={resetFields}
                                            search={search}
                                            filter={
                                                form.fileType.value !== 'TEXT'
                                                    ? form.fileType
                                                    : ''
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />

                                        <div className="knowledge-base__folders-wrap">
                                            {isDragActive && (
                                                <DropzonePlaceholder />
                                            )}
                                            {!isDragActive &&
                                            !folderFiles.length ? (
                                                <BasePlaceholder
                                                    onUploadFile={onUploadFile}
                                                />
                                            ) : (
                                                <div className="knowledge-base__folders">
                                                    {folderFiles.map(
                                                        (x, idx) => (
                                                            <React.Fragment
                                                                key={x.id}
                                                            >
                                                                <FileItem
                                                                    uploadPercent={
                                                                        uploadPercent
                                                                    }
                                                                    downloadFile={
                                                                        downloadFile
                                                                    }
                                                                    onDragEnd={
                                                                        onDragEnd
                                                                    }
                                                                    onDragStart={
                                                                        onDragStart
                                                                    }
                                                                    checkboxesData={
                                                                        checkboxesData
                                                                    }
                                                                    handleChangeCheckboxes={
                                                                        handleChangeCheckboxes
                                                                    }
                                                                    x={x}
                                                                    folderId={
                                                                        x.id
                                                                    }
                                                                    onDragLeave={
                                                                        onDragLeave
                                                                    }
                                                                    idx={idx}
                                                                    onChangeName={
                                                                        onChangeName
                                                                    }
                                                                    onDragOver={
                                                                        onDragOver
                                                                    }
                                                                    onDrop={
                                                                        onDrop
                                                                    }
                                                                    handleClickModalInterface={
                                                                        handleClickModalInterface
                                                                    }
                                                                    removeCanceledFile={
                                                                        removeCanceledFile
                                                                    }
                                                                    getDetailFile={
                                                                        getDetailFile
                                                                    }
                                                                    onUploadFile={
                                                                        onUploadFile
                                                                    }
                                                                    setFolderFiles={
                                                                        setFolderFiles
                                                                    }
                                                                    folderFiles={
                                                                        folderFiles
                                                                    }
                                                                    lastIndex={
                                                                        folderFiles.length -
                                                                        1
                                                                    }
                                                                    onDropChild={
                                                                        onDropChild
                                                                    }
                                                                    removeFileHandler={
                                                                        removeFileHandler
                                                                    }
                                                                    changeNameFileDebounce={
                                                                        changeNameFileDebounce
                                                                    }
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {interfaceModal && (
                        <Modal
                            isOpen={interfaceModal}
                            setIsOpen={() => setInterfaceModal(!interfaceModal)}
                            customStyle={{
                                width: '653px',
                            }}
                        >
                            <KnowledgeModals
                                interfaceModal={interfaceModal}
                                setFolderFiles={setFolderFiles}
                                folders={folderFiles}
                                type={typeModal}
                                resetCheckboxes={resetCheckboxes}
                                closeModal={() => setInterfaceModal(false)}
                                setSuccess={setSuccess}
                                success={success}
                                selectedItems={checkboxesData}
                                currentItem={item}
                                removeFileHandler={removeFileHandler}
                            />
                        </Modal>
                    )}
                </div>
                {selectedFileCnt > 0 && (
                    <BaseFooter
                        download={() =>
                            downloadFile(
                                checkboxesData[Object.keys(checkboxesData)[0]]
                                    .file,
                                checkboxesData[Object.keys(checkboxesData)[0]]
                                    .name
                            )
                        }
                        count={selectedFileCnt}
                        handleClickModalInterface={handleClickModalInterface}
                        removeItems={() => massDeleteHandler(checkboxesData)}
                        clear={resetCheckboxes}
                    />
                )}

                <AboutFile
                    aboutFile={aboutFile}
                    setOpen={setOpen}
                    open={open}
                />
            </section>
        </>
    );
};

export default Base;
