import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'helpers/hooks';
import {
    useAddUserStudentGroupsMutation,
    useLazyFetchUserGroupsQuery,
} from 'api/services';
import { notify } from 'utils/functions';
import AddGroupsModalItem from './AddGroupsModalItem';
import { useTranslation } from 'react-i18next';
import SelectableModal from 'pages/users/common/UsersModalWrapper/SelectableModal';

const AddGroupsModal = ({ isOpen, setIsOpen, selectedStudentId }) => {
    const { userSpace } = useSelector((state) => state.space);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const [groups, setGroups] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [trigger] = useLazyFetchUserGroupsQuery();
    const [addUserStudentGroups, { isLoading }] =
        useAddUserStudentGroupsMutation();
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const { t } = useTranslation('common');

    const onAddGroups = async () => {
        const { error } = await addUserStudentGroups({
            spaceId: userSpace.id,
            studentId: selectedStudentId,
            groups: selectedGroups,
        });
        if (error) {
            notify('error', error.data.error);
        }
        if (!error) {
            notify('success', t('NOTIFY_SUCCESSFULLY_INVITED_TO_GROUP'));
            setIsOpen(false);
            setSearch('');
            setSelectedGroups([]);
        }
    };

    const onSelectAll = () => {
        if (isSelectedAll) {
            setIsSelectedAll(false);
            setSelectedGroups([]);
        } else {
            setIsSelectedAll(true);
            setSelectedGroups(groups.results.map((c) => c.id));
        }
    };
    const onSearch = (e) => setSearch(e.target.value);
    const onSelect = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedGroups([...selectedGroups, value]);
        } else {
            setSelectedGroups(selectedGroups.filter((s) => s !== value));
        }
    };

    const loadMoreGroups = async () => {
        if (!groups?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            id: userSpace.id,
            page: page + 1,
            page_size: pageSize,
            search: debouncedSearch,
        });
        const modifiedResults = [...groups.results, ...data.results];
        setGroups({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        if (userSpace) {
            trigger({ id: userSpace.id, page, page_size: pageSize }).then(
                ({ data }) => setGroups(data)
            );
        }
    }, [userSpace]);

    useEffect(() => {
        debouncedSearch &&
            trigger({
                id: userSpace.id,
                page: 1,
                page_size: pageSize,
                search: debouncedSearch,
            }).then(({ data }) => {
                setPage(1);
                setGroups(data);
            });
    }, [debouncedSearch]);
    return (
        <SelectableModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('CHOOSE_GROUPS')}
            btnText={t('SAVE')}
            searchPlaceholder={t('USERS_GROUPS_SEARCH')}
            isSearchable={true}
            btnFixed={true}
            btnLoading={isLoading}
            btnDisabled={isLoading || selectedGroups.length === 0}
            isSelectedAll={isSelectedAll}
            search={search}
            currentCount={groups?.results?.length}
            maxCount={groups?.count}
            hasMore={groups?.hasMore}
            items={groups?.results}
            onSearch={onSearch}
            onSubmit={onAddGroups}
            onLoadMore={loadMoreGroups}
            onSelectAll={onSelectAll}
            renderItem={(group) => (
                <AddGroupsModalItem
                    key={group.id}
                    isSelected={selectedGroups.includes(group.id)}
                    onSelect={onSelect}
                    {...group}
                />
            )}
        />
    );
};

export default AddGroupsModal;
