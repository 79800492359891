import React, { useEffect, useState } from 'react';
import { Collapse, Grid } from '@mui/material';
import {
    Heading,
    Icon,
    IconButton,
} from '@akhter-studios/classlytics-front-ui-components';
import './CreateCourseFiles.scss';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import EducationFiles from 'components/common/EducationalFiles/EducationFiles';
import { useSelector } from 'react-redux';
import { useSavingStatus } from 'components/layout/SavingStatusContext';
import { notify } from 'utils/functions';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useTranslation } from 'react-i18next';
import { formatBytes } from 'helpers/functions';
import { nanoid } from 'nanoid';
import { createCourseFile } from 'pages/courses/services';
import { useFetchCourseMutation } from 'api/services';

const CreateCourseFiles = () => {
    const { t } = useTranslation('common');

    const [uploadPercent, setUploadPercent] = useState({});
    const [fetchCourse] = useFetchCourseMutation();
    const previewStatus = usePreview();

    const [files, setFiles] = useState([]);

    const { changeStatus } = useSavingStatus();

    const [filesOpen, setFilesOpen] = useState(false);

    const { userSpace } = useSelector((state) => state.space);
    const { courseId } = useParams();

    const updateLockIcon = (id) => {
        setFiles(
            files.map((file) =>
                file.id === id
                    ? { ...file, isDownload: !file.isDownload }
                    : file
            )
        );
    };

    const removeEducationalMaterialLocally = (id) => {
        setFiles(files.filter((file) => file.id !== id));
    };

    const { course } = useSelector((state) => state.course);

    useEffect(() => {
        if (course) {
            setFiles(course?.educationalMaterials);
        }
    }, [course]);

    const makeFile = (file, isRemove, responseItem) => {
        let newArr = [...files];
        if (isRemove) {
            newArr = [responseItem, ...newArr].filter((x) => x.id !== file.id);
        } else {
            newArr = [file, ...newArr];
        }
        setFiles(newArr);
    };

    const onFileInputChange = async (e) => {
        changeStatus(t('GENERAL_FILE_LOADING'));
        const file = e.target.files[0];
        if (file?.name?.replace(/\.[^/.]+$/, '')?.length > 64) {
            notify('error', t('ERROR_TOO_LONG_FILENAME'));
            changeStatus(null);
            return;
        }
        const data = new FormData();
        data.append('file', file);
        data.append('title', file.name);
        data.append('course_id', courseId);
        data.append('size', formatBytes(file.size));
        const tempFile = {
            title: file.name + '.' + file.type,
            isTemp: true,
            size: formatBytes(file.size),
            id: nanoid(),
        };
        makeFile(tempFile);
        createCourseFile({
            spaceId: userSpace?.id,
            body: data,
            setUploadPercent,
            id: tempFile?.id,
            tempFile,
        }).then((data) => {
            let newUploadPercents = { ...uploadPercent };
            delete newUploadPercents[tempFile.id];
            setUploadPercent(newUploadPercents);
            makeFile(tempFile, true, data);
            fetchCourse({ spaceId: userSpace?.id, courseId });
        });
        changeStatus(null);
    };
    return (
        <div className={'course-files'}>
            <Grid
                className={'course-files__heading'}
                container
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Heading
                    className={classnames('course-files__title', {
                        'course-files__title--active': false,
                    })}
                    variant={'h5'}
                >
                    {t('LEARNING_FILES')}
                </Heading>
                <IconButton onClick={() => setFilesOpen(!filesOpen)}>
                    <Icon name={filesOpen ? 'arrow-down' : 'arrow-right'} />
                </IconButton>
            </Grid>
            <Collapse in={filesOpen} unmountOnExit>
                <div className={'course-files__content'}>
                    <EducationFiles
                        uploadPercent={uploadPercent}
                        isReadOnly={previewStatus !== PREVIEW_STATUS.draft}
                        files={files}
                        updateLockIcon={updateLockIcon}
                        removeEducationalMaterialLocally={
                            removeEducationalMaterialLocally
                        }
                    />
                    <label
                        className={classnames('course-files__label', {
                            'course-files__label--hidden':
                                previewStatus !== PREVIEW_STATUS.draft,
                        })}
                    >
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                        >
                            <Icon name={'file'} />
                            <Heading
                                variant={'h5'}
                                className={'course-files__add'}
                            >
                                {t('ADD_FILE')}
                            </Heading>
                        </Grid>
                        <input
                            disabled={previewStatus !== PREVIEW_STATUS.draft}
                            type="file"
                            onChange={onFileInputChange}
                            className={'course-files__input'}
                        />
                    </label>
                </div>
            </Collapse>
        </div>
    );
};

export default CreateCourseFiles;
