import React, { useState } from 'react';
import Icon from 'components/common/Icon/Icon';
import TooltipInterface from 'pages/knowledge/TooltipInterface';
import { Input } from '@akhter-studios/classlytics-front-ui-components';
import { Checkbox } from '@mui/material';
import useOnSaveInput from 'helpers/hooks/useOnSaveInput';
import { iconsView } from 'pages/knowledge/constants';
import { useTranslation } from 'react-i18next';

const FileItemInputChilds = ({
    x,
    onDragStart,
    idx,
    onDragEnd,
    onDragOver,
    onChangeName,
    folderId,
    handleClickModalInterface,
    removeFileHandler,
    indexChild,
    handleChangeCheckboxes,
    checkboxesData,
    getDetailFile,
}) => {
    const [isEdit, setIsEdit] = useState(false);

    const { value, setValue } = useOnSaveInput();
    const onSaveInput = () => {
        const valueData = value.value || x.name;
        const id = value.id || x.id;
        onChangeName(id, valueData, {
            ...value.x,
            parentId: value.parentId,
            index: value.index,
        });
        setIsEdit(false);
    };
    const { t } = useTranslation('common');
    const downloadFile = () => {
        window.open(x.file);
    };
    return (
        <>
            <div className="files-wrap">
                <div
                    key={x.id}
                    className="left"
                    draggable={true}
                    onDragStart={(e) => {
                        onDragStart(
                            e,
                            {
                                ...x,
                                idx,
                                indexChild,
                                parentIndex: idx,
                                isChild: true,
                            },
                            true
                        );
                    }}
                    onDrop={() => console.log('childDrop')}
                    onDragOver={(e) => onDragOver(e, x)}
                    onDragLeave={onDragEnd}
                >
                    <Checkbox
                        checked={Boolean(checkboxesData[x.id])}
                        onChange={() => handleChangeCheckboxes(x)}
                    />
                    <Icon name={iconsView[x.expansion] || 'file'} />
                    <div className="knowledge-base__items-info child">
                        <div>
                            {isEdit ? (
                                <div>
                                    <Input
                                        groupClassName={'auth-fields'}
                                        value={value?.value || x.name}
                                        autoFocus={isEdit}
                                        onChange={(e) =>
                                            setValue({
                                                value: e.target.value,
                                                id: x.id,
                                                index: indexChild,
                                                parentId: folderId,
                                                x,
                                            })
                                        }
                                    />
                                </div>
                            ) : (
                                <div className="knowledge-base__items-name">
                                    {x.name}.
                                    <span>{x.expansion || t('FILE_TYPE')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="right">
                    <TooltipInterface
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        x={x}
                        onSave={onSaveInput}
                        getDetailFile={getDetailFile}
                        removeFileHandler={removeFileHandler}
                        downloadFile={downloadFile}
                        idx={idx}
                        handleClickModalInterface={handleClickModalInterface}
                    />
                </div>
            </div>
        </>
    );
};

export default FileItemInputChilds;
