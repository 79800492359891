import React, { useState } from 'react';
import shortid from 'shortid';
import FileItemInput from 'pages/knowledge/FileItemInput';

const FileItem = ({
    onDragEnd,
    onDragStart,
    x,
    idx,
    onChangeName,
    onDragOver,
    onDrop,
    changeNameFileDebounce,
    onDragLeave,
    onDragLeaveItems,
    folderId,
    onUploadFile,
    handleClickModalInterface,
    removeFileHandler,
    lastIndex,
    handleChangeCheckboxes,
    checkboxesData,
    getDetailFile,
    uploadPercent,
    removeCanceledFile,
    downloadFile,
}) => {
    const [open, setOpen] = useState(false);

    const isFolder = x.type === 'folder';
    return (
        <div
            key={x.id}
            className="knowledge-base-main-item"
            onDragLeave={(e) => {}}
        >
            <div
                className="knowledge-base__drop top"
                index={idx === 0 ? 0 : idx}
            />

            <FileItemInput
                checkboxesData={checkboxesData}
                onDragEnd={onDragEnd}
                onDragLeaveItems={onDragLeaveItems}
                onDrop={onDrop}
                onDragOver={onDragOver}
                setOpen={setOpen}
                open={open}
                onDragLeave={onDragLeave}
                onDragStart={onDragStart}
                isFolder={isFolder}
                changeNameFileDebounce={changeNameFileDebounce}
                x={x}
                handleChangeCheckboxes={handleChangeCheckboxes}
                onChangeName={onChangeName}
                getDetailFile={getDetailFile}
                idx={idx}
                onUploadFile={onUploadFile}
                uploadPercent={uploadPercent}
                folderId={folderId}
                downloadFile={downloadFile}
                removeCanceledFile={removeCanceledFile}
                removeFileHandler={removeFileHandler}
                handleClickModalInterface={handleClickModalInterface}
            />
            {lastIndex === idx && (
                <div className="knowledge-base__drop" index={idx + 1} />
            )}
        </div>
    );
};

export default FileItem;
