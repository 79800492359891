import React from 'react';
import {
    Progress,
    Button,
} from '@akhter-studios/classlytics-front-ui-components';
import {
    calculateCoursePassed,
    courseInfoProgress,
} from 'pages/courses/CoursesDetailed/constants';
import { useStartCourseApiMutation } from 'api/services';
import { useNavigate, useParams } from 'react-router-dom';
import { moduleTypeUrl } from 'pages/course-test/functions';
import { useTranslation } from 'react-i18next';

const CourseAbout = ({
    startCourse,
    spaceId,
    testId,
    qtyPassed,
    qtyMaterials,
    proceed,
    refetch,
    isDisabled,
    isFetching,
}) => {
    const [startCourseApi, { isLoading }] = useStartCourseApiMutation();
    const { t } = useTranslation('common');
    const { id } = useParams();
    const navigate = useNavigate();
    const proceedCourse = () => {
        const url = moduleTypeUrl({
            id: proceed.id,
            courseId: id,
            spaceId,
            type: proceed.type,
        });
        navigate(url);
    };
    return (
        <div className="courses-detailed-right mobile-hidden">
            <h6>{t('PREVIEW_ABOUT_COURSE')}</h6>
            <div className="courses-detailed-progress">
                <div className="courses-detailed-progress__info">
                    <span>
                        {t('PREVIEW_PASSED')} {qtyPassed}/{qtyMaterials}
                    </span>
                    <div>
                        {calculateCoursePassed({ qtyPassed, qtyMaterials })}%
                    </div>
                </div>
                <Progress
                    className={'cards-item__progress'}
                    value={qtyPassed}
                    max={qtyMaterials}
                />
            </div>
            {courseInfoProgress({
                testId,
                spaceId,
                startCourse,
                startCourseApi,
                proceed,
                proceedCourse,
                refetch,
                t,
            }).map(({ title, handler, isShow }, idx) =>
                isShow ? (
                    <Button
                        variant={'primary'}
                        onClick={handler}
                        disabled={!isDisabled}
                        fullWidth
                        key={idx}
                    >
                        {title}
                    </Button>
                ) : null
            )}
        </div>
    );
};

export default CourseAbout;
