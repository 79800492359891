import {
    AddCoursesModal,
    AddGroupsModal,
    StudentDetailHeading,
    StudentDetailInfo,
} from '../../common';
import './StudentDetail.scss';
import {
    useFetchUserStudentQuery,
    useRemoveUserStudentMutation,
} from 'api/services';
import { useLocationScope } from 'helpers/hooks';
import { renderModal } from 'pages/users/views/student-detail/consts';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Outlet, useNavigate } from 'react-router-dom';

const StudentDetail = () => {
    const navigate = useNavigate();
    const scope = useLocationScope();
    const { id: studentId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { isSuccess, data: student } = useFetchUserStudentQuery(
        { spaceId: userSpace?.id, studentId },
        { skip: !userSpace || !studentId }
    );
    const [removeUserStudent] = useRemoveUserStudentMutation();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAddCoursesModal, setIsOpenAddCoursesModal] = useState(null);
    const [isOpenAddGroupsModal, setIsOpenAddGroupsModal] = useState(null);

    const onOpenAddCoursesModal = () => {
        setIsOpenAddCoursesModal(true);
    };

    const onOpenAddGroupsModal = () => {
        setIsOpenAddGroupsModal(true);
    };

    const onRemove = async () => {
        const { error } = await removeUserStudent({
            spaceId: userSpace.id,
            students: [+studentId],
        });
        if (!error) {
            navigate(-1);
        }
    };

    if (!isSuccess) {
        return null;
    }
    return (
        <article className={'layout-main'}>
            <div className={'student-detail'}>
                <StudentDetailInfo
                    studentId={student.user.id}
                    avatar={student.user.avatar}
                    firstName={student.user.firstName}
                    lastName={student.user.lastName}
                    email={student.user.email}
                    qtyCourses={student.qtyCourses}
                    qtyGroups={student.qtyGroups}
                    isActive={student.isActive}
                    onOpenAddCoursesModal={onOpenAddCoursesModal}
                    onOpenAddGroupsModal={onOpenAddGroupsModal}
                    onRemove={onRemove}
                />
                <StudentDetailHeading selectedStudentId={student?.user.id} />
                <Outlet context={[isOpen, setIsOpen]} />
                {isOpenAddCoursesModal ? (
                    <AddCoursesModal
                        isOpen={isOpenAddCoursesModal}
                        setIsOpen={setIsOpenAddCoursesModal}
                        selectedStudentId={student?.user.id}
                    />
                ) : null}
                {isOpenAddGroupsModal ? (
                    <AddGroupsModal
                        isOpen={isOpenAddGroupsModal}
                        setIsOpen={setIsOpenAddGroupsModal}
                        selectedStudentId={student?.user.id}
                    />
                ) : null}
                {isOpen
                    ? renderModal({
                          isOpen,
                          setIsOpen,
                          selectedStudentId: student?.user.id,
                      })[scope]
                    : null}
            </div>
        </article>
    );
};

export default StudentDetail;
