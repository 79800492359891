import { nanoid } from 'nanoid';

const actions = ({ onRemove, onContainerOpenChangeTitle }) => [
    {
        id: nanoid(),
        text: 'GROUP_CHANGE_NAME',
        onClick: onContainerOpenChangeTitle,
        border: true,
    },
    {
        id: nanoid(),
        text: 'GROUP_DELETE',
        onClick: onRemove,
    },
];

export { actions };
