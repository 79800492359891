import {Heading} from "@akhter-studios/classlytics-front-ui-components";
import "./HeaderSelectBreadcrumb.scss";

const HeaderSelectBreadcrumb = () => {
    return (
        <Heading className={"header-select-breadcrumb"} variant={"h4"}>Курсы</Heading>
    );
};

export default HeaderSelectBreadcrumb;
