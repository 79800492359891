import { useEffect, useState } from 'react';

const useTimer = ({ seconds = 60, sessionKey = 'time' }) => {
    let intervalId = null;
    const sessionTime = Number(sessionStorage.getItem(sessionKey));
    const [time, setTime] = useState(sessionTime || 0);
    const start = () => {
        setTime(seconds);
    };

    useEffect(() => {
        if (time === 0) {
            clearInterval(intervalId);
            sessionStorage.removeItem(sessionKey);
        } else {
            intervalId = setInterval(() => {
                setTime(time - 1);
            }, 1000);
            sessionStorage.setItem(sessionKey, String(time - 1));
        }
        return () => {
            clearInterval(intervalId);
            sessionStorage.removeItem(sessionKey);
        };
    }, [time]);

    return {
        time,
        start,
    };
};

export default useTimer;
