import SettingsCompany from 'pages/settings/common/SettingsCompany';
import SettingsColors from 'pages/settings/common/SettingsColors';

const renderComponent = {
    '': <SettingsCompany />,
    '?scope=company': <SettingsCompany />,
    '?scope=colors': <SettingsColors />,
};

const DEFAULT_COLOR = '#ffffff';
const DEFAULT_MAIN_BRAND_COLOR = '#38a6e2';
const DEFAULT_FONT = {
    label: 'Inter',
    value: 'Inter',
};

export {
    renderComponent,
    DEFAULT_COLOR,
    DEFAULT_FONT,
    DEFAULT_MAIN_BRAND_COLOR,
};
