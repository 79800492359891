import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSidebarShow: false,
};

export const utilSlice = createSlice({
    name: 'util',
    initialState,
    reducers: {
        setIsSidebarShow(state) {
            state.isSidebarShow = !state.isSidebarShow;
        },
    },
});

export const { setIsSidebarShow } = utilSlice.actions;
export default utilSlice.reducer;
