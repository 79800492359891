import React, { useEffect, useMemo, useState } from 'react';
import { Editor } from 'components/common';
import { useParams } from 'react-router-dom';
import { useGetLessonMutation } from 'api/services';
import useEditor from 'helpers/hooks/useEditor';
import './Lesson.scss';
import { useSelector } from 'react-redux';
import { getEditorJsTools, getTools } from 'components/common/Editor/constants';
import { TextareaAutosize } from '@mui/material';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { onlyCenterMinHeight } from 'utils/functions/helpers';

const Lesson = ({ fetchStudentURL, readOnlyStudent }) => {
    const previewStatus = usePreview();
    const [lessonName, setLessonName] = useState('');
    const { lessonId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { lesson, dataUpdated } = useSelector((state) => state.lesson);
    const { ready } = useSelector((state) => state.editor);
    const sameLesson = useMemo(
        () => lessonId === lesson?.id,
        [lessonId, lesson?.id]
    );
    const { courseId } = useParams();
    const [fetchLesson, { isLoading }] = useGetLessonMutation();
    useEffect(() => {
        if ((!sameLesson && userSpace) || dataUpdated) {
            fetchLesson({
                spaceId: userSpace?.id,
                lessonId: lessonId,
                fetchStudentURL,
            });
        }
    }, []);
    const { t } = useTranslation('common');
    useEffect(() => {
        if (lesson && lesson?.name && lesson.name !== lessonName) {
            setLessonName(lesson.name);
        }
    }, [lesson]);

    const { childRef, onEditorChange, outerSave } = useEditor({
        isDisabled:
            Boolean(fetchStudentURL) || previewStatus !== PREVIEW_STATUS.draft,
    });
    if (isLoading || !sameLesson) {
        return (
            <div className={'layout-main'}>
                <div style={onlyCenterMinHeight(60)}>
                    {t('GENERAL_LOADING')}
                </div>
            </div>
        );
    }
    return (
        <>
            <div className={'layout-main'}>
                <div
                    className={classnames('lesson', {
                        draft: previewStatus === PREVIEW_STATUS.draft,
                    })}
                >
                    <div className={'lesson__content'}>
                        <TextareaAutosize
                            value={lessonName}
                            onChange={(e) => setLessonName(e.target.value)}
                            name="lesson-title"
                            id="lesson-title"
                            rows="1"
                            disabled={
                                previewStatus !== PREVIEW_STATUS.draft ||
                                readOnlyStudent
                            }
                            className={'lesson__title'}
                            placeholder={t('LESSON_NAME')}
                        />
                        <Editor
                            courseId={courseId}
                            tools={getTools(
                                [
                                    'embedPDF',
                                    'embedDOC',
                                    'header',
                                    'table',
                                    'strikethrough',
                                    'embed',
                                    'list',
                                    'linkTool',
                                    'code',
                                    'inlineCode',
                                    'quote',
                                    'underline',
                                    'delimiter',
                                    'customMarker',
                                    'Color',
                                    'image',
                                    'attaches',
                                    'knowledge',
                                    'video',
                                    'toggleList',
                                    'audio',
                                ],
                                getEditorJsTools(t, userSpace?.id, courseId)
                            )}
                            readOnly={
                                previewStatus !== PREVIEW_STATUS.draft ||
                                readOnlyStudent
                            }
                            isDisabled={fetchStudentURL}
                            ref={childRef}
                            initialData={lesson}
                            onChange={onEditorChange}
                            onSave={() => {}}
                            outsideSave={outerSave}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Lesson;
