import React from 'react';
import DetailCourseStatisticsItem from 'pages/analytics/views/courses/DetailCourses/DetailCourseStatisticsItem';
import useFormatDate from 'helpers/hooks/useFormatDate';
import { onErrorImg } from 'utils/functions/helpers';
import { useTranslation } from 'react-i18next';
const DetailCourseProfile = ({ data }) => {
    const { t } = useTranslation('common');
    return (
        <div className="analytics-detail-course__profile">
            <div className="analytics-detail-course__profile-wrap j-c-b">
                <div className="analytics-detail-course__profile-info d-flex">
                    <div className="analytics-detail-course__profile-img">
                        <img
                            src={data.image}
                            onError={(e) => onErrorImg(e)}
                            alt="avatar"
                        />
                    </div>
                    <div className="analytics-detail-course__profile-text">
                        <div className="analytics-detail-course__profile-title">
                            {data.name}
                        </div>
                        <div className="analytics-detail-course__profile-date d-flex">
                            <span>{t('ANALYTICS_CREATED')}</span>
                            <div>{useFormatDate(data.created_at)}</div>
                        </div>
                        <div className="analytics-detail-course__profile-statistics d-flex desktop-hidden">
                            <DetailCourseStatisticsItem
                                title={t('ANALYTICS_AVERAGE_SCORE')}
                                value={data.average_score || '-'}
                            />
                            <DetailCourseStatisticsItem
                                title={t('ANALYTICS_PASS_FOR')}
                                value={data.passage_time || '-'}
                            />
                        </div>
                    </div>
                    <div className="analytics-detail-course__profile-statistics d-flex mobile-hidden">
                        <DetailCourseStatisticsItem
                            title={t('ANALYTICS_AVERAGE_SCORE')}
                            value={data.average_score || '-'}
                        />
                        <DetailCourseStatisticsItem
                            title={t('ANALYTICS_PASS_FOR')}
                            value={data.passage_time || '-'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailCourseProfile;
