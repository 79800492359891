import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'components/common';
import List from 'pages/knowledge/List';
import { moreList } from 'pages/knowledge/constants';
import useOutsideClick from 'helpers/hooks/useOutsideClick';
import { checkIsCuteFrame } from 'utils/functions/helpers';

const TooltipInterface = ({
    x,
    setOpen,
    uploadPercent,
    isFolder,
    handleClickModalInterface,
    open,
    isEdit,
    setIsEdit,
    getDetailFile,
    onSave,
    downloadFile,
    removeCanceledFile,
}) => {
    const [isMore, setIsMore] = useState(false);
    const ref = useOutsideClick(() => setIsMore(false));
    const listRef = useRef(null);
    const rename = () => setIsEdit(true);
    useEffect(() => {
        if (isMore) {
            checkIsCuteFrame(listRef.current, 'moveToTop');
        }
    }, [isMore]);
    if (isEdit) {
        return (
            <div className="knowledge-base__items-edit">
                <Icon name="square-check" handler={onSave} />
                <Icon name="square-cancel" handler={() => setIsEdit(false)} />
            </div>
        );
    }
    return (
        <div className="knowledge-base__items-interface">
            {isFolder && x.files.length ? (
                <Icon
                    handler={() => {
                        setOpen(!open);
                    }}
                    name={open ? 'arrow-top' : 'arrow-down'}
                />
            ) : (
                <div style={{ width: '48px', height: '48px' }} />
            )}
            <div ref={ref} className="d-flex">
                {x.isTemp ? (
                    <Icon
                        name="cancel-icon"
                        handler={() => {
                            uploadPercent[x.id].cancel();
                            removeCanceledFile(x.id);
                        }}
                    />
                ) : (
                    <Icon name="more" handler={() => setIsMore(!isMore)} />
                )}
                <div ref={listRef}>
                    {isMore && (
                        <List
                            setIsMore={setIsMore}
                            removeItem={() =>
                                handleClickModalInterface('remove', x)
                            }
                            list={moreList({
                                rename,
                                infoFile: () => getDetailFile(x.id),
                                moveFolder: () =>
                                    handleClickModalInterface('folder', x),
                                download: downloadFile,
                                isFolder,
                                send: () =>
                                    handleClickModalInterface('send', x),
                            })}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TooltipInterface;
