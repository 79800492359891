import { findFontTypes } from 'components/feature/GlobalSettingsSpace/functions';
import { DEFAULT_MAIN_BRAND_COLOR } from 'pages/settings/consts';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import pickTextColorBasedOnBgColor from 'utils/functions/pickTextColorBasedOnBgColor';

const GlobalStyle = createGlobalStyle`
      .Toastify__toast-body {
        div {
          font-family: ${(props) => `${props.font} ${props.fontType}`};
        }
      }
      * {
        font-family: ${(props) => `${props.font} ${props.fontType} !important`};
      }
      body {
        font-family: ${(props) => `${props.font} ${props.fontType}`};
        background-color: ${(props) => props.backgroundColor};
      }
      header {
        background-color: ${(props) => props.menuBackgroundColor} !important;
      }
      .sidebar {
        background-color: ${(props) => props.backgroundColor};
      }
      .sidebar hr {
        border-color: ${(props) => props.backgroundColor} !important;
      }
      button[data-variant=secondary] {  
        background-color: ${(props) => props.secondaryColor};
        color: ${(props) => pickTextColorBasedOnBgColor(props.secondaryColor)};
      }
      button[data-variant=secondary]:hover, button[data-variant=secondary]:active, button[data-variant=secondary]:focus {
        background-color: ${(props) => props.secondaryColor} !important;
        border-color: ${(props) => '#edeff0'} !important;
        color: ${(props) =>
            pickTextColorBasedOnBgColor(props.secondaryColor)} !important;
        opacity: 0.8 !important;
      }
      button[data-variant=primary] {
        background-color: ${(props) => props.primaryColor};
        border-color: ${(props) => props.primaryColor};
        color: ${(props) => pickTextColorBasedOnBgColor(props.primaryColor)};
      }
      button[data-variant=primary]:hover, button[data-variant=primary]:active, button[data-variant=primary]:focus {
        background-color: ${(props) => props.primaryColor} !important;
        border-color: ${(props) => props.primaryColor} !important;
        color: ${(props) =>
            pickTextColorBasedOnBgColor(props.primaryColor)} !important;
        opacity: 0.8 !important;
      }
      button[data-variant=tertiary] {
        background-color: ${(props) => props.colorTertiaryButton};
        color: ${(props) =>
            pickTextColorBasedOnBgColor(props.colorTertiaryButton)};
      }
`;
const GlobalSettingsSpace = () => {
    const {
        font,
        colorSecondaryButton,
        brandColor,
        backgroundColor,
        colorTertiaryButton,
        menuBackgroundColor,
    } = useSelector((state) => state.settingSpace);

    return (
        <GlobalStyle
            font={font ?? 'Inter'}
            fontType={findFontTypes(font ?? 'Inter')}
            secondaryColor={colorSecondaryButton}
            primaryColor={
                String(brandColor).toLowerCase() === '#FFFFFF'.toLowerCase() ||
                !brandColor
                    ? DEFAULT_MAIN_BRAND_COLOR
                    : brandColor
            }
            backgroundColor={backgroundColor}
            colorTertiaryButton={colorTertiaryButton}
            menuBackgroundColor={menuBackgroundColor}
        />
    );
};

export default GlobalSettingsSpace;
