import {useLocation, Navigate, Outlet} from "react-router-dom";

const RequireAuth = ({condition, redirectPath="/auth/login"}) => {
    const location = useLocation();

    return (
        condition
            ? <Outlet/>
            : <Navigate to={redirectPath} state={{from: location}} replace/>
    );
};

export default RequireAuth;
