import { nanoid } from 'nanoid';
import {
    CONTENT_COURSES_ROUTE,
    CONTENT_FAVORITES_ROUTE,
    CONTENT_MAIN_ROUTE,
    CONTENT_ANALYTICS_ROUTE,
    CONTENT_DOCUMENTS_ROUTE,
    CONTENT_CALENDAR_ROUTE,
    CONTENT_SETTINGS_ROUTE,
    CONTENT_CHAT_ROUTE,
    CONTENT_NOTIFICATION_ROUTE,
    CONTENT_PROFILE_ROUTE,
    CONTENT_USERS_ROUTE,
} from 'utils/consts';

const items = [
    // {
    //     id: nanoid(),
    //     icon: 'home',
    //     name: 'NAVIGATION_MAIN',
    //     roles: ['mentor'],
    //     to: CONTENT_MAIN_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'courses',
        name: 'NAVIGATION_COURSES',
        roles: ['mentor', 'student'],
        to: '/content/courses',
    },
    // {
    //     id: nanoid(),
    //     icon: 'heart',
    //     name: 'Избранные',
    //     to: CONTENT_FAVORITES_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'analytics',
        name: 'NAVIGATION_ANALYTICS',
        roles: ['mentor'],
        to: CONTENT_ANALYTICS_ROUTE,
    },
    // {
    //     id: nanoid(),
    //     icon: 'calendar',
    //     name: 'Календарь',
    //     to: CONTENT_CALENDAR_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'user-group',
        name: 'NAVIGATION_USERS',
        roles: ['mentor'],
        to: '/content/users',
    },
    {
        id: nanoid(),
        icon: 'documents',
        name: 'DOCUMENTS',
        roles: ['mentor'],
        to: CONTENT_DOCUMENTS_ROUTE,
    },
    {
        id: nanoid(),
        icon: 'settings',
        name: 'SETTINGS',
        roles: ['mentor'],
        to: CONTENT_SETTINGS_ROUTE,
        isDivider: true,
    },
];

const testMobileItems = (back, student, settings, preview, next) => {
    return [
        {
            id: nanoid(),
            icon: 'arrow-left',
            handler: back,
            to: '',
        },
        {
            id: nanoid(),
            icon: 'student',
            handler: student,
            to: '',
        },
        {
            id: nanoid(),
            icon: 'settings',
            handler: settings,
            to: '',
        },
        {
            id: nanoid(),
            icon: 'eye-hidden-yes',
            handler: preview,
            to: '',
        },
        {
            id: nanoid(),
            icon: 'arrow-right',
            handler: next,
            to: '',
        },
    ];
};
const mobileItems = [
    // {
    //     id: nanoid(),
    //     icon: 'home',
    //     name: 'NAVIGATION_MAIN',
    //     roles: ['mentor'],
    //     to: CONTENT_MAIN_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'courses',
        name: 'NAVIGATION_COURSES',
        roles: ['mentor', 'student'],
        to: CONTENT_COURSES_ROUTE,
    },
    // {
    //     id: nanoid(),
    //     icon: 'chat',
    //     name: 'CHAT',
    //     roles: ['mentor'],
    //     to: CONTENT_CHAT_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'notification',
        name: 'NOTIFICATION',
        roles: ['mentor'],
        to: CONTENT_NOTIFICATION_ROUTE,
    },
    {
        id: nanoid(),
        icon: 'menu',
        name: 'MENU',
        roles: ['mentor', 'student'],
        to: '/content/menu',
    },
];

const locationPathMenus = {
    '/content/test/create': (back, student, settings, preview, next) =>
        testMobileItems(back, student, settings, preview, next),
};

export { items, mobileItems, locationPathMenus };
