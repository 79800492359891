import {
    Heading,
    Icon,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import defaultAvatar from 'assets/img/png/img.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import './StudentCoursesAccess.scss';
import { useTranslation } from 'react-i18next';

const StudentCoursesAccess = ({ courses, hasMore, loadMore }) => {
    const navigate = useNavigate();
    const { userSpace } = useSelector((state) => state.space);
    const { t } = useTranslation('common');
    const onDetailed = (id) =>
        navigate(`/content/courses/detailed/${userSpace?.id}/${id}`);
    return (
        <section className={'student-courses-access'}>
            <Heading className={'student-courses-access__title'} variant={'h2'}>
                {t('STUDENT_COURSE_LIST')}
            </Heading>
            <InfiniteScroll
                className={'student-courses-access__list'}
                dataLength={courses.length}
                next={loadMore}
                hasMore={hasMore}
                loader={null}
            >
                {courses.map(({ id, name, image, isFavorite, available }) => (
                    <li key={id} className={'student-courses-access__item'}>
                        <img
                            className={'item__img'}
                            src={image || defaultAvatar}
                            alt={name}
                            onClick={() => onDetailed(id)}
                        />
                        {!available ? (
                            <div
                                className={classNames(
                                    'item__fav',
                                    'item__fav--lock',
                                    { isWhite: true }
                                )}
                            >
                                <Icon name={'lock'} />
                            </div>
                        ) : null}
                        {/*<div className={classNames('item__fav', {isWhite: !isFavorite})}>*/}
                        {/*    <Icon*/}
                        {/*        name={isFavorite ? 'heart-favorite' : 'heart'}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <Heading
                            className={'item__name break-all'}
                            variant={'h5'}
                        >
                            {name}
                        </Heading>
                    </li>
                ))}
            </InfiniteScroll>
        </section>
    );
};

export default StudentCoursesAccess;
