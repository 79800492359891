import { axiosRequest } from 'api/xhr';
import { authActions } from 'store/reducers/authSlice';
import { Mutex } from 'async-mutex';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { store } from 'store';
import axios from 'axios';
import { BASE_URL_AUTH_SERVICE } from '../../../configs';
import { CONTENT_COURSES_ROUTE } from 'utils/consts';

const mutex = new Mutex();

const axiosBaseQuery = async ({ url, method, data, params }) => {
    try {
        const result = await axiosRequest({ url, method, data, params });
        return { data: result };
    } catch (axiosError) {
        return {
            error: axiosError.response,
        };
    }
};

export const baseQueryWithReAuth = async (args, api) => {
    await mutex.waitForUnlock();
    const currentArgs = { ...args, data: args.body };
    let result = await axiosBaseQuery(currentArgs);
    if (result.error) {
        if (result.error.status === 401) {
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                try {
                    const refreshResult = await axios.post(
                        `${BASE_URL_AUTH_SERVICE}/refresh`,
                        { token: store.getState().auth.refreshToken }
                    );
                    if (refreshResult.data) {
                        const { token, refresh } = refreshResult.data;
                        api.dispatch(authActions.setToken({ token }));
                        api.dispatch(
                            authActions.setRefreshToken({ token: refresh })
                        );
                        result = await axiosBaseQuery(currentArgs);
                    } else {
                        api.dispatch(authActions.logout());
                    }
                } catch (e) {
                    api.dispatch(authActions.logout());
                } finally {
                    release();
                }
            } else {
                await mutex.waitForUnlock();
                result = await axiosBaseQuery(currentArgs);
            }
        } else if (result.error.status === 403) {
            window.location.href = CONTENT_COURSES_ROUTE;
        }
    }
    return result;
};

export const baseQuery = baseQueryWithReAuth;
export const baseApi = createApi({
    baseQuery,
    endpoints: () => ({}),
});
