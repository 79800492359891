import { useSelector } from 'react-redux';
import HeaderLogo from 'components/common/Header/HeaderLogo';
import HeaderBreadcrumbs from 'components/common/Header/HeaderBreadcrumbs';
import HeaderHelper from 'components/common/Header/HeaderHelper';
import './HeaderUsers.scss';
const HeaderUsers = () => {
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    return (
        <header
            className={'header'}
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <HeaderLogo />
            <HeaderBreadcrumbs />
            <HeaderHelper />
        </header>
    );
};

export default HeaderUsers;
