import React from 'react';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import en from 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useTranslation } from 'react-i18next';
const plugins = {
    relativeTime,
    isSameOrAfter,
    isToday,
    isYesterday,
    updateLocale,
};

for (const pluginKey of Object.keys(plugins)) {
    dayjs.extend(plugins[pluginKey]);
}
dayjs.locale(ru);
dayjs.updateLocale('ru', {
    months: [
        'января.',
        'февраля.',
        'марта.',
        'апреля.',
        'мая.',
        'июня',
        'июля.',
        'августа.',
        'сентрября.',
        'октября.',
        'ноября.',
        'декабря.',
    ],
});
const useFormatDate = (date) => {
    const { t, i18n } = useTranslation('common');
    const convertDate = dayjs(date).locale(i18n?.language || 'ru');

    if (convertDate.isSameOrAfter(Date.now())) {
        return t('DATE_NOW');
    }
    if (convertDate.isYesterday()) {
        return t('DATE_IS_YESTERDAY', { date: convertDate.format('H:mm') });
    }
    const convertToPositive = Math.abs(convertDate.diff(new Date(), 'h'));
    const isToday = convertDate.isToday();
    if (convertToPositive > 5 && isToday) {
        return t('DATE_IS_TODAY', { date: convertDate.format('H:mm') });
    }
    if (convertToPositive < 5) {
        return convertDate.fromNow();
    }
    return convertDate.format('DD MMMM');
};

export default useFormatDate;
