import {
    Checkbox,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import CourseImg from 'assets/img/png/img.png';
import './CourseItem.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const CourseItem = (props) => {
    const { t } = useTranslation('common');
    const { id, image, name, isSelected, onSelect } = props;

    const onContainerSelected = () => {
        onSelect(
            {
                target: { checked: !isSelected, value: id },
            },
            { id, image, name }
        );
    };
    return (
        <div
            className={classNames('course-item', { selected: isSelected })}
            onClick={onContainerSelected}
        >
            <img
                className={'course-item__img'}
                src={image ?? CourseImg}
                alt={name}
            />
            <div className={'course-item__content'}>
                <Typography className={'course-item__name'} variant={'body3'}>
                    {name}
                </Typography>
                <Typography className={'course-item__invite'} variant={'body4'}>
                    {t('COURSE_INVITE_MESSAGE')}
                </Typography>
            </div>
            <Checkbox
                value={id}
                checked={isSelected}
                className={'course-item__checkbox'}
                onChange={(e) => onSelect(e, { id, name, image })}
            />
        </div>
    );
};

export default CourseItem;
