import { baseApi, baseQuery } from 'api/xhr';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const courseApi = baseApi.injectEndpoints({
    tagTypes: ['Courses', 'Course', 'CourseTags'],
    endpoints: (build) => ({
        fetchCourses: build.query({
            query: ({ spaceId, ...params }) => ({
                url: `/course-space/${spaceId}/courses/`,
                params,
            }),
            providesTags: ['Courses'],
            transformResponse: (baseQueryReturnValue) => {
                if (baseQueryReturnValue.length === 0) {
                    return {
                        results: [],
                        count: 0,
                        hasMore: false,
                    };
                } else {
                    return {
                        results: baseQueryReturnValue.results,
                        count: baseQueryReturnValue.count,
                        hasMore: !!baseQueryReturnValue.next,
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'Course', id: arg.courseId.id },
            ],
        }),
        saveProgressCourse: build.mutation({
            query: ({ spaceId, body }) => ({
                url: `/students/${spaceId}/save-progress/`,
                method: 'POST',
                body,
            }),
        }),
        startCourseApi: build.mutation({
            query: ({ spaceId, testId }) => ({
                url: `/students/${spaceId}/courses/${testId}/start-course/`,
                method: 'POST',
            }),
        }),
        createCourse: build.mutation({
            query: ({ spaceId }) => ({
                url: `/course-space/${spaceId}/courses/`,
                method: 'POST',
            }),
        }),
        deleteCourse: build.mutation({
            query: ({ spaceId, courseId }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/`,
                method: 'DELETE',
            }),
        }),
        duplicateCourse: build.mutation({
            query: ({ spaceId, courseId }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/duplicate/`,
                method: 'POST',
            }),
        }),
        archiveCourse: build.mutation({
            query: ({ spaceId, courseId }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/archiving/`,
                method: 'PUT',
            }),
        }),
        publishCourse: build.mutation({
            query: ({ spaceId, courseId, body }) => ({
                url: `/course-space/${spaceId}/publish/${courseId}/`,
                method: 'PUT',
                body,
            }),
        }),
        publishCourseWithMaterials: build.mutation({
            query: ({ spaceId, courseId, body, whole }) => ({
                url: `/course-space/${spaceId}/publish/${courseId}/publication-by-sample/${
                    whole ? '?whole=true' : ''
                }`,
                method: 'PUT',
                body,
            }),
        }),
        fetchCoursesMain: build.mutation({
            query: ({ spaceId, status = 'available', role }) => ({
                url:
                    role === 'student'
                        ? `/students/${spaceId}/courses/?filter=${status}`
                        : `/course-space/${spaceId}/courses/?filter=${status}`,
                method: 'GET',
            }),
            transformResponse: (baseQueryReturnValue) => {
                return baseQueryReturnValue.results;
            },
            providesTags: ['Courses'],
        }),
        fetchRole: build.mutation({
            query: ({ spaceId, status }) => ({
                url: `/users/${spaceId}/me/`,
                method: 'GET',
            }),
            providesTags: ['Courses'],
        }),
        deleteCourseAfter: build.mutation({
            query: ({ spaceId, courseId }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/delete-after/`,
                method: 'DELETE',
            }),
        }),
        deleteCourseCancel: build.mutation({
            query: ({ spaceId, courseId, ...body }) => ({
                url: `/course-space/${spaceId}/courses/${courseId}/cancel-deletion/`,
                method: 'PATCH',
                body,
            }),
        }),
        finishCourse: build.mutation({
            query: ({ courseId, spaceId }) => ({
                url: `/students/${spaceId}/courses/${courseId}/finish-course/`,
                method: 'POST',
            }),
        }),
        fetchCourseDetail: build.query({
            query: ({ spaceId, id }) => ({
                url: `/students/${spaceId}/courses/${id}/`,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return baseQueryReturnValue;
            },
            providesTags: ['Courses'],
        }),
        fetchCourseDetailMaterials: build.query({
            query: ({ spaceId, id }) => ({
                url: `/students/${spaceId}/courses/${id}/get-materials/`,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return baseQueryReturnValue;
            },
            providesTags: ['Courses'],
        }),
        fetchCourseTestDetail: build.query({
            query: ({ spaceId, testId }) => ({
                url: `/course-space/${spaceId}/students/tests/${testId}/`,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return baseQueryReturnValue;
            },
            providesTags: ['Courses'],
        }),
        //educationalMaterials
        uploadEducationalMaterial: build.mutation({
            query: ({ spaceId, data }) => ({
                url: `/course-space/${spaceId}/educational-materials/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Course'],
        }),
        // lesson
        fetchLesson: build.query({
            query: ({ spaceId, fetchStudentURL, lessonId }) => ({
                url:
                    fetchStudentURL ||
                    `/course-space/${spaceId}/lessons/${lessonId}/`,
            }),
            refetchOnMountOrArgChange: true,
            providesTags: ['Lesson'],
        }),
        getLesson: build.mutation({
            query: ({ spaceId, fetchStudentURL, lessonId, ...params }) => ({
                url:
                    fetchStudentURL ||
                    `/course-space/${spaceId}/lessons/${lessonId}/`,
                method: 'GET',
                params,
            }),
            refetchOnMountOrArgChange: true,
            providesTags: ['Lesson'],
        }),
        fetchCourseTags: build.query({
            query: ({ spaceId, ...params }) => ({
                url: `/course-space/${spaceId}/tags/`,
                method: 'GET',
                params,
            }),
            providesTags: ['CourseTags'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue.results.map(({ name }) => ({
                    label: name,
                    value: name,
                }));
            },
        }),
        fetchPublishItems: build.query({
            query: ({ spaceId, courseId }) => ({
                url: `/course-space/${spaceId}/publish/${courseId}/`,
            }),
        }),
        addCourseTags: build.mutation({
            query: ({ spaceId, ...body }) => ({
                url: `/course-space/${spaceId}/tags/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['CourseTags'],
        }),
        fetchCourseSimilarTags: build.query({
            query: ({ spaceId, ...params }) => ({
                url: `/course-space/${spaceId}/tags/similar-tags/`,
                method: 'GET',
                params,
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue.map(({ name }) => ({
                    label: name,
                    value: name,
                }));
            },
        }),
    }),
});

export const {
    useFetchCourseTestDetailQuery,
    useFetchCourseDetailQuery,
    useFetchCourseDetailMaterialsQuery,
    useFetchCoursesQuery,
    useLazyFetchCoursesQuery,
    useFetchLessonQuery,
    useGetLessonMutation,
    useCreateCourseMutation,
    useStartCourseApiMutation,
    useDeleteCourseMutation,
    useDuplicateCourseMutation,
    useArchiveCourseMutation,
    useSaveProgressCourseMutation,
    usePublishCourseMutation,
    usePublishCourseWithMaterialsMutation,
    useFinishCourseMutation,
    useFetchCoursesMainMutation,
    useFetchRoleMutation,
    useDeleteCourseAfterMutation,
    useDeleteCourseCancelMutation,
    useUploadEducationalMaterialMutation,
    useFetchCourseTagsQuery,
    useLazyFetchCourseTagsQuery,
    useFetchPublishItemsQuery,
    useAddCourseTagsMutation,
    useFetchCourseSimilarTagsQuery,
    useLazyFetchCourseSimilarTagsQuery,
} = courseApi;
