import { nanoid } from 'nanoid';
import {
    CONTENT_ANALYTICS_ROUTE,
    CONTENT_CHAT_ROUTE,
    CONTENT_COURSES_ROUTE,
    CONTENT_DOCUMENTS_ROUTE,
    CONTENT_MAIN_ROUTE,
    CONTENT_NOTIFICATION_ROUTE,
    CONTENT_PROFILE_ROUTE,
    CONTENT_SETTINGS_ROUTE,
} from 'utils/consts';

const items = [
    // {
    //     id: nanoid(),
    //     icon: 'home',
    //     name: 'NAVIGATION_MAIN',
    //     roles: ['mentor'],
    //     to: CONTENT_MAIN_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'courses',
        name: 'NAVIGATION_COURSES',
        roles: ['mentor', 'student'],
        to: CONTENT_COURSES_ROUTE,
    },
    {
        id: nanoid(),
        icon: 'analytics',
        name: 'NAVIGATION_ANALYTICS',
        roles: ['mentor'],
        to: CONTENT_ANALYTICS_ROUTE,
    },
    {
        id: nanoid(),
        icon: 'user-group',
        name: 'NAVIGATION_USERS',
        roles: ['mentor'],
        to: '/content/users',
    },
    {
        id: nanoid(),
        icon: 'documents',
        name: 'DOCUMENTS',
        roles: ['mentor'],
        to: CONTENT_DOCUMENTS_ROUTE,
    },
    {
        id: nanoid(),
        icon: 'settings',
        name: 'SETTINGS',
        roles: ['mentor'],
        to: CONTENT_SETTINGS_ROUTE,
        isDivider: true,
    },
];

const mobileItems = [
    // {
    //     id: nanoid(),
    //     icon: 'home',
    //     name: 'NAVIGATION_MAIN',
    //     roles: ['mentor'],
    //     to: CONTENT_MAIN_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'admin-courses',
        name: 'NAVIGATION_COURSES',
        roles: ['mentor', 'student'],
        to: CONTENT_COURSES_ROUTE,
    },
    // {
    //     id: nanoid(),
    //     icon: 'chat',
    //     name: 'CHAT',
    //     roles: ['mentor'],
    //     to: CONTENT_CHAT_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'notification',
        name: 'NOTIFICATION',
        roles: ['mentor'],
        to: CONTENT_NOTIFICATION_ROUTE,
    },
    {
        id: nanoid(),
        icon: 'menu',
        name: 'MENU',
        roles: ['mentor'],
        to: '/content/menu',
    },
];

export { items, mobileItems };
