import React, { useState } from 'react';
import AnalyticsSearchFilter from 'pages/analytics/common/AnalyticsSearchFilter/AnalyticsSearchFilter';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import {
    studentsTable,
    studentsTableHeadings,
} from 'pages/analytics/common/consts';
import { useNavigate } from 'react-router-dom';
import './Students.scss';
import { useFetchStudentsQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useSelector } from 'react-redux';
import EmptyPlaceholders from 'pages/analytics/common/EmptyPlaceholders/EmptyPlaceholders';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'helpers/hooks';
const Students = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const debouncedSearchInput = useDebounce(search, 800);

    const { data, isLoading } = useFetchStudentsQuery({
        spaceId: userSpace?.id,
        page: page - 1,
        search: debouncedSearchInput,
    });

    const navigate = useNavigate();

    const onSearch = (e) => setSearch(e.target.value);

    const goToDetail = (item) => {
        navigate(`/content/analytics/detail/course/student/${item.id}`);
    };

    if (isLoading) return <div>{t('GENERAL_LOADING')}</div>;

    return (
        <section className="students analytics-study">
            <AnalyticsSearchFilter
                onSearch={onSearch}
                searchPlaceholder={t('USERS_STUDENTS_SEARCH')}
                totalCount={data.total}
            />
            {!(data?.items || []).length ? (
                <EmptyPlaceholders
                    title={t('ANALYTICS_EMPTY_TITLE')}
                    description={t('ANALYTICS_EMPTY_STUDENTS_DESC')}
                />
            ) : (
                <AnalyticsTable
                    isStudent
                    handler={goToDetail}
                    headings={studentsTableHeadings}
                    body={data.items || []}
                    withImgColumn
                    withoutBorder
                    withoutPagination
                    total={data.total}
                    page={page}
                    onChangePage={setPage}
                />
            )}
        </section>
    );
};

export default Students;
