import { useState } from 'react';
import {
    Avatar,
    Dropdown,
    Icon,
} from '@akhter-studios/classlytics-front-ui-components';
import { useSelector } from 'react-redux';
import { useMeQuery } from 'api/services';
import ProfileMenuList from 'components/common/Header/ProfileMenuList';
import './HeaderProfile.scss';

const HeaderProfile = () => {
    const { userSpace } = useSelector((state) => state.space);
    const { data: profile } = useMeQuery(
        { id: userSpace?.id },
        { skip: !userSpace }
    );
    const [isShow, setIsShow] = useState(false);

    return (
        <Dropdown
            className={'header-profile'}
            isShow={isShow}
            setIsShow={setIsShow}
        >
            <Dropdown.Header className={'header-profile__header'}>
                {profile?.avatar ? (
                    <Avatar
                        src={profile.avatar}
                        alt={'avatar'}
                        variant={'rounded'}
                    />
                ) : (
                    <Avatar
                        alt={'avatar'}
                        variant={'rounded'}
                        avatarText={`${
                            profile?.firstName?.charAt(0).toUpperCase() || ''
                        }${profile?.lastName?.charAt(0).toUpperCase() || ''}`}
                    />
                )}
                <Icon
                    className={'header-profile__icon'}
                    name={isShow ? 'arrow-up' : 'arrow-down'}
                    onClick={() => setIsShow((prev) => !prev)}
                />
            </Dropdown.Header>
            <Dropdown.Body className={'header-profile__body'}>
                <ProfileMenuList setIsShow={setIsShow} />
            </Dropdown.Body>
        </Dropdown>
    );
};

export default HeaderProfile;
