import {nanoid} from "nanoid";

const coursesMock = [
    {
        id: nanoid(),
        isFavorite: true,
        isLock: true,
        name: "BBS- Bachelor of Business Studies",
        subjectCount: "Пройдено 7/14",
        percentage: 55
    },
    {
        id: nanoid(),
        isFavorite: false,
        isLock: false,
        name: "BBS- Bachelor of Business Studies",
        subjectCount: "Пройдено 7/14",
        percentage: 55
    },
    {
        id: nanoid(),
        isFavorite: true,
        isLock: true,
        name: "BBS- Bachelor of Business Studies",
        subjectCount: "Пройдено 7/14",
        percentage: 55
    },
    {
        id: nanoid(),
        isFavorite: false,
        isLock: false,
        name: "BBS- Bachelor of Business Studies",
        subjectCount: "Пройдено 7/14",
        percentage: 55
    },
    {
        id: nanoid(),
        isFavorite: true,
        isLock: true,
        name: "BBS- Bachelor of Business Studies",
        subjectCount: "Пройдено 7/14",
        percentage: 55
    },
    {
        id: nanoid(),
        isFavorite: false,
        isLock: false,
        name: "BBS- Bachelor of Business Studies",
        subjectCount: "Пройдено 7/14",
        percentage: 55
    }
];

export {coursesMock};