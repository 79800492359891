import { Typography } from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './AuthError.scss';
import { useTranslation } from 'react-i18next';

const AuthError = ({ className, error }) => {
    const { t } = useTranslation('common');
    return error ? (
        <Typography
            className={classNames('error-text', className)}
            variant={'body3'}
            style={{ color: '#ff2f24' }}
        >
            {t(error)}
        </Typography>
    ) : null;
};

export default AuthError;
