import { useState, useEffect } from 'react';
import { useDebounce } from 'helpers/hooks';
import { useSelector } from 'react-redux';
import {
    useLazyFetchUserStudentsQuery,
    useAddUserGroupStudentsMutation,
} from 'api/services';
import { notify } from 'utils/functions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectableModal from 'pages/users/common/UsersModalWrapper/SelectableModal';
import { StudentModalItem } from 'pages/users/common/StudentModalItem';

const AddStudentsModal = ({ isOpen, setIsOpen }) => {
    const { id: groupId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const [trigger] = useLazyFetchUserStudentsQuery();
    const [addUserGroupStudents, { isLoading }] =
        useAddUserGroupStudentsMutation();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const [students, setStudents] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const { t } = useTranslation('common');
    const onAddStudents = async () => {
        const { error } = await addUserGroupStudents({
            spaceId: userSpace.id,
            groupId,
            users: selectedStudents,
        });
        if (error) {
            notify('error', error.data.error);
        }
        if (!error) {
            notify('success', t('NOTIFY_SUCCESSFULLY_INVITED_TO_GROUP'));
            setIsOpen(false);
            setSearch('');
            setStudents([]);
        }
    };

    const onSearch = (e) => setSearch(e.target.value);
    const onSelect = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedStudents([...selectedStudents, +value]);
        } else {
            setSelectedStudents(selectedStudents.filter((s) => s !== +value));
        }
    };
    const onSelectAll = () => {
        if (isSelectedAll) {
            setIsSelectedAll(false);
            setSelectedStudents([]);
        } else {
            setIsSelectedAll(true);
            setSelectedStudents(students.results.map((c) => c.user.id));
        }
    };

    const loadMoreStudents = async () => {
        if (!students?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            spaceId: userSpace.id,
            page: page + 1,
            page_size: pageSize,
            search: debouncedSearch,
        });
        const modifiedResults = [...students.results, ...data.results];
        setStudents({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(
        () =>
            userSpace &&
            trigger({ spaceId: userSpace.id, page, page_size: pageSize }).then(
                ({ data }) => setStudents(data)
            ),
        [userSpace]
    );
    useEffect(
        () =>
            trigger({
                spaceId: userSpace.id,
                page: 1,
                page_size: pageSize,
                search: debouncedSearch,
            }).then(({ data }) => {
                setPage(1);
                setStudents(data);
            }),
        [debouncedSearch]
    );
    return (
        <SelectableModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('STUDENTS_CHOOSE')}
            btnText={t('SAVE')}
            searchPlaceholder={t('USERS_STUDENTS_SEARCH')}
            isSelectedAll={isSelectedAll}
            isSearchable={true}
            btnLoading={isLoading}
            btnDisabled={isLoading || selectedStudents.length === 0}
            search={search}
            currentCount={students?.results?.length}
            maxCount={students?.count}
            hasMore={students?.hasMore}
            items={students?.results}
            onSearch={onSearch}
            onSubmit={onAddStudents}
            onLoadMore={loadMoreStudents}
            onSelectAll={onSelectAll}
            renderItem={({ user }) => (
                <StudentModalItem
                    key={user.id}
                    user={user}
                    isSelected={selectedStudents.includes(user.id)}
                    onSelect={onSelect}
                />
            )}
        />
    );
};

export default AddStudentsModal;
