import React from 'react';
import AnalyticsHeaderMobile from 'pages/analytics/common/AnalyticsHeaderMobile/AnalyticsHeaderMobile';
import {
    Button,
    Search,
} from '@akhter-studios/classlytics-front-ui-components';
import SortList from 'pages/analytics/common/SortList/SortList';
import {
    courseModalSort,
    tableHeadingStudents,
} from 'pages/analytics/views/groups/consts';
import { useFetchGroupStudentsQuery } from 'api/services/AnalyticsService/AnalyticsService';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import StudentMobileContent from 'pages/analytics/views/groups/Modals/StudentMobileContent';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Student = ({ selectedCourse, handleToggleModal }) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);

    const { data } = useFetchGroupStudentsQuery({
        spaceId: userSpace?.id,
        id: selectedCourse.id,
    });

    return (
        <section className="analytics-groups-modal">
            <AnalyticsHeaderMobile
                title={selectedCourse.name}
                close={handleToggleModal}
            />
            <div className="analytics-groups-modal__title">Учащиеся курса</div>
            <Search placeholder={t('USERS_STUDENTS_SEARCH')} />
            <div className="analytics-groups-modal__course-total d-flex">
                <div>Всего: </div>
                <span>{(data || []).length}</span>
            </div>
            <SortList data={courseModalSort} />
            <div className="analytics-groups-modal__courses mobile-hidden">
                <AnalyticsTable
                    withImgColumn
                    isStudent
                    body={data || []}
                    headings={tableHeadingStudents}
                />
            </div>
            <div className="desktop-hidden">
                <StudentMobileContent data={data || []} />
            </div>
            <div className="analytics-groups-modal__back mobile-hidden">
                <Button variant="secondary">Назад</Button>
            </div>
        </section>
    );
};

export default Student;
