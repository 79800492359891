import SettingsItem from 'pages/settings/common/SettingsItem';
import Select from 'react-select';
import { CustomDropdownIndicator } from 'components/custom-react-select';
import {
    options,
    styles,
} from 'pages/settings/common/SettingsColors/SettingsColorsFont/consts';
import { useState } from 'react';
import { DEFAULT_FONT } from 'pages/settings/consts';
import { useTranslation } from 'react-i18next';
import { useAction } from 'helpers/hooks';
import { useUpdateSpaceMutation } from 'api/services';
import { useSelector } from 'react-redux';
const SettingsColorsFont = () => {
    const { t } = useTranslation('common');
    const { setFont: setGlobalFont } = useAction();
    const { userSpace } = useSelector((state) => state.space);
    const [font, setFont] = useState(
        userSpace?.font
            ? {
                  label: userSpace?.font,
                  value: userSpace?.font,
              }
            : DEFAULT_FONT
    );
    const [updateSpace] = useUpdateSpaceMutation();
    const onChangeFont = (selectedOption) => setFont(selectedOption);
    const onResetFont = async () => {
        setFont(DEFAULT_FONT);
        setGlobalFont(DEFAULT_FONT.value);
        await updateSpace({
            id: userSpace.id,
            font: DEFAULT_FONT.value,
        });
    };
    const onApply = async () => {
        setGlobalFont(font.value);
        await updateSpace({
            id: userSpace.id,
            font: font.value,
        });
    };
    return (
        <SettingsItem
            title={t('SETTINGS_FONT')}
            isInfo={false}
            onReset={onResetFont}
            onApply={onApply}
        >
            <Select
                className={'settings-item__select'}
                placeholder={t('SETTINGS_FONT')}
                options={options}
                styles={styles}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                value={font}
                onChange={onChangeFont}
            />
        </SettingsItem>
    );
};

export default SettingsColorsFont;
