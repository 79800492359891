import React, { useEffect, useState } from 'react';
import Lesson from 'pages/lesson/Lesson';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useFetchCourseDetailMaterialsQuery,
    useFetchLessonQuery,
    useFinishCourseMutation,
    useSaveProgressCourseMutation,
} from 'api/services';
import { Button, Modal } from '@akhter-studios/classlytics-front-ui-components';
import { notify } from 'utils/functions';
import { nextOrCompletedCourseBtn } from 'pages/courses/CoursesDetailed/constants';
import { Header, Icon, Sidebar } from 'components/common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalItem } from 'pages/course-test/views/StudentTest/Modal';
import DialogModalCustom from 'components/common/DialogModalCustom/DialogModalCustom';
import TestInfoModal from 'pages/lesson/LessonStudent/TestInfoModal';
import CoursesStudentContent from 'pages/courses/CoursesDetailed/CoursesStudentContent';
import CourseMaterialNavigation from 'pages/courses/CourseMaterialNavigation';
const LessonStudent = () => {
    const { lessonId, courseId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const [success, setSuccess] = useState(false);
    const [contentSidebarOpen, setContentSidebarOpen] = useState(false);
    const [testModal, setTestModal] = useState(false);
    const {
        data: lesson,
        isLoading,
        isFetching,
    } = useFetchLessonQuery(
        {
            spaceId: localStorage.getItem('spaceId'),
            lessonId: lessonId,
            fetchStudentURL: `/students/${userSpace?.id}/lessons/${lessonId}/`,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );
    const { data: materials, refetch } = useFetchCourseDetailMaterialsQuery(
        { spaceId: userSpace?.id, id: courseId },
        {
            skip: !userSpace,
            refetchOnMountOrArgChange: true,
        }
    );
    useEffect(() => {
        setContentSidebarOpen(false);
        refetch();
    }, [lesson]);

    const [saveProgressCourse] = useSaveProgressCourseMutation();
    const [finishCourse] = useFinishCourseMutation();
    const navigate = useNavigate();
    const redirectTo = (url) => {
        navigate(url);
    };
    const { t } = useTranslation('common');
    const finishCourseClick = () => {
        refetch();
        setSuccess((prev) => !prev);
    };
    const finishCourseModalClick = () => {
        finishCourse({ spaceId: userSpace.id, courseId })
            .unwrap()
            .then(() => {
                setTimeout(() => {
                    navigate('/content/courses');
                }, 1000);
                notify('success', t('NOTIFY_SUCCESSFULLY_ENDED'));
            })
            .catch((err) => {
                notify('error', err.data.error || 'Что то пошло не такы');
            });
    };

    const toggleTestModal = () => {
        setTestModal((prev) => !prev);
    };

    const { title, handler } = nextOrCompletedCourseBtn({
        spaceId: userSpace?.id,
        courseId,
        moduleType: 'lesson',
        moduleId: lessonId,
        redirectTo,
        nextRoute: lesson?.nextRoute || {},
        saveProgressCourse,
        finish: finishCourseClick,
        t,
    });

    const apply = () => {
        refetch();
        checkTestWithTime ? setTestModal((prev) => !prev) : handler();
    };

    const checkTestWithTime = typeof lesson?.nextRoute?.duration === 'number';
    if (isFetching) return <></>;
    return (
        <>
            <Header hiddenBreadCrumbs={true} hiddenSearch={true} />
            <Sidebar />
            <section className="layout-main">
                {!isLoading && (
                    <div className={'lesson__material-navigation'}>
                        <CourseMaterialNavigation
                            next={lesson?.next}
                            prev={lesson?.prev}
                            nextIsDisabled={!lesson?.fullPassed}
                        />
                    </div>
                )}
                <Lesson
                    readOnlyStudent={true}
                    fetchStudentURL={`/students/${userSpace?.id}/lessons/${lessonId}/`}
                />
                {!isLoading && !lesson?.isPassed && (
                    <Button
                        className={'auth-button'}
                        type={'submit'}
                        variant={'primary'}
                        fullWidth
                        onClick={apply}
                        style={{ maxWidth: '700px', margin: '50px auto' }}
                    >
                        {title}
                    </Button>
                )}
                <Modal
                    isOpen={success}
                    setIsOpen={() => setSuccess((prev) => !prev)}
                >
                    <ModalItem
                        toggleModal={() => setSuccess((prev) => !prev)}
                        btnTitle={t('STUDENT_TEST_ALERT_BUTTON_COMPLETED')}
                        handler={finishCourseModalClick}
                        type={'success'}
                        customTitle={t('STUDENT_TEST_ALERT_TITLE_COMPLETED')}
                    />
                </Modal>
                <CoursesStudentContent
                    materials={materials}
                    contentOpen={contentSidebarOpen}
                    setContentOpen={setContentSidebarOpen}
                />
                <DialogModalCustom
                    modal
                    open={testModal}
                    setOpen={setTestModal}
                    customHeight={415}
                >
                    <TestInfoModal
                        ready={handler}
                        onClose={toggleTestModal}
                        data={lesson?.nextRoute}
                    />
                </DialogModalCustom>
            </section>
        </>
    );
};

export default LessonStudent;
