import React from 'react';
import {
    Icon,
    Progress,
} from '@akhter-studios/classlytics-front-ui-components';
import { downloadBlob } from 'utils/functions/helpers';
import './index.scss';
import { formatBytes } from 'helpers/functions';
import { Grid } from '@mui/material';
import {
    useRemoveEducationalMaterialMutation,
    useUpdateEducationalMaterialMutation,
} from 'api/services';
import { useSelector } from 'react-redux';
import { usePreview } from 'components/layout/PreviewContext';

const EducationFiles = ({
    files = [],
    isReadOnly,
    disabledDownload,
    updateLockIcon,
    removeEducationalMaterialLocally,
    uploadPercent,
}) => {
    const previewStatus = usePreview();
    const { userSpace } = useSelector((state) => state.space);
    const [updateMaterial] = useUpdateEducationalMaterialMutation();
    const [removeEducationalMaterial] = useRemoveEducationalMaterialMutation();
    const onLockToggle = async (id, isLocked) => {
        updateLockIcon(id);
        await updateMaterial({
            spaceId: userSpace?.id,
            materialId: id,
            data: {
                isDownload: !isLocked,
            },
        });
    };
    const onRemove = async (id) => {
        removeEducationalMaterialLocally(id);
        await removeEducationalMaterial({
            spaceId: userSpace?.id,
            materialId: id,
        });
    };
    return (
        <div className="educational-files">
            <div className="educational-files-wrapper">
                <div className="educational-files__items">
                    {files.map((x) => (
                        <Grid
                            className="educational-files__item"
                            key={x.id}
                            container
                            alignItems={'center'}
                        >
                            <Grid xs={10} item>
                                <Grid
                                    wrap={'nowrap'}
                                    container
                                    item
                                    alignItems={'center'}
                                >
                                    <Icon name="knowledge-folder" />
                                    <a
                                        href={
                                            x.isDownload || previewStatus
                                                ? x.file
                                                : null
                                        }
                                        target="_blank"
                                        className="educational-files__name"
                                    >
                                        {x.title}
                                    </a>
                                    <span className={'educational-files__size'}>
                                        {isNaN(x.size)
                                            ? x.size
                                            : formatBytes(x.size)}
                                    </span>
                                </Grid>
                                {x.isTemp && (
                                    <Progress
                                        className={
                                            'educational-files__progress'
                                        }
                                        value={uploadPercent[x.id]?.value || 0}
                                        max={100}
                                    />
                                )}
                            </Grid>
                            <Grid
                                container
                                item
                                xs={2}
                                alignItems={'center'}
                                justifyContent={'flex-end'}
                            >
                                {x.isTemp && (
                                    <div
                                        className={'educational-files__percent'}
                                    >
                                        {uploadPercent[x.id]?.value || 0}%
                                    </div>
                                )}
                                {!isReadOnly && (
                                    <>
                                        <Icon
                                            onClick={() =>
                                                onLockToggle(x.id, x.isDownload)
                                            }
                                            name={
                                                x.isDownload
                                                    ? 'un-lock'
                                                    : 'lock'
                                            }
                                        />
                                        <Icon
                                            onClick={() => onRemove(x.id)}
                                            className={
                                                'educational-files__remove'
                                            }
                                            name={'trash'}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EducationFiles;
