import { axiosRequest } from 'api/xhr';

export const createCourseFile = ({
    spaceId,
    body,
    setUploadPercent,
    id,
    tempFile,
}) =>
    axiosRequest.post(`/course-space/${spaceId}/educational-materials/`, body, {
        onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercent((prev) => ({
                ...prev,
                [id]: {
                    ...prev[id],
                    ...tempFile,
                    value: percentCompleted,
                },
            }));
        },
    });
