import React from 'react';
import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';

const AnalyticsTitle = () => {
    const { t } = useTranslation('common');
    return (
        <div className="users__title">
            <Heading className={'users-title__name'} variant={'h1'}>
                {t('ANALYTICS')}
            </Heading>
        </div>
    );
};

export default AnalyticsTitle;
