import React from 'react';
import './HeaderStudentTest.scss';
import HeaderLogo from 'components/common/Header/HeaderLogo';
import HeaderHelper from 'components/common/Header/HeaderHelper';
import Timer from 'components/common/Header/HeaderStudentTest/Timer/Timer';
import ArrowBack from 'components/ArrowBack/ArrowBack';
const HeaderStudentTest = ({ isPassed }) => {
    return (
        <header className="header">
            <ArrowBack />
            <HeaderLogo />
            {!isPassed && <Timer isPassed={isPassed} />}
            <HeaderHelper />
        </header>
    );
};

export default HeaderStudentTest;
