import React from 'react';
import { Icon } from 'components/common';
import { Progress } from '@akhter-studios/classlytics-front-ui-components';
import { formatBytes } from 'helpers/functions';
import Loader from 'components/common/Loader/Loader';
const FileState = ({ file, percent, cancel }) => {
    return (
        <div className="file-state">
            <Icon name="upload-excel" />
            <div className="file-state__progress">
                <div className="j-c-b">
                    <div className="d-flex">
                        <div className="file-state__name">{file.name}</div>
                        <div className="file-state__size">
                            {formatBytes(file.size)}
                        </div>
                    </div>
                    {percent !== 100 && (
                        <div className="file-state__percent">{percent}%</div>
                    )}
                </div>

                {percent !== 100 && <Progress max={100} value={percent} />}
            </div>

            {percent === 100 ? (
                <Loader />
            ) : (
                <Icon name="cancel-icon" handler={cancel} />
            )}
        </div>
    );
};

export default FileState;
