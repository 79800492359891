import React, { useState } from 'react';
import defaultImg from 'assets/img/png/img.png';
import useFormatDate from 'helpers/hooks/useFormatDate';
import cn from 'classnames';
const NotificationItem = ({ item, index, setNotifyItem }) => {
    const { text, id, description, created, image, read } = item;
    const [error, setError] = useState(false);
    const date = useFormatDate(created);
    return (
        <li
            className={cn('notifications__item', {
                isRead: !read,
            })}
            onClick={() => setNotifyItem(item, index)}
        >
            <div className="notifications__item-wrapper">
                <div className="notifications__item-img">
                    {error ? (
                        <div />
                    ) : (
                        <img
                            src={image || defaultImg}
                            onError={() => setError(true)}
                        />
                    )}
                </div>
                <div className="notifications__item-info">
                    <div className="notifications__item-time">{date}</div>
                    <div className="notifications__item-title">{text}</div>
                    <span>{description}</span>
                </div>
            </div>
        </li>
    );
};

export default NotificationItem;
