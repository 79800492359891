import { useNavigate } from 'react-router-dom';
import {
    useArchiveCourseMutation,
    useDeleteCourseAfterMutation,
    useDeleteCourseCancelMutation,
    useDeleteCourseMutation,
    useDuplicateCourseMutation,
    usePublishCourseWithMaterialsMutation,
} from 'api/services';
import { actions } from './consts';
import CDropdown from 'pages/courses/CDropdown/CDropdown';
import { useSelector } from 'react-redux';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { useState } from 'react';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import CreateCourseSettingsBottomSheetMenu from 'pages/courses/Course/create-course-settings/CreateCourseSettingsBottomSheetMenu';

const CoursesDropdown = ({
    id,
    isShow,
    status,
    setIsShow,
    onShow,
    onShowDeleteModal,
    remaining,
    draftId,
}) => {
    const isMobile = useIsMobile();
    const { userSpace } = useSelector((state) => state.space);
    const navigate = useNavigate();
    const [deleteCourse] = useDeleteCourseMutation();
    const [deleteCourseAfter] = useDeleteCourseAfterMutation();
    const [publishCourse] = usePublishCourseWithMaterialsMutation();
    const [deleteCourseCancel] = useDeleteCourseCancelMutation();
    const [duplicateCourse] = useDuplicateCourseMutation();
    const [archiveCourse] = useArchiveCourseMutation();
    const [isOpenSettingsMenu, setIsOpenSettingsMenu] = useState(false);
    const onEditCourse = () =>
        navigate(`/content/courses/create/${draftId || id}`);
    const onNavigateToSettings = () => {
        if (isMobile) {
            setIsShow(false);
            setIsOpenSettingsMenu(true);
        } else {
            navigate(
                `/content/courses/create/${
                    status === 'active' ? draftId : id
                }/settings`
            );
        }
    };

    const onPublishCourse = async () => {
        await publishCourse({
            spaceId: userSpace?.id,
            courseId: id,
            body: { courseId: id, materials: [] },
            whole: true,
        });
    };
    const onDeleteCourse = () =>
        deleteCourse({ spaceId: userSpace?.id, courseId: id });
    const onDeleteCourseAfter = () =>
        deleteCourseAfter({ spaceId: userSpace?.id, courseId: id });
    const onDeleteCourseCancel = (status) =>
        deleteCourseCancel({ spaceId: userSpace?.id, courseId: id, status });
    const onDuplicate = () => {
        duplicateCourse({ spaceId: userSpace?.id, courseId: id, status });
    };
    const onArchiveCourse = () => {
        archiveCourse({ spaceId: userSpace?.id, courseId: id });
    };
    const onDropdownAction = (cb) => {
        setIsShow(false);
        cb();
    };
    return (
        <>
            <CDropdown
                icon={'more'}
                show={isShow}
                setShow={setIsShow}
                onShowMoreClick={onShow}
                items={
                    actions({
                        onDropdownAction,
                        onEditCourse,
                        onPublishCourse,
                        onDeleteCourse,
                        onDuplicate,
                        onDeleteCourseAfter,
                        onDeleteCourseCancel,
                        onArchiveCourse,
                        onNavigateToSettings,
                        onShowDeleteModal,
                        remaining,
                    })[status]
                }
            />
            <BottomSheetCustom
                open={isOpenSettingsMenu}
                setOpen={setIsOpenSettingsMenu}
                minHeight={250}
            >
                <CreateCourseSettingsBottomSheetMenu courseId={id} />
            </BottomSheetCustom>
        </>
    );
};

export default CoursesDropdown;
