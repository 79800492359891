import { useWindowSize } from 'helpers/hooks';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { items, mobileItems } from './consts';
import {
    Divider,
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import SidebarWrapper from 'components/common/Sidebar/SidebarWrapper';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const SidebarUsers = () => {
    const { t } = useTranslation('common');
    const { width } = useWindowSize();
    const { profile } = useSelector((state) => state.profile);

    return (
        <SidebarWrapper>
            <List
                items={width < 769 ? mobileItems : items}
                renderItem={({ id, to, icon, name, roles, isDivider }) =>
                    roles.includes(profile.role) ? (
                        <li key={id} className={'sidebar-menu__item'}>
                            {isDivider && (
                                <Divider className={'sidebar-menu__divider'} />
                            )}
                            <NavLink
                                to={to ? to : '#'}
                                className={({ isActive }) =>
                                    classNames('sidebar-menu__link', {
                                        active: isActive,
                                    })
                                }
                            >
                                <Icon
                                    className={'sidebar-menu__icon'}
                                    name={icon}
                                />
                                {name && (
                                    <Typography
                                        className={'sidebar-menu__text'}
                                        variant={'body3'}
                                    >
                                        {t(name)}
                                    </Typography>
                                )}
                            </NavLink>
                        </li>
                    ) : null
                }
            />
        </SidebarWrapper>
    );
};

export default SidebarUsers;
