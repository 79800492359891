import { combineReducers } from '@reduxjs/toolkit';
import {
    spaceApi,
    courseApi,
    authApi,
    profileApi,
    studentCourseApi,
    userStudentApi,
    userMentorApi,
    userGroupApi,
    userApi,
    notificationApi,
} from 'api/services';
import authReducer from './authSlice';
import editorReducer from './editorSlice';
import utilReducer from './utilSlice';
import foundCoursesReducer from './foundCoursesSlice';
import testReducer from './testSlice';
import spaceReducer from './spaceSlice';
import profileReducer from './profileSlice';
import courseReducer from './courseSlice';
import lessonReducer from './lessonSlice';
import userStudentReducer from './userStudentSlice';
import userMentorReducer from './userMentorSlice';
import userGroupReducer from './userGroupSlice';
import notificationReducer from './notificationSlice';
import coursesReducer from './coursesSlice';
import settingSpaceReducer from './settingSpaceSlice';
import { searchCourseApi } from 'api/services/SearchCourseService/SearchCourseService';
import { analyticsApi } from 'api/services/AnalyticsService/AnalyticsService';

const rootReducer = combineReducers({
    auth: authReducer,
    editor: editorReducer,
    foundCourses: foundCoursesReducer,
    util: utilReducer,
    test: testReducer,
    space: spaceReducer,
    profile: profileReducer,
    course: courseReducer,
    lesson: lessonReducer,
    userStudent: userStudentReducer,
    userMentor: userMentorReducer,
    userGroup: userGroupReducer,
    notification: notificationReducer,
    courses: coursesReducer,
    settingSpace: settingSpaceReducer,
    [authApi.reducerPath]: authApi.reducer,
    [spaceApi.reducerPath]: spaceApi.reducer,
    [courseApi.reducerPath]: courseApi.reducer,
    [searchCourseApi.reducerPath]: searchCourseApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [studentCourseApi.reducerPath]: studentCourseApi.reducer,
    [userStudentApi.reducerPath]: userStudentApi.reducer,
    [userMentorApi.reducerPath]: userMentorApi.reducer,
    [userGroupApi.reducerPath]: userGroupApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
});

export default rootReducer;
