import React from 'react';
import './BottomSheetCustom.scss';
import { BottomSheet } from 'react-spring-bottom-sheet';
const BottomSheetCustom = ({
    open,
    setOpen,
    children,
    minHeight,
    customHeight,
}) => {
    return (
        <BottomSheet
            onDismiss={() => setOpen(false)}
            snapPoints={({ maxHeight }) => [minHeight || 430, maxHeight]}
            open={open}
        >
            {children}
        </BottomSheet>
    );
};

export default BottomSheetCustom;
