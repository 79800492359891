import { createContext, useCallback, useContext, useState } from 'react';

const SavingStatusContext = createContext();
export const useSavingStatus = () => useContext(SavingStatusContext);

export const SavingStatusProvider = ({ children }) => {
    const [statusActive, setStatusActive] = useState(null);
    const changeStatus = (status) => setStatusActive(status);
    return (
        <SavingStatusContext.Provider
            value={{
                status: statusActive,
                changeStatus,
            }}
        >
            {children}
        </SavingStatusContext.Provider>
    );
};
