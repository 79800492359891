import { useSearchParams } from 'react-router-dom';
import { useTimer } from 'helpers/hooks';
import { useResendingMessageMutation } from 'api/services';
import { AUTH_FORGOT_ROUTE } from 'utils/consts';
import {
    Heading,
    Button,
} from '@akhter-studios/classlytics-front-ui-components';
import { AuthLink } from 'components/feature';
import VerificationLottie from './VerificationLottie';
import VerificationTypography from './VerificationDesc';
import 'pages/auth/Auth.scss';
import './Verification.scss';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Verification = () => {
    const { t, i18n } = useTranslation('common');
    const [resendingMessage, { isLoading }] = useResendingMessageMutation();
    const { time, start } = useTimer({
        seconds: 60,
        sessionKey: 'verificationTime',
    });
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const { hostname } = window.location;
    const onResendMessage = async () => {
        if (email) {
            await resendingMessage({
                email,
                redirectTo: 'forgot',
                lang: i18n.language,
                ori_domain: hostname,
            });
            await start();
        }
    };

    useEffect(() => {
        return () => {
            sessionStorage.removeItem('verificationTime');
        };
    }, []);
    return (
        <>
            <VerificationLottie />
            <Heading className={'verification-title'} variant={'h1'}>
                {t('AUTH_CONFIRM_TITLE')}
            </Heading>
            <VerificationTypography email={email} />
            <Button
                className={'verification-btn'}
                variant={'secondary'}
                loading={isLoading}
                onClick={onResendMessage}
                disabled={time > 0}
            >
                {time > 0 ? t('SEND_FORWARD', { time }) : t('SEND_EMAIL_AGAIN')}
            </Button>
            <AuthLink
                text={t('AUTH_VERIFICATION_INVALID_LOGIN')}
                linkText={t('ENTER_AGAIN')}
                link={AUTH_FORGOT_ROUTE}
            />
        </>
    );
};

export default Verification;
