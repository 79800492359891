import React from 'react';
import CourseImage from 'pages/courses/CoursesDetailed/CourseImage';
import EducationFiles from 'components/common/EducationalFiles/EducationFiles';
import CourseContent from 'pages/courses/CoursesDetailed/CourseContent';
import {
    Button,
    Progress,
} from '@akhter-studios/classlytics-front-ui-components';
import {
    calculateCoursePassed,
    courseInfoProgress,
} from 'pages/courses/CoursesDetailed/constants';
import { Editor } from 'components/common';
import useEditor from 'helpers/hooks/useEditor';
import { useStartCourseApiMutation } from 'api/services';
import { useNavigate, useParams } from 'react-router-dom';
import { moduleTypeUrl } from 'pages/course-test/functions';
import { useTranslation } from 'react-i18next';
import { getEditorJsTools, getTools } from 'components/common/Editor/constants';
import { useSelector } from 'react-redux';
import CourseTabs from 'pages/courses/CourseTabs/CourseTabs';

const CoursesInfo = ({
    data,
    testId,
    spaceId,
    startCourse,
    proceed,
    refetch,
    isDisabled,
    isFetching,
}) => {
    const { outerSave } = useEditor({
        isDisabled: true,
    });
    const { userSpace } = useSelector((state) => state.space);

    const [startCourseApi, { isLoading }] = useStartCourseApiMutation();
    const { id } = useParams();
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const proceedCourse = () => {
        const url = moduleTypeUrl({
            id: proceed.id,
            courseId: id,
            spaceId,
            type: proceed.type,
        });
        navigate(url);
    };
    if (!data) return <></>;
    return (
        <div className="courses-detailed-left">
            <CourseImage image={data?.image} />
            <h4>{data?.name}</h4>
            <div className="courses-detailed-progress desktop-hidden">
                <div className="courses-detailed-progress__info">
                    <span>
                        {t('STUDENT_COURSE_PASSED')} {data.qtyPassed}/
                        {data.qtyMaterials}
                    </span>
                    <div>
                        {calculateCoursePassed({
                            qtyPassed: data.qtyPassed,
                            qtyMaterials: data.qtyMaterials,
                        })}
                        %
                    </div>
                </div>
                <Progress
                    className={'cards-item__progress'}
                    value={data.qtyPassed}
                    max={data.qtyMaterials}
                />
            </div>
            <Editor
                tools={getTools(
                    [
                        'embedPDF',
                        'embedDOC',
                        'header',
                        'strikethrough',
                        'embed',
                        'list',
                        'linkTool',
                        'quote',
                        'underline',
                        'delimiter',
                        'customMarker',
                        'Color',
                        'toggleList',
                        'audio',
                        'image',
                        'table',
                        'video',
                        'attaches',
                    ],
                    getEditorJsTools(t, userSpace?.id, id)
                )}
                readOnly={true}
                isDisabled={true}
                initialData={data}
                courseId={data?.id}
                outsideSave={outerSave}
            />
            <CourseTabs data={data} />
            <div className="desktop-hidden">
                {courseInfoProgress({
                    testId,
                    spaceId,
                    startCourse,
                    startCourseApi,
                    proceed,
                    proceedCourse,
                    refetch,
                    t,
                }).map(({ title, handler, isShow }, idx) =>
                    isShow ? (
                        <Button
                            variant={'primary'}
                            onClick={handler}
                            disabled={!isDisabled}
                            fullWidth
                            key={idx}
                        >
                            {title}
                        </Button>
                    ) : null
                )}
            </div>
        </div>
    );
};

export default CoursesInfo;
