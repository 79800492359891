import React, { memo } from 'react';
import './AddTest.scss';
import { Icon } from 'components/common';
const AddTest = ({ title, handler }) => {
    return (
        <div className="test-add" onClick={handler}>
            <Icon name="plus" />
            <span>{title}</span>
        </div>
    );
};

export default memo(AddTest);
