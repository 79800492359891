import React, { memo } from 'react';
import { Search } from '@akhter-studios/classlytics-front-ui-components';
import CustomSelect from 'components/common/CustomSelect/CustomSelect';
import { showAllList, typeFileList } from 'pages/knowledge/constants';
import { useTranslation } from 'react-i18next';

const BaseTop = ({ search, setSearch, setLoader, handleChangeBlock, form }) => {
    const { t } = useTranslation('common');
    return (
        <div className="knowledge-base-top">
            <Search
                groupClassName={'header-search'}
                fullWidth
                value={search}
                placeholder={t('DOCUMENTS_SEARCH')}
                onChange={(e) => {
                    setSearch(e.target.value);
                    setLoader(true);
                }}
            />
            <CustomSelect
                className="showType"
                name="showType"
                handler={handleChangeBlock}
                list={showAllList.filter((x) => x.id !== form.showType.id)}
                value={form.showType}
            />
            <CustomSelect
                name="fileType"
                handler={handleChangeBlock}
                list={typeFileList.filter((x) => x.id !== form.fileType.id)}
                value={form.fileType}
            />
        </div>
    );
};

export default memo(BaseTop);
