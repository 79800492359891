import { nanoid } from 'nanoid';
import { Icon } from '@akhter-studios/classlytics-front-ui-components';
import React from 'react';
import useFormatDate from 'helpers/hooks/useFormatDate';
import dayjs from 'dayjs';
import i18next from 'i18next';

export const tabs = () => {
    return [
        {
            id: nanoid(),
            icon: 'courses',
            text: 'COURSES',
            search: 'students',
            to: 'courses',
        },
        {
            id: nanoid(),
            icon: 'test',
            text: 'TESTS',
            search: 'students',
            to: 'test',
        },
    ];
};

export const actionsFilter = [
    {
        id: nanoid(),
        text: 'ANALYTICS_ACTIONS_FILTER_ACTIVE',
        type: 'active',
    },
    {
        id: nanoid(),
        text: 'ANALYTICS_ACTIONS_FILTER_PENDING',
        type: 'pending',
        border: true,
    },
    {
        id: nanoid(),
        text: 'ANALYTICS_ACTIONS_FILTER_NOT_STARTED',
        type: null,
    },
    {
        id: nanoid(),
        text: 'ANALYTICS_ACTIONS_FILTER_WITHOUT_ACCESS',
        type: null,
    },
];

export const actionsFilterStudent = [
    {
        id: nanoid(),
        text: 'SHOW_ALL',
        type: null,
    },
    {
        id: nanoid(),
        text: 'ANALYTICS_ACTIONS_FILTER_ACTIVE',
        type: 'COMPLETED',
    },
    {
        id: nanoid(),
        text: 'Not passed',
        type: 'NOT_STARTED',
        border: true,
    },
];

export const coursesHeading = {
    name: 'NAME',
    courseDate: 'ANALYTICS_ON_COURSE_WITH',
    lessons: 'ANALYTICS_LESSONS',
    tests: 'SHOWN_TESTS',
    progress: 'ANALYTICS_PROGRESS',
};

export const testHeading = {
    name: 'NAME',
    passed: 'ANALYTICS_PASSED',
    average: 'ANALYTICS_AVERAGE_SCORE',
    attempts: 'ANALYTICS_ATTEMPTS',
    arrow: '',
};

const progressObj = ({ progress, progressPercent, t }) => {
    let obj = {
        COMPLETED: t('ANALYTICS_PASSED'),
        STARTED: 'Started',
    };
    return obj[progress];
};

export const courseBody = ({ data, format, t }) => {
    return data.map((x) => ({
        id: x.Course.id,
        name: (
            <div className="course-access">
                <div>{x.Course.name}</div>
                {!x.available && <span>Доступ отозван</span>}
            </div>
        ),
        courseDate: dayjs(x.Course.created_at)
            .locale(i18next.language)
            .format('D MMM YYYY'),
        lessons: `${x.lesson_passed_qty || '-'}/${x.lessons_qty || '-'}`,
        tests: `${x.test_passed_qty || '-'}/${x.tests_qty || '-'}`,
        progress: progressObj({
            progress: x.progress,
            progressPercent: x.progress_qty,
            t,
        }),
        progressType: x.progress,
    }));
};

export const testBody = (data) => {
    return (data || []).map((x) => ({
        id: x.Test.id,
        name: (
            <div className="statistics-item">
                <div className="value-black">{x.Test.name}</div>
                {x.course_name && (
                    <span className="title">{x.course_name}</span>
                )}
            </div>
        ),
        passed: x.date_passage
            ? dayjs(x.date_passage)
                  .locale(i18next.language)
                  .format('D MMM YYYY')
            : '-',
        average: x.average_score,
        attempts: x.attempt_qty,
        arrow: <Icon name="arrow-right" />,
    }));
};

export const courseStatisticsList = ({ data, selectedCourse, formatDate }) => {
    return (
        {
            COMPLETED: [
                {
                    id: nanoid(),
                    title: 'ANALYTICS_START_OF_THE_COURSE',
                    value: formatDate(data?.CourseProgress.start),
                },
                {
                    id: nanoid(),
                    title: 'ANALYTICS_END_OF_THE_COURSE',
                    value: formatDate(data?.CourseProgress.end),
                },
                {
                    id: nanoid(),
                    title: 'ANALYTICS_TOTAL_TIME',
                    value: data?.total_time,
                },

                {
                    id: nanoid(),
                    title: 'ANALYTICS_PASSED_TESTS',
                    value: `${data?.passage_qty || '0'}/${
                        data?.tests_qty || '0'
                    }`,
                },
                {
                    id: nanoid(),
                    title: 'ANALYTICS_AVERAGE_SCORE',
                    value: data?.average_score,
                },
            ],
            STARTED: [
                {
                    id: nanoid(),
                    title: 'ANALYTICS_START_OF_THE_COURSE',
                    value: formatDate(data?.CourseProgress.start),
                },
                {
                    id: nanoid(),
                    title: 'ANALYTICS_LATEST_ACTIVITY_ON_THE_COURSE',
                    value:
                        formatDate(data?.CourseProgress.last_activity) || '-',
                },
                {
                    id: nanoid(),
                    title: 'ANALYTICS_PASSED_TESTS',
                    value: `${data?.passage_qty || '0'}/${
                        data?.tests_qty || '0'
                    }`,
                },
                {
                    id: nanoid(),
                    title: 'ANALYTICS_AVERAGE_SCORE',
                    value: data?.average_score,
                },
            ],
        }[selectedCourse.progressType] || []
    );
};

export const testAnswerStates = [
    {
        id: nanoid(),
        title: 'How many lives is a cat said to have?',
        type: 'success-state',
    },
    {
        id: nanoid(),
        title: 'Which former British colony was given back to China in 1997?',
        type: 'error-state',
    },
    {
        id: nanoid(),
        title: 'In tennis, what piece of fruit is found at the top of the men’s Wimbledon trophy?',
        type: 'success-state',
    },
    {
        id: nanoid(),
        title: 'Which Central American country has a name which translates to English as “The Saviour”?',
        type: 'success-state',
    },
];
