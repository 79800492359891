const fields = [
    {
        id: 'profileCurrentPassword',
        type: 'password',
        name: 'current_password',
        label: 'PROFILE_CURRENT_PASSWORD',
    },
    {
        id: 'profileNewPassword',
        type: 'password',
        name: 'password',
        label: 'PROFILE_NEW_PASSWORD',
    },
    {
        id: 'profileRepeatNewPassword',
        type: 'password',
        name: 'second_password',
        label: 'PROFILE_REENTER_PASSWORD',
    },
];

export { fields };
