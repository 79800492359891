import { nanoid } from 'nanoid';

const tabs = [
    {
        id: nanoid(),
        icon: 'courses',
        text: 'COURSE_STATUS_ACTIVE',
        search: '?scope=active',
        to: '/content/courses?scope=active',
    },
    {
        id: nanoid(),
        icon: 'create',
        text: 'COURSE_STATUS_DRAFT',
        search: '?scope=inactive',
        to: '/content/courses?scope=inactive',
    },
    {
        id: nanoid(),
        icon: 'archive',
        text: 'COURSE_STATUS_ARCHIVE',
        search: '?scope=archived',
        to: '/content/courses?scope=archived',
    },
];

export { tabs };
