import { useExistUserQuery } from 'api/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    AUTH_INVITE_ROUTE,
    AUTH_LOGIN_ROUTE,
    AUTH_REGISTER_ROUTE,
} from 'utils/consts';
import { Typography } from '@akhter-studios/classlytics-front-ui-components';
import './Access.scss';
const Access = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const domain = searchParams.get('domain');
    const data = searchParams.get('data');
    const {
        data: exist,
        isError,
        isLoading,
        isSuccess,
    } = useExistUserQuery({ email }, { skip: !email });

    if (isError) {
        navigate(AUTH_LOGIN_ROUTE);
    }

    if (exist && isSuccess) {
        navigate(AUTH_LOGIN_ROUTE);
    }

    if (!exist && isSuccess) {
        navigate({
            pathname: AUTH_INVITE_ROUTE,
            search: `?email=${email}&domain=${domain ?? ''}&data=${data ?? ''}`,
        });
    }
    return (
        <article className={'access'}>
            {isLoading ? (
                <Typography variant={'body3'}>
                    {t('GENERAL_LOADING')}
                </Typography>
            ) : null}
        </article>
    );
};

export default Access;
