import {
    IconButton,
    Icon,
} from '@akhter-studios/classlytics-front-ui-components';
import { useNavigate, useParams } from 'react-router-dom';
import './HeaderView.scss';
import { usePreview } from 'components/layout/PreviewContext';
const HeaderView = ({ onEyeClick }) => {
    return (
        <IconButton className={'header-view'} onClick={onEyeClick}>
            <Icon name={'eye-hidden-yes'} />
        </IconButton>
    );
};

export default HeaderView;
