import React from 'react';
import { ModalWrapper } from 'components/common';
import {
    ModalContentsTest,
    modalTestBtns,
    modalTitlesBtns,
} from 'pages/course-test/views/StudentTest/constants';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { isDisabledForm } from 'utils/functions';
import { useFinishCourseMutation } from 'api/services';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { checkTestPercent } from 'utils/functions/helpers';

const ModalTest = ({
    modal,
    handler,
    reTakeTheTest,
    test,
    btnTitle,
    percent,
    setModal,
}) => {
    const toggleModal = () => {
        setModal({ ...modal, open: !modal.open });
    };
    return (
        <ModalWrapper
            customStyle={{ width: 594 }}
            toggle={toggleModal}
            open={modal.open}
            className="test-modal"
        >
            <ModalItem
                toggleModal={toggleModal}
                btnTitle={btnTitle}
                reTakeTheTest={reTakeTheTest}
                checkResultTest={checkTestPercent(test.minAcceptance, percent)}
                percent={percent}
                desiredResult={test.minAcceptance}
                handler={handler}
                type={modal.type}
            />
        </ModalWrapper>
    );
};

export const ModalItem = ({
    type,
    checkResultTest,
    reTakeTheTest,
    percent,
    toggleModal,
    handler,
    btnTitle,
    customTitle,
    desiredResult,
}) => {
    const [finishCourse] = useFinishCourseMutation();
    const { userSpace } = useSelector((state) => state.space);
    const { courseId } = useParams();
    const navigate = useNavigate();
    const finishCourseClick = () => {
        finishCourse({ spaceId: userSpace.id, courseId });
        navigate('/content/courses');
    };
    const next = () => {
        toggleModal();
        setTimeout(() => handler(), 500);
    };
    const { t } = useTranslation('common');
    const { title, description, action, otherAction } = ModalContentsTest({
        type,
        handler,
        percent,
        successPercent: desiredResult,
        btnTitle,
        finishedFn: finishCourseClick,
        t,
    });
    const testsInfoData = modalTitlesBtns({
        handler,
        btnTitle,
        toggleModal,
        t,
        reTakeTheTest,
    })[type];
    return (
        <div className="modal-item">
            <div className="modal-item__title">{customTitle || title}</div>
            {description && (
                <div className="modal-item__desc">{description}</div>
            )}
            <Button
                className={'auth-button'}
                type={'submit'}
                variant={'primary'}
                fullWidth
                onClick={() =>
                    testsInfoData.mainAction
                        ? testsInfoData.mainAction()
                        : modalTestBtns({
                              type,
                              finishCourseClick,
                              next,
                              action,
                          })
                }
            >
                {testsInfoData?.mainActionTitle || btnTitle}
            </Button>
        </div>
    );
};

export default ModalTest;
