import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'helpers/hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    useAddUserGroupCoursesMutation,
    useLazyFetchUserStudentCoursesQuery,
} from 'api/services';
import { notify } from 'utils/functions';
import SelectableModal from 'pages/users/common/UsersModalWrapper/SelectableModal';
import { CourseItem } from 'pages/users/common';

const AddCoursesToGroupModal = ({ isOpen, setIsOpen }) => {
    const { t } = useTranslation('common');
    const { id: groupId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const [trigger] = useLazyFetchUserStudentCoursesQuery();
    const [addUserGroupCourses, { isLoading }] =
        useAddUserGroupCoursesMutation();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const [courses, setCourses] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const status = 'active';

    const onAddCourses = async () => {
        const { error } = await addUserGroupCourses({
            spaceId: userSpace.id,
            groupId,
            courses: selectedCourses,
        });
        if (error) {
            notify('error', error.data.error);
        }
        if (!error) {
            notify('success', t('NOTIFY_SUCCESSFULLY_INVITED_TO_COURSE'));
            setIsOpen(false);
            setSearch('');
            setSelectedCourses([]);
        }
    };

    const onSelectAll = () => {
        if (isSelectedAll) {
            setIsSelectedAll(false);
            setSelectedCourses([]);
        } else {
            setIsSelectedAll(true);
            setSelectedCourses(courses.results.map((c) => c.id));
        }
    };
    const onSearch = (e) => setSearch(e.target.value);
    const onSelect = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedCourses([...selectedCourses, value]);
        } else {
            setSelectedCourses(selectedCourses.filter((s) => s !== value));
        }
    };

    const loadMoreCourses = async () => {
        if (!courses?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            space_id: userSpace.id,
            status,
            page: page + 1,
            page_size: pageSize,
            search: debouncedSearch,
        });
        const modifiedResults = [...courses.results, ...data.results];
        setCourses({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(
        () =>
            userSpace &&
            trigger({
                space_id: userSpace.id,
                status,
                page,
                page_size: pageSize,
            }).then(({ data }) => setCourses(data)),
        [userSpace]
    );
    useEffect(
        () =>
            trigger({
                space_id: userSpace.id,
                status,
                page: 1,
                page_size: pageSize,
                search: debouncedSearch,
            }).then(({ data }) => {
                setPage(1);
                setCourses(data);
            }),
        [debouncedSearch]
    );
    return (
        <SelectableModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('CHOOSE_COURSES')}
            btnText={t('SAVE')}
            searchPlaceholder={t('SEARCH_COURSES')}
            isSearchable={true}
            isSelectedAll={isSelectedAll}
            search={search}
            btnLoading={isLoading}
            btnDisabled={isLoading || selectedCourses.length === 0}
            currentCount={courses?.results?.length}
            maxCount={courses?.count}
            hasMore={courses?.hasMore}
            items={courses?.results}
            onSearch={onSearch}
            onSubmit={onAddCourses}
            onLoadMore={loadMoreCourses}
            onSelectAll={onSelectAll}
            renderItem={(course) => (
                <CourseItem
                    key={course.id}
                    isSelected={selectedCourses.includes(course.id)}
                    onSelect={onSelect}
                    {...course}
                />
            )}
        />
    );
};

export default AddCoursesToGroupModal;
