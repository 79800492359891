import { baseApi } from 'api/xhr';
import {
    CHECK_DOMAIN_URL,
    CHECK_EMAIL_URL,
    LOGIN_URL,
    REGISTER_URL,
    REGISTER_BY_INVITATION,
    RESENDING_MESSAGE_URL,
    RESET_PASSWORD_URL,
    USER_CHANGE_STATUS_URL,
} from 'utils/consts';

export const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation({
            query: (body) => ({
                url: LOGIN_URL,
                method: 'POST',
                body,
            }),
        }),
        resetPassword: build.mutation({
            query: ({ email, lang, oriDomain }) => ({
                url: RESET_PASSWORD_URL,
                method: 'GET',
                params: { email, lang, ori_domain: oriDomain },
            }),
        }),
        resendingMessage: build.mutation({
            query: (params) => ({
                url: RESENDING_MESSAGE_URL,
                method: 'GET',
                params,
            }),
        }),
        changePassword: build.mutation({
            query: (body, oriDomain) => ({
                url: RESET_PASSWORD_URL + `?ori_domain=${oriDomain}`,
                method: 'PATCH',
                body,
            }),
        }),
        checkEmail: build.mutation({
            query: (params) => ({
                url: CHECK_EMAIL_URL,
                method: 'GET',
                params,
            }),
        }),
        checkDomain: build.mutation({
            query: ({ domain }) => ({
                url: CHECK_DOMAIN_URL,
                method: 'GET',
                params: { domain },
            }),
        }),
        register: build.mutation({
            query: ({ lang, ...body }) => ({
                url: REGISTER_URL,
                method: 'POST',
                body,
                params: { lang },
            }),
        }),
        registerByInvitation: build.mutation({
            query: ({ lang, ...body }) => ({
                url: REGISTER_BY_INVITATION,
                method: 'POST',
                body,
                params: { lang },
            }),
        }),
        userChangeStatus: build.mutation({
            query: (body) => ({
                url: USER_CHANGE_STATUS_URL,
                method: 'PATCH',
                body,
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useResendingMessageMutation,
    useChangePasswordMutation,
    useCheckEmailMutation,
    useCheckDomainMutation,
    useRegisterMutation,
    useRegisterByInvitationMutation,
    useUserChangeStatusMutation,
} = authApi;
