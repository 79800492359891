import { Avatar } from '@akhter-studios/classlytics-front-ui-components';
import DefaultImg from 'assets/img/png/img.png';
import './FormatOptionLabelUser.scss';

const FormatOptionLabelUser = (props) => {
    const { label, image } = props;
    return (
        <div className={'format-option-label-user'}>
            <Avatar
                className={'format-option-label-user__img'}
                src={image ?? DefaultImg}
                variant={'rounded'}
            />
            {label}
        </div>
    );
};

export default FormatOptionLabelUser;
