import React, { memo, useEffect } from 'react';
import {
    Switch,
    Button,
    Input,
} from '@akhter-studios/classlytics-front-ui-components';

import './SettingTestContent.scss';
import { TextField } from '@mui/material';
import { banToNegativeNumber } from 'utils/functions/helpers';
import { useTranslation } from 'react-i18next';
import { clearValueFromSwitch } from 'pages/course-test/constants';
const SettingTestContent = ({
    switches,
    handleChangeSetting,
    test,
    handleResetValueFromSwitch,
    saveTest,
    handleChange,
    settingTest,
}) => {
    const { time, percent, limit, random, consider } = switches;
    useEffect(() => {
        clearValueFromSwitch(switches, handleResetValueFromSwitch);
    }, [time, percent, limit]);
    const isDisabled = () => {
        const { duration, minAcceptance, limit } = settingTest;
        return duration || minAcceptance || limit || random || consider;
    };
    const { t } = useTranslation('common');
    return (
        <div className="setting-content">
            <h4>{t('MENTOR_TEST_SETTINGS')}</h4>
            <div className="setting-content__field">
                <div className="setting-content__field-title">
                    <div>{t('MENTOR_TEST_SETTINGS_TIME_LIMIT')}</div>
                    <Switch
                        checked={time}
                        onChange={() => handleChange('time')}
                    />
                </div>
                {time && (
                    <TextField
                        onChange={(e) => {
                            handleChangeSetting(e, true);
                        }}
                        InputProps={{
                            endAdornment: (
                                <span style={{ color: '#637D8B' }}>
                                    {t('MENTOR_TEST_TIME_MINUTES')}
                                </span>
                            ),
                        }}
                        value={settingTest.duration}
                        groupClassName="time"
                        variant="standard"
                        name="duration"
                        label={t('MENTOR_TEST_SETTINGS_TIME_DURATION')}
                    />
                )}
            </div>
            <div className="setting-content__field">
                <div className="setting-content__field-title">
                    <div>{t('MENTOR_TEST_SETTINGS_MINIMUM_SCORE')}</div>
                    <Switch
                        checked={percent}
                        onChange={() => handleChange('percent')}
                    />
                </div>
                {percent && (
                    <TextField
                        onChange={(e) => {
                            if (Number(e.target.value) < 101) {
                                handleChangeSetting(e);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <span style={{ color: '#637D8B' }}>%</span>
                            ),
                        }}
                        groupClassName={'percent'}
                        value={settingTest.minAcceptance}
                        name="minAcceptance"
                        variant="standard"
                        label={t('MENTOR_TEST_SETTINGS_MINIMUM_SCORE_VALUE')}
                    />
                )}
            </div>
            <div className="setting-content__field">
                <div className="setting-content__field-title">
                    <div>
                        <div>{t('MENTOR_TEST_QUESTION_ATTEMPT_LIMIT')}</div>
                        <div className="setting-content__field-desc">
                            {t(
                                'MENTOR_TEST_QUESTION_ATTEMPT_LIMIT_DESCRIPTION'
                            )}
                        </div>
                    </div>
                    <Switch
                        checked={limit}
                        onChange={() => handleChange('limit')}
                    />
                </div>
                {limit && (
                    <TextField
                        onChange={handleChangeSetting}
                        value={settingTest.limit}
                        name="limit"
                        variant="standard"
                        label={t('MENTOR_TEST_QUESTION_ATTEMPT_MAX')}
                    />
                )}
            </div>
            <div className="setting-content__field">
                <div className="setting-content__field-title">
                    <div>{t('MENTOR_TEST_QUESTION_RANDOM_ORDER')}</div>
                    <Switch
                        checked={random}
                        onChange={() => handleChange('random')}
                    />
                </div>
            </div>
            <div className="setting-content__field">
                <div className="setting-content__field-title">
                    <div className="consider">
                        <div>{t('MENTOR_TEST_QUESTION_COUNT_TOTAL')}</div>
                        <span>
                            {t('MENTOR_TEST_QUESTION_COUNT_TOTAL_DESC')}
                        </span>
                    </div>
                    <Switch
                        checked={consider}
                        onChange={() => handleChange('consider')}
                    />
                </div>
            </div>
            <div className="setting-content__btn">
                <Button
                    disabled={!isDisabled()}
                    variant="primary"
                    fullWidth
                    onClick={saveTest}
                >
                    {t('MENTOR_TEST_SETTINGS_SAVE')}
                </Button>
            </div>
        </div>
    );
};

export default memo(SettingTestContent);
