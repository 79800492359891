import { useFormik } from 'formik';
import { useLoginMutation } from 'api/services';
import { useAction } from 'helpers/hooks';
import { authRequest } from 'api/xhr';
import { loginSchema } from 'utils/schemas';
import { AUTH_REGISTER_ROUTE, LOGIN_URL, SPACES_ROUTE } from 'utils/consts';
import { fields } from '../consts';
import {
    AuthButton,
    AuthError,
    AuthFields,
    AuthLink,
} from 'components/feature';
import LoginLink from '../LoginLink';
import 'pages/auth/Auth.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
    // const [login, {isLoading, isError, error}] = useLoginMutation();
    const navigate = useNavigate();
    const { setToken, setRefreshToken } = useAction();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation('common');

    const onSubmit = async (data) => {
        // const {error} = await login(data);
        setIsLoading(true);
        try {
            const { token, refresh } = await authRequest.post(LOGIN_URL, {
                ...data,
                email: data.email.toLowerCase(),
            });
            setIsLoading(false);
            if (token) {
                setError(null);
                setToken({ token });
                setRefreshToken({ token: refresh });
                navigate(SPACES_ROUTE);
            }
        } catch (e) {
            setIsLoading(false);
            setError(e.response.data.detail);
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginSchema(t),
        onSubmit,
    });
    return (
        <>
            {error && <AuthError error={error} />}
            <form className={'auth-form'} onSubmit={handleSubmit}>
                <AuthFields
                    fields={fields}
                    values={values}
                    errors={errors}
                    error={!!error}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                />
                <LoginLink />
                <AuthButton
                    isLoading={isLoading}
                    isValid={isValid}
                    text={t('LOGIN_TO_CLASSLYTICS')}
                />
                <AuthLink
                    text={t('NO_ACCOUNT')}
                    linkText={t('REGISTER')}
                    link={AUTH_REGISTER_ROUTE}
                />
            </form>
        </>
    );
};

export default LoginForm;
