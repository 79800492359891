import { useState } from 'react';
import { nanoid } from 'nanoid';
import { useDebouncedCallback } from 'use-debounce';
import { useMoveMaterialMutation } from 'api/services';
import { useSelector } from 'react-redux';

export default function useMaterialMove({ materials, isReadOnly }) {
    const { userSpace } = useSelector((state) => state.space);
    const makeRaw = (data) =>
        data.reduce((acc, material, index) => {
            if (index === 0) {
                acc.push({
                    id: nanoid(),
                    materialId: nanoid(),
                    name: 'content',
                    children: [],
                    contentHolder: true,
                });
            }
            if (material.type === 'module') {
                acc.push({ ...material, isOpen: true });
            } else {
                const contentHolder = acc.find((m) => m?.contentHolder);
                contentHolder.children.push(material);
            }
            return acc;
        }, []);
    const [allLessons, setAllLessons] = useState(makeRaw(materials));
    const toggleModule = (materialId) => {
        setAllLessons((prevData) =>
            prevData.map((module) =>
                module.materialId === materialId
                    ? { ...module, isOpen: !module.isOpen }
                    : module
            )
        );
    };
    const setModuleOpen = (materialId, bool) => {
        setAllLessons((prevData) =>
            prevData.map((module) =>
                module.materialId === materialId
                    ? { ...module, isOpen: bool }
                    : module
            )
        );
    };
    const updateLessons = () => setAllLessons(makeRaw(materials));
    const addMaterial = (newMaterial) => {
        if (newMaterial?.parentId) {
            setAllLessons(
                allLessons.map((material) =>
                    material?.materialId === newMaterial?.parentId
                        ? {
                              ...material,
                              children: [...material.children, newMaterial],
                          }
                        : material
                )
            );
        } else {
            if (newMaterial?.type !== 'module') {
                setAllLessons(
                    allLessons?.length
                        ? allLessons.map((material) =>
                              material?.contentHolder
                                  ? {
                                        ...material,
                                        children: [
                                            ...material.children,
                                            newMaterial,
                                        ],
                                    }
                                  : material
                          )
                        : makeRaw([newMaterial])
                );
            } else {
                const hasContentHolder = allLessons.some(
                    (m) => m?.contentHolder
                );
                const data = [
                    ...allLessons,
                    { ...newMaterial, isOpen: true, children: [] },
                ];
                setAllLessons(hasContentHolder ? data : makeRaw(data));
            }
        }
    };
    const removeMaterial = (materialId, parentId, type) => {
        if (parentId) {
            setAllLessons(
                allLessons.map((material) =>
                    material.materialId === parentId
                        ? {
                              ...material,
                              children: material.children.filter(
                                  (innerMaterial) =>
                                      innerMaterial.id !== materialId
                              ),
                          }
                        : material
                )
            );
        } else {
            if (type !== 'module') {
                setAllLessons(
                    allLessons.map((material) =>
                        material.contentHolder
                            ? {
                                  ...material,
                                  children: material.children.filter(
                                      (innerMaterial) =>
                                          innerMaterial.id !== materialId
                                  ),
                              }
                            : material
                    )
                );
            } else {
                const removingModule = allLessons.find(
                    (module) => module.materialId === materialId
                );
                if (removingModule) {
                    setAllLessons(
                        allLessons
                            .map((module) =>
                                module.contentHolder
                                    ? {
                                          ...module,
                                          children: [
                                              ...module.children,
                                              ...removingModule.children.map(
                                                  (material) => ({
                                                      ...material,
                                                      parentId: null,
                                                  })
                                              ),
                                          ],
                                      }
                                    : module
                            )
                            .filter(
                                (module) => module.materialId !== materialId
                            )
                    );
                }
            }
        }
    };
    const updateMaterialName = (materialId, name) => {
        setAllLessons(
            allLessons.map((material) =>
                material.id === materialId
                    ? { ...material, name }
                    : {
                          ...material,
                          children: [...material.children].map((m) =>
                              m.id === materialId ? { ...m, name } : m
                          ),
                      }
            )
        );
    };

    const [moveMaterial] = useMoveMaterialMutation();

    const makeReady = (data) =>
        data.reduce((acc, current) => {
            if (current?.contentHolder) {
                current.children.map((lesson) => acc.push(lesson));
            } else {
                acc.push(current);
            }
            return acc;
        }, []);

    const moveToModule = useDebouncedCallback(async () => {
        if (isReadOnly) {
            return;
        }
        try {
            await moveMaterial({
                spaceId: userSpace?.id,
                materials: makeReady(allLessons),
            });
        } catch (e) {}
    }, 1000);

    const reorderArray = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onModuleDrop = ({ removedIndex, addedIndex }) => {
        if (isReadOnly) {
            return;
        }
        let newModules = [...allLessons];
        newModules = reorderArray(newModules, removedIndex + 1, addedIndex + 1);
        setAllLessons(newModules);
        moveToModule();
    };

    const onLessonDrop = (
        { removedIndex, addedIndex, payload },
        moduleId,
        moduleIdx
    ) => {
        if (isReadOnly) {
            return;
        }
        const newModules = [...allLessons];
        const module = { ...newModules.find((m) => m.id === moduleId) };
        if (removedIndex !== null) {
            module.children = [...module.children];
            const [item] = module.children.splice(removedIndex, 1);
            if (addedIndex !== null) {
                module.children.splice(addedIndex, 0, item);
            }
        } else if (addedIndex !== null) {
            module.children = [...module.children];
            module.children.splice(addedIndex, 0, payload);
        }
        newModules.splice(moduleIdx, 1, module);
        setAllLessons(newModules);
        moveToModule();
    };
    return {
        onModuleDrop,
        onLessonDrop,
        toggleModule,
        setModuleOpen,
        allLessons,
        updateLessons,
        addMaterial,
        removeMaterial,
        updateMaterialName,
    };
}
