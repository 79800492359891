import React, { useEffect, useState } from 'react';
import { useLazyFetchSettingsByDomainQuery } from 'api/services';
import { DOMAINS } from 'utils/functions/findDomainToRedirect';
import { GlobalSettingsSpace } from 'components/feature';
import Spinner from 'components/common/Spinner/Spinner';

const CustomDomainSettings = ({ children }) => {
    const [fetchSpaces] = useLazyFetchSettingsByDomainQuery();
    const [loaded, setLoaded] = useState(true);
    const getCustomSettings = async () => {
        const hostName = window.location.hostname.toLowerCase();
        const findDomain = ({ domain }) => hostName.match(domain);
        const originalDomain = DOMAINS.find((d) => !d.custom).domain;
        if (
            !findDomain({ domain: originalDomain }) &&
            !findDomain({ domain: 'localhost' })
        ) {
            fetchSpaces(hostName.split('.')[1]);
        }
        setLoaded(true);
    };
    useEffect(() => {
        getCustomSettings().then();
    }, []);
    return loaded ? (
        <>
            <GlobalSettingsSpace />
            {children}
        </>
    ) : (
        <Spinner />
    );
};

export default CustomDomainSettings;
