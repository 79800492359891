import { createSlice } from '@reduxjs/toolkit';
import { ACCESS_TOKEN, AUTH_LOGIN_ROUTE, REFRESH_TOKEN } from 'utils/consts';
import { axiosRequest } from 'api/xhr';
import { authApi } from 'api/services';
import { getPathWithOriDomain } from 'utils/functions/findDomainToRedirect';

const initialState = {
    token: localStorage.getItem(ACCESS_TOKEN) || '',
    refreshToken: localStorage.getItem(REFRESH_TOKEN) || '',
    registerFormValues: {},
};

const saveToken = ({ token }) => {
    axiosRequest.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem(ACCESS_TOKEN, token);
};

const logoutWithDomain = () => {
    const path = getPathWithOriDomain();
    window.location.href = path + AUTH_LOGIN_ROUTE;
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, { payload }) => {
            const { token } = payload;
            localStorage.setItem(ACCESS_TOKEN, token);
            state.token = token;
        },
        setRefreshToken: (state, { payload }) => {
            const { token } = payload;
            localStorage.setItem(REFRESH_TOKEN, token);
            state.refreshToken = token;
        },
        setRegisterFormValues: (state, { payload }) => {
            state.registerFormValues = payload;
        },
        logout: (state) => {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem('spaceId');
            state.token = '';
            logoutWithDomain();
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                authApi.endpoints.login.matchFulfilled,
                (state, { payload }) => {
                    const { token } = payload;
                    saveToken({ token });
                    state.token = token;
                }
            )
            .addMatcher(
                authApi.endpoints.changePassword.matchFulfilled,
                (state, { meta }) => {
                    const { token } = meta.arg.originalArgs;
                    saveToken({ token });
                    state.token = token;
                }
            )
            .addMatcher(
                authApi.endpoints.registerByInvitation.matchFulfilled,
                (state, { payload }) => {
                    const { token } = payload;
                    saveToken({ token });
                    state.token = token;
                }
            )
            .addMatcher(
                authApi.endpoints.checkEmail.matchFulfilled,
                (state, { meta }) => {
                    const args = meta.arg.originalArgs;
                    state.registerFormValues = {
                        ...state.registerFormValues,
                        ...args,
                    };
                }
            )
            .addMatcher(
                authApi.endpoints.checkDomain.matchFulfilled,
                (state, { meta }) => {
                    const args = meta.arg.originalArgs;
                    state.registerFormValues = {
                        ...state.registerFormValues,
                        ...args,
                    };
                }
            );
    },
});

const authActions = authSlice.actions;
export { authActions };
export default authSlice.reducer;
