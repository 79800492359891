import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ruCommon from './../../../locales/ru/common.json';
import enCommon from './../../../locales/en/common.json';
i18next
    // .use(Backend)
    .use(LanguageDetector)
    .init({
        resources: {
            ru: {
                common: ruCommon,
            },
            en: {
                common: enCommon,
            },
        },
        interpolation: { escapeValue: false },
        fallbackLng: 'en',
        react: {
            useSuspense: false,
        },
    })
    .then((res) => res);

export default function WithTranslations({ children }) {
    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
}
