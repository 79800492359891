import { memo } from 'react';
import { CustomLottie } from 'components/common';
import animationData from 'assets/lotties/done.json';

const DoneLottie = () => {
    return (
        <CustomLottie
            lottie={JSON.stringify(animationData)}
            width={144}
            height={144}
            loop={false}
        />
    );
};

export default memo(DoneLottie);
