import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import PasswordForm from './PasswordForm';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';

const Password = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <Heading className={'auth-title'} variant={'h1'}>
                {t('CREATE_NEW_PASSWORD')}
            </Heading>
            <PasswordForm />
        </>
    );
};

export default Password;
