import { useState } from 'react';
import { Link } from 'react-router-dom';
import { tabs } from './consts';
import {
    Icon,
    SegmentButton,
    Typography,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './StudentDetailHeading.scss';
import { useTranslation } from 'react-i18next';
import { useLocationScope, useWindowSize } from 'helpers/hooks';
import {
    renderModal,
    renderText,
} from 'pages/users/views/student-detail/consts';

const StudentDetailHeading = ({ selectedStudentId }) => {
    const { t } = useTranslation('common');
    const { width } = useWindowSize();
    const [isOpen, setIsOpen] = useState(false);
    const scope = useLocationScope();

    const onOpen = () => setIsOpen(true);
    return (
        <section className={'student-detail-heading'}>
            <List
                className={'student-detail-heading__tabs'}
                items={tabs}
                renderItem={({ id, to, text, icon, search }) => (
                    <li key={id}>
                        <Link
                            to={to}
                            className={classNames(
                                'student-detail-heading__tab',
                                {
                                    active:
                                        search ===
                                        (scope.length > 0 ? scope : 'courses'),
                                }
                            )}
                        >
                            <Icon name={icon} />
                            <Typography variant={'body4'}>{t(text)}</Typography>
                        </Link>
                    </li>
                )}
            />
            {width > 769 ? (
                <SegmentButton
                    className={'student-detail-heading__btn'}
                    size={'small'}
                    onClick={onOpen}
                >
                    <Icon name={'plus'} />
                    <Typography variant={'body4'}>
                        {t(renderText[scope])}
                    </Typography>
                </SegmentButton>
            ) : null}
            {isOpen
                ? renderModal({ isOpen, setIsOpen, selectedStudentId })[scope]
                : null}
        </section>
    );
};

export default StudentDetailHeading;
