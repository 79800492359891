import { useTranslation } from 'react-i18next';
import {
    Button,
    Icon,
    Search,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { UsersModalWrapper } from 'pages/users/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import './SelectableModal.scss';

const SelectableModal = (props) => {
    const {
        children,
        isOpen,
        setIsOpen,
        isSearchable = false,
        isSelectedAll = false,
        hasMore = false,
        btnLoading = false,
        btnDisabled = false,
        isBack = false,
        btnText = '',
        searchPlaceholder = '',
        search = '',
        currentCount = 0,
        maxCount = 0,
        items = [],
        onSubmit = () => {},
        onSearch = () => {},
        onSelectAll = () => {},
        onLoadMore = () => {},
        onBack = () => {},
        renderItem = null,
        ...rest
    } = props;
    const { t } = useTranslation('common');
    return (
        <UsersModalWrapper
            className={'selectable-modal'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            {...rest}
        >
            {isSearchable ? (
                <Search
                    groupClassName={'selectable-modal__search'}
                    placeholder={searchPlaceholder}
                    value={search}
                    onChange={onSearch}
                    fullWidth
                />
            ) : null}
            <div className={'selectable-modal__filter'}>
                <Typography
                    variant={'body4'}
                    className={'selectable-modal__count'}
                >
                    {t('SHOWN_COURSES')}:{' '}
                    <span>
                        {t('USERS_MODAL_TOTAL', { currentCount, maxCount })}
                    </span>
                </Typography>
                <div
                    className={classNames('selectable-modal__all', {
                        isSelectedAll,
                        disabled: items.length === 0,
                    })}
                    onClick={onSelectAll}
                >
                    <Typography variant={'body4'}>{t('CHOOSE_ALL')}</Typography>
                    <Icon name={'checked'} />
                </div>
            </div>
            {items.length > 0 ? (
                <InfiniteScroll
                    className={'selectable-modal__list'}
                    dataLength={items.length}
                    next={onLoadMore}
                    hasMore={hasMore}
                    loader={null}
                    height={406}
                >
                    {items.map(renderItem)}
                </InfiniteScroll>
            ) : null}
            <div
                className={classNames('selectable-modal__btn', {
                    multiButton: isBack,
                })}
            >
                {isBack ? (
                    <Button
                        variant={'secondary'}
                        size={'small'}
                        fullWidth
                        onClick={onBack}
                    >
                        {t('BACK')}
                    </Button>
                ) : null}
                <Button
                    variant={'primary'}
                    size={'small'}
                    onClick={onSubmit}
                    loading={btnLoading}
                    disabled={btnDisabled}
                    fullWidth
                >
                    {btnText}
                </Button>
            </div>
        </UsersModalWrapper>
    );
};

export default SelectableModal;
