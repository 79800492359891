import { baseApi } from 'api/xhr';

export const userStudentApi = baseApi.injectEndpoints({
    tagTypes: ['UserStudentCourses', 'UserStudentGroups'],
    endpoints: (build) => ({
        fetchUserStudents: build.query({
            query: ({ spaceId, ...params }) => ({
                url: `/user-part/${spaceId}/students/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
        }),
        fetchUserSearchStudents: build.query({
            query: (params) => ({
                url: `/search/users/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results.map((r) => {
                        return {
                            ...r,
                            user: {
                                id: r.profile.id,
                                avatar: r.avatar,
                                email: r.email,
                                firstName: r.firstName,
                                lastName: r.lastName,
                                isActive: r.isActive,
                            },
                        };
                    }),
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
        }),
        fetchUserStudent: build.query({
            query: ({ spaceId, studentId }) => ({
                url: `/user-part/${spaceId}/students/${studentId}/`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['UserStudentCourses', 'UserStudentGroups'],
        }),
        removeUserStudent: build.mutation({
            query: ({ spaceId, ...body }) => ({
                url: `/user-part/${spaceId}/students/remove-student/`,
                method: 'POST',
                body,
            }),
        }),
        fetchUserStudentCourses: build.query({
            query: (params) => ({
                url: `/search/courses/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
            keepUnusedDataFor: 5,
        }),
        addUserStudentCourses: build.mutation({
            query: ({ spaceId, studentId, ...body }) => ({
                url: `/user-part/${spaceId}/students/${studentId}/add-courses/`,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 5,
            invalidatesTags: ['UserStudentCourses'],
        }),
        removeUserStudentCourses: build.mutation({
            query: ({ spaceId, userId, ...body }) => ({
                url: `/user-part/${spaceId}/students/${userId}/remove-courses/`,
                method: 'POST',
                body,
            }),
        }),
        fetchUserStudentGroups: build.query({
            query: (params) => ({
                url: `/search/groups/`,
                params,
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    count: baseQueryReturnValue.count,
                    hasMore: !!baseQueryReturnValue.next,
                };
            },
            providesTags: ['UserStudentGroups'],
        }),
        addUserStudentGroups: build.mutation({
            query: ({ spaceId, studentId, ...body }) => ({
                url: `/user-part/${spaceId}/students/${studentId}/add-to-groups/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['UserStudentGroups'],
        }),
        removeUserStudentGroups: build.mutation({
            query: ({ spaceId, userId, ...body }) => ({
                url: `/user-part/${spaceId}/students/${userId}/remove-groups/`,
                method: 'POST',
                body,
            }),
        }),
        addUserStudentCoursesMany: build.mutation({
            query: ({ spaceId, ...body }) => ({
                url: `/user-part/${spaceId}/students/add-courses/many/`,
                method: 'POST',
                body,
            }),
        }),
        addUserStudentGroupsMany: build.mutation({
            query: ({ spaceId, ...body }) => ({
                url: `/user-part/${spaceId}/students/add-groups/many/`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useFetchUserStudentsQuery,
    useLazyFetchUserStudentsQuery,
    useFetchUserSearchStudentsQuery,
    useLazyFetchUserSearchStudentsQuery,
    useFetchUserStudentQuery,
    useFetchUserStudentCoursesQuery,
    useLazyFetchUserStudentCoursesQuery,
    useRemoveUserStudentMutation,
    useAddUserStudentCoursesMutation,
    useAddUserStudentGroupsMutation,
    useFetchUserStudentGroupsQuery,
    useLazyFetchUserStudentGroupsQuery,
    useRemoveUserStudentCoursesMutation,
    useRemoveUserStudentGroupsMutation,
    useAddUserStudentCoursesManyMutation,
    useAddUserStudentGroupsManyMutation,
} = userStudentApi;
