import { AudioTool } from 'components/common/Editor/tools';
import { make } from 'components/common/Editor/tools/utils';
import { isMobileBrowser } from 'helpers/functions';

export default class EmbedPDF extends AudioTool {
    makePDFEmbed(file) {
        const isMobile = window.innerWidth < 768 || isMobileBrowser();
        let object = make('object', '', {
            data: file.url,
            width: '100%',
            height: '500px',
        });
        if (isMobile) {
            object = make('iframe', '', {
                src: file.url,
                width: '100%',
                height: '500px',
            });
        }
        const p = make('p', '');
        const a = make('a', '', { href: file.url });
        p.innerHTML = 'Unable to display PDF file.';
        a.innerHTML = 'Download instead';
        p.appendChild(a);
        object.appendChild(p);
        return object;
    }
    showFileData() {
        this.nodes.wrapper.classList.add('cdx-attaches--pdf');
        const { file, title } = this.data;
        const fileInfo = make('div', this.CSS.fileInfo);
        this.nodes.title = make('div', this.CSS.title);
        fileInfo.appendChild(this.nodes.title);
        this.nodes.title.dataset.placeholder = this.api.i18n.t('File title');
        this.nodes.title.textContent = title || '';
        const pdf = this.makePDFEmbed(file);
        this.nodes.wrapper.appendChild(pdf);
        this.nodes.wrapper.appendChild(fileInfo);
    }
}
