import React from 'react';
import {Icon} from "@akhter-studios/classlytics-front-ui-components";
import {components} from "react-select";

const {ClearIndicator} = components;

const CustomClearIndicator = (props) => {
    return (
        <ClearIndicator {...props}>
            <Icon name={"close"}/>
        </ClearIndicator>
    );
};

export default CustomClearIndicator;
