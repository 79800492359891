import { useSelector } from 'react-redux';
import {
    useAddUserGroupMutation,
    useLazySearchCoursesQuery,
    useLazySearchUsersQuery,
} from 'api/services';
import { useFormik } from 'formik';
import { isDisabledForm, notify } from 'utils/functions';
import { Button, Input } from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
import { CustomAsyncSelect } from 'components/custom-react-select';
import { InvitedModal } from 'pages/users/common/UsersModalWrapper/InvitedModal';
import {
    FormatOptionLabelCourse,
    FormatOptionLabelUser,
} from 'pages/users/select-formats';
import DefaultMultiValue from 'components/custom-react-select/CustomMultiValue/DefaultMultiValue';
import { useState } from 'react';
import { CoursesModal } from 'pages/users/common/InviteStudentModal/CoursesModal';
import { addGroupSchema } from 'utils/schemas';
import { debounce } from 'utils/functions/helpers';

const AddGroupModal = ({ isOpen, setIsOpen }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const [addUserGroup, { isLoading }] = useAddUserGroupMutation();
    const [trigger] = useLazySearchUsersQuery();
    const [courseTrigger] = useLazySearchCoursesQuery();
    const { t, i18n } = useTranslation('common');
    const [isOpenCoursesModal, setIsOpenCoursesModal] = useState(false);

    const onSubmit = async (values, { resetForm }) => {
        const { title, courses, emails } = values;
        const newEmail = emails.map((e) => e.value);
        const newCourses = courses.map((e) => e.value);

        const { error } = await addUserGroup({
            spaceId: userSpace?.id,
            title,
            emails: newEmail,
            courses: newCourses,
            target: 'addGroup',
            lang: i18n.language,
        });
        if (!error) {
            resetForm({ values: '' });
            setIsSuccess(true);
        }
    };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        isValid,
    } = useFormik({
        initialValues: {
            title: '',
            emails: [],
            courses: [],
        },
        validationSchema: addGroupSchema,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit,
    });

    const loadEmails = async (searchValue, callback) => {
        if (searchValue) {
            const res =
                userSpace &&
                (await trigger({
                    space_id: userSpace.id,
                    search: searchValue.toLowerCase(),
                    unique: false,
                    role: 'student',
                    target: 'add_group',
                }));
            const newUsers = res.data.results.map(({ email, ...student }) => ({
                user: {
                    ...student,
                },
                label: email,
                value: email,
            }));
            callback(newUsers);
        } else {
            callback([]);
        }
    };

    const loadCourses = async (searchValue, callback) => {
        if (searchValue) {
            const res =
                userSpace &&
                (await courseTrigger({
                    space_id: userSpace.id,
                    status: 'active',
                    search: searchValue,
                }));
            const newCourses = res.data.results.map(({ id, name, image }) => ({
                label: name,
                value: id,
                image,
            }));
            callback(newCourses);
        } else {
            callback([]);
        }
    };

    const debounceLoadEmails = debounce(loadEmails, 700);
    const debounceLoadCourses = debounce(loadCourses, 700);

    const onNext = () => setIsOpenCoursesModal(true);
    const onBack = () => setIsOpenCoursesModal(false);
    const onChangeEmails = (selectedOptions) =>
        setFieldValue('emails', selectedOptions);
    const onChangeCourses = (selectedOptions) =>
        setFieldValue('courses', selectedOptions);
    return isOpenCoursesModal ? (
        <CoursesModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentCourses={values.courses}
            setFieldValue={setFieldValue}
            onBack={onBack}
        />
    ) : (
        <InvitedModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t(
                isSuccess ? 'INVITATION_SENT_SUCCESSFULLY' : 'CREATE_GROUP'
            )}
            isSuccess={isSuccess}
        >
            <form onSubmit={handleSubmit}>
                <Input
                    id={'add-group-modal-title'}
                    name={'title'}
                    label={t('NAME_GROUP')}
                    placeholder={t('GROUP_NAME')}
                    value={values.title}
                    helperText={touched['title'] && t(errors['title'])}
                    error={touched['title'] && errors['title']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <CustomAsyncSelect
                    className={'field'}
                    name={'emails'}
                    placeholder={t('ADD_STUDENT')}
                    iconName={'search'}
                    defaultOptions
                    isMulti
                    isClearable={false}
                    isError={!!(touched['emails'] && errors['emails'])}
                    helperText={t(errors['emails'])}
                    formatOptionLabel={FormatOptionLabelUser}
                    loadOptions={debounceLoadEmails}
                    value={values.emails}
                    onChange={onChangeEmails}
                    onBlur={() => {
                        handleBlur({ target: { name: 'emails' } });
                    }}
                />
                <CustomAsyncSelect
                    className={'field'}
                    name={'courses'}
                    placeholder={t('GIVE_ACCESS_TO_COURSES')}
                    iconName={'arrow-right'}
                    loadOptions={debounceLoadCourses}
                    defaultOptions
                    isMulti
                    isClearable={false}
                    isError={!!(touched['courses'] && errors['courses'])}
                    helperText={t(errors['courses'])}
                    formatOptionLabel={(props) => (
                        <FormatOptionLabelCourse t={t} {...props} />
                    )}
                    value={values.courses}
                    onChange={onChangeCourses}
                    onBlur={() => {
                        handleBlur({ target: { name: 'courses' } });
                    }}
                    onNext={onNext}
                    components={{
                        MultiValue: DefaultMultiValue,
                    }}
                />
                <Button
                    type={'submit'}
                    className={'add-group-modal__btn'}
                    variant={'primary'}
                    size={'medium'}
                    loading={isLoading}
                    disabled={isDisabledForm({ isValid, isLoading })}
                    fullWidth
                >
                    {t('CREATE_GROUP')}
                </Button>
            </form>
        </InvitedModal>
    );
};

export default AddGroupModal;
