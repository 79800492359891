import { Editor } from 'components/common';
import CreateCourseCover from './CreateCourseCover';
import { useParams } from 'react-router-dom';
import {
    useFetchCourseMutation,
    useUpdateCourseMutation,
    useUpdateCourseNameMutation,
} from 'api/services';
import { useEffect, useMemo, useState } from 'react';
import CreateCourseContent from './CreateCourseContent/CreateCourseContent';
import './CreateCourse.scss';
import useEditor from 'helpers/hooks/useEditor';
import { useSavingStatus } from 'components/layout/SavingStatusContext';
import CreateCourseFiles from 'pages/courses/Course/create-course/CreateCourseFiles/CreateCourseFiles';
import { useSelector } from 'react-redux';
import { TextareaAutosize } from '@mui/material';
import { getEditorJsTools, getTools } from 'components/common/Editor/constants';
import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { MaterialsContextProvider } from 'components/layout/MaterialsContext/MaterialsContext';
import Spinner from 'components/common/Spinner/Spinner';

function CreateCourse() {
    const { t } = useTranslation('common');
    const { course, dataUpdated } = useSelector((state) => state.course);
    const [fetchCourse, { isSuccess, isLoading }] = useFetchCourseMutation();

    const { changeStatus } = useSavingStatus();

    const { ready } = useSelector((state) => state.editor);

    const [updateCourse] = useUpdateCourseMutation();

    const [updateCourseName] = useUpdateCourseNameMutation();

    const [courseName, setCourseName] = useState('');

    const courseNameChangeDebounce = useDebouncedCallback(
        () =>
            updateCourseName({
                spaceId: userSpace?.id,
                courseId,
                name:
                    courseName ||
                    t('COURSE_NAME_PLACEHOLDER', {
                        number: course?.courseOrder + 1,
                    }),
            }),
        1000
    );
    const courseNameChange = async (e) => {
        setCourseName(e.target.value);
        courseNameChangeDebounce();
    };

    const { childRef, outerSave, onEditorChange } = useEditor({});

    const { courseId } = useParams();

    const sameCourse = useMemo(
        () => courseId === course?.id,
        [courseId, course?.id]
    );
    const { userSpace } = useSelector((state) => state.space);

    useEffect(() => {
        if (!sameCourse || dataUpdated) {
            fetchCourse({ spaceId: userSpace?.id, courseId });
        }
    }, []);

    const onSave = async (savedData) => {
        changeStatus(t('GENERAL_SAVING'));
        try {
            const data = {
                blocks: savedData?.blocks,
                name:
                    courseName ||
                    t('COURSE_NAME_PLACEHOLDER', {
                        number: course?.courseOrder + 1,
                    }),
            };
            await updateCourse({
                spaceId: userSpace?.id,
                courseId,
                ...data,
            });
        } catch (e) {
            console.error(e);
        } finally {
            changeStatus(null);
        }
    };

    useEffect(() => {
        if (
            courseName !== course?.name &&
            (isSuccess || (sameCourse && !dataUpdated))
        ) {
            setCourseName(
                course?.name ||
                    t('COURSE_NAME_PLACEHOLDER', {
                        number: course?.courseOrder + 1,
                    })
            );
        }
        if (
            (isSuccess || sameCourse) &&
            !course?.name &&
            !dataUpdated &&
            Number.isInteger(course?.courseOrder)
        ) {
            updateCourseName({
                spaceId: userSpace?.id,
                courseId,
                name: t('COURSE_NAME_PLACEHOLDER', {
                    number: course?.courseOrder + 1,
                }),
            });
        }
    }, [course]);
    return (
        (isSuccess || sameCourse) && (
            <div className={'layout-main'}>
                <div
                    className={cn('course draft', {
                        'course--ready': ready && (isSuccess || sameCourse),
                    })}
                >
                    <CreateCourseCover />
                    <div className={'course__title-wrapper'}>
                        {!isLoading && (
                            <TextareaAutosize
                                name={'name'}
                                onChange={courseNameChange}
                                className="course__title"
                                type="text"
                                maxLength={200}
                                placeholder={t('ENTER_COURSE_NAME')}
                                value={courseName}
                            />
                        )}
                    </div>
                    <Editor
                        courseId={courseId}
                        dataUpdated={dataUpdated}
                        tools={getTools(
                            [
                                'embedPDF',
                                'embedDOC',
                                'header',
                                'strikethrough',
                                'embed',
                                'list',
                                'linkTool',
                                'quote',
                                'underline',
                                'delimiter',
                                'customMarker',
                                'Color',
                                'toggleList',
                                'audio',
                                'image',
                                'code',
                                'table',
                                'video',
                                'attaches',
                            ],
                            getEditorJsTools(t, userSpace?.id, courseId)
                        )}
                        ref={childRef}
                        onSave={onSave}
                        outsideSave={outerSave}
                        initialData={course}
                        onChange={onEditorChange}
                        placeholderOnEmpty={'ENTER_COURSE_DESCRIPTION'}
                    />
                    <CreateCourseFiles
                        additionalMaterials={course?.educationalMaterials}
                    />
                    <MaterialsContextProvider materials={course.materials}>
                        <CreateCourseContent materials={course.materials} />
                    </MaterialsContextProvider>
                </div>
            </div>
        )
    );
}

export default CreateCourse;
