import {
    Navigate,
    Route,
    Routes,
    Outlet,
    useSearchParams,
} from 'react-router-dom';
import {
    AUTH_LOGIN_ROUTE,
    AUTH_ROUTE,
    CONTENT_COURSES_ROUTE,
    CONTENT_MAIN_ROUTE,
    CONTENT_ROUTE,
    CREATE_SPACE_ROUTE,
    PREVIEW,
    SPACES_ROUTE,
} from 'utils/consts';
import { ContentLayout, HeaderSidebarLayout } from 'components/layout';
import RequireAuth from './RequireAuth';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { WithCheckDomain } from 'helpers/hoc';
import PreviewLayout from 'components/layout/PreviewLayout';
import Profile from 'pages/profile/Profile';
import Main from 'pages/main/Main';
import CoursesWrapper from 'pages/courses/CoursesWrapper/CoursesWrapper';
import Base from 'pages/knowledge/Base';
import CreateCourse from 'pages/courses/Course/create-course/CreateCourse';
import CreateCourseSettings from 'pages/courses/Course/create-course-settings/CreateCourseSettings';
import CoursesDetailed from 'pages/courses/CoursesDetailed';
import CreateTest from 'pages/course-test/views/Create';
import StudentTest from 'pages/course-test/views/StudentTest';
import Lesson from 'pages/lesson/Lesson';
import LessonStudent from 'pages/lesson/LessonStudent/LessonStudent';
import StudentCoursesProcess from 'pages/courses/student-courses/views/student-courses-process/StudentCoursesProcess';
import Users from 'pages/users/Users';
import Students from 'pages/users/views/students/Students';
import Mentors from 'pages/users/views/mentors/Mentors';
import Groups from 'pages/users/views/groups/Groups';
import StudentDetail from 'pages/users/views/student-detail/StudentDetail';
import StudentDetailCourses from 'pages/users/views/student-detail/courses/Courses';
import StudentDetailGroups from 'pages/users/views/student-detail/groups/Groups';
import GroupDetail from 'pages/users/views/group-detail/GroupDetail';
import GroupDetailCourses from 'pages/users/views/group-detail/courses/Courses';
import GroupDetailStudents from 'pages/users/views/group-detail/students/Students';
import CoursePreview from 'pages/preview/CoursePreview';
import LessonPreview from 'pages/preview/LessonPreview/LessonPreview';
import TestPreview from 'pages/preview/TestPreview/TestPreview';
import Spaces from 'pages/spaces/Spaces';
import CreateSpace from 'pages/spaces/create-space/CreateSpace';
import Auth from 'pages/auth/Auth';
import Login from 'pages/auth/views/login/Login';
import Forgot from 'pages/auth/views/forgot/Forgot';
import Password from 'pages/auth/views/password/Password';
import Confirm from 'pages/auth/views/confirm/Confirm';
import Done from 'pages/auth/views/done/Done';
import Verification from 'pages/auth/views/verification/Verification';
import Register from 'pages/auth/views/register/Register';
import RegisterPersonalDataForm from 'pages/auth/views/register/FormComponents/PersonalDataForm';
import RegisterAboutCompanyForm from 'pages/auth/views/register/FormComponents/AboutCompanyForm';
import RegisterConfirmationForm from 'pages/auth/views/register/FormComponents/ConfirmationForm';
import Invite from 'pages/auth/views/invite/Invite';
import InvitePersonalDataForm from 'pages/auth/views/invite/FormComponents/PersonalDataForm';
import InviteConfirmationForm from 'pages/auth/views/invite/FormComponents/ConfirmationForm';
import ProtectedRoute from 'routes/ProtectedRoute';
import Course from 'pages/courses/Course/Course/Course';
import HeaderUsers from 'components/common/Header/HeaderUsers';
import SidebarUsers from 'components/common/Sidebar/SidebarUsers';
import Notification from 'pages/notification/Notification';
import Menu from 'pages/menu/Menu';
import MenuHeader from 'pages/menu/MenuHeader';
import {
    PREVIEW_STATUS,
    PreviewProvider,
} from 'components/layout/PreviewContext';
import HeaderMentorCourse from 'components/common/Header/HeaderMentorCourse';
import { useAction } from 'helpers/hooks';
import ProfileDetails from 'pages/profile/views/profile-details';
import Settings from 'pages/settings';
import { Sidebar } from 'components/common';
import HeaderSettingsSpace from 'components/common/Header/HeaderSettingsSpace';
import HeaderProfilePage from 'components/common/Header/HeaderProfilePage';
import Access from 'pages/access';
import LessonCreate from 'pages/lesson/LessonCreate/LessonCreate';
import Analytics from 'pages/analytics/Analytics';
import Review from 'pages/analytics/views/review/Review';
import Courses from 'pages/analytics/views/courses/Courses';
import AnalyticsStudents from 'pages/analytics/views/students/Students';
import AnalyticsGroups from 'pages/analytics/views/groups/Groups';
import DetailCourses from 'pages/analytics/views/courses/DetailCourses/DetailCourses';
import CourseLesson from 'pages/analytics/views/courses/DetailCourses/views/lesson/CourseLesson';
import AnalyticsStudentDetail from 'pages/analytics/views/students/StudentDetail/StudentDetail';
import AnalyticsStudentDetailCourse from 'pages/analytics/views/students/StudentDetail/StudentDetailCourse';
import Test from 'pages/analytics/views/courses/DetailCourses/views/test/Test';
import DetailCoursesStudents from 'pages/analytics/views/courses/DetailCourses/views/students/DetailCoursesStudents';
import HeaderCourseSettings from 'components/common/Header/HeaderCourseSettings';
import StudentDetailTest from 'pages/analytics/views/students/StudentDetail/StudentDetailTest';
const AppRoutes = () => {
    const [searchParams] = useSearchParams();
    const { registerFormValues } = useSelector((state) => state.auth);
    const { token: authToken } = useSelector((state) => state.auth);
    const { profile } = useSelector((state) => state.profile);
    const isAuth = useMemo(() => !!authToken, [authToken]);
    const isValidRegisterFormValues =
        Object.keys(registerFormValues).length > 0;
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const domain = searchParams.get('domain');
    const data = searchParams.get('data');
    return (
        <Routes>
            <Route path={AUTH_ROUTE} element={<Auth />}>
                <Route path={'login'} element={<Login />} />
                <Route path={'forgot'} element={<Forgot />} />
                <Route element={<ProtectedRoute condition={token} replace />}>
                    <Route path={'forgot/password'} element={<Password />} />
                </Route>
                <Route element={<ProtectedRoute condition={email} replace />}>
                    <Route path={'confirm'} element={<Confirm />} />
                    <Route path={'verification'} element={<Verification />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute condition={token && domain} replace />
                    }
                >
                    <Route path={'done'} element={<Done />} />
                </Route>
                <Route path={'register'} element={<Register />}>
                    <Route index element={<RegisterPersonalDataForm />} />
                    <Route
                        element={
                            <ProtectedRoute
                                condition={isValidRegisterFormValues}
                                redirectTo={'/auth/register'}
                            />
                        }
                    >
                        <Route
                            path={'about-company'}
                            element={<RegisterAboutCompanyForm />}
                        />
                        <Route
                            path={'confirmation'}
                            element={<RegisterConfirmationForm />}
                        />
                    </Route>
                    <Route
                        path="*"
                        element={<Navigate to={'/auth/register'} replace />}
                    />
                </Route>
                <Route path={'invite'} element={<Invite />}>
                    <Route
                        element={
                            <ProtectedRoute
                                condition={email && domain && data}
                                replace
                            />
                        }
                    >
                        <Route index element={<InvitePersonalDataForm />} />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                condition={isValidRegisterFormValues}
                                redirectTo={'/auth/invite'}
                            />
                        }
                    >
                        <Route
                            path={'confirmation'}
                            element={<InviteConfirmationForm />}
                        />
                    </Route>
                    <Route
                        path="*"
                        element={<Navigate to={'/auth/invite'} replace />}
                    />
                </Route>
                <Route
                    path="*"
                    element={<Navigate to={AUTH_LOGIN_ROUTE} replace />}
                />
            </Route>
            <Route
                element={
                    <RequireAuth
                        condition={isAuth}
                        redirectPath={'/auth/login'}
                    />
                }
            >
                <Route path={SPACES_ROUTE}>
                    <Route index element={<Spaces />} />
                    <Route
                        path={CREATE_SPACE_ROUTE}
                        element={<CreateSpace />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to={SPACES_ROUTE} replace />}
                    />
                </Route>
            </Route>
            <Route
                path={PREVIEW}
                element={
                    <WithCheckDomain>
                        <PreviewLayout>
                            <Outlet />
                        </PreviewLayout>
                    </WithCheckDomain>
                }
            >
                <Route path={'courses/:courseId'} element={<CoursePreview />} />
                <Route path={'lesson/:lessonId'} element={<LessonPreview />} />
                <Route path={'test/:testId'} element={<TestPreview />} />
            </Route>
            <Route
                path={CONTENT_ROUTE}
                element={
                    <WithCheckDomain noNavigation>
                        <ContentLayout>
                            <Outlet />
                        </ContentLayout>
                    </WithCheckDomain>
                }
            >
                {/*<Route path={'main'} element={<Main />} />*/}
                <Route
                    element={
                        <HeaderSidebarLayout
                            headerComponent={<HeaderProfilePage />}
                            sidebarComponent={<SidebarUsers />}
                        />
                    }
                >
                    <Route path={'profile'} element={<Profile />} />
                    <Route
                        path={'profile/:profileId'}
                        element={<ProfileDetails />}
                    />
                </Route>

                <Route path={'courses'} element={<CoursesWrapper />} />
                <Route
                    element={
                        <ProtectedRoute
                            condition={profile?.role === 'mentor'}
                            replace
                            redirectTo={CONTENT_COURSES_ROUTE}
                        />
                    }
                >
                    <Route path={'knowledge/base/'} element={<Base />} />
                    <Route
                        element={
                            <PreviewProvider
                                previewStatus={PREVIEW_STATUS.active}
                            >
                                <HeaderSidebarLayout
                                    headerComponent={<HeaderMentorCourse />}
                                />
                            </PreviewProvider>
                        }
                    >
                        <Route
                            path={'courses/:courseId'}
                            element={<Course />}
                        />
                        <Route
                            path={'courses/:courseId/test/:testId'}
                            element={<CreateTest />}
                        />
                        <Route
                            path={'courses/:courseId/lesson/:lessonId'}
                            element={<Lesson />}
                        />
                    </Route>
                    <Route
                        element={
                            <PreviewProvider
                                previewStatus={PREVIEW_STATUS.draft}
                            >
                                <HeaderSidebarLayout
                                    headerComponent={<HeaderMentorCourse />}
                                />
                            </PreviewProvider>
                        }
                    >
                        <Route
                            path={'courses/create/:courseId'}
                            element={<CreateCourse />}
                        />
                        <Route
                            path={'courses/create/:courseId/lesson/:lessonId'}
                            element={<LessonCreate />}
                        />
                        <Route
                            path={'courses/create/:courseId/test/:testId'}
                            element={<CreateTest />}
                        />
                    </Route>
                    <Route
                        element={
                            <HeaderSidebarLayout
                                headerComponent={<HeaderCourseSettings />}
                            />
                        }
                    >
                        <Route
                            path={'courses/create/:courseId/settings'}
                            element={<CreateCourseSettings />}
                        />
                    </Route>
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            condition={profile?.role === 'student'}
                            replace
                            redirectTo={CONTENT_COURSES_ROUTE}
                        />
                    }
                >
                    <Route
                        path={'courses/detailed/:spaceId/:id'}
                        element={<CoursesDetailed />}
                    />
                    <Route
                        path={
                            'course/:courseId/student/test/:testId/space/:spaceId'
                        }
                        element={<StudentTest />}
                    />
                    <Route
                        path={
                            'course/:courseId/lesson/:lessonId/space/:spaceId'
                        }
                        element={<LessonStudent />}
                    />
                    <Route
                        path={'student-courses/process'}
                        element={<StudentCoursesProcess />}
                    />
                </Route>
                <Route
                    element={
                        <HeaderSidebarLayout
                            headerComponent={<HeaderUsers />}
                            sidebarComponent={<SidebarUsers />}
                        />
                    }
                >
                    <Route
                        element={
                            <ProtectedRoute
                                condition={profile?.role === 'mentor'}
                                replace
                                redirectTo={CONTENT_COURSES_ROUTE}
                            />
                        }
                    >
                        <Route path={'users'} element={<Users />}>
                            <Route index element={<Students />} />
                            <Route path={'students'} element={<Students />} />
                            <Route path={'admins'} element={<Mentors />} />
                            <Route path={'groups'} element={<Groups />} />
                        </Route>
                    </Route>
                </Route>
                <Route
                    element={
                        <HeaderSidebarLayout
                            headerComponent={<HeaderUsers />}
                            sidebarComponent={<Sidebar />}
                        />
                    }
                >
                    <Route
                        element={
                            <ProtectedRoute
                                condition={profile?.role === 'mentor'}
                                replace
                                redirectTo={CONTENT_COURSES_ROUTE}
                            />
                        }
                    >
                        <Route path={'analytics'} element={<Analytics />}>
                            <Route index element={<Review />} />
                            <Route path="review" element={<Review />} />
                            <Route path="courses" element={<Courses />} />
                            <Route
                                path="students"
                                element={<AnalyticsStudents />}
                            />
                            <Route
                                path="groups"
                                element={<AnalyticsGroups />}
                            />
                        </Route>
                        <Route
                            path="analytics/detail/course/:id"
                            element={<DetailCourses />}
                        >
                            <Route index element={<CourseLesson />} />
                            <Route path="lesson" element={<CourseLesson />} />
                            <Route path="test" element={<Test />} />
                            <Route
                                path="students"
                                element={<DetailCoursesStudents />}
                            />
                        </Route>
                        <Route
                            path="analytics/detail/course/student/:id"
                            element={<AnalyticsStudentDetail />}
                        >
                            <Route
                                index
                                element={<AnalyticsStudentDetailCourse />}
                            />
                            <Route
                                path="courses"
                                element={<AnalyticsStudentDetailCourse />}
                            />
                            <Route
                                path="test"
                                element={<StudentDetailTest />}
                            />
                        </Route>
                    </Route>
                </Route>

                <Route
                    element={
                        <HeaderSidebarLayout
                            headerComponent={<HeaderUsers />}
                            sidebarComponent={<SidebarUsers />}
                        />
                    }
                >
                    <Route
                        element={
                            <ProtectedRoute
                                condition={profile?.role === 'mentor'}
                                replace
                                redirectTo={CONTENT_COURSES_ROUTE}
                            />
                        }
                    >
                        <Route
                            path={'users/students/:id'}
                            element={<StudentDetail />}
                        >
                            <Route index element={<StudentDetailCourses />} />
                            <Route
                                path={'courses'}
                                element={<StudentDetailCourses />}
                            />
                            <Route
                                path={'groups'}
                                element={<StudentDetailGroups />}
                            />
                        </Route>
                    </Route>
                </Route>
                <Route
                    element={
                        <HeaderSidebarLayout
                            headerComponent={<HeaderUsers />}
                            sidebarComponent={<SidebarUsers />}
                        />
                    }
                >
                    <Route path={'users/groups/:id'} element={<GroupDetail />}>
                        <Route index element={<GroupDetailStudents />} />
                        <Route
                            path={'students'}
                            element={<GroupDetailStudents />}
                        />
                        <Route
                            path={'courses'}
                            element={<GroupDetailCourses />}
                        />
                    </Route>
                    <Route path={'notification'} element={<Notification />} />
                </Route>
                <Route
                    element={
                        <HeaderSidebarLayout
                            headerComponent={<MenuHeader />}
                            sidebarComponent={<SidebarUsers />}
                        />
                    }
                >
                    <Route path={'menu'} element={<Menu />} />
                </Route>
                <Route
                    element={
                        <HeaderSidebarLayout
                            headerComponent={<HeaderSettingsSpace />}
                        />
                    }
                >
                    <Route
                        element={
                            <ProtectedRoute
                                condition={profile?.role === 'mentor'}
                                redirectTo={'/content/courses'}
                            />
                        }
                    >
                        <Route path={'settings'} element={<Settings />} />
                    </Route>
                </Route>
                <Route
                    path="*"
                    element={<Navigate to={CONTENT_COURSES_ROUTE} replace />}
                />
            </Route>
            <Route path={'access'} element={<Access />} />
            <Route
                path="*"
                element={<Navigate to={AUTH_LOGIN_ROUTE} replace />}
            />
        </Routes>
    );
};

export default AppRoutes;
