import React, { useState } from 'react';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import {
    analyticsDetailCourseHeading,
    analyticsDetailCourseTable,
} from 'pages/analytics/views/courses/DetailCourses/consts';
import DetailCourseFilterSearch from 'pages/analytics/views/courses/DetailCourses/DetailCourseFilterSearch';
import { useFetchDetailCourseLessonQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useParams, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'helpers/hooks';

const CourseLesson = () => {
    const { t } = useTranslation('common');
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { totalLessons } = useOutletContext();
    const [search, setSearch] = useState('');
    const debouncedSearchInput = useDebounce(search, 800);
    const { data } = useFetchDetailCourseLessonQuery({
        spaceId: userSpace?.id,
        id,
        search: debouncedSearchInput,
    });

    const onSearch = (e) => setSearch(e.target.value);
    return (
        <>
            <DetailCourseFilterSearch
                // isFilter={true}
                searchPlaceholder={t('SEARCH_LESSONS')}
                totalCount={totalLessons}
                onSearch={onSearch}
            />
            <AnalyticsTable
                headings={analyticsDetailCourseHeading}
                body={data || []}
            />
        </>
    );
};

export default CourseLesson;
