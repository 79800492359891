import { useAction } from 'helpers/hooks';
import { List } from '@akhter-studios/classlytics-front-ui-components';
import { anotherItems, items } from './consts';
import classNames from 'classnames';
import './MenuList.scss';
import MenuItem from 'pages/menu/MenuItem';
import { useSelector } from 'react-redux';

const MenuList = () => {
    const { profile } = useSelector((state) => state.profile);
    const { logout } = useAction();
    return (
        <>
            {profile?.role === 'mentor' ? (
                <List
                    className={'menu-list'}
                    items={items}
                    renderItem={(item) => <MenuItem key={item.id} {...item} />}
                />
            ) : null}
            <List
                className={classNames('menu-list', {
                    another: true,
                })}
                items={anotherItems({ logout })}
                renderItem={(item) => <MenuItem key={item.id} {...item} />}
            />
        </>
    );
};

export default MenuList;
