import { useSelector } from 'react-redux';
import { useMeQuery } from 'api/services';
import MainForm from './MainForm';
import './ProfileMain.scss';

const ProfileMain = () => {
    const { userSpace } = useSelector((state) => state.space);
    const { data: profile } = useMeQuery(
        { id: userSpace?.id },
        { skip: !userSpace }
    );
    return profile ? (
        <MainForm
            avatar={profile.avatar}
            firstName={profile.firstName ?? ''}
            lastName={profile.lastName ?? ''}
            middleName={profile.middleName ?? ''}
            role={profile.role ?? ''}
            email={profile.email ?? ''}
            phoneNumber={profile.phoneNumber ?? ''}
            birthDay={profile.birthDay}
            birthMonth={profile.birthMonth}
            birthYear={profile.birthYear}
        />
    ) : null;
};

export default ProfileMain;
