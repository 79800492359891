import { nanoid } from 'nanoid';

const tabs = [
    {
        id: nanoid(),
        to: 'courses',
        icon: 'courses',
        text: 'COURSES',
        search: 'courses',
    },
    {
        id: nanoid(),
        to: 'groups',
        icon: 'user-group',
        text: 'GROUPS',
        search: 'groups',
    },
];

export { tabs };
