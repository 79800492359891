import React, { useEffect } from 'react';
import { removeDuplicateObjects } from 'utils/functions/helpers';

const usePaste = ({ isFocus, fieldRef, setFieldValue, values }) => {
    useEffect(() => {
        if (isFocus) {
            const handlePasteAnywhere = (event) => {
                const parsedText = [
                    ...new Set(
                        event.clipboardData
                            .getData('text')
                            .split(/\s+/)
                            .map((el) => el)
                    ),
                ].filter((el) => el);
                const options = parsedText.map((value) => ({
                    value,
                    label: value,
                }));
                setFieldValue(
                    'emails',
                    removeDuplicateObjects(
                        [...values.emails, ...options],
                        'value'
                    )
                );
                fieldRef.current.blur();
            };

            window.addEventListener('paste', handlePasteAnywhere);

            return () => {
                window.removeEventListener('paste', handlePasteAnywhere);
            };
        }
    }, [isFocus]);
    return {};
};

export default usePaste;
