import classNames from "classnames";
import "./BottomModal.scss";

const BottomModal = ({children, className}) => {
    return (
        <div className={classNames("bottom-modal", className)}>
            {children}
        </div>
    );
};

export default BottomModal;
