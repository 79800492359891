import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
    baseComponents,
    baseStyles,
} from 'components/custom-react-select/consts';

const CustomSelect = forwardRef((props, ref) => {
    const { t } = useTranslation('common');
    const { components, styles } = props;
    return (
        <Select
            {...props}
            loadingMessage={() => t('GENERAL_LOADING')}
            noOptionsMessage={({ inputValue }) =>
                inputValue.length > 0 ? t('GENERAL_NOT_FOUND') : null
            }
            components={{
                ...baseComponents,
                ...components,
            }}
            styles={{
                ...baseStyles({ isError: props.isError }),
                ...styles,
            }}
            ref={ref}
        />
    );
});

export default CustomSelect;
