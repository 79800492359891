import React, { useEffect, useState } from 'react';
import Icon from '../../components/common/Icon/Icon';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import cn from 'classnames';
import { notify } from 'utils/functions/notify';
import { fetchDetailFile, moveFileApi } from './services';
import { useParams } from 'react-router-dom';
import SendFilesToCourse from 'pages/knowledge/ModalContent/SendFilesToCourse/SendFilesToCourse';
import { Trans, useTranslation } from 'react-i18next';
const SelectFolder = ({
    folders,
    setSuccess,
    success,
    closeModal,
    setFolderFiles,
    currentItem,
}) => {
    const [data, setData] = useState([]);
    const [selectFolder, setSelectFolder] = useState(null);

    const { spaceId } = useParams();
    const { t } = useTranslation('common');
    useEffect(() => {
        setData(folders.filter((x) => x.type === 'folder'));
    }, []);
    const handleClick = () => {
        try {
            let result = [...folders];
            result = result.map((x) =>
                x.id === selectFolder.id
                    ? { ...x, files: [...x.files, currentItem] }
                    : x
            );

            result = result.filter((x) => x.id !== currentItem.id);
            setFolderFiles(result);
            moveFileApi(spaceId, result).then((result) => {
                notify('success', t('NOTIFY_SUCCESSFULLY_MOVED_TO_FOLDER'));
                setSuccess(!success);
                closeModal();
            });
        } catch (e) {}
    };
    return (
        <section className="select-folder">
            <h5>Выберите папку</h5>
            <div className="select-folder__items">
                {data.map((x) => (
                    <div
                        className={cn('select-folder__item', {
                            active: selectFolder?.id === x.id,
                        })}
                        onClick={() => setSelectFolder(x)}
                    >
                        <div className="icon">
                            <Icon name="folder" />
                        </div>
                        <div className="info">
                            <span>Папка</span>
                            <div>{x.name}</div>
                        </div>
                    </div>
                ))}
            </div>
            <Button variant="contained" onClick={handleClick}>
                Отправить
            </Button>
        </section>
    );
};

const RemoveFiles = ({ removeHandler, currentItem, cancelHandler }) => {
    const { t } = useTranslation('common');
    const isFolder = currentItem.type === 'folder';
    return (
        <section className="remove-file">
            <h6 className="text-center">
                {isFolder ? t('BASE_REMOVE_FOLDER') : t('BASE_REMOVE_FILE')}
            </h6>
            <span>
                <Trans t={t} i18nKey={'BASE_REMOVE_FILE_DESCRIPTION'}>
                    Файл {{ name: currentItem.name }} будет удален безвозвратно,
                    и <strong>исчезнет из всех уроков</strong>
                </Trans>
            </span>
            <div className="remove-file__wrapper">
                <Button
                    variant="secondary"
                    onClick={() =>
                        removeHandler(currentItem.id, {
                            ...currentItem,
                            files: currentItem.files || [],
                        })
                    }
                >
                    {t('BASE_REMOVE_BUTTON_OK')}
                </Button>
                <Button variant="primary" onClick={cancelHandler}>
                    {t('BASE_REMOVE_BUTTON_CANCEL')}
                </Button>
            </div>
        </section>
    );
};

const DetailFile = ({ currentItem, closeModal }) => {
    const { t } = useTranslation('common');
    const [detailItem, setDetailItem] = useState(null);
    const { spaceId } = useParams();
    useEffect(() => {
        if (currentItem) {
            fetchDetailFile(currentItem.id, spaceId).then((result) => {
                setDetailItem(result);
            });
        }
    }, [currentItem]);
    if (!detailItem) return null;
    return (
        <section className="detail-file">
            <h5 className="text-center">Подробная информация</h5>
            <ul>
                <li>
                    <div>Название файла</div>
                    <span>{detailItem.name}</span>
                </li>
                <li>
                    <div>{t('BASE_MATERIAL_CREATION_DATE')}</div>
                    <span>{detailItem.created}</span>
                </li>
                <li>
                    <div>{t('BASE_MATERIAL_AUTHOR')}</div>
                    <span>{detailItem.username}</span>
                </li>
            </ul>
            <Button onClick={closeModal}>Закрыть</Button>
        </section>
    );
};

const KnowledgeModals = ({
    folders,
    setFolderFiles,
    type,
    removeFileHandler,
    closeModal,
    setSuccess,
    success,
    currentItem,
    interfaceModal,
    selectedItems,
    resetCheckboxes,
}) => {
    const displayContent = () => {
        switch (type) {
            case 'folder':
                return (
                    <SelectFolder
                        closeModal={closeModal}
                        setFolderFiles={setFolderFiles}
                        folders={folders}
                        setSuccess={setSuccess}
                        success={success}
                        currentItem={currentItem}
                    />
                );
            case 'remove':
                return (
                    <RemoveFiles
                        removeHandler={removeFileHandler}
                        cancelHandler={closeModal}
                        currentItem={currentItem}
                    />
                );
            case 'send':
                return (
                    <SendFilesToCourse
                        closeModal={closeModal}
                        selectedItems={selectedItems}
                        currentItem={currentItem}
                        resetCheckboxes={resetCheckboxes}
                    />
                );

            case 'detail':
                return (
                    <DetailFile
                        closeModal={closeModal}
                        currentItem={currentItem}
                    />
                );
            default:
                return 'like me';
        }
    };
    return <div autoFocus={interfaceModal}>{displayContent()}</div>;
};

export default KnowledgeModals;
