import { nanoid } from 'nanoid';

const tabs = [
    {
        id: nanoid(),
        icon: 'company',
        text: 'SETTINGS_COMPANY',
        search: '?scope=company',
        to: '/content/settings?scope=company',
    },
    {
        id: nanoid(),
        icon: 'colors',
        text: 'SETTINGS_COLORS',
        search: '?scope=colors',
        to: '/content/settings?scope=colors',
    },
];

export { tabs };
