import { useState } from 'react';
import {
    AddCoursesManyModal,
    AddGroupsManyModal,
    BottomModalWrapper,
} from 'pages/users/common';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';

const StudentBottomModal = ({
    count,
    maxCount,
    selectedStudents,
    onClear,
    onRemove,
}) => {
    const { t } = useTranslation('common');
    const [isOpenCourses, setIsOpenCourses] = useState(false);
    const [isOpenGroups, setIsOpenGroups] = useState(false);

    const onOpenCourses = () => setIsOpenCourses(true);
    const onOpenGroups = () => setIsOpenGroups(true);
    return (
        <>
            <BottomModalWrapper
                count={count}
                maxCount={maxCount}
                onClear={onClear}
            >
                <Button
                    variant={'secondary'}
                    size={'small'}
                    onClick={onOpenCourses}
                >
                    {t('ADD_COURSE')}
                </Button>
                <Button
                    variant={'secondary'}
                    size={'small'}
                    onClick={onOpenGroups}
                >
                    {t('ADD_TO_GROUP')}
                </Button>
                <Button variant={'tertiary'} size={'small'} onClick={onRemove}>
                    {t('STUDENTS_DELETE')}
                </Button>
            </BottomModalWrapper>
            {isOpenCourses ? (
                <AddCoursesManyModal
                    isOpen={isOpenCourses}
                    setIsOpen={setIsOpenCourses}
                    count={count}
                    students={selectedStudents}
                />
            ) : null}
            {isOpenGroups ? (
                <AddGroupsManyModal
                    isOpen={isOpenGroups}
                    setIsOpen={setIsOpenGroups}
                    count={count}
                    students={selectedStudents}
                />
            ) : null}
        </>
    );
};

export default StudentBottomModal;
