import React from 'react';
import UsersFilterSearch from 'components/common/UsersFilterSearch/UsersFilterSearch';
import { useState } from 'react';
import {
    actionsFilter,
    actionsFilterStudent,
} from 'pages/analytics/views/students/consts';

const StudentDetailFilterSearch = ({
    searchPlaceholder,
    onSearch,
    search,
    isFilter,
    totalPlaceholder,
    isStudent = false,
    totalCount,
    setType,
}) => {
    const [isShow, setIsShow] = useState(false);
    const [selectedAction, setSelectedAction] = useState({
        text: 'SHOW_ALL',
        type: null,
    });
    const onShow = () => setIsShow((prev) => !prev);
    const onFilter = ({ type, text }) => {
        setSelectedAction({ type, text });
        setType(type);
        setIsShow(false);
    };
    return (
        <UsersFilterSearch
            totalPlaceholder={totalPlaceholder}
            searchPlaceholder={searchPlaceholder}
            search={search}
            onSearch={onSearch}
            isFilter={isFilter}
            setIsShow={setIsShow}
            selectedAction={selectedAction}
            onShow={onShow}
            isShow={isShow}
            otherActions={isStudent ? actionsFilterStudent : actionsFilter}
            onFilter={onFilter}
            totalCount={totalCount}
        />
    );
};

export default StudentDetailFilterSearch;
