import {
    Avatar,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './FormatOptionLabelStudent.scss';

const FormatOptionLabelStudent = (props) => {
    const { user, label } = props;
    const { email, firstName, lastName, avatar } = user;
    return (
        <div className={'format-option-label-student'}>
            <Avatar
                src={avatar}
                className={'format-option-label-student__img'}
                alt={'avatar'}
                variant={'rounded'}
                avatarText={`${firstName?.charAt(0).toUpperCase() || ''}${
                    lastName?.charAt(0).toUpperCase() || ''
                }`}
            />
            <div className={'format-option-label-student__content'}>
                <Typography
                    className={'format-option-label-student__name'}
                    variant={'body3'}
                >
                    {label}
                </Typography>
                <Typography
                    className={'format-option-label-student__email'}
                    variant={'body4'}
                >
                    {email}
                </Typography>
            </div>
        </div>
    );
};

export default FormatOptionLabelStudent;
