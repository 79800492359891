import {
    Icon,
    IconButton,
} from '@akhter-studios/classlytics-front-ui-components';

const SwiperButtonPrev = ({ onClick }) => {
    return (
        <IconButton onClick={onClick}>
            <Icon name={'arrow-left'} />
        </IconButton>
    );
};

export default SwiperButtonPrev;
