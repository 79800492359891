import { useEffect, useRef } from 'react';

export default function useOutsideClick(callback) {
    const ref = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (!ref.current || ref.current.contains(e.target)) {
                return;
            }
            callback(e);
        };

        document.addEventListener('click', checkIfClickedOutside);
        return () => {
            document.removeEventListener('click', checkIfClickedOutside);
        };
    }, [ref]);

    return ref;
}
