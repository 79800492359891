import { Outlet } from 'react-router-dom';
import { Header, Sidebar } from 'components/common';

const HeaderSidebarLayout = ({
    headerComponent = <Header />,
    sidebarComponent = <Sidebar />,
}) => {
    return (
        <>
            {headerComponent}
            {sidebarComponent}
            <Outlet />
        </>
    );
};

export default HeaderSidebarLayout;
