import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useUploadCourseCoverMutation } from 'api/services';
import { useSelector } from 'react-redux';
import {
    Icon,
    SegmentButton,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import CreateCourseCoverModal from '../CreateCourseCoverModal';
import './CreateCourseCover.scss';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useTranslation } from 'react-i18next';

const CreateCourseCover = ({ isActionHide = false }) => {
    const { course } = useSelector((state) => state.course);
    const [coverImg, setCoverImg] = useState('');

    useEffect(() => {
        if (course?.image) {
            setCoverImg(course?.image);
        }
    }, [course]);

    const { userSpace } = useSelector((state) => state.space);
    const { courseId } = useParams();

    const [uploadCourseCover] = useUploadCourseCoverMutation();

    const [coverPreview, setCoverPreview] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const previewStatus = usePreview();
    const onUploadOpen = (id) => document.getElementById(id).click();

    const onUploadCover = async (e) => {
        const file = e.target.files[0];
        const {
            data: { image },
        } = await uploadCourseCover({
            spaceId: userSpace.id,
            courseId,
            file,
        });
        setCoverImg(image);
    };

    const { t } = useTranslation('common');

    const onCoverChange = (e) => {
        const file = e.target.files[0];
        setCoverPreview(URL.createObjectURL(file));
    };
    return (
        <section className={'create-course-cover'}>
            <input
                className={'create-course-cover__upload'}
                type="file"
                id="uploadBtn"
                disabled={previewStatus !== PREVIEW_STATUS.draft}
                onChange={onUploadCover}
            />
            <input
                className={'create-course-cover__upload'}
                type="file"
                id="changeBtn"
                disabled={previewStatus !== PREVIEW_STATUS.draft}
                onChange={onCoverChange}
            />
            {coverImg ? (
                <div className={'create-course-cover__cover'}>
                    <img
                        className={'create-course-cover__img'}
                        src={coverImg}
                        alt={'cover'}
                    />
                    {!isActionHide ? (
                        <SegmentButton
                            className={'create-course-cover__edit'}
                            size={'small'}
                            onClick={() =>
                                previewStatus === PREVIEW_STATUS.draft &&
                                setIsOpen(true)
                            }
                        >
                            <Icon name={'cover'} />
                            <Typography variant={'body4'}>
                                {t('CHANGE_COVER')}
                            </Typography>
                        </SegmentButton>
                    ) : null}
                </div>
            ) : !isActionHide ? (
                <SegmentButton
                    className={'create-course-cover__btn'}
                    size={'small'}
                    onClick={() => onUploadOpen('uploadBtn')}
                >
                    <Icon name={'cover'} />
                    <Typography variant={'body4'}>{t('ADD_COVER')}</Typography>
                </SegmentButton>
            ) : null}
            <CreateCourseCoverModal
                setCoverImg={setCoverImg}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                cover={coverImg}
                coverPreview={coverPreview}
                onUploadOpen={onUploadOpen}
            />
        </section>
    );
};

export default CreateCourseCover;
