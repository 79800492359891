import React from "react";
import "./Spinner.scss";
const Spinner = () => {
  return (
    <section className="spinner">
      <div className="spinner-loader"></div>
    </section>
  );
};

export default Spinner;
