import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumbs } from '@akhter-studios/classlytics-front-ui-components';
import './HeaderBreadcrumbs.scss';
import { pickTextColorBasedOnBgColor } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useEffect } from 'react';
import { useFetchCourseNameMutation } from 'api/services';

const HeaderBreadcrumbs = ({ currentCourseName }) => {
    const { t } = useTranslation('common');
    const { pathname } = useLocation();
    const { courseId, id } = useParams();
    const { breadcrumbsName, course } = useSelector((state) => state.course);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const preview = usePreview();
    const [fetchCourseName] = useFetchCourseNameMutation();
    const { userSpace } = useSelector((state) => state.space);
    useEffect(() => {
        if (!breadcrumbsName.length && courseId) {
            fetchCourseName({ spaceId: userSpace?.id, courseId });
        }
    }, []);
    const getBreadCrumbs = () => {
        if (
            (pathname.includes('courses') && courseId) ||
            pathname.includes('lesson') ||
            pathname.includes('test')
        ) {
            return (
                <Breadcrumbs className={'header-breadcrumbs'}>
                    <Link
                        to={'/content/courses'}
                        style={{
                            color: pickTextColorBasedOnBgColor(
                                menuBackgroundColor
                            ),
                        }}
                    >
                        {t('NAVIGATION_COURSES')}
                    </Link>
                    {course ? (
                        <>
                            <span
                                style={{
                                    color: pickTextColorBasedOnBgColor(
                                        menuBackgroundColor
                                    ),
                                }}
                            >
                                »
                            </span>
                            <Link
                                to={
                                    preview === PREVIEW_STATUS.draft
                                        ? `/content/courses/create/${courseId}`
                                        : `/content/courses/${courseId || id}`
                                }
                                className={'header-breadcrumbs--long'}
                                style={{
                                    color: pickTextColorBasedOnBgColor(
                                        menuBackgroundColor
                                    ),
                                }}
                            >
                                {breadcrumbsName ||
                                    currentCourseName ||
                                    t('COURSE_NAME_PLACEHOLDER', {
                                        number: course?.courseOrder + 1 || '',
                                    })}
                            </Link>
                        </>
                    ) : null}
                </Breadcrumbs>
            );
        } else {
            return null;
        }
    };

    return getBreadCrumbs();
};

export default HeaderBreadcrumbs;
