import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import SettingsColorsMainBrand from 'pages/settings/common/SettingsColors/SettingsColorsMainBrand';
import SettingsColorsSecondaryButton from 'pages/settings/common/SettingsColors/SettingsColorsSecondaryButton';
import SettingsColorsTertiaryButton from 'pages/settings/common/SettingsColors/SettingsColorsTertiaryButton';
import SettingsColorsMainBackgroundMenu from 'pages/settings/common/SettingsColors/SettingsColorsMainBackgroundMenu/SettingsColorsMainBackgroundMenu';
import SettingsColorsFont from 'pages/settings/common/SettingsColors/SettingsColorsFont';
import SettingsColorsMainBackground from 'pages/settings/common/SettingsColors/SettingsColorsMainBackground';
import './SettingsColors.scss';
import { useTranslation } from 'react-i18next';
const SettingsColors = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <Heading className={'settings-colors__title'} variant={'h1'}>
                {t('SETTINGS_COLORS')}
            </Heading>
            <SettingsColorsFont />
            <SettingsColorsMainBrand />
            <SettingsColorsSecondaryButton />
            <SettingsColorsTertiaryButton />
            <SettingsColorsMainBackgroundMenu />
            <SettingsColorsMainBackground />
        </>
    );
};

export default SettingsColors;
