import React from 'react';
import { onlyCenterMinHeight } from 'utils/functions/helpers';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
const Placeholders = ({ search, resetFields, filter }) => {
    const { t } = useTranslation('common');
    if (search && filter) {
        return (
            <div style={onlyCenterMinHeight(60)}>
                {t('BASE_FILTER_FILES_RESULTS_PLACEHOLDER')}
                <Button onClick={resetFields} variant="secondary">
                    {t('BASE_FILTER_FILES_RESULTS_RESET')}
                </Button>
            </div>
        );
    }
    if (filter) {
        return (
            <div style={onlyCenterMinHeight(60)}>
                {t('BASE_FORMAT_FILTER_RESULTS_PLACEHOLDER')}
                <Button onClick={resetFields} variant="secondary">
                    {t('BASE_FILTER_FILES_RESULTS_RESET')}
                </Button>
            </div>
        );
    }
    if (search) {
        return (
            <div style={onlyCenterMinHeight(60)}>
                {t('BASE_SEARCH_FILES_RESULTS_PLACEHOLDER')}
            </div>
        );
    }
    return false;
};

export default Placeholders;
