import React from 'react';
import HeaderLogo from 'components/common/Header/HeaderLogo';
import HeaderBreadcrumbs from 'components/common/Header/HeaderBreadcrumbs';
import HeaderHelper from 'components/common/Header/HeaderHelper';
import { useTranslation } from 'react-i18next';
import ArrowBack from 'components/ArrowBack/ArrowBack';
import HeaderUploadCourseButton from 'components/common/Header/HeaderUploadCourseButton';
import { useNavigate, useParams } from 'react-router-dom';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useSelector } from 'react-redux';
import { usePublishCourseMutation } from 'api/services';
import HeaderSettings from 'components/common/Header/HeaderSettings';
import HeaderHistory from 'components/common/Header/HeaderHistory';
import HeaderSelectBreadcrumb from 'components/common/Header/HeaderSelectBreadcrumb';

const HeaderCourseSettings = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const previewStatus = usePreview();
    const { course } = useSelector((state) => state.course);
    const [publishCourse] = usePublishCourseMutation();
    const { courseId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const onPublish = async () => {
        await publishCourse({ spaceId: userSpace?.id, courseId });
    };
    const openEditor = async () => {
        navigate(`/content/courses/create/${course?.draft?.id || courseId}`);
    };
    return (
        <header
            className={'header header-mentor-course'}
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <div className={'header-mentor-course__left'}>
                <HeaderLogo />
                <HeaderBreadcrumbs />
                <ArrowBack />
                <HeaderSelectBreadcrumb />
            </div>
            <div className={'header-mentor-course__right'}>
                <div className={'header-mentor-course__helper'}>
                    {/*<HeaderHistory />*/}
                    <HeaderSettings disabled={true} />
                    <HeaderUploadCourseButton
                        icon={
                            previewStatus !== PREVIEW_STATUS.draft
                                ? 'edit'
                                : 'upload'
                        }
                        onClick={
                            previewStatus !== PREVIEW_STATUS.draft
                                ? openEditor
                                : onPublish
                        }
                        buttonText={
                            previewStatus !== PREVIEW_STATUS.draft
                                ? t('EDIT_COURSE')
                                : t('PUBLISH_COURSE')
                        }
                    />
                </div>
                <HeaderHelper />
            </div>
        </header>
    );
};

export default HeaderCourseSettings;
