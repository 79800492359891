import { nanoid } from 'nanoid';

const tabs = [
    {
        id: nanoid(),
        icon: 'student',
        text: 'STUDENTS',
        search: 'students',
        to: '/content/users/students',
    },
    {
        id: nanoid(),
        icon: 'admin',
        text: 'ADMINS',
        search: 'admins',
        to: '/content/users/admins',
    },
    {
        id: nanoid(),
        icon: 'user-group',
        text: 'GROUPS',
        search: 'groups',
        to: '/content/users/groups',
    },
];

export { tabs };
