import { createSlice } from '@reduxjs/toolkit';
import { spaceApi } from 'api/services';

const initialState = {
    font: 'Inter',
    menuBackgroundColor: '#ffffff',
    colorSecondaryButton: '#ffffff',
    colorTertiaryButton: '#ffffff',
    backgroundColor: '#ffffff',
    brandColor: '#38a6e2',
};

export const settingSpaceSlice = createSlice({
    name: 'settingSpace',
    initialState,
    reducers: {
        setFont: (state, { payload }) => {
            state.font = payload;
        },
        setMenuBackgroundColor: (state, { payload }) => {
            state.menuBackgroundColor = payload;
        },
        setColorSecondaryButton: (state, { payload }) => {
            state.colorSecondaryButton = payload;
        },
        setColorTertiaryButton: (state, { payload }) => {
            state.colorTertiaryButton = payload;
        },
        setBackgroundColor: (state, { payload }) => {
            state.backgroundColor = payload;
        },
        setBrandColor: (state, { payload }) => {
            state.brandColor = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                spaceApi.endpoints.fetchSpace.matchFulfilled,
                (state, { payload }) => {
                    const {
                        font,
                        brandColor,
                        backgroundColor,
                        menuBackgroundColor,
                        colorSecondaryButton,
                        colorTertiaryButton,
                    } = payload;
                    state.font = font;
                    state.menuBackgroundColor = menuBackgroundColor;
                    state.colorSecondaryButton = colorSecondaryButton;
                    state.brandColor = brandColor;
                    state.backgroundColor = backgroundColor || '#ffffff';
                    state.colorTertiaryButton =
                        colorTertiaryButton || '#ffffff';
                }
            )
            .addMatcher(
                spaceApi.endpoints.fetchSettingsByDomain.matchFulfilled,
                (state, { payload }) => {
                    const {
                        font,
                        brandColor,
                        backgroundColor,
                        menuBackgroundColor,
                        colorSecondaryButton,
                        colorTertiaryButton,
                    } = payload;
                    state.font = font;
                    state.menuBackgroundColor = menuBackgroundColor;
                    state.colorSecondaryButton = colorSecondaryButton;
                    state.brandColor = brandColor;
                    state.backgroundColor = backgroundColor || '#ffffff';
                    state.colorTertiaryButton =
                        colorTertiaryButton || '#ffffff';
                }
            );
    },
});

const settingSpaceActions = settingSpaceSlice.actions;
export { settingSpaceActions };

export default settingSpaceSlice.reducer;
