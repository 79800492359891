import { renderComponent } from './consts';
import { useLocation } from 'react-router-dom';
import './Settings.jsx.scss';
import SettingsMenu from 'pages/settings/common/SettingsMenu';

const Settings = () => {
    const { search } = useLocation();
    return (
        <article className={'settings layout-main'}>
            <SettingsMenu />
            <div className={'settings__content'}>{renderComponent[search]}</div>
        </article>
    );
};

export default Settings;
