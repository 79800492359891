import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUpdateProfileMutation } from 'api/services';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { isDisabledForm, notify } from 'utils/functions';
import './UpdatePasswordForm.scss';
import { AuthFields } from 'components/feature';
import { fields } from '../consts';
import { profilePasswordSchema } from 'utils/schemas';
import { useSelector } from 'react-redux';

const UpdatePasswordForm = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [updateProfile, { isLoading }] = useUpdateProfileMutation();
    const onSubmit = async (values, { resetForm }) => {
        const { current_password, password } = values;
        const formData = new FormData();
        formData.append('current_password', current_password);
        formData.append('new_password', password);
        const { error } = await updateProfile({
            id: userSpace.id,
            body: formData,
        });
        if (!error) {
            notify('success', t('NOTIFY_SUCCESSFULLY_SAVED'));
            resetForm({});
        } else {
            notify('error', error?.error);
        }
    };

    const {
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        isValid,
    } = useFormik({
        initialValues: {
            current_password: '',
            password: '',
            second_password: '',
        },
        validateOnBlur: true,
        validationSchema: profilePasswordSchema(t),
        onSubmit,
    });
    return (
        <form className={'update-password-form'} onSubmit={handleSubmit}>
            <AuthFields
                fields={fields}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                fullWidth
            />
            <Button
                className={'update-password-form__btn'}
                variant={'primary'}
                size={'small'}
                loading={isLoading}
                disabled={isDisabledForm({ isValid, isLoading })}
            >
                {t('PROFILE_UPDATE_PASSWORD')}
            </Button>
        </form>
    );
};

export default UpdatePasswordForm;
