import React from 'react';
import {
    Heading,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
import './FoundCoursesEmpty.scss';

const FoundCoursesEmpty = () => {
    const { t } = useTranslation('common');
    return (
        <article className={'found-courses-empty'}>
            <Heading className={'found-courses-empty__title'} variant={'h3'}>
                {t('GENERAL_NOT_FOUND')} 🔎
            </Heading>
            <Typography
                className={'found-courses-empty__desc'}
                variant={'body3'}
            >
                {t('GENERAL_NOT_FOUND_COURSES_DESC')}
            </Typography>
        </article>
    );
};

export default FoundCoursesEmpty;
