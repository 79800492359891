import { reorderArray } from 'utils/functions/helpers';
import { Icon } from 'components/common';
import {
    addFieldApi,
    addQuestionApi,
    changeBlockInfoApi,
    changeFieldApi,
    changeTypeBlockApi,
    duplicateBlockApi,
    moveDndApi,
    removeFieldApi,
    removeQuestionApi,
    saveSettingTestApi,
    studentTesting,
    updateTestApi,
} from 'pages/course-test/course-test-services';
import { notify } from 'utils/functions';
import { useTranslation } from 'react-i18next';

export const createTest = ({
    test,
    setSwitches,
    switches,
    isOpen,
    setIsOpen,
    setTest,
    timeIsOver,
    test_id,
    spaceId,
    setResultPercentAction,
    saveProgressCourse,
    setModal,
    modal,
    isStudent,
    handleChangeAnsweredBlocks,
    settingTest,
    t,
}) => {
    const addBlock = (type) => {
        const data = {
            question: '',
            description: '',
            test_id,
            type,
        };
        addQuestionApi(data, spaceId).then((result) => {
            const responseData = {
                ...data,
                id: result.id,
                variants: [],
            };
            setTest(() => ({
                ...test,
                criterion: [...test.criterion, responseData],
            }));
            notify('success', t('NOTIFY_SUCCESSFULLY_CREATED'));
        });
    };
    const addField = (id) => {
        const newBlocks = [...test.criterion];
        const item = newBlocks.find((x) => x.id === id);
        const dataObj = {
            label: null,
            placeholder: t('MENTOR_TEST_QUESTION_NUMBER', {
                number: item.variants.length + 1,
            }),
            question_id: id,
            correctChoice: false,
        };
        addFieldApi(spaceId, dataObj).then((data) => {
            item.variants = [...item.variants, { ...dataObj, id: data.id }];
            setTest(() => ({ ...test, criterion: newBlocks }));
        });
    };

    const onDrop = (result, { variants, id }) => {
        const { removedIndex, addedIndex } = result;
        let newBlocks = [...test.criterion];
        if (variants) {
            const item = newBlocks.find((x) => x.id === id);
            item.variants = reorderArray(variants, removedIndex, addedIndex);
        } else {
            newBlocks = reorderArray(test.criterion, removedIndex, addedIndex);
        }

        moveDndApi(spaceId, newBlocks).then(() => {
            notify('success', t('NOTIFY_SUCCESSFULLY_UPDATED'));
        });
        setTest(() => ({ ...test, criterion: newBlocks }));
    };

    const updateTest = (name, value, withoutApi) => {
        let newTest = { ...test };
        newTest[name] = value;
        if (!withoutApi) {
            updateTestApi(spaceId, test_id, newTest).then((result) => {
                // notify('success', t('NOTIFY_SUCCESSFULLY_UPDATED'));
            });
        }
        setTest(newTest);
    };

    const updateTestName = (name, value) => {
        let newTest = { ...test };
        newTest[name] = value;
        setTest(newTest);
    };

    const onChangeBlockInfo = ({ target: { name, value } }, idx, fetch) => {
        let result = [...test.criterion];
        let item = result[idx];
        if (item) {
            item[name] = value;
        }
        if (fetch) {
            changeBlockInfoApi(spaceId, item.id, item).then((result) => {});
            // notify('success', t('NOTIFY_SUCCESSFULLY_UPDATED')));
        }
        setTest(() => ({ ...test, criterion: result }));
    };

    const duplicateBlock = (item, index) => {
        let result = [...test.criterion];
        const data = {
            ...item,
            question: `${item.question} ${t('TEST_DUPLICATE')}`,
            isDuplicate: true,
        };
        duplicateBlockApi(data, spaceId, t('TEST_DUPLICATE')).then((data) => {
            result.splice(index + 1, 0, {
                ...item,
                question: `${item.question} ${t('TEST_DUPLICATE')}`,
                id: data.id,
                variants: data.variants.map((el, idx) => ({
                    ...el,
                    placeholder: item.variants[idx].placeholder,
                })),
            });
            setTest(() => ({ ...test, criterion: result }));
            notify('success', t('NOTIFY_SUCCESSFULLY_DUPLICATED'));
        });
    };

    const deleteBlock = (item) => {
        let result = [...test.criterion];
        result = result.filter((x) => x.id !== item.id);
        removeQuestionApi(spaceId, item.id).then(() => {
            setTest(() => ({ ...test, criterion: result }));
            notify('success', t('NOTIFY_SUCCESSFULLY_DELETED'));
        });
    };

    const handleChangeField = (item, index, type, parentItem) => {
        let result = [...test.criterion];
        if (isStudent) {
            handleChangeAnsweredBlocks(parentItem, item);
        }
        const { id } = item;
        const checkRole = isStudent ? 'choice' : 'correctChoice';
        if (type === 'single-choice') {
            result[index].variants = result[index].variants.map((x) =>
                x.id === id
                    ? { ...x, [checkRole]: true }
                    : { ...x, [checkRole]: false }
            );
        } else {
            result[index].variants = result[index].variants.map((x) =>
                x.id === id ? { ...x, [checkRole]: !x[checkRole] } : x
            );
        }
        if (!isStudent) {
            const fieldItem = result[index].variants.find((el) => el.id === id);
            changeFieldApi(spaceId, item.id, fieldItem)
                .then(() => {
                    setTest(() => ({ ...test, criterion: result }));
                })
                .catch((err) => notify('error', t('NOTIFY_ERROR_WENT_WRONG')));
        } else {
            setTest(() => ({ ...test, criterion: result }));
        }
    };

    const handleChangeLabel = ({ index, value, childIndex }, isFetch) => {
        let result = [...test.criterion];
        result[index].variants[childIndex].label = value;
        let item = result[index].variants[childIndex];
        if (isFetch) {
            changeFieldApi(spaceId, item.id, item)
                .then(() => {})
                .catch((err) => {
                    notify('error', err.response.data.msg);
                });
        }
        setTest(() => ({ ...test, criterion: result }));
    };

    const addDescription = (index) => {
        let result = [...test.criterion];
        result[index].existDescription = true;
        setTest(() => ({ ...test, criterion: result }));
    };

    const toggleSettingTest = () => setIsOpen(!isOpen);
    const handleChangeSwitch = (name) =>
        setSwitches({ ...switches, [name]: !switches[name] });

    const changeTypeBlock = (data, type) => {
        let result = [...test.criterion];
        const newData = {
            ...data,
            type,
        };
        changeTypeBlockApi(newData, spaceId, data.id).then(() => {
            result = result.map((x) =>
                x.id === data.id
                    ? {
                          ...x,
                          type,
                          variants: x.variants.map((el) => ({
                              ...el,
                              correctChoice: false,
                          })),
                      }
                    : x
            );
            setTest(() => ({ ...test, criterion: result }));
            notify('success', t('NOTIFY_SUCCESSFULLY_CHANGED'));
        });
    };

    const removeField = (id, index) => {
        let result = [...test.criterion];
        removeFieldApi(spaceId, id).then(() => {
            result[index].variants = result[index].variants.filter(
                (x) => x.id !== id
            );
            setTest(() => ({ ...test, criterion: result }));
            notify('success', t('NOTIFY_SUCCESSFULLY_DELETED'));
        });
    };

    const modalType = (type) => {
        setModal({ ...modal, type, open: true });
    };

    const checkModalTest = (resultPercent, minAcceptance) => {
        if (Object.keys(test.nextRoute || {}).length) {
            if (timeIsOver && minAcceptance > 0 && test.attemptsQty) {
                return 'timeSpentOne';
            }
            if (timeIsOver && !minAcceptance && !test.attemptsQty) {
                return 'timeSpentTwo';
            }
            if (timeIsOver && resultPercent < minAcceptance) {
                return 'timeSpentThree';
            }
        }

        if (!minAcceptance) {
            return 'success';
        }
        if (resultPercent && minAcceptance) {
            if (
                !Object.keys(test.nextRoute).length &&
                resultPercent >= minAcceptance
            ) {
                return 'completed';
            }
            if (resultPercent >= minAcceptance) {
                return 'success';
            }
            if (resultPercent < minAcceptance) {
                return 'failed';
            }
        }
        if (!Object.keys(test.nextRoute || {}).length && resultPercent > 0) {
            return 'completed';
        }
        if (resultPercent !== 0) {
            return 'success';
        }
        if (resultPercent === 0) {
            return 'failed';
        }

        return 'failed';
    };
    const completeTestStudent = () => {
        studentTesting({ spaceId, test_id, body: test.criterion })
            .then((result) => {
                setResultPercentAction(result.percent);
                modalType(
                    checkModalTest(result.percent, Number(test.minAcceptance))
                );
                setTest(() => ({
                    ...test,
                    nextRoute: result.nextRoute,
                    criterion: result.result,
                }));
            })
            .catch((err) => {
                console.log('error Response', err.response);
                console.log('err Data', err.data);
                notify(
                    'error',
                    err.response.data.detail ||
                        err.response?.data?.error ||
                        err.response?.error
                );
            });
    };
    const saveSettingTest = () => {
        const data = {
            ...settingTest,
            random: switches.random,
            consider: switches.consider,
        };
        for (let key in data) {
            if (data[key] === null || data[key] === '') {
                delete data[key];
            }
        }
        saveSettingTestApi({ spaceId, id: test_id, body: data }).then(() => {
            notify('success', t('NOTIFY_SUCCESSFULLY_SAVED'));
        });
    };
    return {
        onDrop,
        addBlock,
        updateTest,
        addField,
        onChangeBlockInfo,
        duplicateBlock,
        handleChangeField,
        handleChangeLabel,
        addDescription,
        toggleSettingTest,
        handleChangeSwitch,
        deleteBlock,
        changeTypeBlock,
        saveSettingTest,
        removeField,
        completeTestStudent,
        updateTestName,
    };
};

export const blockSettingsData = ({
    duplicateBlock,
    addDescription,
    item,
    deleteBlock,
    spaceId,
    changeTypeBlock,
    index,
}) => {
    const changeType =
        item.type === 'single-choice' ? 'multiple-choice' : 'single-choice';
    return [
        {
            title: 'MENTOR_TEST_QUESTION_ADD_DESCRIPTION',
            type: 'single-choice',
            handler: () => addDescription(index),
            icon: <Icon name="description" />,
            isShow: true,
        },
        {
            title:
                item.type === 'single-choice'
                    ? 'MENTOR_TEST_QUESTION_CHANGE_TO_SOME_RIGHT_ANSWERS'
                    : 'MENTOR_TEST_QUESTION_CHANGE_TO_ONE_RIGHT_ANSWER',
            type: 'another-choice',
            handler: () => changeTypeBlock(item, changeType),
            icon: <Icon name="radio-frame" />,
            isShow: true,
        },
        {
            title: 'MENTOR_TEST_QUESTION_DUPLICATE',
            type: 'multiple-choice',
            icon: <Icon name="duplicate" />,
            handler: () => duplicateBlock(item, index),
            isShow: true,
        },
        {
            title: 'MENTOR_TEST_QUESTION_REMOVE',
            type: 'remove',
            icon: <Icon name="remove-square" />,
            handler: () => deleteBlock(item),
            isShow: true,
        },
    ];
};
export const moduleTypeUrl = ({ id, courseId, type, spaceId }) => {
    const urls = {
        test: `/content/course/${courseId}/student/test/${id}/space/${spaceId}?testStudent`,
        lesson: `/content/course/${courseId}/lesson/${id}/space/${spaceId}`,
        module: `/content/course/${courseId}/lesson/${id}/space/${spaceId}`,
    };
    return urls[type] || '#';
};
