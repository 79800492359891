import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { store } from 'store';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { BASE_URL_ANALYTICS_SERVICE } from '../../../configs';

export const analyticsApi = createApi({
    tagTypes: ['Analytics'],
    reducerPath: 'AnalyticsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL_ANALYTICS_SERVICE,
        prepareHeaders: (headers) => {
            headers.set(
                'Authorization',
                `Bearer ${store.getState().auth.token}`
            );
            return headers;
        },
    }),
    endpoints: (build) => ({
        fetchGeneralAnalytics: build.query({
            query: ({ spaceId }) => ({
                url: `/general-analytics/space:${spaceId}`,
            }),
        }),
        fetchReviewCoursesInfo: build.query({
            query: ({ spaceId, page, search }) => ({
                url: `/courses-info/space:${spaceId}?offset=${
                    page * 10
                }&limit=10&search=${search}`,
            }),
        }),
        fetchCoursesPopular: build.query({
            query: ({ spaceId, page }) => ({
                url: `/courses/popular/space:${spaceId}?offset=${
                    page * 10
                }&limit=10`,
            }),
        }),
        fetchStudents: build.query({
            query: ({ spaceId, page, search }) => ({
                url: `/students-info/space:${spaceId}?offset=${
                    page * 10
                }&limit=10&search=${search}`,
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                console.log({ baseQueryReturnValue });
                return {
                    items: baseQueryReturnValue.items.map((x) => {
                        return {
                            firstName: x.Student.first_name,
                            lastName: x.Student.last_name,
                            name:
                                x.Student.first_name +
                                ' ' +
                                x.Student.last_name,
                            average:
                                x.average_score === 0 ? '0' : x.average_score,
                            course_qty: x.course_qty,
                            email: x.Student.email,
                            joined: dayjs(x.Student.created_at)
                                .locale(i18next.language)
                                .format('DD MMMM'),
                            img: x.Student.avatar,
                            id: x.Student.id,
                        };
                    }),
                    total: baseQueryReturnValue.total_qty,
                };
            },
        }),
        fetchCourseInfoStudents: build.query({
            query: ({ spaceId, id, ...params }) => ({
                url: `/students-info/courses:${id}/space:${spaceId}`,
                params,
            }),
        }),
        fetchDetailCourse: build.query({
            query: ({ spaceId, id }) => ({
                url: `/course-detail:${id}/space:${spaceId}`,
            }),
        }),
        fetchDetailCourseLesson: build.query({
            query: ({ spaceId, id, ...params }) => ({
                url: `/lessons-info:${id}/space:${spaceId}`,
                params,
            }),
        }),
        fetchDetailCourseTest: build.query({
            query: ({ spaceId, id }) => ({
                url: `/tests-info:${id}/space:${spaceId}`,
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                console.log({ baseQueryReturnValue });
                return {
                    items: baseQueryReturnValue.items.map((x) => ({
                        name: x.Test.name,
                        average: x.average_score,
                        id: x.Test.id,
                    })),
                };
            },
        }),
        fetchDetailCourseTestStatistics: build.mutation({
            query: ({ spaceId, id }) => ({
                url: `/tests-statistics/detail:${id}/space:${spaceId}`,
            }),
        }),
        fetchStudentDetail: build.query({
            query: ({ spaceId, id }) => ({
                url: `/students-detail:${id}/space:${spaceId}`,
            }),
        }),
        fetchStudentDetailCourses: build.query({
            query: ({ spaceId, id, ...params }) =>
                console.log({ params }) || {
                    url: `/students:${id}/courses/space:${spaceId}`,
                    params,
                },
        }),
        fetchStudentDetailCoursesStatistics: build.query({
            query: ({ spaceId, courseId, studentId }) => ({
                url: `/course-detail:${courseId}/statistics/by-student:${studentId}/space:${spaceId}`,
            }),
        }),
        fetchStudentDetailTest: build.query({
            query: ({ spaceId, id, ...params }) => ({
                url: `/tests-info/student:${id}/space:${spaceId}`,
                params,
            }),
        }),
        fetchStudentDetailTestStatistics: build.query({
            query: ({ spaceId, id, studentId }) => ({
                url: `/test-detail:${id}/statistics/student:${studentId}/space:${spaceId}`,
            }),
        }),
        fetchGroups: build.query({
            query: ({ spaceId, page, search }) => ({
                url: `/groups/space:${spaceId}?offset=${
                    page * 10
                }&limit=10&search=${search}`,
            }),
        }),
        fetchGroupDetail: build.query({
            query: ({ spaceId, id }) => ({
                url: `/group-info/${id}/space:${spaceId}`,
            }),
        }),
        fetchGroupCourses: build.query({
            query: ({ spaceId, id }) => ({
                url: `/group:${id}/courses/space:${spaceId}`,
            }),
        }),
        fetchGroupStudents: build.query({
            query: ({ spaceId, id }) => ({
                url: `/group:${id}/students/space:${spaceId}`,
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                console.log({ baseQueryReturnValue });
                return baseQueryReturnValue.map((x) => {
                    return {
                        img: x.Student.avatar,
                        name: x.Student.first_name + ' ' + x.Student.last_name,
                        email: x.Student.email,
                        average: x.average_score || '4,56',
                        progress: x.progress || '77%',
                    };
                });
            },
        }),
    }),
});

export const {
    useFetchGroupStudentsQuery,
    useFetchGroupCoursesQuery,
    useFetchGroupDetailQuery,
    useFetchGroupsQuery,
    useFetchStudentDetailTestStatisticsQuery,
    useFetchStudentDetailCoursesStatisticsQuery,
    useFetchStudentDetailTestQuery,
    useFetchStudentDetailCoursesQuery,
    useFetchStudentDetailQuery,
    useFetchDetailCourseTestStatisticsMutation,
    useFetchDetailCourseTestQuery,
    useFetchDetailCourseLessonQuery,
    useFetchDetailCourseQuery,
    useFetchStudentsQuery,
    useFetchCoursesPopularQuery,
    useFetchGeneralAnalyticsQuery,
    useFetchReviewCoursesInfoQuery,
    useFetchCourseInfoStudentsQuery,
} = analyticsApi;
