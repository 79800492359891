import {
    createFIle,
    fetchDetailFile,
    massDeleteAPi,
    moveFileApi,
    removeFileAndFolderApi,
    removeFileApi,
} from 'pages/knowledge/services';
import { notify } from 'utils/functions/notify';
import {
    applyDrag,
    applyDragChild,
    moveToMainList,
} from 'utils/functions/DragDrop';
import { formatBytes } from 'helpers/functions';
import { nanoid } from 'nanoid';
export const dndFiles = ({
    setCurrentItem,
    success,
    setSuccess,
    currentItem,
    spaceId,
    folderFiles,
    currentIndex,
    setFolderFiles,
    isParent,
    setIsParent,
    setCurrentIndex,
    setInterfaceModal,
    setTypeModal,
    setItem,
    changeNameFileDebounce,
    resetIsSelected,
    setOpen,
    setAboutFile,
    setUploadPercent,
    uploadPercent,
    t,
}) => {
    const onDragEnd = (e, item) => {
        e.preventDefault();
        item.classList.remove('draggable-target');
    };

    const onDragLeave = (e, item) => {
        e.preventDefault();
        item.classList.remove('draggable-target');
    };
    const onDragOver = (e, item) => {
        item.classList.add('draggable-target');
    };

    const onChangeName = (id, value, isChildItem) => {
        if (isChildItem) {
            const index = isChildItem.index;
            const parentId = isChildItem.parentId;
            let findItem = folderFiles.find((x) => x.id === parentId);
            findItem.files[index].name = findItem.files[index].name = value;
            setFolderFiles((prev) =>
                [...prev].map((x) =>
                    x.id === findItem.id ? { ...x, files: findItem.files } : x
                )
            );
            changeNameFileDebounce(value, id);
        } else {
            setFolderFiles((prev) =>
                [...prev].map((x) => (x.id === id ? { ...x, name: value } : x))
            );
        }
    };

    const makeFile = (file, isRemove, responseItem) => {
        if (isRemove) {
            setFolderFiles((prev) =>
                (responseItem ? [responseItem, ...prev] : prev).filter(
                    (x) => x.id !== file.id
                )
            );
        } else {
            setFolderFiles((prev) => [file, ...prev]);
        }
    };

    const uploadFileCancel = (cancelFn, uploadId) => {
        setUploadPercent((prev) => ({
            ...prev,
            [uploadId]: { ...prev[uploadId], cancel: cancelFn },
        }));
    };

    const removeCanceledFile = (uploadId) => {
        let newArr = [...folderFiles];
        const newUploadPercent = { ...uploadPercent };
        delete newUploadPercent[uploadId];
        setUploadPercent(newUploadPercent);
        newArr = newArr.filter((x) => x.id !== uploadId);
        setFolderFiles(newArr);
    };

    const onUploadFile = async (e, folderId, id, input) => {
        for (let item of e) {
            await uploadItemFile({ folderId, file: item, id: nanoid(), input });
        }
    };
    const uploadItemFile = ({ folderId, file, id, input }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);
        formData.append('learning_space_id', 5);
        if (folderId) {
            formData.append('folder_id', folderId);
        }
        const tempFile = {
            name: file.name + '.' + file.type,
            isTemp: true,
            size: formatBytes(file.size),
            id,
            type: 'file',
        };

        makeFile(tempFile);
        return createFIle(
            spaceId,
            formData,
            setUploadPercent,
            id,
            tempFile,
            uploadFileCancel
        )
            .then((data) => {
                notify('success', t('NOTIFY_SUCCESSFULLY_CREATED'));
                let newUploadPercents = { ...uploadPercent };
                delete newUploadPercents[tempFile.id];
                setUploadPercent(newUploadPercents);
                makeFile(tempFile, true, data.results);
            })
            .catch((err) => {
                notify('error', err.response.data.results.msg);
                makeFile(tempFile, true);
            })
            .finally(() => {
                if (input) {
                    input.value = '';
                }
            });
    };
    const onDragStart = (e, item) => {
        setCurrentItem(item);
    };
    const onDrop = (e, item, element) => {
        e.preventDefault();
        const dragData = currentItem?.isChild
            ? applyDragChild(folderFiles, { item, currentItem })
            : applyDrag(folderFiles, { item, currentItem });
        moveFileApi(spaceId, dragData).then((result) => {
            notify('success', t('NOTIFY_SUCCESSFULLY_SAVED'));
            dragData.forEach((x) => {
                x?.files?.forEach((el) => delete el['isChild']);
            });
            setFolderFiles(dragData);
        });
        element.classList.remove('draggable-target');
    };

    const onDropChild = (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        const dragData = applyDragChild(folderFiles, { item, currentItem });
        setFolderFiles(dragData);
        e.target.classList.remove('draggable-target');
    };

    const handleClickModalInterface = (type, item) => {
        setInterfaceModal(true);
        setTypeModal(type);
        setItem(item);
    };

    const removeFileHandler = (id, item) => {
        (item.files.length
            ? removeFileAndFolderApi(spaceId, id)
            : removeFileApi(spaceId, id)
        ).then((result) => {
            notify('success', t('NOTIFY_SUCCESSFULLY_DELETED'));
            setSuccess(!success);
            setInterfaceModal(false);
        });
    };

    const massDeleteHandler = (obj) => {
        const stringIds = Object.keys(obj).join(',');
        massDeleteAPi(spaceId, stringIds).then(() => {
            notify('success', t('NOTIFY_SUCCESSFULLY_DELETED'));
            setSuccess(!success);
            resetIsSelected(obj);
        });
    };
    const onDragEnterParent = (e) => {
        const className = e.target.className;
        const isElemIndex = e.target.getAttribute('index');
        if (isElemIndex) {
            setCurrentIndex(Number(isElemIndex));
        } else {
            setCurrentIndex(null);
        }
        if (currentItem?.isChild && className === 'knowledge-base__folders') {
            setIsParent(true);
        } else {
            setIsParent(false);
        }
    };
    const onDragEndParent = (e) => {
        if (currentIndex !== null) {
            const newArr = moveToMainList(
                currentItem,
                folderFiles,
                currentIndex
            );
            moveFileApi(spaceId, newArr).then((result) => {
                notify('success', t('NOTIFY_SUCCESSFULLY_SAVED'));
                setFolderFiles(newArr);
            });
        } else {
            if (isParent) {
                const newArr = moveToMainList(currentItem, folderFiles);
                moveFileApi(spaceId, newArr).then((result) => {
                    notify('success', t('NOTIFY_SUCCESSFULLY_SAVED'));
                    setFolderFiles(newArr);
                });
            }
        }
    };
    const getDetailFile = (elementId) => {
        fetchDetailFile(elementId, spaceId).then((r) => {
            setAboutFile(r.results);
            setOpen(true);
        });
    };
    return {
        onDragEndParent,
        onDragEnd,
        removeFileHandler,
        handleClickModalInterface,
        onDragEnterParent,
        onDrop,
        onDragStart,
        onDragLeave,
        onDragOver,
        onUploadFile,
        removeCanceledFile,
        onDropChild,
        onChangeName,
        massDeleteHandler,
        getDetailFile,
    };
};
