import React from 'react';
import './Loader.scss';
const Loader = () => {
    return (
        <div className="loader">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle
                    cx="50"
                    cy="50"
                    r="30"
                    fill="transparent"
                    stroke-width="8px"
                    stroke-dasharray="160"
                />
            </svg>
        </div>
    );
};

export default Loader;
