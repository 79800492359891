import { Link, useLocation } from 'react-router-dom';
import { tabs } from './consts';
import {
    Icon,
    List,
    SegmentButton,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './CoursesHeading.scss';
import { useTranslation } from 'react-i18next';

const CoursesHeading = ({ onCreateCourse, setPage }) => {
    const { t } = useTranslation('common');
    const { search: locationSearch } = useLocation();
    const resetPage = () => setPage(1);
    return (
        <section className={'courses-heading'}>
            <List
                className={'courses-heading__tabs'}
                items={tabs}
                renderItem={({ id, icon, text, to, search = '' }) => (
                    <li key={id} className={'courses-heading__tab'}>
                        <Link
                            className={classNames('courses-heading__link', {
                                active:
                                    search ===
                                    (locationSearch.length > 0
                                        ? locationSearch
                                        : '?scope=active'),
                            })}
                            to={to}
                            onClick={resetPage}
                        >
                            <Icon name={icon} />
                            <Typography variant={'body4'}>{t(text)}</Typography>
                        </Link>
                    </li>
                )}
            />
            <SegmentButton
                className={'courses-heading__btn'}
                onClick={onCreateCourse}
                size={'small'}
            >
                <Icon name={'plus'} />
                <Typography variant={'body4'}>{t('NEW_COURSE')}</Typography>
            </SegmentButton>
        </section>
    );
};

export default CoursesHeading;
