import { createSlice } from '@reduxjs/toolkit';
import { notificationApi } from 'api/services';

const initialState = {
    isOpen: false,
    notifications: [],
    unread_qty: 0,
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        notificationToggle: (state) => {
            state.isOpen = !state.isOpen;
        },
        updateNotifications: (state, { payload }) => {
            state.notifications = payload;
        },
        addNotification: (state, { payload }) => {
            state.notifications.push(payload);
        },
        readNotification: (state, { payload }) => {
            state.notifications[payload].read = true;
        },
    },
    extraReducers: (build) => {
        build.addMatcher(
            notificationApi.endpoints.getNotifications.matchFulfilled,
            (state, { payload }) => {
                state.notifications = payload.notifies;
                state.unread_qty = payload.unread_qty;
            }
        );
    },
});

const notificationActions = notificationSlice.actions;
export { notificationActions };

export default notificationSlice.reducer;
