import React, { createContext, useContext } from 'react';

const PreviewContext = createContext();
export const usePreview = () => useContext(PreviewContext);

export const PREVIEW_STATUS = {
    active: 'active',
    draft: 'draft',
    preview: 'preview',
};

export const PreviewProvider = ({ children, previewStatus }) => {
    return (
        <PreviewContext.Provider value={previewStatus}>
            {children}
        </PreviewContext.Provider>
    );
};
