import { Helmet } from 'react-helmet-async';
import { SavingStatusProvider } from 'components/layout/SavingStatusContext';
import './ContentLayout.scss';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import getFaviconEl from 'pages/settings/common/SettingsCompany/SettingsCompanyFavIcon/getFaviconEl';
import { GlobalSettingsSpace } from 'components/feature';
import { useTranslation } from 'react-i18next';
import { isEyacademycca } from 'utils/functions/findDomainToRedirect';
const ContentLayout = ({ children, headerChildren }) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const isEY = useMemo(() => isEyacademycca(), []);

    useEffect(() => {
        if (userSpace.favicon) {
            const favicon = getFaviconEl();
            favicon.href = userSpace.favicon;
        }
    }, [userSpace.favicon]);
    return (
        <>
            <Helmet>
                <title>
                    {userSpace.siteName ||
                        (isEY ? t('HEAD_TITLE_EY') : t('HEAD_TITLE'))}
                </title>
            </Helmet>
            <div className={'content-layout'}>
                <SavingStatusProvider>{children}</SavingStatusProvider>
            </div>
        </>
    );
};

export default ContentLayout;
