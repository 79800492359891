import { useSelector } from 'react-redux';
import { useWindowSize } from 'helpers/hooks';
import SidebarMenu from './SidebarMenu';
import classNames from 'classnames';
import './Sidebar.scss';

const Sidebar = ({ isTestHeader }) => {
    const { isSidebarShow } = useSelector((state) => state.util);
    const { width } = useWindowSize();

    return (
        <aside
            className={classNames('sidebar', {
                'sidebar-small': isSidebarShow && width > 768,
            })}
        >
            <SidebarMenu />
        </aside>
    );
};

export default Sidebar;
