import Lottie from 'react-lottie';

const CustomLottie = ({
    lottie,
    width,
    height,
    loop = true,
    autoplay = true,
}) => {
    const defaultOptions = {
        loop: loop,
        autoplay: autoplay,
        animationData: JSON.parse(lottie),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return <Lottie options={defaultOptions} height={width} width={height} />;
};

export default CustomLottie;
