const confirmationForm = [
    {
        id: 'confirmationPassword',
        type: 'password',
        name: 'password',
        label: 'PASSWORD',
        isError: true,
    },
    {
        id: 'confirmationSecondPassword',
        type: 'password',
        name: 'second_password',
        label: 'PROFILE_REENTER_PASSWORD',
    },
];

export { confirmationForm };
