import { moduleTypeUrl } from 'pages/course-test/functions';
import { notify } from 'utils/functions';

export const courseInfoProgress = ({
    startCourse,
    startCourseApi,
    proceed = {},
    testId,
    spaceId,
    proceedCourse,
    refetch,
    t,
}) => {
    const startIsBtn = startCourse && !Object.keys(proceed).length;
    const continueIsBtn = Object.keys(proceed).length;
    return [
        {
            isShow: startIsBtn,
            title: t('STUDENT_COURSE_START'),
            handler: () =>
                startCourseApi({ testId, spaceId })
                    .unwrap()
                    .then(() => refetch())
                    .catch((e) => {
                        notify('error', e.data.error);
                    }),
        },
        {
            isShow: continueIsBtn,
            title: t('STUDENT_COURSE_CONTINUE'),
            handler: proceedCourse,
        },
    ];
};

export const courseModuleItemState = ({ isAvailable, isPassed }) => {
    if (!isAvailable && !isPassed) return 'block';
    if (isAvailable && isPassed) return 'checked';
    return 'play-course';
};

export const calculateCoursePassed = ({ qtyMaterials, qtyPassed }) => {
    return isNaN(parseInt((qtyPassed * 100) / qtyMaterials))
        ? 0
        : parseInt((qtyPassed * 100) / qtyMaterials);
};

export const moduleStateBtn = ({
    saveProgressCourse,
    redirectTo,
    id,
    type,
    spaceId,
    courseId,
    moduleType,
    moduleId,
    finish,
    t,
}) => {
    const body = {
        type: moduleType,
        id: moduleId,
    };
    return {
        nextLesson: {
            title: t('STUDENT_LESSON_NEXT'),
            handler: () => {
                saveProgressCourse({ spaceId, body }).then(() => {
                    const url = moduleTypeUrl({ id, courseId, type, spaceId });
                    redirectTo(url);
                });
            },
        },
        nextTest: {
            title: t('GO_TO_TEST'),
            handler: () => {
                saveProgressCourse({ spaceId, body }).then(() => {
                    const url = moduleTypeUrl({ id, courseId, type, spaceId });
                    redirectTo(url);
                });
            },
        },
        nextModule: {
            title: t('STUDENT_MODULE_NEXT'),
            handler: () => {
                saveProgressCourse({ spaceId, body }).then(() => {
                    const url = moduleTypeUrl({ id, courseId, type, spaceId });
                    redirectTo(url);
                });
            },
        },
        complete: {
            title: t('STUDENT_COURSE_FINISH'),
            handler: finish,
        },
    };
};

const typeObj = {
    lesson: 'nextLesson',
    test: 'nextTest',
};

export const nextOrCompletedCourseBtn = ({
    nextRoute,
    saveProgressCourse,
    redirectTo,
    spaceId,
    courseId,
    finish,
    moduleType,
    moduleId,
    t,
}) => {
    if (!nextRoute || !Object.keys(nextRoute).length) {
        return moduleStateBtn({ courseId, finish, t })['complete'];
    }

    const { currentParent, nextParent, id, type } = nextRoute;
    const checkType = moduleStateBtn({
        saveProgressCourse,
        redirectTo,
        id,
        moduleType,
        moduleId,
        type,
        spaceId,
        courseId,
        t,
    })[typeObj[type]];
    if (nextParent !== currentParent && nextParent) {
        return moduleStateBtn({
            saveProgressCourse,
            redirectTo,
            id,
            type,
            moduleType,
            moduleId,
            spaceId,
            courseId,
            t,
        })['nextModule'];
    }
    if (typeObj[type]) {
        return checkType;
    }
};
