import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFetchCourseMutation } from 'api/services';
import useEditor from 'helpers/hooks/useEditor';
import { useParams } from 'react-router-dom';
import { Editor } from 'components/common';
import cn from 'classnames';
import CreateCourseCover from 'pages/courses/Course/create-course/CreateCourseCover';
import { TextareaAutosize } from '@mui/material';
import { getEditorJsTools, getTools } from 'components/common/Editor/constants';
import CreateCourseFiles from 'pages/courses/Course/create-course/CreateCourseFiles/CreateCourseFiles';
import CreateCourseContent from 'pages/courses/Course/create-course/CreateCourseContent/CreateCourseContent';
import './index.scss';
import { MaterialsContextProvider } from 'components/layout/MaterialsContext/MaterialsContext';

const Course = () => {
    const { t } = useTranslation('common');
    const { course } = useSelector((state) => state.course);
    const [fetchCourse, { isSuccess }] = useFetchCourseMutation();

    const { childRef, outerSave, onEditorChange } = useEditor({});

    const { courseId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const sameCourse = useMemo(
        () => courseId === course?.id,
        [course?.id, courseId]
    );
    const { ready } = useSelector((state) => state.editor);
    useEffect(() => {
        if (courseId !== course?.id) {
            fetchCourse({ spaceId: userSpace?.id, courseId });
        }
    }, []);

    return (
        (isSuccess || sameCourse) && (
            <div className={'layout-main'}>
                <div
                    className={cn('course course--active', {
                        'course--ready': (isSuccess || sameCourse) && ready,
                    })}
                >
                    <CreateCourseCover isActionHide={true} />
                    <div className={'course__title-wrapper'}>
                        <TextareaAutosize
                            disabled
                            name={'name'}
                            onChange={() => {}}
                            className="course__title"
                            type="text"
                            placeholder={t('ENTER_COURSE_NAME')}
                            value={course?.name}
                        />
                    </div>
                    <Editor
                        readOnly
                        tools={getTools(
                            [
                                'embedPDF',
                                'embedDOC',
                                'header',
                                'strikethrough',
                                'embed',
                                'list',
                                'linkTool',
                                'quote',
                                'underline',
                                'delimiter',
                                'customMarker',
                                'Color',
                                'toggleList',
                                'audio',
                                'image',
                                'table',
                                'video',
                                'attaches',
                            ],
                            getEditorJsTools(t, userSpace?.id, courseId)
                        )}
                        courseId={courseId}
                        ref={childRef}
                        onSave={() => {}}
                        outsideSave={outerSave}
                        initialData={course}
                        onChange={onEditorChange}
                        placeholderOnEmpty={'ENTER_COURSE_DESCRIPTION'}
                    />
                    <CreateCourseFiles
                        additionalMaterials={course?.educationalMaterials}
                    />
                    <MaterialsContextProvider
                        materials={course.materials}
                        isReadOnly={true}
                    >
                        <CreateCourseContent materials={course.materials} />
                    </MaterialsContextProvider>
                </div>
            </div>
        )
    );
};

export default Course;
