import { createSlice } from '@reduxjs/toolkit';
import { courseApi } from 'api/services';

const initialState = {
    courses: {
        results: [],
        count: 0,
        hasMore: false,
    },
};

const deleteReducer = (state, { meta, payload }) => {
    const { courseId } = meta.arg.originalArgs;
    state.courses.results = state.courses.results.filter(
        (course) => course.id !== courseId
    );
    state.courses.count -= 1;
};

export const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setCourses: (state, { payload }) => {
            state.courses = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                courseApi.endpoints.fetchCourses.matchFulfilled,
                (state, { payload }) => {
                    state.courses = payload;
                }
            )
            .addMatcher(
                courseApi.endpoints.deleteCourseAfter.matchFulfilled,
                deleteReducer
            )
            .addMatcher(
                courseApi.endpoints.archiveCourse.matchFulfilled,
                deleteReducer
            )
            .addMatcher(
                courseApi.endpoints.duplicateCourse.matchFulfilled,
                (state, { meta, payload }) => {
                    const { id: newCourseId } = payload;
                    const { courseId, status } = meta.arg.originalArgs;
                    if (status !== 'active') {
                        const currentCourse = state.courses.results.find(
                            (course) => course.id === courseId
                        );
                        state.courses.results = [
                            {
                                ...currentCourse,
                                id: newCourseId,
                            },
                            ...state.courses.results,
                        ];
                        state.courses.count += 1;
                    }
                }
            )
            .addMatcher(
                courseApi.endpoints.publishCourseWithMaterials.matchFulfilled,
                deleteReducer
            )
            .addMatcher(
                courseApi.endpoints.deleteCourseCancel.matchFulfilled,
                (state, { meta, payload }) => {
                    const { courseId } = meta.arg.originalArgs;
                    state.courses.results = state.courses.results.map(
                        (course) => {
                            if (course.id === courseId) {
                                return {
                                    ...course,
                                    remained: null,
                                    status: 'inactive',
                                };
                            } else {
                                return course;
                            }
                        }
                    );
                }
            )
            .addMatcher(
                courseApi.endpoints.deleteCourse.matchFulfilled,
                deleteReducer
            );
    },
});

const coursesActions = coursesSlice.actions;
export { coursesActions };

export default coursesSlice.reducer;
