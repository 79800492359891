import React from 'react';
import {
    Heading,
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { tabs } from 'pages/courses/Course/create-course-settings/CreateCourseSettingsMenu/consts';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CreateCourseSettingsBottomSheetMenu.scss';

const CreateCourseSettingsBottomSheetMenu = ({ courseId }) => {
    const { t } = useTranslation('common');
    return (
        <>
            <Heading
                variant={'h4'}
                className={'create-course-settings-bottom-sheet-menu__title'}
            >
                {t('SETTINGS')}
            </Heading>
            <List
                className={'create-course-settings-bottom-sheet-menu__list'}
                items={tabs({ courseId })}
                renderItem={({ id, icon, text, search, to, isDivider }) => (
                    <li
                        key={id}
                        className={
                            'create-course-settings-bottom-sheet-menu__item'
                        }
                    >
                        <Link
                            to={to}
                            className={
                                'create-course-settings-bottom-sheet-menu__link'
                            }
                        >
                            <Icon name={icon} />
                            <Typography
                                className={
                                    'create-course-settings-bottom-sheet-menu__text'
                                }
                                variant={'body4'}
                            >
                                {t(text)}
                            </Typography>
                        </Link>
                    </li>
                )}
            />
        </>
    );
};

export default CreateCourseSettingsBottomSheetMenu;
