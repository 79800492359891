import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
    useFetchUserGroupQuery,
    useRemoveUserGroupMutation,
} from 'api/services';
import './GroupDetail.scss';
import {
    ChangeGroupTitleModal,
    GroupDetailHeading,
    GroupDetailInfo,
} from 'pages/users/common';
import { renderModal } from 'pages/users/views/group-detail/consts';
import { useLocationScope } from 'helpers/hooks';

const GroupDetail = () => {
    const navigate = useNavigate();
    const scope = useLocationScope();
    const { id: groupId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { isSuccess, data: group } = useFetchUserGroupQuery(
        { spaceId: userSpace?.id, groupId },
        { skip: !userSpace || !groupId }
    );
    const [removeUserGroup] = useRemoveUserGroupMutation();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenChangeTitleModal, setIsOpenChangeTitleModal] = useState(false);

    const onOpenChangeTitle = () => setIsOpenChangeTitleModal(true);

    const onRemove = async () => {
        const { error } = await removeUserGroup({
            spaceId: userSpace.id,
            groupId,
        });
        if (!error) {
            navigate(-1);
        }
    };

    if (!isSuccess) {
        return null;
    }
    return (
        <article className={'layout-main group-detail-wrapper'}>
            <div className={'group-detail'}>
                <GroupDetailInfo
                    title={group.title}
                    accessCourses={group.accessCourses}
                    qtyParticipants={group.qtyParticipants}
                    onRemove={onRemove}
                    onOpenChangeTitle={onOpenChangeTitle}
                />
                <GroupDetailHeading />
                <Outlet context={[isOpen, setIsOpen]} />
            </div>
            {isOpenChangeTitleModal ? (
                <ChangeGroupTitleModal
                    isOpen={isOpenChangeTitleModal}
                    setIsOpen={setIsOpenChangeTitleModal}
                    groupId={groupId}
                />
            ) : null}
            {isOpen ? renderModal({ isOpen, setIsOpen })[scope] : null}
        </article>
    );
};

export default GroupDetail;
