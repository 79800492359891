import React, { useState } from 'react';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import {
    analyticsDetailTestHeading,
    analyticsDetailTestTable,
} from 'pages/analytics/views/courses/DetailCourses/consts';
import TestStatistics from 'pages/analytics/views/courses/DetailCourses/views/test/TestStatistics';
import DialogModalCustom from 'components/common/DialogModalCustom/DialogModalCustom';
import { useParams } from 'react-router-dom';
import {
    useFetchDetailCourseTestQuery,
    useFetchDetailCourseTestStatisticsMutation,
} from 'api/services/AnalyticsService/AnalyticsService';
import { useSelector } from 'react-redux';

const Test = () => {
    const [showDetail, setShowDetail] = useState(false);
    const [testInfo, setTestInfo] = useState({});
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);

    const [fetchDetailCourseTestStatistics] =
        useFetchDetailCourseTestStatisticsMutation();

    const { data } = useFetchDetailCourseTestQuery({
        spaceId: userSpace?.id,
        id,
    });

    const handler = (item) => {
        fetchDetailCourseTestStatistics({
            spaceId: userSpace?.id,
            id: item.id,
        }).then((result) => {
            setTestInfo((prev) => ({
                ...prev,
                title: item.name,
                data: result.data,
            }));
        });
        setShowDetail(true);
    };
    return (
        <section className="analytics-test">
            <AnalyticsTable
                withoutBorder={true}
                isClickable
                testMobileTable
                handler={handler}
                headings={analyticsDetailTestHeading}
                body={analyticsDetailTestTable(data?.items)}
            />
            <DialogModalCustom
                customHeight={window.innerHeight}
                open={showDetail}
                setOpen={setShowDetail}
            >
                <TestStatistics data={testInfo?.data} title={testInfo?.title} />
            </DialogModalCustom>
        </section>
    );
};

export default Test;
