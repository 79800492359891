import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { BottomModalWrapper } from 'pages/users/common';

const GroupBottomModal = ({ count, maxCount, onClear, onRemove }) => {
    return (
        <BottomModalWrapper count={count} maxCount={maxCount} onClear={onClear}>
            {/*<Button variant={'tertiary'} size={'small'} onClick={onRemove}>*/}
            {/*    Удалить группы*/}
            {/*</Button>*/}
        </BottomModalWrapper>
    );
};

export default GroupBottomModal;
