import { useRef } from 'react';
import useOutsideClick from 'helpers/hooks/useOutsideClick';
import { useDebouncedCallback } from 'use-debounce';
import { useKey } from 'helpers/hooks/useKey';

export default function useEditor({
    onChangeDebounceTime = 0,
    saveOnCTRLS = true,
    isDisabled = false,
}) {
    const childRef = useRef(null);
    const outerSave = async () => {
        if (isDisabled) return;
        childRef.current?.saveRef();
    };

    const wrapperRef = useOutsideClick(() => outerSave());
    const onEditorChange = useDebouncedCallback(async () => {
        await outerSave();
    }, onChangeDebounceTime);

    useKey('ctrls', () => {
        if (saveOnCTRLS) {
            outerSave().then();
        }
    });

    return {
        childRef,
        wrapperRef,
        onEditorChange,
        outerSave,
    };
}
