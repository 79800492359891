import SettingsCard from 'pages/settings/common/SettingsCard';
import { useMemo, useState } from 'react';
import { Input } from '@akhter-studios/classlytics-front-ui-components';
import { useDebounce } from 'helpers/hooks';
import './SettingsCompanyAppName.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useUpdateSpaceEffect from 'pages/settings/useUpdateSpaceEffect';
import { isEyacademycca } from 'utils/functions/findDomainToRedirect';
const SettingsCompanyAppName = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [title, setTitle] = useState(userSpace?.siteName);
    const debouncedTitle = useDebounce(title, 700);
    const onChangeTitle = async (e) => await setTitle(e.target.value);

    const { isError, error } = useUpdateSpaceEffect({
        field: title,
        fieldName: 'siteName',
        fieldRequestName: 'site_name',
        debouncedField: debouncedTitle,
    });
    const isEY = useMemo(() => isEyacademycca(), []);
    return (
        <SettingsCard
            className={'settings-company-app-name'}
            title={t('SETTINGS_SITE_NAME_TITLE')}
            description={t('SETTINGS_SITE_NAME_DESC')}
            isDownloadBtn={false}
            isRemoveBtn={false}
            isError={isError}
            errorText={error?.data['siteName']}
        >
            <Input
                groupClassName={'settings-company-app-name__input'}
                value={title ?? (isEY ? t('HEAD_TITLE_EY') : t('HEAD_TITLE'))}
                onChange={onChangeTitle}
            />
        </SettingsCard>
    );
};

export default SettingsCompanyAppName;
