import { testActions } from 'store/reducers/testSlice';
import { authActions } from 'store/reducers/authSlice';
import { editorActions } from 'store/reducers/editorSlice';
import { courseActions } from 'store/reducers/courseSlice';
import { userStudentActions } from 'store/reducers/userStudentSlice';
import { userMentorActions } from 'store/reducers/userMentorSlice';
import { userGroupActions } from 'store/reducers/userGroupSlice';
import { notificationActions } from 'store/reducers/notificationSlice';
import { coursesActions } from 'store/reducers/coursesSlice';
import { settingSpaceActions } from 'store/reducers/settingSpaceSlice';
import {lessonActions} from "store/reducers/lessonSlice";

const actions = {
    ...authActions,
    ...editorActions,
    ...testActions,
    ...courseActions,
    ...lessonActions,
    ...userStudentActions,
    ...userMentorActions,
    ...userGroupActions,
    ...notificationActions,
    ...coursesActions,
    ...settingSpaceActions,
};

export default actions;
