import { useLocation } from 'react-router-dom';
import { userRender } from 'pages/users/functions';
import {
    Heading,
    Icon,
    MoreButton,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './UsersTitle.scss';
import { useLocationScope, useWindowSize } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';

const UsersTitle = ({ onOpen }) => {
    const { width } = useWindowSize();
    const { t } = useTranslation('common');
    const scope = useLocationScope();
    return (
        <section className={'users-title'}>
            <Heading className={'users-title__name'} variant={'h1'}>
                {t(userRender({ search: scope, node: 'title' }))}
            </Heading>
            {/*<MoreButton className={"users-title__btn"} disabled={true}>*/}
            {/*    <Typography variant={"body4"}>Импорт студентов csv</Typography>*/}
            {/*    <Icon name={"table"}/>*/}
            {/*</MoreButton>*/}
            {width < 476 ? (
                <MoreButton className={'users-title__more'} onClick={onOpen}>
                    <Typography variant={'body4'}>
                        {t(
                            userRender({ search: scope, node: 'mobileBtnText' })
                        )}
                    </Typography>
                    <Icon name={'plus'} />
                </MoreButton>
            ) : null}
        </section>
    );
};

export default UsersTitle;
