import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useResetPasswordMutation } from 'api/services';
import { emailSchema } from 'utils/schemas';
import { AUTH_LOGIN_ROUTE, AUTH_VERIFICATION_ROUTE } from 'utils/consts';
import { Input } from '@akhter-studios/classlytics-front-ui-components';
import { AuthButton, AuthError, AuthLink } from 'components/feature';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';

const ForgotForm = () => {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();
    const [resetPassword, { isLoading, isError, error }] =
        useResetPasswordMutation();
    const { hostname } = window.location;
    const onSubmit = async (values) => {
        const { email } = values;
        const { error } = await resetPassword({
            email: email.toLowerCase(),
            lang: i18n.language,
            oriDomain: hostname,
        });
        if (!error) {
            navigate({
                pathname: AUTH_VERIFICATION_ROUTE,
                search: `?email=${email}`,
            });
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
    } = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: emailSchema(t),
        onSubmit,
    });
    return (
        <>
            {isError && <AuthError error={error.data?.detail} />}
            <form className={'auth-form'} onSubmit={handleSubmit}>
                <Input
                    id={'forgotEmail'}
                    name={'email'}
                    type={'email'}
                    label={t('ENTER_EMAIL')}
                    placeholder={'login@mail.com'}
                    value={values.email}
                    error={touched['email'] && Boolean(errors.email)}
                    helperText={touched['email'] && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <AuthButton
                    isLoading={isLoading}
                    isValid={isValid}
                    text={t('AUTH_FORGOT_PASSWORD_BTN')}
                />
                <AuthLink
                    text={t('AUTH_FORGOT_PASSWORD_LINK')}
                    linkText={t('AUTH_LOGIN')}
                    link={AUTH_LOGIN_ROUTE}
                />
            </form>
        </>
    );
};

export default ForgotForm;
