import { useFormik } from 'formik';
import { notify } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    useAddCourseTagsMutation,
    useLazyFetchCourseSimilarTagsQuery,
    useUpdateCourseMutation,
} from 'api/services';
import {
    currentComplexityLabel,
    options,
} from 'pages/courses/Course/create-course-settings/consts';
import {
    Button,
    Heading,
} from '@akhter-studios/classlytics-front-ui-components';
import {
    CustomSelect,
    OptionOutsideAsyncCreatableSelect,
} from 'components/custom-react-select';
import './CreateCourseSettingsAboutCourseForm.scss';
import { courseSettingsAboutCourseSchema } from 'utils/schemas';
import { useCallbackPrompt } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { WarnModal } from 'components/common';

const CreateCourseSettingsAboutCourseForm = ({
    complexity = '',
    tags = [],
}) => {
    const { t } = useTranslation('common');
    const { courseId } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const [trigger] = useLazyFetchCourseSimilarTagsQuery();
    const [updateCourse, { isLoading: isUpdateCourseLoading }] =
        useUpdateCourseMutation();
    const [addCourseTags, { isLoading: isAddCourseTagsLoading }] =
        useAddCourseTagsMutation();
    const [showDialog, setShowDialog] = useState(false);
    const { showPrompt, setShowPrompt, confirmNavigation, cancelNavigation } =
        useCallbackPrompt(showDialog);
    const onSubmit = async (values) => {
        setShowDialog(false);
        const { complexity, tags } = values;
        const newTags = tags.map((t) => t.value);
        await updateCourse({
            spaceId: userSpace.id,
            courseId,
            complexity: complexity.value,
        });
        await addCourseTags({
            spaceId: userSpace.id,
            course_id: courseId,
            tags: newTags,
        });
        notify('success', t('NOTIFY_SUCCESSFULLY_SAVED'));
    };

    const { values, errors, touched, handleSubmit, handleBlur, setFieldValue } =
        useFormik({
            initialValues: {
                tags,
                complexity: {
                    label: '',
                    value: '',
                },
            },
            validateOnBlur: true,
            validationSchema: courseSettingsAboutCourseSchema,
            onSubmit,
        });

    useEffect(() => {
        if (complexity) {
            setFieldValue('complexity', {
                label: t(currentComplexityLabel[complexity]),
                value: complexity,
            });
        }
    }, [complexity]);

    useEffect(() => {
        if (tags.length > 0) {
            setFieldValue('tags', tags);
        }
    }, [tags]);

    const loadTags = async (searchValue, callback) => {
        if (searchValue) {
            const res = await trigger({
                spaceId: userSpace?.id,
                similar: searchValue,
            });
            const newCourseTags = res.data;
            callback(newCourseTags);
        }
    };
    const onChangeTags = (selectedOptions) => {
        setFieldValue('tags', selectedOptions);
        setShowDialog(true);
    };
    const onChangeComplexity = (selectedOption) => {
        setFieldValue('complexity', selectedOption);
        setShowDialog(true);
    };
    return (
        <>
            <form
                className={'create-course-settings-about-course'}
                onSubmit={handleSubmit}
            >
                <Heading
                    className={'create-course-settings-about-course__title'}
                    variant={'h2'}
                >
                    {t('COURSE_SETTINGS')}
                </Heading>
                <CustomSelect
                    className={'create-course-settings-about-course__field'}
                    name={'complexity'}
                    placeholder={t('COURSE_SETTINGS_COMPLEXITY')}
                    options={options(t)}
                    value={values.complexity}
                    isError={!!(touched.complexity && errors.complexity)}
                    helperText={t(errors.complexity?.value)}
                    onChange={onChangeComplexity}
                    onBlur={() => {
                        handleBlur({ target: { name: 'complexity' } });
                    }}
                />
                <OptionOutsideAsyncCreatableSelect
                    className={'create-course-settings-about-course__field'}
                    name={'tags'}
                    placeholder={t('COURSE_SETTINGS_TAGS')}
                    iconName={'search'}
                    loadOptions={loadTags}
                    defaultOptions
                    isMulti
                    isClearable={false}
                    value={values.tags}
                    isError={!!(touched.tags && errors.tags)}
                    helperText={t(errors.tags)}
                    onChange={onChangeTags}
                    onBlur={() => {
                        handleBlur({ target: { name: 'tags' } });
                    }}
                />
                <Button
                    className={'create-course-settings-about-course__btn'}
                    variant={'primary'}
                    size={'small'}
                    loading={isUpdateCourseLoading || isAddCourseTagsLoading}
                    disabled={
                        isUpdateCourseLoading ||
                        isAddCourseTagsLoading ||
                        Object.keys(errors).length !== 0
                    }
                    fullWidth
                >
                    {t('SAVE')}
                </Button>
            </form>
            <WarnModal
                isOpen={showPrompt}
                setIsOpen={setShowPrompt}
                onClose={() => {
                    cancelNavigation();
                    setShowDialog(false);
                }}
                onConfirm={async () => {
                    confirmNavigation();
                    await onSubmit(values);
                }}
            />
        </>
    );
};

export default CreateCourseSettingsAboutCourseForm;
