import {memo} from "react";
import {Link} from "react-router-dom";
import {Typography} from "@akhter-studios/classlytics-front-ui-components";
import "./AuthLink.scss";

const AuthLink = ({text, linkText, link}) => {
    return (
        <div className={"auth-link"}>
            <Typography className={"auth-link__text"} variant={"body3"}>
                {text}
            </Typography>
            <Typography className={"auth-link__link"} variant={"body3"}>
                <Link to={link}>
                    {linkText}
                </Link>
            </Typography>
        </div>
    );
};

export default memo(AuthLink);
