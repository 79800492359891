import React, { useRef, useState } from 'react';
import { Icon } from 'components/common';
import CourseContentItem from 'pages/courses/CoursesDetailed/CourseContentItem';
import { TextareaAutosize } from '@mui/material';

const CourseContentItemToggle = ({ name, description, children, id }) => {
    const [show, setShow] = useState(false);
    return (
        <div className="courses-detailed-content-toggle" key={id}>
            <div
                className="courses-detailed-content-toggle__title"
                onClick={() => setShow(!show)}
            >
                <div>{name || 'Пусто'}</div>
                <Icon name={!show ? 'arrow-down' : 'arrow-top'} />
            </div>
            {show && (
                <div className="courses-detailed-content-toggle__items">
                    {description && (
                        <TextareaAutosize
                            name={'name'}
                            className="textarea-cls"
                            style={{ marginBottom: '20px' }}
                            type="text"
                            value={description}
                        />
                    )}
                    {children.map((x, idx) => (
                        <CourseContentItem {...x} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CourseContentItemToggle;
