import React, { useState } from 'react';

const useSaveData = ({ isGrouped } = {}) => {
    const [data, setData] = useState({});
    const handleChange = (item) => {
        let result = { ...data };
        if (result[item.id]) {
            delete result[item.id];
        } else {
            result[item.id] = item;
        }
        setData(result);
    };

    const handleChangeGrouped = (parentItem, item) => {
        let result = { ...data };
        if (result[parentItem.id]?.[item.id]) {
            console.log({ parentItem: result[parentItem.id] });
            delete result[parentItem.id][item.id];
            if (!Object.keys(result[parentItem.id]).length) {
                delete result[parentItem.id];
            }
        } else {
            result[parentItem.id] = {
                ...result[parentItem.id],
                [item.id]: item,
            };
        }
        setData(result);
    };

    const resetIsSelected = (obj) => {
        let result = { ...data };
        Object.keys(obj).forEach((x) => delete result[x.id]);
        setData(result);
    };
    const reset = () => setData({});
    return [
        data,
        isGrouped ? handleChangeGrouped : handleChange,
        reset,
        resetIsSelected,
    ];
};

export default useSaveData;
