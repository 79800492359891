import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import ForgotForm from './ForgotForm';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';

const Forgot = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <Heading className={'auth-title'} variant={'h1'}>
                {t('AUTH_FORGOT_PASSWORD_TITLE')}
            </Heading>
            <ForgotForm />
        </>
    );
};

export default Forgot;
