import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TableHeads = ({ headings }) => {
    const { t } = useTranslation('common');
    return (
        <TableHead>
            <TableRow>
                {Object.keys(headings).map((heading, index) => {
                    return (
                        <TableCell
                            size="small"
                            align={!index ? 'left' : 'right'}
                            style={{ width: !index ? '300px' : '' }}
                            key={index}
                        >
                            <div>{t(headings[heading])}</div>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default TableHeads;
