import { useLocation } from 'react-router-dom';

const useLocationScope = () => {
    const { pathname } = useLocation();
    const pathNameArr = pathname.split('/');
    const scope = pathNameArr[pathNameArr.length - 1];

    return scope;
};

export default useLocationScope;
