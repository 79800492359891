import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { getSameMaterialsLength, materialOrder } from 'helpers/functions';
import cn from 'classnames';
import { Icon } from 'components/common';
import CoursesModule from 'pages/courses/CoursesModules/CoursesModule/CoursesModule';
import { Grid } from '@mui/material';
import CourseMaterialItem from 'pages/courses/CourseMaterialItem/CourseMaterialItem';
import { PREVIEW_STATUS, usePreview } from 'components/layout/PreviewContext';
import { useMaterialsContext } from 'components/layout/MaterialsContext/MaterialsContext';

const CoursesModules = ({ onCreate, newMaterialId }) => {
    const previewStatus = usePreview();
    const { allLessons, onLessonDrop, onModuleDrop } = useMaterialsContext();
    return (
        <div className={'course-materials__modules'}>
            <Container
                orientation={'vertical'}
                dragHandleSelector=".module-move"
                groupName={'module'}
                dragClass={'course-material--drag'}
                onDrop={(res) => onModuleDrop(res)}
            >
                {allLessons
                    ?.filter((module) => !module?.contentHolder)
                    .map((module, index) => {
                        const modulesTotal = getSameMaterialsLength(
                            allLessons,
                            module.type
                        );
                        const moduleIndex = index + 1;
                        const notEmpty =
                            module.children.filter((el) => !el.empty).length >
                            0;
                        return !notEmpty &&
                            previewStatus === PREVIEW_STATUS.preview ? null : (
                            <Draggable key={`${module.id}-${moduleIndex}`}>
                                <CoursesModule
                                    isReadOnly={
                                        previewStatus !== PREVIEW_STATUS.draft
                                    }
                                    index={index}
                                    idx={moduleIndex}
                                    modulesTotal={modulesTotal}
                                    key={module.id}
                                    module={module}
                                    materials={module.children}
                                    onCreate={onCreate}
                                >
                                    <Container
                                        orientation={'vertical'}
                                        groupName={'lesson'}
                                        dragHandleSelector={'.lesson-move'}
                                        dragClass={'course-material--drag'}
                                        getChildPayload={(idx) =>
                                            module.children[idx]
                                        }
                                        onDrop={(res) =>
                                            onLessonDrop(
                                                res,
                                                module.id,
                                                moduleIndex
                                            )
                                        }
                                    >
                                        {module.children?.map((material, idx) =>
                                            material.empty &&
                                            previewStatus ===
                                                PREVIEW_STATUS.preview ? null : (
                                                <Draggable
                                                    key={`${material.id}-${idx}`}
                                                >
                                                    <Grid
                                                        container
                                                        className={cn(
                                                            'course-material',
                                                            {
                                                                'course-material__read-only':
                                                                    previewStatus !==
                                                                    PREVIEW_STATUS.draft,
                                                            }
                                                        )}
                                                        alignItems={'center'}
                                                    >
                                                        <div className="lesson-move course-material__move">
                                                            <Icon name="move" />
                                                        </div>
                                                        <CourseMaterialItem
                                                            parentId={
                                                                material?.parentId
                                                            }
                                                            idx={idx}
                                                            materialOrder={
                                                                materialOrder(
                                                                    module.children,
                                                                    material.type,
                                                                    material.id
                                                                ) + 1
                                                            }
                                                            key={material.id}
                                                            isEditorOn={
                                                                material.id ===
                                                                newMaterialId
                                                            }
                                                            materialId={
                                                                material.id
                                                            }
                                                            name={
                                                                material?.name
                                                            }
                                                            type={
                                                                material?.type
                                                            }
                                                            empty={
                                                                material?.empty
                                                            }
                                                        />
                                                    </Grid>
                                                </Draggable>
                                            )
                                        )}
                                    </Container>
                                </CoursesModule>
                            </Draggable>
                        );
                    })}
            </Container>
        </div>
    );
};

export default CoursesModules;
