import './SettingsColorsMainBackgroundAppSample.scss';
import SettingsColorsMainBackgroundSample from 'pages/settings/common/SettingsColors/SettingsColorsMainBackgroundMenu/SettingsColorsMainBackgroundSample';
import { useSelector } from 'react-redux';
const SettingsColorsMainBackgroundAppSample = ({ backgroundColor }) => {
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);

    return (
        <div className={'settings-colors-main-background-app-sample'}>
            <SettingsColorsMainBackgroundSample
                backgroundColor={menuBackgroundColor}
            />
            <div
                className={
                    'settings-colors-main-background-app-sample__content'
                }
            >
                <aside
                    className={
                        'settings-colors-main-background-app-sample__sidebar'
                    }
                    style={{ backgroundColor }}
                ></aside>
                <section
                    className={
                        'settings-colors-main-background-app-sample__section'
                    }
                    style={{ backgroundColor }}
                ></section>
            </div>
        </div>
    );
};

export default SettingsColorsMainBackgroundAppSample;
