import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import { SpaceList } from './views';
import './Spaces.scss';
import { useTranslation } from 'react-i18next';

const Spaces = () => {
    const { t } = useTranslation('common');
    return (
        <section className={'spaces'}>
            <Heading className={'spaces-title'}>{t('CHOOSE_SPACE')}</Heading>
            <SpaceList />
        </section>
    );
};

export default Spaces;
