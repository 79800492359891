import React from 'react';
import useIsMobile from 'helpers/hooks/useIsMobile';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import AboutFileContent from 'pages/knowledge/ModalContent/AboutFile/AboutFileContent';
import { DialogModal } from '@akhter-studios/classlytics-front-ui-components';
import './AboutFile.scss';
const AboutFile = ({ open, setOpen, aboutFile }) => {
    const isMobile = useIsMobile();
    if (isMobile) {
        return (
            <BottomSheetCustom setOpen={setOpen} open={open}>
                <AboutFileContent aboutFile={aboutFile} />
            </BottomSheetCustom>
        );
    }

    return (
        <div className="about-file">
            <DialogModal isOpen={open} setIsOpen={setOpen}>
                <AboutFileContent aboutFile={aboutFile} />
            </DialogModal>
        </div>
    );
};

export default AboutFile;
