import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useAction } from 'helpers/hooks';
import { inviteSchema } from 'utils/schemas';
import { AUTH_INVITE_ROUTE, CONFIRMATION_ROUTE } from 'utils/consts';
import { personalDataForm } from '../consts';
import { Typography } from '@akhter-studios/classlytics-front-ui-components';
import { AuthButton, AuthFields } from 'components/feature';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';

const PersonalDataForm = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [email] = useState(searchParams.get('email') ?? 'login@mail.com');
    const data = searchParams.get('data');
    const domain = searchParams.get('domain');
    const { registerFormValues } = useSelector((state) => state.auth);
    const { setRegisterFormValues } = useAction();

    const onSubmit = async (values) => {
        const newData = {
            ...registerFormValues,
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            email,
            data,
            domain,
        };
        setRegisterFormValues(newData);
        navigate(`${AUTH_INVITE_ROUTE}${CONFIRMATION_ROUTE}`);
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
        isSubmitting,
    } = useFormik({
        initialValues: {
            first_name: registerFormValues?.first_name ?? '',
            last_name: registerFormValues?.last_name ?? '',
        },
        validationSchema: inviteSchema(t)[pathname],
        onSubmit,
    });
    return (
        <>
            <Typography
                className={'auth-desc'}
                variant={'body3'}
                dangerouslySetInnerHTML={{
                    __html: t('AUTH_INVITE_PERSONAL_DATA', {
                        email,
                    }),
                }}
            ></Typography>
            <form className={'auth-form'} onSubmit={handleSubmit}>
                <AuthFields
                    fields={personalDataForm}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                />
                <AuthButton
                    isLoading={isSubmitting}
                    isValid={isValid}
                    text={t('NEXT')}
                />
            </form>
        </>
    );
};

export default PersonalDataForm;
