import { nanoid } from 'nanoid';
import {
    Icon,
    Progress,
} from '@akhter-studios/classlytics-front-ui-components';
import React from 'react';
import useFormatDate from 'helpers/hooks/useFormatDate';
export const tabs = () => {
    return [
        {
            id: nanoid(),
            icon: 'courses',
            text: 'ANALYTICS_LESSONS',
            search: 'students',
            to: 'lesson',
        },
        {
            id: nanoid(),
            icon: 'test',
            text: 'TESTS',
            search: 'students',
            to: 'test',
        },
        {
            id: nanoid(),
            icon: 'user-group',
            text: 'STUDENTS',
            search: 'students',
            to: 'students',
        },
    ];
};

export const analyticsDetailCourseHeading = {
    name: 'NAME',
    // involvement: 'ANALYTICS_INVOLVEMENT',
    pass_for: 'ANALYTICS_PASS_FOR',
};

export const analyticsDetailTestHeading = {
    name: 'NAME',
    average: 'ANALYTICS_AVERAGE_SCORE',
};
export const analyticsDetailStudentsHeading = {
    name: 'PROFILE_NAME',
    average: 'ANALYTICS_AVERAGE_SCORE',
    progress: 'ANALYTICS_PROGRESS',
};

const findProgress = ({ progress, t }) => {
    if (progress === 'STARTED') {
        return 'Started';
    }
    if (progress === 'COMPLETED') {
        return t('ANALYTICS_COMPLETED');
    }
    return t('ANALYTICS_NOT_STARTED');
};

export const analyticsDetailsStudentTable = ({ data, t }) =>
    data.map((x) => ({
        img: x.Student.avatar,
        firstName: x.Student.first_name,
        lastName: x.Student.last_name,
        name: (
            <div>
                <div>{x.Student.first_name + ' ' + x.Student.last_name}</div>
                <span
                    style={{
                        fontSize: '11px',
                        fontWeight: '600px',
                        color: '#637D8B',
                    }}
                >
                    {x.Student.email}
                </span>
            </div>
        ),
        average: x.average_score,
        progress: (
            <div className="d-flex detail-course-student-progress">
                <Progress
                    className={'cards-item__progress'}
                    value={x.passage_qty}
                    max={x.material_qty}
                />
                <div className="completed">
                    {findProgress({ progress: x.progress, t })}
                </div>
            </div>
        ),
    }));
export const analyticsDetailTestTable = (data) =>
    (data || []).map((x, idx) => ({
        ...x,
        name: (
            <>
                <div>
                    {idx + 1}. {x.name}
                </div>
                {x.updated_at && (
                    <div
                        style={{
                            background: '#F7F9FA',
                            fontSize: '10px',
                            borderRadius: '4px',
                        }}
                    >
                        {useFormatDate(x.updated_at)}
                    </div>
                )}
            </>
        ),
        average: (
            <div className="d-flex">
                <span>{x.average}</span>
                <Icon name="arrow-right" />
            </div>
        ),
    }));

export const analyticsDetailCourseTable = [
    {
        name: (
            <>
                <div>'1. What is a high five'</div>
                <div
                    style={{
                        background: '#F7F9FA',
                        fontSize: '10px',
                        borderRadius: '4px',
                    }}
                >
                    Изменен: 1 ноября 2022
                </div>
            </>
        ),
        involvement: '80%',
        pass: '32 мин',
    },
    {
        name: '2. History of high fiving',
        involvement: '100%',
        pass: '1 час',
    },
    {
        name: '3. Cultural sensitivity: who you should never high five to',
        involvement: '80%',
        pass: '32 мин',
    },
];

export const testStatistics = [
    {
        mainTitle: '1. When was the very first high five documented?\n',
        data: [
            {
                title: 'Неправильных ответов',
                success: false,
                subDescription: 'Чаще всего отвечают: А',
                percent: 30,
            },
            {
                title: 'Правильных ответов',
                success: true,
                percent: 70,
            },
        ],
    },
    {
        mainTitle: '2. When was the very first high five documented?\n',
        data: [
            {
                title: 'Неправильных ответов',
                success: false,
                subDescription: 'Чаще всего отвечают: А',
                percent: 30,
            },
            {
                title: 'Правильных ответов',
                success: true,
                percent: 70,
            },
        ],
    },
    {
        mainTitle: '1. When was the very first high five documented?\n',
        data: [
            {
                title: 'Неправильных ответов',
                success: false,
                subDescription: 'Чаще всего отвечают: А',
                percent: 30,
            },
            {
                title: 'Правильных ответов',
                success: true,
                percent: 70,
            },
        ],
    },
];
