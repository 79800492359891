import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import SettingsCompanyName from 'pages/settings/common/SettingsCompany/SettingsCompanyName';
import SettingsCompanyDomain from 'pages/settings/common/SettingsCompany/SettingsCompanyDomain';
import SettingsCompanyFavIcon from 'pages/settings/common/SettingsCompany/SettingsCompanyFavIcon';
import SettingsCompanySquareLogo from 'pages/settings/common/SettingsCompany/SettingsCompanySquareLogo';
import SettingsCompanyLogo from 'pages/settings/common/SettingsCompany/SettingsCompanyLogo';
import SettingsCompanyAppName from 'pages/settings/common/SettingsCompany/SettingsCompanyAppName';
import './SettingsCompany.scss';
import { useTranslation } from 'react-i18next';
const SettingsCompany = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <Heading className={'settings-company__title'} variant={'h1'}>
                {t('SETTINGS_COMPANY')}
            </Heading>
            <SettingsCompanyName />
            <SettingsCompanyDomain />
            <SettingsCompanyFavIcon />
            <SettingsCompanyAppName />
            <SettingsCompanyLogo />
            <SettingsCompanySquareLogo />
        </>
    );
};

export default SettingsCompany;
