import { baseApi } from 'api/xhr';

export const searchCourseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCourseByName: build.mutation({
            query: (params) => ({
                url: `/search/courses/`,
                method: 'GET',
                params,
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue.results;
            },
        }),
        fetchUserCoursesSearch: build.mutation({
            query: ({ spaceId, ...params }) => ({
                url: `/course-space/${spaceId}/courses/search/`,
                method: 'GET',
                params,
            }),
        }),
    }),
});

export const { useGetCourseByNameMutation, useFetchUserCoursesSearchMutation } =
    searchCourseApi;
