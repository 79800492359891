import { createSlice } from '@reduxjs/toolkit';

export const editorSlice = createSlice({
    name: 'editor',
    initialState: {
        ready: false,
    },
    reducers: {
        makeReady: (state) => {
            state.ready = true;
        },
    },
});

const editorActions = editorSlice.actions;
export { editorActions };
export default editorSlice.reducer;
