import { Button, Input } from '@akhter-studios/classlytics-front-ui-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateSpaceMutation } from 'api/services';
import { findAppIdFromDomain } from 'utils/functions/findDomainToRedirect';
import { authRequest } from 'api/xhr';
import { GENERATE_TOKEN_URL } from 'utils/consts';

const SettingsCompanyDomain = () => {
    const { t } = useTranslation('common');
    const { token, refreshToken } = useSelector((state) => state.auth);
    const { userSpace } = useSelector((state) => state.space);
    const [domain, setDomain] = useState(userSpace.domain);
    const [mainDomain, setMainDomain] = useState('.classlytics.io');
    const [updateSpace] = useUpdateSpaceMutation();
    const onChangeDomain = (e) => {
        let value = e.target.value.toLowerCase();
        value = value.replace(/[^A-Za-z]/gi, '');
        setDomain(value);
    };
    const onApply = async () => {
        const { pathname, host, hostname, protocol } = window.location;
        const hostNameArr = host.split('.');
        const isLocalHost = hostname.split('.').includes('localhost');
        const isDev = hostname.split('.').includes('akhter');
        let hostName = '';
        try {
            await updateSpace({
                id: userSpace.id,
                domain,
            });
            const { token: generateToken } = await authRequest.post(
                GENERATE_TOKEN_URL,
                { token, domain }
            );
            if (isLocalHost) {
                hostName = hostNameArr[hostNameArr.length - 1];
                window.location.href = `${protocol}//${domain}.${hostName}${pathname}?oauth=${generateToken}&refresh_token=${refreshToken}`;
            } else if (isDev) {
                setDomain('.akhter.studio');
                const appId = findAppIdFromDomain(hostNameArr);
                hostName = hostNameArr.slice(appId).join('.');
                window.location.href = `${protocol}//${domain}.${hostName}${pathname}?oauth=${generateToken}&refresh_token=${refreshToken}`;
            } else {
                hostName = hostNameArr.slice(1).join('.');
                setDomain(`.${hostName}`);
                window.location.href = `${protocol}//${domain}.${hostName}${pathname}?oauth=${generateToken}&refresh_token=${refreshToken}`;
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        const { host, hostname } = window.location;
        const hostNameArr = host.split('.');
        const isDev = hostname.split('.').includes('akhter');
        const isLocalHost = hostname.split('.').includes('localhost');
        if (isDev) {
            setMainDomain('.akhter.studio');
        } else if (!isLocalHost) {
            const hostName = hostNameArr.slice(1).join('.');
            setMainDomain(`.${hostName}`);
        }
    }, []);
    return (
        <div className={'settings-company__field'}>
            <Input
                id={'settingsDomain'}
                label={t('SETTINGS_DOMAIN')}
                value={domain}
                // domain={mainDomain}
                onChange={onChangeDomain}
            />
            <Button
                variant={'secondary'}
                size={'small'}
                disabled={domain.length === 0}
                onClick={onApply}
            >
                {t('APPLY')}
            </Button>
            {/*<Button variant={'secondary'} size={'small'} disabled={true}>*/}
            {/*    {t('SETTINGS_UNIQUE_DOMAIN')}*/}
            {/*</Button>*/}
        </div>
    );
};

export default SettingsCompanyDomain;
