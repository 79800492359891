import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAction, useDebounce } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';
import {
    useLazyFetchUserMentorsQuery,
    useRemoveUserMentorMutation,
} from 'api/services';
import {
    MentorBottomModal,
    MentorItem,
    UserEmpty,
    UserSearchEmpty,
} from 'pages/users/common';
import { UsersTableFilter } from 'pages/users/common/UsersTable/UsersTableFilter';
import { UsersTableList } from 'pages/users/common/UsersTable/UsersTableList';
import classNames from 'classnames';
import './Mentors.scss';

const Mentors = () => {
    const { t } = useTranslation('common');
    const [isOpen, setIsOpen] = useOutletContext();
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [type, setType] = useState(null);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const { userSpace } = useSelector((state) => state.space);
    const { mentors } = useSelector((state) => state.userMentor);
    const { setUserMentors } = useAction();
    const [trigger, { isLoading }] = useLazyFetchUserMentorsQuery();
    const [removeMentor] = useRemoveUserMentorMutation();
    const [selectedMentors, setSelectedMentors] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 15;
    const onFilter = (type) => setType(type);
    const onSearch = (e) => setSearch(e.target.value);
    const onSelectedMentor = (e) => {
        const { checked, value } = e.target;
        const numberValue = +value;
        let updatedList = [...selectedMentors];
        if (checked) {
            updatedList = [...updatedList, numberValue];
        } else {
            updatedList = updatedList.filter((p) => p !== numberValue);
        }
        setSelectedMentors(updatedList);
    };
    const onSelectedAllMentors = (e) => {
        const { checked } = e.target;
        const updatedList = mentors.results.map((p) => p.id);
        if (checked) {
            setIsCheckedAll(true);
            setSelectedMentors(updatedList);
        } else {
            setIsCheckedAll(false);
            setSelectedMentors([]);
        }
    };
    const onClearSelectedMentors = () => {
        setIsCheckedAll(false);
        setSelectedMentors([]);
    };

    const onRemoveMentor = async (id) =>
        await removeMentor({ id: userSpace.id, mentors: [id] });

    const onRemoveSelectedMentors = async () =>
        await removeMentor({ id: userSpace.id, mentors: selectedMentors }).then(
            ({ data }) => {
                if (data.length === 0) {
                    onClearSelectedMentors();
                }
            }
        );

    const loadMoreMentors = async () => {
        if (!mentors.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            id: userSpace?.id,
            type,
            search: debouncedSearch,
            page: page + 1,
            page_size: pageSize,
        });
        const modifiedResults = [...mentors.results, ...data.results];
        setUserMentors({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        if (userSpace) {
            trigger({
                id: userSpace?.id,
                type,
                search: debouncedSearch,
                page,
                page_size: pageSize,
            });
        }
    }, [userSpace]);

    useEffect(() => {
        if (userSpace) {
            setPage(1);
            trigger({
                id: userSpace?.id,
                type,
                search: debouncedSearch,
                page: 1,
                page_size: pageSize,
            });
        }
    }, [debouncedSearch, type]);

    const renderTable = () => {
        if (isLoading) {
            return null;
        } else if (search.length > 0 && !mentors?.results.length) {
            return <UserSearchEmpty />;
        } else if (mentors?.results.length === 0) {
            return (
                <UserEmpty
                    title={t('LIST_EMPTY')}
                    desc={t('ADD_STUDENTS_DESCRIPTION')}
                    btnText={t('INVITE_ADMIN')}
                    onClick={() => setIsOpen(true)}
                />
            );
        } else {
            return mentors?.results.length > 0 ? (
                <UsersTableList
                    count={mentors?.count}
                    pageSize={pageSize}
                    items={mentors.results}
                    loadMore={loadMoreMentors}
                    hasMore={mentors.hasMore}
                    renderItem={(mentor) => (
                        <MentorItem
                            key={mentor.id}
                            isSelected={selectedMentors.includes(mentor.id)}
                            onSelectedMentor={onSelectedMentor}
                            onRemove={onRemoveMentor}
                            {...mentor}
                        />
                    )}
                />
            ) : null;
        }
    };
    return (
        <div className={'mentors-wrapper'}>
            <div
                className={classNames('mentors', {
                    shift: selectedMentors.length > 0,
                })}
            >
                <UsersTableFilter
                    count={mentors?.count}
                    search={search}
                    searchPlaceholder={t('USERS_ADMINS_SEARCH')}
                    isFilter={true}
                    isChecked={isCheckedAll}
                    onFilter={onFilter}
                    onSearch={onSearch}
                    onSelectedAll={onSelectedAllMentors}
                />
                {renderTable()}
            </div>
            {selectedMentors.length > 0 ? (
                <MentorBottomModal
                    count={selectedMentors.length}
                    maxCount={mentors?.count}
                    onClear={onClearSelectedMentors}
                    onRemove={onRemoveSelectedMentors}
                />
            ) : null}
        </div>
    );
};

export default Mentors;
