import { useRef } from 'react';
import { nanoid } from 'nanoid';
import { params } from './params';
import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    Heading,
    MoreButton,
} from '@akhter-studios/classlytics-front-ui-components';
import SwiperButtonPrev from 'components/common/CustomSwiper/SwiperButtonPrev';
import SwiperButtonNext from 'components/common/CustomSwiper/SwiperButtonNext';
import classNames from 'classnames';

import 'swiper/css';
import 'swiper/css/virtual';
import './CustomSwiper.scss';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Virtual]);

const CustomSwiper = ({
    className,
    slides = [],
    renderSlide,
    title,
    onViewAll,
    loadMore,
}) => {
    const swiperRef = useRef(null);
    const { t } = useTranslation('common');

    return (
        <div className={'customSwiper'}>
            <div className={'customSwiper-header'}>
                <Heading
                    className={'customSwiper-header__title'}
                    variant={'h1'}
                >
                    {title}
                </Heading>
                <div className={'customSwiper-header__helpers'}>
                    <MoreButton onClick={onViewAll} disabled={true}>
                        {t('GENERAL_ALL')}
                    </MoreButton>
                    <div className={'customSwiper-header__buttons'}>
                        <SwiperButtonPrev
                            onClick={() => swiperRef.current.swiper.slidePrev()}
                        />
                        <SwiperButtonNext
                            onClick={() => swiperRef.current.swiper.slideNext()}
                        />
                    </div>
                </div>
            </div>
            <Swiper
                ref={swiperRef}
                onReachEnd={() => {
                    loadMore && loadMore();
                }}
                className={classNames('customSwiper-swiper', className)}
                {...params}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={nanoid()} virtualIndex={index}>
                        {renderSlide(slide)}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default CustomSwiper;
