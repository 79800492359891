import { nanoid } from 'nanoid';
import { CONTENT_PROFILE_ROUTE, CONTENT_SETTINGS_ROUTE } from 'utils/consts';

const headerProfileNavs = ({ logout, role }) => [
    {
        id: nanoid(),
        icon: 'user',
        name: 'GO_TO_PROFILE',
        isDivider: true,
        isShow: true,
        to: CONTENT_PROFILE_ROUTE,
    },
    // {
    //     id: nanoid(),
    //     icon: 'settings',
    //     name: 'PROFILE_SETTINGS',
    //     isDivider: true,
    //     isShow: role === 'mentor',
    //     to: CONTENT_SETTINGS_ROUTE,
    // },
    {
        id: nanoid(),
        icon: 'log-in-out',
        name: 'LOGOUT',
        isShow: true,
        to: null,
        onClick: () => logout(),
    },
];

export { headerProfileNavs };
