import React from 'react';
import UsersFilterSearch from 'components/common/UsersFilterSearch/UsersFilterSearch';
import { useState } from 'react';

const DetailCourseFilterSearch = ({
    searchPlaceholder,
    onSearch,
    search,
    isFilter,
    onFilter,
    onShow,
    totalCount,
}) => {
    const [isShow, setIsShow] = useState(false);
    const [selectedAction, setSelectedAction] = useState({
        text: 'SHOW_ALL',
        type: null,
    });
    return (
        <UsersFilterSearch
            searchPlaceholder={searchPlaceholder}
            search={search}
            onSearch={onSearch}
            isFilter={isFilter}
            setIsShow={setIsShow}
            selectedAction={selectedAction}
            onShow={onShow}
            isShow={isShow}
            onFilter={onFilter}
            totalCount={totalCount}
        />
    );
};

export default DetailCourseFilterSearch;
