import React from 'react';
import { Checkbox } from '@mui/material';
import defaultImg from 'assets/img/png/img.png';
const CourseItem = ({
    image,
    id,
    name,
    item,
    handleChangeCheckboxes,
    checkboxesData,
}) => {
    return (
        <div className="sendFiles-course sendFile-course__item j-c-b" key={id}>
            <div className="d-flex">
                <div className="sendFiles-course__img">
                    <img src={image || defaultImg} alt="" />
                </div>
                <div className="break-all">{name}</div>
            </div>
            <Checkbox
                checked={Boolean(checkboxesData[id])}
                onChange={() => handleChangeCheckboxes(item)}
            />
        </div>
    );
};

export default CourseItem;
