import React, { memo, useRef, useState } from 'react';
import { Icon } from 'components/common';
import './index.scss';
import AddTest from 'pages/course-test/common/AddTest/AddTest';
import useOutsideClick from 'helpers/hooks/useOutsideClick';
import List from 'pages/course-test/views/List/List';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAction } from 'helpers/hooks';

const CreateBlock = ({ addBlock, addField, tests }) => {
    const [open, setOpen] = useState(false);
    const { toggleDataUpdated } = useAction();
    const { t } = useTranslation('common');
    const data = [
        {
            title: 'MENTOR_TEST_ONE_RIGHT_ANSWER',
            type: 'single-choice',
            icon: <Icon name="radio-frame" />,
            isShow: true,
        },
        {
            title: 'MENTOR_TEST_SOME_RIGHT_ANSWERS',
            type: 'multiple-choice',
            icon: <Icon name="radio-frame" />,
            isShow: true,
        },
    ];
    const handleClickToggle = () => {
        setOpen(!open);
    };
    const handleClick = (type) => {
        addBlock(type);
        handleClickToggle();
        toggleDataUpdated(true);
    };
    return (
        <div
            className={classNames(`create-test-block`, {
                existTest: tests.length > 0,
            })}
        >
            {open && (
                <List data={data} setOpen={setOpen} handleClick={handleClick} />
            )}
            <AddTest
                title={t('MENTOR_TEST_ADD_QUESTION')}
                handler={handleClickToggle}
            />
        </div>
    );
};

export default memo(CreateBlock);
