import React from 'react';
import CourseContentItem from 'pages/courses/CoursesDetailed/CourseContentItem';
import CourseContentItemToggle from 'pages/courses/CoursesDetailed/CourseContentItemToggle';

const CourseContent = ({ data }) => {
    return (
        <div className="courses-detailed-content">
            {(data || [])
                .filter((x) => !x.children)
                .map((item, index) => (
                    <CourseContentItem {...item} key={index} />
                ))}
            {(data || [])
                .filter((x) => x.children)
                .map((item, idx) => (
                    <CourseContentItemToggle key={idx} {...item} />
                ))}
        </div>
    );
};

export default CourseContent;
