import React from 'react';
import './Analytics.scss';
import AnalyticsTitle from 'pages/analytics/common/AnalyticsTitle/AnalyticsTitle';
import AnalyticsHeading from 'pages/analytics/common/AnalyticsHeading/AnalyticsHeading';
import { Outlet } from 'react-router-dom';
const Analytics = () => {
    return (
        <section className="analytics users layout-main">
            <AnalyticsTitle />
            <AnalyticsHeading />
            <Outlet />
        </section>
    );
};

export default Analytics;
