import React, { useRef, useState } from 'react';
import Icon from 'components/common/Icon/Icon';
import {
    Input,
    Progress,
} from '@akhter-studios/classlytics-front-ui-components';
import TooltipInterface from 'pages/knowledge/TooltipInterface';
import FileItemInputChilds from 'pages/knowledge/FileItemInputChilds';
import { iconsView } from 'pages/knowledge/constants';
import { Checkbox } from '@mui/material';
import useOnSaveInput from 'helpers/hooks/useOnSaveInput';

const FileItemInput = ({
    onDragEnd,
    onDrop,
    onDragOver,
    setOpen,
    open,
    onDragLeave,
    onDragStart,
    isFolder,
    changeNameFileDebounce,
    x,
    onChangeName,
    idx,
    removeFileHandler,
    handleClickModalInterface,
    onDragLeaveItems,
    folderId,
    handleChangeCheckboxes,
    checkboxesData,
    getDetailFile,
    uploadPercent,
    removeCanceledFile,
    downloadFile,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const { value, setValue } = useOnSaveInput({ value: x.name });
    const refEl = useRef(null);
    const onSaveInput = () => {
        const valueData = value.value || x.name;
        const id = value.id || x.id;
        changeNameFileDebounce(valueData, id);
        onChangeName(id, valueData);
        setIsEdit(false);
    };
    return (
        <div
            className={`knowledge-base__items ${idx}`}
            onDragEnd={(e) => onDragEnd(e, refEl.current)}
            onDragLeave={(e) => onDragLeave(e, refEl.current)}
            ref={refEl}
            onDragOver={(e) => onDragOver(e, refEl.current)}
        >
            <div
                draggable={!isEdit}
                className="knowledge-base__items-wrap"
                onDrop={(e) => {
                    onDrop(e, { ...x, idx }, refEl.current);
                }}
                onDragStart={(e) => {
                    onDragStart(e, { ...x, idx });
                    setOpen(false);
                }}
            >
                <div className="left">
                    {!x.isTemp && (
                        <div className="move folder-drag-handler">
                            <Icon name="move" />
                        </div>
                    )}

                    {!x.isTemp && (
                        <Checkbox
                            checked={Boolean(checkboxesData[x.id])}
                            onChange={() => handleChangeCheckboxes(x)}
                        />
                    )}
                    <Icon
                        name={
                            isFolder
                                ? 'document-folder'
                                : iconsView[x.expansion] || 'file'
                        }
                    />
                    <div className="knowledge-base__items-info">
                        {isEdit ? (
                            <div>
                                <Input
                                    groupClassName={'auth-fields'}
                                    value={value?.value}
                                    autoFocus={isEdit}
                                    onChange={(e) => {
                                        setValue({
                                            value: e.target.value,
                                            id: x.id,
                                        });
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="knowledge-base__items-name">
                                <div className="j-c-b">
                                    <div
                                        style={{
                                            wordBreak: 'break-all',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        {x.name}
                                        {x.expansion && `${x.expansion}`}
                                    </div>
                                    {x.isTemp && (
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            {uploadPercent[x.id]?.value || 0}%
                                        </div>
                                    )}
                                </div>
                                {x.isTemp && (
                                    <Progress
                                        className={'cards-item__progress'}
                                        value={uploadPercent[x.id]?.value || 0}
                                        max={100}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="right">
                    <TooltipInterface
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        x={x}
                        getDetailFile={getDetailFile}
                        downloadFile={() => downloadFile(x.file, x.name, x)}
                        setOpen={setOpen}
                        open={open}
                        removeFileHandler={removeFileHandler}
                        isFolder={isFolder}
                        idx={idx}
                        uploadPercent={uploadPercent}
                        onSave={onSaveInput}
                        handleClickModalInterface={handleClickModalInterface}
                        removeCanceledFile={removeCanceledFile}
                    />
                </div>
            </div>
            <div className="files">
                {open &&
                    x.files.map((x, indexChild) => (
                        <FileItemInputChilds
                            x={x}
                            indexChild={indexChild}
                            onDragStart={onDragStart}
                            idx={idx}
                            checkboxesData={checkboxesData}
                            onDragEnd={onDragEnd}
                            getDetailFile={getDetailFile}
                            handleChangeCheckboxes={handleChangeCheckboxes}
                            onDragOver={onDragOver}
                            onChangeName={onChangeName}
                            folderId={folderId}
                            handleClickModalInterface={
                                handleClickModalInterface
                            }
                            removeFileHandler={removeFileHandler}
                        />
                    ))}
            </div>
        </div>
    );
};

export default FileItemInput;
