import { useState } from 'react';
import { Avatar, Icon } from '@akhter-studios/classlytics-front-ui-components';
import './UploadAvatar.scss';

const UploadAvatar = ({ avatar, firstName, lastName, setFieldValue }) => {
    const [avatarPreview, setAvatarPreview] = useState(avatar);
    const onOpenUpload = () =>
        document.getElementById('uploadProfileAvatar').click();
    const onUploadAvatar = async (e) => {
        const file = e.target.files[0];
        setAvatarPreview(URL.createObjectURL(file));
        setFieldValue('avatar', file);
    };
    return (
        <div className={'upload-avatar'}>
            <div className={'upload-avatar__outer'}>
                {avatar ? (
                    <Avatar
                        className={'upload-avatar__thumbnail'}
                        src={avatarPreview}
                        alt={'avatar'}
                        variant={'rounded'}
                    />
                ) : (
                    <Avatar
                        className={'upload-avatar__thumbnail'}
                        alt={'avatar'}
                        variant={'rounded'}
                        avatarText={`${
                            firstName?.charAt(0).toUpperCase() || ''
                        }${lastName?.charAt(0).toUpperCase() || ''}`}
                    />
                )}
                <div className={'upload-avatar__inner'} onClick={onOpenUpload}>
                    <Icon name={'photo'} />
                </div>
            </div>
            <input
                className={'upload-avatar__upload'}
                type={'file'}
                id={'uploadProfileAvatar'}
                onChange={onUploadAvatar}
            />
        </div>
    );
};

export default UploadAvatar;
