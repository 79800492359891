const dataURLtoFile = (dataUrl) => {
    let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    let fileName = mime.split("/");
    fileName = `${fileName[0]}.${fileName[1]}`;
    return new File([u8arr], fileName, {type: mime});
};

export default dataURLtoFile;