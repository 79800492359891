import {StudentCoursesAccessContainer} from "./common/StudentCoursesAccessContainer";
import {StudentCoursesSwiperContainer} from "./common/StudentCoursesSwiperContainer";
import {Header, Sidebar} from "components/common";

const StudentCourses = () => {
    return (
        <>
            <Header hiddenSearch={true}/>
            <Sidebar />
            <article className={"student-courses layout-main"}>
                <StudentCoursesSwiperContainer/>
                <StudentCoursesAccessContainer/>
            </article>
        </>
    );
};

export default StudentCourses;
