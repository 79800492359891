import { axiosRequest } from 'api/xhr/axios/axios';

const IQANAT_UPLOAD_FILE_URL =
    'https://api.iqanat.akhter.studio/storage/files/upload/anonymous/';

export const uploadFile = async (file, spaceId, courseId) => {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    return await axiosRequest.post(
        `/course-space/${spaceId}/keeper/?course_id=${courseId}`,
        formData
    );
};

export const updateFiles = async (spaceId, data) => {
    return await axiosRequest.post(
        `/course-space/${spaceId}/keeper/refresh/media-links/`,
        { data }
    );
};

export const getFiles = async (spaceId) => {
    return await axiosRequest.get(
        `/knowledge-of-space/${spaceId}/knowledge-base/`
    );
};

export const removeFile = (spaceId, fileId) => {
    return axiosRequest.delete(`/course-space/${spaceId}/keeper/${fileId}`);
};
