import React from 'react';
import './EmptyPlaceholders.scss';
import classNames from 'classnames';
const EmptyPlaceholders = ({ title, description, onlyCenter }) => {
    return (
        <div
            className={classNames('empty-placeholder', {
                onlyCenter,
            })}
        >
            <div className="empty-placeholder__title">{title}</div>
            <div className="empty-placeholder__desc">{description}</div>
        </div>
    );
};

export default EmptyPlaceholders;
