import 'pages/auth/Auth.scss';
import { Heading } from '@akhter-studios/classlytics-front-ui-components';
import CreateSpaceForm from './CreateSpaceForm';
import { useTranslation } from 'react-i18next';

const CreateSpace = () => {
    const { t } = useTranslation('common');
    return (
        <div className={'auth'}>
            <div className={'auth-wrapper'}>
                <Heading className={'auth-title'} variant={'h1'}>
                    {t('NEW_SPACE')}
                </Heading>
                <CreateSpaceForm />
            </div>
        </div>
    );
};

export default CreateSpace;
