import React, { useEffect, useState } from 'react';
import { Search } from '@akhter-studios/classlytics-front-ui-components';
import CourseItem from 'pages/knowledge/ModalContent/SendFilesToCourse/CourseItem';
import { fetchCourses, sendFileToCourseApi } from 'pages/knowledge/services';
import { useSelector } from 'react-redux';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import useSaveData from 'helpers/hooks/useSaveData';
import useDebounce from '../../../../helpers/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import Success from 'pages/knowledge/ModalContent/SendFilesToCourse/Success';
import InfiniteScroll from 'react-infinite-scroll-component';
import useIsMobile from 'helpers/hooks/useIsMobile';
const SendFilesToCourse = ({
    currentItem,
    resetCheckboxes,
    selectedItems,
    closeModal,
}) => {
    const [search, setSearch] = useState('');
    const { userSpace } = useSelector((state) => state.space);
    const [courseItems, setCourseItems] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const valueSearch = useDebounce(search, 500);
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (userSpace) {
            fetchData(valueSearch);
        }
    }, [userSpace, valueSearch]);
    useEffect(() => {
        if (isSuccess) {
            return () => resetCheckboxes();
        }
    }, [isSuccess]);

    const fetchData = async (value) => {
        if (!hasMore && valueSearch) return;
        setPage((prev) => prev + 1);
        await fetchCourses(userSpace?.id, valueSearch, page).then((result) => {
            setHasMore(!!result.next);
            setCount(result.count);
            if (valueSearch) {
                setPage(1);
            }
            setCourseItems(
                valueSearch
                    ? result.results
                    : (prev) => prev.concat(result.results)
            );
        });
    };

    const [checkboxesData, handleChangeCheckboxes, resetIsSelected] =
        useSaveData();

    const isMobile = useIsMobile();
    const { t } = useTranslation('common');
    const sendClick = () => {
        const filesIds = Object.keys(selectedItems).join(',') || currentItem.id;
        const courseIds = Object.keys(checkboxesData).join(',');
        sendFileToCourseApi(userSpace.id, filesIds, courseIds).then(
            (result) => {
                setIsSuccess(true);
            }
        );
    };

    if (isSuccess) {
        const items =
            Object.keys(selectedItems).length > 0 ? selectedItems : currentItem;
        return (
            <Success
                currentItem={currentItem}
                resetCheckboxes={resetCheckboxes}
                selectedFiles={items}
                selectedCourses={checkboxesData}
            />
        );
    }

    const displayType = () => {
        const length = Object.keys(selectedItems).length;
        const type =
            Object.keys(selectedItems).length > 0
                ? selectedItems[Object.keys(selectedItems)[0]].type
                : currentItem.type;

        if (type === 'folder') {
            return length > 1 ? t('BASE_FOLDERS') : t('BASE_FOLDER');
        }
        return length > 1 ? t('BASE_FILES') : t('BASE_FILE');
    };

    const displayExpansion = (expansion) => {
        if (!expansion) return '';
        return `.${expansion}`;
    };

    return (
        <div className="sendFiles">
            <h4>{t('BASE_CHOOSE_COURSE')}</h4>
            <div className="sendFiles-desc">
                <div>
                    {displayType()}{' '}
                    <span className="break-all">
                        <strong>
                            {' '}
                            {currentItem
                                ? '' +
                                  '«' +
                                  currentItem.name +
                                  '.' +
                                  currentItem.expansion +
                                  '»,'
                                : Object.keys(selectedItems).map(
                                      (x) =>
                                          '«' +
                                          selectedItems[x].name +
                                          displayExpansion(
                                              selectedItems[x].expansion
                                          ) +
                                          '»' +
                                          ', '
                                  )}
                        </strong>
                    </span>{' '}
                    {currentItem || Object.keys(selectedItems).length === 1
                        ? t('BASE_SEND_TO_COURSE_TXT_ONE')
                        : t('BASE_SEND_TO_COURSE_TXT_SOME')}
                </div>
            </div>
            <Search
                groupClassName={'header-search search-course'}
                fullWidth
                value={search}
                placeholder={t('BASE_SEND_TO_COURSE_SEARCH_COURSE')}
                onChange={(e) => setSearch(e.target.value)}
            />
            <div id="scrollableBase">
                <InfiniteScroll
                    height={isMobile ? '' : 300}
                    className="sendFiles-courses"
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<div>{t('GENERAL_LOADING')}</div>}
                    scrollableTarget="scrollableBase"
                    dataLength={courseItems.length}
                >
                    {courseItems.map((x) => (
                        <React.Fragment key={x.id}>
                            <CourseItem
                                item={x}
                                handleChangeCheckboxes={handleChangeCheckboxes}
                                checkboxesData={checkboxesData}
                                {...x}
                            />
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </div>
            <div className="sendFiles-footer">
                <div className="d-flex mobile-hidden">
                    <Button variant="secondary" fullWidth onClick={closeModal}>
                        {t('BASE_SEND_TO_COURSE_BACK-BUTTON')}
                    </Button>
                    <Button
                        variant="primary"
                        fullWidth
                        onClick={sendClick}
                        disabled={!Object.keys(checkboxesData).length}
                    >
                        {t('BASE_SEND_TO_COURSE_SEND-BUTTON')}
                    </Button>
                </div>
                <Button
                    className="desktop-hidden"
                    variant="primary"
                    fullWidth
                    onClick={sendClick}
                    disabled={!Object.keys(checkboxesData).length}
                >
                    {t('BASE_SEND_TO_COURSE_SEND-BUTTON')}
                </Button>
            </div>
        </div>
    );
};

export default SendFilesToCourse;
