import React from 'react';
import DetailCourseStatisticsItem from 'pages/analytics/views/courses/DetailCourses/DetailCourseStatisticsItem';
import { Progress } from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
const StudentMobileContent = ({ data }) => {
    const { t } = useTranslation('common');
    return (
        <div className="groups-student-mobile desktop-hidden">
            {data.map((el) => {
                return (
                    <div className="groups-student-mobile__item">
                        <div className="groups-student-mobile-wrapper">
                            <div className="d-flex">
                                <img src={el.img} alt="" />
                                <div>
                                    <div>{el.name}</div>
                                    <span>{el.email}</span>
                                </div>
                            </div>
                            <DetailCourseStatisticsItem
                                value="4,76"
                                title={t('ANALYTICS_AVERAGE_SCORE')}
                            />
                        </div>
                        <div className="groups-student-mobile__progress">
                            <div className="groups-student-mobile__progress-info d-flex">
                                <span>{t('PREVIEW_PASSED')}: </span>
                                <div> 88%</div>
                            </div>
                            <Progress max={100} value={88} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default StudentMobileContent;
