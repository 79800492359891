import GroupImg from 'assets/img/png/group-detail.png';
import './GroupDetailInfo.scss';
import {
    Dropdown,
    Heading,
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { actions } from './consts';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const GroupDetailInfo = ({
    title,
    accessCourses,
    qtyParticipants,
    onRemove,
    onOpenChangeTitle,
}) => {
    const { t } = useTranslation('common');
    const [isShow, setIsShow] = useState(false);

    const onContainerOpenChangeTitle = () => {
        onOpenChangeTitle();
        setIsShow(false);
    };

    const onShow = () => setIsShow((prev) => !prev);
    return (
        <section className={'group-detail-info'}>
            <img
                className={'group-detail-info__img'}
                src={GroupImg}
                alt={title}
            />
            <div className={'group-detail-info__content'}>
                <div className={'group-detail-info__about'}>
                    <div className={'group-detail-info__name'}>
                        <Heading variant={'h4'}>{title}</Heading>
                        <Dropdown
                            isShow={isShow}
                            setIsShow={setIsShow}
                            className={'group-detail-info__dropdown'}
                        >
                            <Dropdown.Header>
                                <Icon
                                    className={'group-detail-info__more'}
                                    name={'more'}
                                    onClick={onShow}
                                />
                            </Dropdown.Header>
                            <Dropdown.Body className={'dropdown__body'}>
                                <List
                                    className={'dropdown__list'}
                                    items={actions({
                                        onRemove,
                                        onContainerOpenChangeTitle,
                                    })}
                                    renderItem={({
                                        id,
                                        text,
                                        border,
                                        onClick,
                                    }) => (
                                        <li
                                            key={id}
                                            className={classNames(
                                                'dropdown__item',
                                                { border }
                                            )}
                                            onClick={onClick}
                                        >
                                            <Typography variant={'body4'}>
                                                {t(text)}
                                            </Typography>
                                        </li>
                                    )}
                                />
                            </Dropdown.Body>
                        </Dropdown>
                    </div>
                </div>
                <div className={'group-detail-info__points'}>
                    <div className={'group-detail-info__student'}>
                        <Typography
                            className={'group-detail-info__qty-name'}
                            variant={'body5'}
                            fontWeightVariant={'medium'}
                        >
                            {t('ABOUT_STUDENTS')}
                        </Typography>
                        <Typography
                            className={'group-detail-info__qty'}
                            variant={'body4'}
                        >
                            {qtyParticipants}
                        </Typography>
                    </div>
                    <div className={'group-detail-info__course'}>
                        <Typography
                            className={'group-detail-info__qty-name'}
                            variant={'body5'}
                            fontWeightVariant={'medium'}
                        >
                            {t('INVITED_COURSES')}
                        </Typography>
                        <Typography
                            className={'group-detail-info__qty'}
                            variant={'body4'}
                        >
                            {accessCourses}
                        </Typography>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GroupDetailInfo;
