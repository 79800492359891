import { nanoid } from 'nanoid';

const tabs = [
    {
        id: nanoid(),
        icon: 'user',
        text: 'PROFILE_MENU_PROFILE',
        search: '?scope=profile',
        to: '/content/profile?scope=profile',
    },
    {
        id: nanoid(),
        icon: 'security',
        text: 'PROFILE_MENU_SECURITY',
        search: '?scope=security',
        to: '/content/profile?scope=security',
    },
    {
        id: nanoid(),
        icon: 'notification',
        text: 'PROFILE_MENU_NOTIFICATIONS',
        search: '?scope=notification',
        to: '/content/profile?scope=notification',
        isDivider: true,
    },
    {
        id: nanoid(),
        icon: 'settings',
        text: 'PROFILE_MENU_SETTINGS',
        search: '?scope=settings',
        to: '/content/profile?scope=settings',
    },
];

export { tabs };
