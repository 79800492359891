import {
    removeFile,
    uploadFile,
} from 'pages/courses/Course/create-course/service';
import { getLocalStorageData } from 'utils/functions';
import { REMOVING_BLOCKS } from 'utils/functions/localStorage';

const getResponseFile = ({ success, file = {}, errorMessage = null }) => {
    if (success) {
        return {
            success,
            file,
        };
    } else {
        return {
            success,
            errorMessage,
        };
    }
};

export const uploadByFile = (file, spaceId, courseId) => {
    try {
        return uploadFile(file, spaceId, courseId).then((res) => {
            const { file: url, name: title, id } = res;
            return getResponseFile({
                success: 1,
                file: { url, title, id },
            });
        });
    } catch (e) {
        return getResponseFile({
            success: 0,
            errorMessage: 'File upload failed!',
        });
    }
};

export const removeFiles = () => {
    const deletingFileIds = getLocalStorageData(REMOVING_BLOCKS);
    if (!deletingFileIds || !deletingFileIds.length) {
        return;
    }
    return Promise.all(deletingFileIds.map((id) => removeFile(5, id)));
};
