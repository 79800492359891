import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './ActiveLink.scss';
import {
    Icon,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
const ActiveLink = ({ icon, text, active, ...rest }) => {
    return (
        <Link
            className={classNames('active-link', {
                active,
            })}
            {...rest}
        >
            <Icon className={'active-link__icon'} name={icon} />
            <Typography className={'active-link__text'} variant={'body4'}>
                {text}
            </Typography>
        </Link>
    );
};

export default ActiveLink;
