import React from 'react';
import { Search } from '@akhter-studios/classlytics-front-ui-components';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { useFetchGroupCoursesQuery } from 'api/services/AnalyticsService/AnalyticsService';
import AnalyticsHeaderMobile from 'pages/analytics/common/AnalyticsHeaderMobile/AnalyticsHeaderMobile';
import SortList from 'pages/analytics/common/SortList/SortList';
import { courseModalSort } from 'pages/analytics/views/groups/consts';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const Course = ({ selectedCourse, handleToggleModal }) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);

    const { data } = useFetchGroupCoursesQuery({
        id: selectedCourse.id,
        spaceId: userSpace?.id,
    });
    return (
        <section className="analytics-groups-modal">
            <AnalyticsHeaderMobile
                title={selectedCourse.name}
                close={handleToggleModal}
            />
            <div className="analytics-groups-modal__title">Доступ к курсам</div>
            <Search placeholder={t('SEARCH_COURSES')} />
            <div className="analytics-groups-modal__course-total d-flex">
                <div>Всего: </div>
                <span>{data?.length}</span>
            </div>
            <SortList data={courseModalSort} />
            <div className="analytics-groups-modal__courses">
                {(data || []).map((el) => (
                    <div
                        key={el.id}
                        className="analytics-groups-modal__courses-item d-flex"
                    >
                        <img src={el.image} alt={el.name} />
                        <div>{el.name}</div>
                    </div>
                ))}
            </div>
            <div className="analytics-groups-modal__back mobile-hidden">
                <Button variant="secondary">Назад</Button>
            </div>
        </section>
    );
};

export default Course;
