import React from 'react';
import { Icon } from 'components/common';
import { useTranslation } from 'react-i18next';

const List = ({ list, removeItem, setIsMore }) => {
    const { t } = useTranslation('common');
    return (
        <div className="list">
            <ul className="list-wrap">
                {list.map((x, index) => (
                    <React.Fragment key={x.id}>
                        {!x.isHide && (
                            <li
                                key={x.id}
                                onClick={() => {
                                    x.handler();
                                    setIsMore(false);
                                }}
                            >
                                {t(x.title)}
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ul>
            <div className="list-line" />
            <div
                className="list-wrap__bottom"
                onClick={() => {
                    removeItem();
                    setIsMore(false);
                }}
            >
                <span>{t('FILE_REMOVE')}</span>
                <Icon name="trash" />
            </div>
        </div>
    );
};

export default List;
