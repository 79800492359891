import React from 'react';
import ReactDOM from 'react-dom';
import KnowledgeContainer from './KnowledgeContainer';
import { make } from 'components/common/Editor/tools/utils';
import { IconChevronDown, IconFile } from '@codexteam/icons';
import download from 'downloadjs'

export default class KnowledgeAttaches {
    static get toolbox() {
        return {
            title: 'Дополнительный материал',
            icon: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14H4.00004C3.63185 14 3.33338 13.7015 3.33338 13.3333L3.33337 2.66667C3.33337 2.29848 3.63185 2 4.00004 2L9.0421 2C9.22781 2 9.4051 2.07746 9.53127 2.21373L12.4892 5.40831C12.6033 5.53154 12.6667 5.6933 12.6667 5.86125L12.6667 13.3333C12.6667 13.7015 12.3682 14 12 14Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.6666 6L9.33329 6C8.9651 6 8.66663 5.70152 8.66663 5.33333L8.66663 2" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
        };
    }

    static get isReadOnlySupported() {
        return true;
    }
    constructor(props) {
        const { data, api, readOnly } = props;
        this.api = api;
        this.readOnly = readOnly;
        this.data = data || [];
        this.CSS = {
            baseClass: this.api.styles.block,
            apiButton: this.api.styles.button,
            loader: this.api.styles.loader,
            wrapper: 'cdx-attaches',
            wrapperWithFile: 'cdx-attaches--with-file',
            wrapperLoading: 'cdx-attaches--loading',
            button: 'cdx-attaches__button',
            title: 'cdx-attaches__title',
            size: 'cdx-attaches__size',
            downloadButton: 'cdx-attaches__download-button',
            fileInfo: 'cdx-attaches__file-info',
            fileIcon: 'cdx-attaches__file-icon',
            fileIconBackground: 'cdx-attaches__file-icon-background',
            fileIconLabel: 'cdx-attaches__file-icon-label',
            input: this.api.styles.input,
            imageWrapper: 'image-tool',
            imageContainer: 'image-tool__image',
            imageEl: 'image-tool__image-picture',
            caption: 'image-tool__caption',
        };
        this.nodes = {
            holder: null,
            wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
            button: null,
            title: null,
            imageContainer: make('div', [this.CSS.imageContainer]),
            imageEl: undefined,
            caption: make('div', [this.CSS.input, this.CSS.caption], {
                contentEditable: !this.readOnly,
            }),
        };
        this.nodes.wrapper.appendChild(this.nodes.imageContainer);
        this.nodes.wrapper.appendChild(this.nodes.caption);
        this.mediaFileExtension = {
            mp4: 'video',
            webm: 'video',
            mp3: 'audio',
            wav: 'audio',
            ogg: 'audio',
            jpeg: 'image',
            gif: 'image',
            png: 'image',
            svg: 'image',
            bmp: 'image',
        };
    }
    getMediaType(extension) {
        return this.mediaFileExtension[extension];
    }
    get EXTENSIONS() {
        return {
            doc: '#1483E9',
            docx: '#1483E9',
            odt: '#1483E9',
            pdf: '#DB2F2F',
            rtf: '#744FDC',
            tex: '#5a5a5b',
            txt: '#5a5a5b',
            pptx: '#E35200',
            ppt: '#E35200',
            mp3: '#eab456',
            mp4: '#f676a6',
            xls: '#11AE3D',
            html: '#2988f0',
            htm: '#2988f0',
            png: '#AA2284',
            jpg: '#D13359',
            jpeg: '#D13359',
            gif: '#f6af76',
            zip: '#4f566f',
            rar: '#4f566f',
            exe: '#e26f6f',
            svg: '#bf5252',
            key: '#00B2FF',
            sketch: '#FFC700',
            ai: '#FB601D',
            psd: '#388ae5',
            dmg: '#e26f6f',
            json: '#2988f0',
            csv: '#11AE3D',
        };
    }
    appendFileIcon(extension) {
        const extensionColor = this.EXTENSIONS[extension];
        const extensionMaxLen = 4;
        const wrapper = make('div', this.CSS.fileIcon);
        const background = make('div', this.CSS.fileIconBackground);
        if (extensionColor) {
            background.style.backgroundColor = extensionColor;
        }
        wrapper.appendChild(background);
        if (extension) {
            let extensionVisible = extension;

            if (extension.length > extensionMaxLen) {
                extensionVisible =
                    extension.substring(0, extensionMaxLen) + '…';
            }

            const extensionLabel = make('div', this.CSS.fileIconLabel, {
                textContent: extensionVisible,
                title: extension,
            });

            if (extensionColor) {
                extensionLabel.style.backgroundColor = extensionColor;
            }

            wrapper.appendChild(extensionLabel);
        } else {
            background.innerHTML = IconFile;
        }
        this.nodes.wrapper.appendChild(wrapper);
    }
    showFileData() {
        this.nodes.wrapper = make('div', this.CSS.wrapper);
        this.nodes.wrapper.classList.add(this.CSS.wrapperWithFile);
        const [{ size, file: fileUrl, name, expansion }] = this.data;
        this.appendFileIcon(expansion);
        const fileInfo = make('div', this.CSS.fileInfo);
        this.nodes.title = make('div', this.CSS.title);
        this.nodes.title.textContent = name || '';
        fileInfo.appendChild(this.nodes.title);
        if (size) {
            let sizePrefix;
            let formattedSize;
            const fileSize = make('div', this.CSS.size);

            if (Math.log10(+size) >= 6) {
                sizePrefix = 'MiB';
                formattedSize = size / Math.pow(2, 20);
            } else {
                sizePrefix = 'KiB';
                formattedSize = size / Math.pow(2, 10);
            }

            fileSize.textContent = formattedSize.toFixed(1);
            fileSize.setAttribute('data-size', sizePrefix);
            fileInfo.appendChild(fileSize);
        }
        this.nodes.wrapper.appendChild(fileInfo);
        if (fileUrl !== undefined) {
            const downloadIcon = make('a', this.CSS.downloadButton, {
                innerHTML: IconChevronDown,
            });
            downloadIcon.addEventListener('click', async function () {
                download(fileUrl);
            });
            this.nodes.wrapper.appendChild(downloadIcon);
        }
        this.nodes.holder.appendChild(this.nodes.wrapper);
    }
    fillImage(url) {
        const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';

        const attributes = {
            src: url,
        };
        if (tag === 'VIDEO') {
            attributes.autoplay = true;
            attributes.loop = true;
            attributes.muted = true;
            attributes.playsinline = true;
        }
        this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);
        this.nodes.imageContainer.appendChild(this.nodes.imageEl);
        this.nodes.holder.appendChild(this.nodes.imageContainer);
    }
    fillAudio(url, extension) {
        const audio = make('audio', '', {
            controls: 'controls',
        });
        const source = make('source', '', {
            src: url,
            type: `audio/${extension}`,
        });
        audio.appendChild(source);
        this.nodes.holder.appendChild(audio);
    }
    fillVideo(url, extension) {
        const video = make('video', '', {
            height: '300',
            width: '650',
            controls: 'controls',
        });
        const source = make('source', '', {
            src: url,
            type: `video/${extension}`,
        });
        video.appendChild(source);
        this.nodes.holder.appendChild(video);
    }
    renderMedia(url, extension) {
        const type = this.getMediaType(extension);
        switch (type) {
            case 'image':
                this.fillImage(url);
                break;
            case 'audio':
                this.fillAudio(url, extension);
                break;
            case 'video':
                this.fillVideo(url, extension);
                break;
            default:
                this.showFileData();
        }
    }
    render() {
        let rootNode = document.createElement('div');
        rootNode.setAttribute('class', this.CSS.baseClass);
        this.nodes.holder = rootNode;
        const onDataChange = (newData) => {
            this.data = newData;
            this.nodes.holder.innerHTML = '';
            rootNode.setAttribute(
                'class',
                `${this.CSS.baseClass} ${this.CSS.imageWrapper}`
            );
            const [{ file: fileUrl, expansion }] = this.data;
            this.nodes.holder = rootNode;
            this.renderMedia(fileUrl, expansion);
        };
        if (!this.data.length) {
            ReactDOM.render(
                <KnowledgeContainer
                    onDataChange={onDataChange}
                    data={this.data}
                    title={this.api.i18n.t('documents')}
                    readOnly={this.readOnly}
                />,
                rootNode
            );
        } else {
            const [{ file: fileUrl, expansion }] = this.data;
            this.renderMedia(fileUrl, expansion);
        }
        return this.nodes.holder;
    }
    save() {
        return this.data;
    }
}
