import React, { memo } from "react";
import icons from "./icons.json";
const Icon = ({ name, handler }) => {
  return (
    <div
      onClick={handler}
      className="icon"
      dangerouslySetInnerHTML={{ __html: icons[name] }}
    />
  );
};

export default memo(Icon);
