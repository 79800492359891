import React, { useState } from 'react';

const useSearch = () => {
    const [search, setSearch] = useState('');

    const onSearch = (e) => setSearch(e.target.value);

    return {
        search,
        setSearch,
        onSearch,
    };
};

export default useSearch;
