import { createSlice } from '@reduxjs/toolkit';
import { userStudentApi } from 'api/services';

const initialState = {
    students: {
        results: [],
        count: [],
        hasMore: false,
    },
    studentCourses: {
        results: [],
        count: [],
        hasMore: false,
    },
    studentGroups: {
        results: [],
        count: [],
        hasMore: false,
    },
    isUpdate: false,
    pageSize: 10,
};

export const userStudentSlice = createSlice({
    name: 'userStudent',
    initialState,
    reducers: {
        setUserStudents: (state, { payload }) => {
            state.students = payload;
        },
        setUserStudentCourses: (state, { payload }) => {
            state.studentCourses = payload;
        },
        setUserStudentGroups: (state, { payload }) => {
            state.studentGroups = payload;
        },
        setIsUpdate: (state, { payload }) => {
            state.isUpdate = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                userStudentApi.endpoints.fetchUserStudents.matchFulfilled,
                (state, { payload }) => {
                    state.students = payload;
                }
            )
            .addMatcher(
                userStudentApi.endpoints.fetchUserSearchStudents.matchFulfilled,
                (state, { payload }) => {
                    state.students = payload;
                }
            )
            .addMatcher(
                userStudentApi.endpoints.removeUserStudent.matchFulfilled,
                (state, { meta, payload }) => {
                    const { students: removeStudents } = meta.arg.originalArgs;
                    state.students.results = state.students.results.filter(
                        (student) => !removeStudents.includes(student.user.id)
                    );
                    state.students.count =
                        state.students.count - removeStudents.length;
                }
            )
            .addMatcher(
                userStudentApi.endpoints.addUserStudentCourses.matchFulfilled,
                (state, { meta }) => {
                    const { studentId, courses } = meta.arg.originalArgs;
                    state.students.results = state.students.results.map(
                        (student) => {
                            if (student.id === studentId) {
                                student.coursesQty += courses.length;
                            }
                            return student;
                        }
                    );
                }
            )
            .addMatcher(
                userStudentApi.endpoints.addUserStudentCoursesMany
                    .matchFulfilled,
                (state, { meta }) => {
                    const { courses, students: addStudents } =
                        meta.arg.originalArgs;
                    state.students.results = state.students.results.map(
                        (student) => {
                            if (addStudents.includes(student.user.id)) {
                                student.coursesQty += courses.length;
                            }
                            return student;
                        }
                    );
                }
            )
            .addMatcher(
                userStudentApi.endpoints.addUserStudentGroupsMany
                    .matchFulfilled,
                (state, { meta }) => {
                    const { groups, students: addStudents } =
                        meta.arg.originalArgs;
                    state.students.results = state.students.results.map(
                        (student) => {
                            if (addStudents.includes(student.user.id)) {
                                student.groupsQty += groups.length;
                            }
                            return student;
                        }
                    );
                }
            )
            .addMatcher(
                userStudentApi.endpoints.fetchUserStudentCourses.matchFulfilled,
                (state, { payload }) => {
                    state.studentCourses = payload;
                }
            )
            .addMatcher(
                userStudentApi.endpoints.removeUserStudentCourses
                    .matchFulfilled,
                (state, { meta }) => {
                    const { courses: removeCourses } = meta.arg.originalArgs;
                    state.studentCourses.results =
                        state.studentCourses.results.filter(
                            (student) => !removeCourses.includes(student.id)
                        );
                    state.studentCourses.count =
                        state.studentCourses.count - removeCourses.length;
                }
            )
            .addMatcher(
                userStudentApi.endpoints.fetchUserStudentGroups.matchFulfilled,
                (state, { payload }) => {
                    state.studentGroups = payload;
                }
            )
            .addMatcher(
                userStudentApi.endpoints.removeUserStudentGroups.matchFulfilled,
                (state, { meta }) => {
                    const { groups: removeGroups } = meta.arg.originalArgs;
                    state.studentGroups.results =
                        state.studentGroups.results.filter(
                            (student) => !removeGroups.includes(student.id)
                        );
                    state.studentGroups.count =
                        state.studentGroups.count - removeGroups.length;
                }
            );
    },
});

const userStudentActions = userStudentSlice.actions;
export { userStudentActions };

export default userStudentSlice.reducer;
