import { useState } from 'react';
import SettingsItem from 'pages/settings/common/SettingsItem';
import { DEFAULT_MAIN_BRAND_COLOR } from 'pages/settings/consts';
import { useTranslation } from 'react-i18next';
import ColorValue from 'pages/settings/common/ColorValue';
import { useSelector } from 'react-redux';
import { useAction } from 'helpers/hooks';
import { useUpdateSpaceMutation } from 'api/services';
import SettingsColorsModalWrapper from 'pages/settings/common/SettingsColors/SettingsColorsModalWrapper';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { pickTextColorBasedOnBgColor } from 'utils/functions';

const SettingsColorsMainBrand = () => {
    const { t } = useTranslation('common');
    const [isOpen, setIsOpen] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const { brandColor } = useSelector((state) => state.settingSpace);
    const { setBrandColor } = useAction();
    const [updateSpace] = useUpdateSpaceMutation();
    const [brandColorPreview, setBrandColorPreview] = useState(
        brandColor || DEFAULT_MAIN_BRAND_COLOR
    );

    const onChangeColor = (e) => setBrandColorPreview(e.target.value);
    const onResetColor = async () => {
        setBrandColor(DEFAULT_MAIN_BRAND_COLOR);
        setBrandColorPreview(DEFAULT_MAIN_BRAND_COLOR);
        await updateSpace({
            id: userSpace.id,
            brand_color: DEFAULT_MAIN_BRAND_COLOR,
        });
    };
    const onApplyColor = async () => {
        setIsOpen(false);
        setBrandColor(brandColorPreview);
        await updateSpace({
            id: userSpace.id,
            brand_color: brandColorPreview,
        });
    };
    const onOpen = () => setIsOpen(true);
    return (
        <>
            <SettingsItem
                title={t('SETTINGS_MAIN_BRAND_TITLE')}
                description={t('SETTINGS_MAIN_BRAND_DESC')}
                btnSecondaryText={t('CHANGE')}
                onReset={onResetColor}
                onApply={onOpen}
            >
                <ColorValue color={brandColor} />
            </SettingsItem>
            <SettingsColorsModalWrapper
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={t('SETTINGS_MAIN_BRAND_TITLE')}
                color={brandColorPreview}
                onApply={onApplyColor}
                onReset={onResetColor}
                onChange={onChangeColor}
                sampleComponent={
                    <Button
                        size={'large'}
                        variant={'primary'}
                        style={{
                            maxWidth: 343,
                            backgroundColor: brandColorPreview,
                            borderColor: brandColorPreview,
                            color: pickTextColorBasedOnBgColor(
                                brandColorPreview
                            ),
                        }}
                        fullWidth
                    >
                        Normal
                    </Button>
                }
            />
        </>
    );
};

export default SettingsColorsMainBrand;
