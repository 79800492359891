import React from 'react';
import DetailCourseStatisticsItem from 'pages/analytics/views/courses/DetailCourses/DetailCourseStatisticsItem';
import { Progress } from '@akhter-studios/classlytics-front-ui-components';
import { useFetchGroupDetailQuery } from 'api/services/AnalyticsService/AnalyticsService';
import useFormatDate from 'helpers/hooks/useFormatDate';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const GroupsStatistics = ({ selectedItem }) => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);

    const { data, isLoading } = useFetchGroupDetailQuery({
        spaceId: userSpace?.id,
        id: selectedItem.id,
    });
    const createdDate = useFormatDate(data?.created_at);
    if (isLoading) return <div>{t('GENERAL_LOADING')}</div>;
    if (!data) {
        return null;
    }
    return (
        <section className="analytics-courses__detail">
            <div className="analytics-courses__title">{data?.name}</div>
            <DetailCourseStatisticsItem
                title={t('CREATED')}
                value={createdDate}
            />
            <DetailCourseStatisticsItem
                title={t('ANALYTICS_PROGRESS')}
                value={data?.average_score || '-'}
            />

            {data.course_progress.length > 0 && (
                <div className="analytics-courses__progress">
                    <div className="analytics-courses__progress-title">
                        {t('COURSE_PROGRESS')}
                    </div>
                    {data.course_progress.map((x) => (
                        <div
                            className="analytics-courses__progress-item"
                            key={x.course_id}
                        >
                            <div className="j-c-b">
                                <span>{x.name}</span>
                                <div>{x.progress_percent || 0}%</div>
                            </div>
                            <Progress
                                value={x.progress_percent || 0}
                                max={100}
                            />
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
};

export default GroupsStatistics;
