import { useSelector } from 'react-redux';
import Courses from 'pages/courses/Courses';
import StudentCourses from 'pages/courses/student-courses/StudentCourses';
import Spinner from 'components/common/Spinner/Spinner';

const renderComponent = {
    mentor: <Courses />,
    student: <StudentCourses />,
};

const CoursesWrapper = () => {
    const { profile } = useSelector((state) => state.profile);
    return profile?.role ? renderComponent[profile.role] : <Spinner />;
};

export default CoursesWrapper;
