import { Icon, Badge } from '@akhter-studios/classlytics-front-ui-components';
import HeaderProfile from '../HeaderProfile';
import './HeaderHelper.scss';
import { useAction } from 'helpers/hooks';
import { useSelector } from 'react-redux';

const HeaderHelper = () => {
    const { notificationToggle } = useAction();

    const { unread_qty } = useSelector((state) => state.notification);
    return (
        <div className={'header-helper'}>
            {/*<Badge value={1}>*/}
            {/*    <Icon className={'header-helper__icon'} name={'chat'} />*/}
            {/*</Badge>*/}
            {unread_qty > 0 ? (
                <Badge value={unread_qty}>
                    <Icon
                        onClick={notificationToggle}
                        className={'header-helper__icon'}
                        name={'notification'}
                    />
                </Badge>
            ) : (
                <Icon
                    onClick={notificationToggle}
                    className={'header-helper__icon'}
                    name={'notification'}
                />
            )}
            <HeaderProfile />
        </div>
    );
};

export default HeaderHelper;
