import React, { useEffect, useState } from 'react';
import AnalyticsSearchFilter from 'pages/analytics/common/AnalyticsSearchFilter/AnalyticsSearchFilter';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import {
    coursesTableHeadings,
    reviewTableBody,
    reviewTableHeadings,
} from 'pages/analytics/common/consts';
import './Courses.scss';
import { useNavigate } from 'react-router-dom';
import { useFetchReviewCoursesInfoQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useSelector } from 'react-redux';
import EmptyPlaceholders from 'pages/analytics/common/EmptyPlaceholders/EmptyPlaceholders';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'helpers/hooks';
const Courses = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const debouncedSearchInput = useDebounce(search, 800);
    const { data, isLoading, refetch, ...rest } =
        useFetchReviewCoursesInfoQuery(
            {
                spaceId: userSpace?.id,
                page: page - 1,
                search: debouncedSearchInput,
            },
            { skip: !userSpace }
        );
    const navigate = useNavigate();
    const goToDetail = (item) =>
        navigate(`/content/analytics/detail/course/${item.id}/lesson`);

    useEffect(() => {
        refetch();
    }, [page, debouncedSearchInput]);
    const onSearch = (e) => setSearch(e.target.value);

    if (isLoading) return <div>{t('GENERAL_LOADING')}</div>;

    return (
        <section className="students analytics-courses">
            <AnalyticsSearchFilter
                searchPlaceholder={t('SEARCH_COURSES')}
                totalCount={data.total}
                onSearch={onSearch}
            />
            {!(data?.items || []).length ? (
                <EmptyPlaceholders
                    title={t('ANALYTICS_EMPTY_TITLE')}
                    description={t('ANALYTICS_EMPTY_REVIEW_DESC')}
                />
            ) : (
                <AnalyticsTable
                    headings={coursesTableHeadings}
                    body={data.items || []}
                    withImgColumn
                    handler={goToDetail}
                    total={data.total}
                    page={page}
                    onChangePage={setPage}
                    withoutBorder
                />
            )}
        </section>
    );
};

export default Courses;
