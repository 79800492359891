import {useDispatch} from "react-redux";
import {setIsSidebarShow} from "store/reducers/utilSlice";
import {Icon} from "@akhter-studios/classlytics-front-ui-components";
import "./HeaderMenuIcon.scss";

const HeaderMenuIcon = () => {
    const dispatch = useDispatch();
    const handleSidebarShow = () => dispatch(setIsSidebarShow());

    return (
        <Icon className={"header-menu-icon"} name={"menu"} onClick={handleSidebarShow}/>
    );
};

export default HeaderMenuIcon;
