import React, { useEffect, useState } from 'react';
import NotificationItem from 'pages/notification/NotificationItem';
const NotificationList = ({ notifications, setNotifyItem }) => {
    return (
        <ul className="notifications__list">
            {notifications?.map((x, index) => (
                <React.Fragment key={x.id}>
                    <NotificationItem
                        index={index}
                        item={x}
                        setNotifyItem={setNotifyItem}
                    />
                </React.Fragment>
            ))}
        </ul>
    );
};

export default NotificationList;
