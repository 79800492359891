export const showAllList = [
    {
        id: 1,
        title: 'SORT',
        value: '',
    },
    {
        id: 2,
        title: 'NEW_FIRST',
        value: 'desc',
    },
    {
        id: 3,
        title: 'OLD_FIRST',
        value: 'asc',
    },
    {
        id: 4,
        title: 'FROM_A_TO_Z',
        value: 'not',
    },
    {
        id: 5,
        title: 'FROM_Z_TO_A',
        value: 'exist',
    },
];

export const typeFileList = [
    {
        id: 1,
        title: 'FILE_TYPE',
        value: 'TEXT',
    },
    {
        id: 2,
        title: 'FILE_TYPE_ARCHIVE',
        value: 'ARCHIVE',
    },
    {
        id: 3,
        title: 'FILE_TYPE_IMAGE',
        value: 'IMAGE',
    },
    {
        id: 4,
        title: 'FILE_TYPE_AUDIO',
        value: 'AUDIO',
    },
    {
        id: 5,
        title: 'FILE_TYPE_OTHERS',
        value: 'OTHER',
    },
];

export const moreList = ({
    download,
    rename,
    send,
    moveFolder,
    infoFile,
    isFolder,
}) => {
    return [
        {
            id: 1,
            name: 'download',
            title: 'FILE_DOWNLOAD',
            isHide: isFolder,
            handler: download,
        },
        {
            id: 2,
            name: 'send',
            title: 'FILE_SEND_TO_COURSES',
            handler: send,
        },
        // {
        //     id: 3,
        //     name: 'folder',
        //     title: 'Поместить в папку',
        //     isHide: isFolder,
        //     handler: moveFolder,
        // },
        {
            id: 4,
            name: 'info',
            title: 'FILE_INFO',
            handler: infoFile,
        },
        {
            id: 5,
            name: 'rename',
            title: 'FILE_RENAME',
            handler: rename,
        },
    ];
};

export const iconsView = {
    pptx: 'ppt',
    pptm: 'ppt',
    ppt: 'ppt',
    doc: 'doc',
    docm: 'doc',
    docx: 'doc',
    xls: 'xlsx',
    xlsx: 'xlsx',
    xlt: 'xlsx',
    jpeg: 'img-icon',
    jpg: 'img-icon',
    png: 'img-icon',
    pdf: 'pdf',
    rar: 'archive',
    zip: 'archive',
    arj: 'archive',
    '7z': 'archive',
    mp3: 'audio',
    txt: 'document-text',
};
