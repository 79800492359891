import {List} from "@akhter-studios/classlytics-front-ui-components";
import InfiniteScroll from "react-infinite-scroll-component";
import "./UsersTableList.scss";

const UsersTableList = ({items, pageSize, count, loadMore, hasMore, renderItem}) => {
    return (
        count > pageSize
            ? <InfiniteScroll
                className={"users-table-list"}
                dataLength={items?.length}
                next={loadMore}
                hasMore={hasMore}
                loader={null}
            >
                {items.map(renderItem)}
            </InfiniteScroll>
            : <List
                className={"users-table-list"}
                items={items}
                renderItem={renderItem}
            />
    );
};

export default UsersTableList;
