import React, { memo } from 'react';
import './SettingTest.scss';
import { Icon } from 'components/common';
import { useTranslation } from 'react-i18next';
const SettingTest = ({ setIsOpen }) => {
    const { t } = useTranslation('common');
    return (
        <div className="setting-test block-interface" onClick={setIsOpen}>
            <span>{t('MENTOR_TEST_SETTINGS')}</span>
            <Icon name="settings" />
        </div>
    );
};

export default memo(SettingTest);
