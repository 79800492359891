import {
    Modal,
    Heading,
    Typography,
    Button,
} from '@akhter-studios/classlytics-front-ui-components';
import './CoursesDeleteModal.scss';
import { useTranslation } from 'react-i18next';

const CoursesDeleteModal = ({ isOpen, setIsOpen, onDelete }) => {
    const { t } = useTranslation('common');
    const onDeleteClick = () => {
        onDelete();
        setIsOpen(false);
    };
    return (
        <Modal
            className={'coursesDeleteModal'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <Heading className={'coursesDeleteModal__title'} variant={'h4'}>
                {t('COURSES_DELETE_TEXT')}
            </Heading>
            <Typography
                className={'coursesDeleteModal__desc'}
                variant={'body3'}
            >
                {t('COURSES_DELETE_DESC')}
            </Typography>
            <div className={'coursesDeleteModal__buttons'}>
                <Button
                    variant={'secondary'}
                    size={'small'}
                    onClick={onDeleteClick}
                >
                    {t('COURSES_DELETE_DESC_YES_SURE')}
                </Button>
                <Button
                    variant={'primary'}
                    size={'small'}
                    onClick={() => setIsOpen(false)}
                >
                    {t('PUBLISH_CANCEL')}
                </Button>
            </div>
        </Modal>
    );
};

export default CoursesDeleteModal;
