import { nanoid } from 'nanoid';

const actions = ({ id, onNavigateToCourse, onRemove }) => [
    {
        id: nanoid(),
        text: 'NAVIGATE_TO_THE_COURSE',
        border: true,
        onClick: () => onNavigateToCourse(),
    },
    {
        id: nanoid(),
        text: 'REMOVE_ACCESS_TO_THE_COURSE',
        onClick: () => onRemove(id),
    },
];

export { actions };
