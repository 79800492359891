import React from 'react';
import PlugImg from 'assets/img/png/plug.png';
import './Plug.scss';
import {
    Heading,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';

const Plug = ({ title, description }) => {
    const { t } = useTranslation('common');
    return (
        <div className={'plug'}>
            <img src={PlugImg} alt="plug" className={'plug__img'} />
            {title ? (
                <Heading className={'plug__title'} variant={'h1'}>
                    {t(title)}
                </Heading>
            ) : null}
            {description ? (
                <Typography className={'plug__desc'} variant={'body4'}>
                    {t(description)}
                </Typography>
            ) : null}
        </div>
    );
};

export default Plug;
