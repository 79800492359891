import { generateYearsBetween, getDaysInMonth } from 'utils/functions';

const generateBirthYears = () => {
    const currentYear = new Date().getFullYear();

    return generateYearsBetween(1900, currentYear).map((y) => ({
        label: y,
        value: y,
    }));
};

const generateBirthDays = ({ month = 2, year = 2021 }) => {
    return getDaysInMonth(month, year).map((y) => ({
        label: new Date(y).getDate(),
        value: new Date(y).getDate(),
    }));
};

export { generateBirthYears, generateBirthDays };
