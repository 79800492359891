import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/common';
import cn from 'classnames';
import { Icon as LibraryIcon } from '@akhter-studios/classlytics-front-ui-components';
import './index.scss';
import { PREVIEW_STATUS } from 'components/layout/PreviewContext';

const CourseMaterialNavigation = ({
    prev,
    next,
    classNames,
    nextIsDisabled,
}) => {
    const { courseId, spaceId } = useParams();
    const { t } = useTranslation('common');
    const getLink = (material) =>
        `/content/course/${courseId}/${
            material.type === 'lesson' ? 'lesson' : 'student/test'
        }/${material.id}/space/${spaceId}`;

    const prevLink = useMemo(() => (prev ? getLink(prev) : ''), [prev]);
    const nextLink = useMemo(() => (next ? getLink(next) : ''), [next]);
    return (
        <Grid
            container
            justifyContent={'space-between'}
            className={`course-navigation ${classNames}`}
        >
            <Grid className={'course-navigation__course'} item xs={0} sm={6}>
                <Link
                    className={'course-navigation__link'}
                    to={`/content/courses/detailed/${spaceId}/${courseId}`}
                >
                    <Icon name="course-content" />
                    <span className={'course-navigation__text'}>
                        {t('STUDENT_GO_TO_COURSE')}
                    </span>
                </Link>
            </Grid>
            <Grid
                className={'course-navigation__materials'}
                item
                container
                xs={12}
                sm={6}
                justifyContent={'flex-end'}
            >
                <Link
                    className={cn('course-navigation__link', {
                        'course-navigation__link--disabled': !prevLink,
                    })}
                    to={prevLink}
                >
                    <LibraryIcon name={'arrow-left'} />
                    <span>{t('STUDENT_MATERIAL_PREV')}</span>
                </Link>
                <Link
                    className={cn(
                        'course-navigation__link course-navigation__link--next',
                        {
                            'course-navigation__link--disabled':
                                !next || nextIsDisabled,
                        }
                    )}
                    to={nextLink}
                >
                    <span>{t('STUDENT_MATERIAL_NEXT')}</span>
                    <LibraryIcon name={'arrow-right'} />
                </Link>
            </Grid>
        </Grid>
    );
};

export default CourseMaterialNavigation;
