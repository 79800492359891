import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useFetchCourseDetailMaterialsQuery,
    useFinishCourseMutation,
    useSaveProgressCourseMutation,
} from 'api/services';
import BlockItem from 'pages/course-test/views/BlockItem/BlockItem';
import './StudentTest.scss';
import { createTest } from 'pages/course-test/functions';
import { fetchTestStudent } from 'pages/course-test/course-test-services';
import { useAction } from 'helpers/hooks';
import {
    Button,
    DefaultInput,
} from '@akhter-studios/classlytics-front-ui-components';
import ModalTest from 'pages/course-test/views/StudentTest/Modal';
import { useSelector } from 'react-redux';
import { nextOrCompletedCourseBtn } from 'pages/courses/CoursesDetailed/constants';
import HeaderStudentTest from 'components/common/Header/HeaderStudentTest/HeaderStudentTest';
import useSaveData from 'helpers/hooks/useSaveData';
import { Sidebar } from 'components/common';
import { useTranslation } from 'react-i18next';
import { setSessionStorage } from 'utils/functions/helpers';
import { getSessionStorageData } from 'utils/functions';
import CoursesStudentContent from 'pages/courses/CoursesDetailed/CoursesStudentContent';
import CourseMaterialNavigation from 'pages/courses/CourseMaterialNavigation';

const Index = ({}) => {
    const { testId, spaceId, courseId } = useParams();
    const [test, setTest] = useState(null);
    const [contentSidebarOpen, setContentSidebarOpen] = useState(false);
    const { setTimerAction, setResultPercentAction } = useAction();
    const { resultPercent, timeIsOver } = useSelector((state) => state.test);
    const [saveProgressCourse] = useSaveProgressCourseMutation();
    const [finishCourse] = useFinishCourseMutation();
    const { userSpace } = useSelector((state) => state.space);
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [modal, setModal] = useState({
        type: '',
        open: false,
    });

    const [answeredBlocks, handleChangeAnsweredBlocks, reset] = useSaveData({
        isGrouped: true,
    });

    const { data: materials, refetch } = useFetchCourseDetailMaterialsQuery(
        { spaceId: userSpace?.id, id: courseId },
        {
            skip: !userSpace,
            refetchOnMountOrArgChange: true,
        }
    );

    const navigate = useNavigate();
    useEffect(() => {
        if (test?.isPassed) {
            sessionStorage.clear();
        }
    }, [test]);
    useEffect(() => {
        setContentSidebarOpen(false);
        refetch();
        if (test) {
            setSessionStorage('testData', test);
        }
    }, [test]);
    useEffect(() => {
        if (test?.criterion) {
            const isDisabled =
                Object.keys(answeredBlocks).length === test.criterion.length;
            setIsDisabledBtn(test?.isPassed ? false : !isDisabled);
        }
    }, [test?.criterion]);

    const handlerGetInfoTest = (retest) => {
        if (sessionStorage.getItem('testId') !== testId) {
            sessionStorage.clear();
        }
        fetchTestStudent(spaceId, testId, retest)
            .then((result) => {
                setTest(getSessionStorageData('testData') || result);
                setTimerAction(result.duration);
                sessionStorage.setItem('testId', testId);
                reset();
            })
            .catch(() => {})
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        handlerGetInfoTest();
        return () => reset();
    }, [testId]);
    const { ...tests } = createTest({
        test,
        setTest,
        timeIsOver,
        spaceId,
        handleChangeAnsweredBlocks,
        saveProgressCourse: test?.nextRoute ? saveProgressCourse : () => {},
        test_id: testId,
        setResultPercentAction,
        isStudent: true,
        setModal,
        modal,
    });
    const redirectTo = (url) => {
        navigate(url);
    };
    const finishCourseClick = () => {
        finishCourse({ spaceId: userSpace.id, courseId }).then(() => {
            sessionStorage.clear();
            navigate('/content/courses');
        });
    };
    const { t } = useTranslation('common');

    const { title, handler } = nextOrCompletedCourseBtn({
        spaceId,
        courseId,
        redirectTo,
        moduleId: testId,
        moduleType: 'test',
        finish: finishCourseClick,
        nextRoute: test?.nextRoute || {},
        saveProgressCourse: test?.nextRoute ? saveProgressCourse : () => {},
        t,
    });
    const existResult = typeof resultPercent === 'number' || test?.isPassed;

    const handlers = () => {
        sessionStorage.clear();
        !existResult ? tests.completeTestStudent() : handler();
    };

    useEffect(() => {
        if (timeIsOver) {
            handlers();
        }
    }, [timeIsOver]);

    const isHiddenBtn = () => {
        if (test?.isCompleted && test?.attemptsQty > 0) {
            return true;
        }
        if (!test?.isCompleted) {
            return true;
        }
    };

    if (!test || isLoading) return <></>;
    return (
        <>
            <HeaderStudentTest isPassed={test?.isPassed} />
            <Sidebar />
            <CoursesStudentContent
                materials={materials}
                contentOpen={contentSidebarOpen}
                setContentOpen={setContentSidebarOpen}
            />
            {test && (
                <section className="section-test__create student-test layout-main">
                    <CourseMaterialNavigation
                        classNames={'student-test__material-navigation'}
                        next={test?.next}
                        prev={test?.prev}
                        nextIsDisabled={!test?.fullPassed}
                    />
                    {test?.name && (
                        <DefaultInput
                            value={test?.name}
                            className="test-name"
                            disabled={true}
                        />
                    )}
                    {test?.criterion.map((x, index) => (
                        <React.Fragment key={x.id}>
                            <BlockItem
                                index={index}
                                item={x}
                                {...x}
                                {...tests}
                                isStudentReadOnly={existResult || test.isPassed}
                                isStudent={true}
                            />
                        </React.Fragment>
                    ))}
                    {isHiddenBtn() && (
                        <div className="student-test__btn">
                            <Button
                                onClick={handlers}
                                disabled={existResult ? false : isDisabledBtn}
                                type={'submit'}
                                variant={'primary'}
                                fullWidth
                            >
                                {existResult ? title : t('STUDENT_TEST_FINISH')}
                            </Button>
                        </div>
                    )}
                    {test.attemptsQty > 0 && (
                        <div
                            onClick={() => handlerGetInfoTest(true)}
                            className="test-link bold"
                        >
                            {t('STUDENT_TEST_ALERT_BUTTON_FAILED')}
                        </div>
                    )}

                    <ModalTest
                        handler={handler}
                        modal={modal}
                        reTakeTheTest={handlerGetInfoTest}
                        percent={resultPercent}
                        setModal={setModal}
                        test={test}
                        btnTitle={title}
                    />
                </section>
            )}
        </>
    );
};

export default Index;
