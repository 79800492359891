import LinkTool from '@editorjs/link';

export default class CustomLink extends LinkTool {
    static get pasteConfig() {
        return {
            patterns: {
                link: /^(ftp|http|https):\/\/[^ "]+$/,
            },
        };
    }
    onFetch(response) {
        if (!response || !response.success) {
            this.fetchingFailed(this.api.i18n.t('Ссылка не валидна'));
            return;
        }
        const metaData = response.meta;
        const link = response.link || this.data.link;
        this.data = {
            meta: metaData,
            link,
        };
        if (!metaData) {
            this.fetchingFailed(this.api.i18n.t('Ошибка ответа от сервера'));
            return;
        }
        this.hideProgress().then(() => {
            this.nodes.inputHolder.remove();
            this.showLinkPreview(metaData);
        });
    }
    onPaste(event) {
        switch (event.type) {
            case 'pattern':
                this.nodes.input.textContent = event.detail.data;
                break;
        }
    }
}
