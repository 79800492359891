import { Input } from '@akhter-studios/classlytics-front-ui-components';
import './AuthFields.scss';
import { useTranslation } from 'react-i18next';

const AuthFields = (props) => {
    const { t } = useTranslation('common');
    const {
        fields,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleChangeDomain,
        error,
    } = props;
    return fields.map(
        ({
            id,
            type,
            name,
            placeholder,
            label,
            domain,
            isAnotherHandle,
            isError = false,
        }) => (
            <Input
                groupClassName={'auth-fields'}
                key={id}
                id={id}
                type={type}
                name={name}
                label={t(label)}
                placeholder={placeholder}
                domain={domain}
                helperText={
                    (touched[name] && errors[name]) || (isError && error)
                }
                error={
                    (touched[name] && errors[name] && Boolean(errors)) ||
                    (isError && Boolean(error))
                }
                value={values[name]}
                onChange={(e) => {
                    isAnotherHandle && handleChangeDomain(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                fullWidth={props.fullWidth}
            />
        )
    );
};

export default AuthFields;
