import { Plug } from 'components/common';
import CreateCourseSettingsMenu from './CreateCourseSettingsMenu';
import { useLocation, useParams } from 'react-router-dom';
import CreateCourseSettingsAboutCourse from 'pages/courses/Course/create-course-settings/CreateCourseSettingsAboutCourse';
import './CreateCourseSettings.scss';

const renderComponent = {
    '': <CreateCourseSettingsAboutCourse />,
    '?scope=aboutCourse': <CreateCourseSettingsAboutCourse />,
    '?scope=students': (
        <Plug
            title={'COURSE_SETTINGS_STUDENTS_PLUG_TITLE'}
            description={'COURSE_SETTINGS_STUDENTS_PLUG_DESC'}
        />
    ),
    '?scope=mentors': (
        <Plug
            title={'COURSE_SETTINGS_MENTORS_PLUG_TITLE'}
            description={'COURSE_SETTINGS_MENTORS_PLUG_DESC'}
        />
    ),
    // '?scope=students': <CreateCourseSettingsStudents />,
    // '?scope=mentors': <CreateCourseSettingsMentors />,
};

const CreateCourseSettings = () => {
    const { search } = useLocation();
    const { courseId } = useParams();

    return (
        <article className={'create-course-settings layout-main'}>
            <CreateCourseSettingsMenu courseId={courseId} />
            <div className={'create-course-settings__content'}>
                {renderComponent[search]}
            </div>
        </article>
    );
};

export default CreateCourseSettings;
