import React, { useState } from 'react';
import StudentDetailFilterSearch from 'pages/analytics/views/students/StudentDetail/StudentDetailFilterSearch';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import {
    courseBody,
    coursesHeading,
} from 'pages/analytics/views/students/consts';
import DialogModalCustom from 'components/common/DialogModalCustom/DialogModalCustom';
import CourseStatistic from 'pages/analytics/views/students/StudentDetail/CourseStatistic/CourseStatistic';
import { useParams, useOutletContext } from 'react-router-dom';
import { useFetchStudentDetailCoursesQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useSelector } from 'react-redux';
import useFormatDate from 'helpers/hooks/useFormatDate';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'helpers/hooks';
import { removeKeysIsEmpty } from 'utils/functions/helpers';

const StudentDetailCourse = () => {
    const { t } = useTranslation('common');
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { totalCourses } = useOutletContext();
    const [search, setSearch] = useState('');
    const [type, setType] = useState(null);
    const debouncedSearchInput = useDebounce(search, 800);
    const { data } = useFetchStudentDetailCoursesQuery({
        spaceId: userSpace?.id,
        id,
        ...removeKeysIsEmpty({
            search: debouncedSearchInput,
            progress: type,
        }),
    });
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const selectedCourseDetail = (item) => {
        setSelectedCourse(item);
        setIsOpen(!isOpen);
    };
    const onSearch = (e) => setSearch(e.target.value);
    return (
        <div className="study-details course-statistics">
            <StudentDetailFilterSearch
                totalPlaceholder={t('INVITED_COURSES')}
                searchPlaceholder={t('SEARCH_COURSES')}
                isFilter={true}
                isStudent={true}
                onSearch={onSearch}
                setType={setType}
                totalCount={totalCourses}
            />
            <AnalyticsTable
                withoutBorder
                handler={selectedCourseDetail}
                isClickable
                headings={coursesHeading}
                body={courseBody({ data: data || [], t })}
            />
            <DialogModalCustom
                open={isOpen}
                setOpen={setIsOpen}
                customHeight={window.innerHeight / 2}
            >
                {isOpen && <CourseStatistic selectedCourse={selectedCourse} />}
            </DialogModalCustom>
        </div>
    );
};

export default StudentDetailCourse;
