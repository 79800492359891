import hexToRgbA from 'utils/functions/hexToRgbA';

const pickTextColorBasedOnBgColor = (hex) => {
    try {
        let rgba = hexToRgbA(hex);
        rgba = rgba.match(/\d+/g);
        if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
            return '#061822';
        } else {
            return '#FFFFFF';
        }
    } catch (e) {
        console.log(e);
    }
};

export default pickTextColorBasedOnBgColor;
