export function make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);
    if (Array.isArray(classNames)) {
        el.classList.add(...classNames);
    } else if (classNames) {
        el.classList.add(classNames);
    }
    for (const attrName in attributes) {
        el[attrName] = attributes[attrName];
    }
    return el;
}

export function getUrlExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

export function isEmpty(node) {
    let content;
    if (node.nodeType !== Node.ELEMENT_NODE) {
        content = node.textContent;
    } else {
        content = node.innerHTML;
        content = content.replaceAll('<br>', '');
    }

    return content.trim().length === 0;
}

export function fragmentToString(fragment) {
    const div = make('div');

    div.appendChild(fragment);

    return div.innerHTML;
}

export class Caret {
    constructor() {
        this.savedFakeCaret = undefined;
    }

    save() {
        const range = Caret.range;
        const cursor = make('span');

        cursor.hidden = true;

        range.insertNode(cursor);

        this.savedFakeCaret = cursor;
    }

    restore() {
        if (!this.savedFakeCaret) {
            return;
        }

        const sel = window.getSelection();
        const range = new Range();

        range.setStartAfter(this.savedFakeCaret);
        range.setEndAfter(this.savedFakeCaret);

        sel.removeAllRanges();
        sel.addRange(range);

        setTimeout(() => {
            this.savedFakeCaret.remove();
        }, 150);
    }

    static get range() {
        const selection = window.getSelection();

        return selection && selection.rangeCount
            ? selection.getRangeAt(0)
            : null;
    }

    static extractFragmentFromCaretPositionTillTheEnd() {
        const selection = window.getSelection();

        if (!selection.rangeCount) {
            return;
        }

        const selectRange = selection.getRangeAt(0);
        let startNode = selectRange.startContainer;

        if (startNode.nodeType !== Node.ELEMENT_NODE) {
            startNode = startNode.parentNode;
        }

        const currentBlockInput = startNode.closest('[contenteditable]');

        selectRange.deleteContents();

        const range = selectRange.cloneRange();

        range.selectNodeContents(currentBlockInput);
        range.setStart(selectRange.endContainer, selectRange.endOffset);

        return range.extractContents();
    }

    static focus(element, atStart = true) {
        const range = document.createRange();
        const selection = window.getSelection();

        range.selectNodeContents(element);
        range.collapse(atStart);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    static isAtStart() {
        const selection = window.getSelection();

        if (selection.focusOffset > 0) {
            return false;
        }

        const focusNode = selection.focusNode;

        const leftSiblings = Caret.getHigherLevelSiblings(focusNode, 'left');

        const nothingAtLeft = leftSiblings.every((node) => {
            return isEmpty(node);
        });

        return nothingAtLeft;
    }

    static getHigherLevelSiblings(from, direction = 'left') {
        let current = from;
        const siblings = [];

        while (
            current.parentNode &&
            current.parentNode.contentEditable !== 'true'
        ) {
            current = current.parentNode;
        }
        const sibling =
            direction === 'left' ? 'previousSibling' : 'nextSibling';

        while (current[sibling]) {
            current = current[sibling];
            siblings.push(current);
        }
        return siblings;
    }
}
