import {useLazyFetchStudentCoursesQuery} from "api/services";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {StudentCoursesAccess} from "../index";

const StudentCoursesAccessContainer = () => {
    const {userSpace} = useSelector(state => state.space);
    const [trigger] = useLazyFetchStudentCoursesQuery();
    const [availableCourses, setAvailableCourses] = useState(null);
    const [page, setPage] = useState(1);
    const pageSize = 15;
    const filter = "available";

    const loadMoreAccessCourses = async () => {
        if (!availableCourses.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const {data} = await trigger({id: userSpace?.id, page: page + 1, page_size: pageSize, filter});
        const modifiedResults = [...availableCourses.results, ...data.results];
        setAvailableCourses({results: modifiedResults, hasMore: data.hasMore});
    };

    useEffect(() => userSpace && trigger({id: userSpace?.id, page, page_size: pageSize, filter}).then(({data}) => setAvailableCourses(data)), [userSpace]);
    return (
        availableCourses?.results.length > 0 && <StudentCoursesAccess
            courses={availableCourses.results}
            hasMore={availableCourses.hasMore}
            loadMore={loadMoreAccessCourses}
        />
    );
};

export default StudentCoursesAccessContainer;
