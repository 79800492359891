import {
    Heading,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import DoneLottie from './DoneLottie';
import DoneRedirect from './DoneRedirect';
import 'pages/auth/Auth.scss';
import './Done.scss';
import { useTranslation } from 'react-i18next';

const Done = () => {
    const { t } = useTranslation('common');
    return (
        <>
            <DoneLottie />
            <Heading className={'done-title'} variant={'h1'}>
                {t('AUTH_DONE_TITLE')}
            </Heading>
            <Typography className={'done-desc'} variant={'body3'}>
                {t('AUTH_DONE_REDIRECT')}
            </Typography>
            <DoneRedirect />
        </>
    );
};

export default Done;
