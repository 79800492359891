import { nanoid } from 'nanoid';

const actions = ({
    onDropdownAction,
    onEditCourse,
    onDuplicate,
    onPublishCourse,
    onDeleteCourse,
    onArchiveCourse,
    onShowDeleteModal,
    onDeleteCourseAfter,
    onDeleteCourseCancel,
    onNavigateToSettings,
    remaining,
}) => ({
    active: [
        {
            id: nanoid(),
            key: 'edit',
            iconName: 'edit',
            name: 'EDIT_COURSE',
            onClick: () => onDropdownAction(onEditCourse),
        },
        {
            id: nanoid(),
            iconName: 'duplicate',
            name: 'DUPLICATE_COURSE',
            onClick: () => onDropdownAction(onDuplicate),
        },
        {
            id: nanoid(),
            iconName: 'settings-square',
            name: 'GO_TO_COURSE_SETTINGS',
            onClick: () => onDropdownAction(onNavigateToSettings),
            isDivider: true,
        },
        {
            id: nanoid(),
            key: 'archived',
            iconName: 'archive',
            name: 'MOVE_TO_ARCHIVE',
            onClick: () => onDropdownAction(onArchiveCourse),
        },
        {
            id: nanoid(),
            iconName: 'trash',
            name: 'DELETE_COURSE',
            onClick: () => onDropdownAction(onShowDeleteModal),
        },
    ],
    inactive: [
        {
            id: nanoid(),
            iconName: 'upload',
            name: 'PUBLISH_CHANGES',
            isDivider: true,
            onClick: () => onDropdownAction(onPublishCourse),
        },
        {
            id: nanoid(),
            iconName: 'edit',
            name: 'EDIT_COURSE',
            onClick: () => onDropdownAction(onEditCourse),
        },
        {
            id: nanoid(),
            iconName: 'duplicate',
            name: 'DUPLICATE_COURSE',
            onClick: () => onDropdownAction(onDuplicate),
        },
        {
            id: nanoid(),
            iconName: 'settings-square',
            name: 'GO_TO_COURSE_SETTINGS',
            onClick: () => onDropdownAction(onNavigateToSettings),
            isDivider: true,
        },
        {
            id: nanoid(),
            iconName: 'archive',
            name: 'MOVE_TO_ARCHIVE',
            onClick: () => onDropdownAction(onArchiveCourse),
        },
        {
            id: nanoid(),
            iconName: 'trash',
            name: 'DELETE_COURSE',
            onClick: () => onDropdownAction(onDeleteCourseAfter),
        },
    ],
    archived: [
        {
            id: nanoid(),
            iconName: 'upload',
            name: 'PUBLISH_COURSE',
            isDivider: true,
            onClick: () => onDropdownAction(onPublishCourse),
        },
        {
            id: nanoid(),
            iconName: 'edit',
            name: 'EDIT_COURSE',
            onClick: () => onDropdownAction(onEditCourse),
        },
        {
            id: nanoid(),
            iconName: 'duplicate',
            name: 'DUPLICATE_COURSE',
            onClick: () => onDropdownAction(onDuplicate),
        },
        {
            id: nanoid(),
            iconName: 'settings-square',
            name: 'GO_TO_COURSE_SETTINGS',
            onClick: () => onDropdownAction(onNavigateToSettings),
            isDivider: true,
        },
        {
            id: nanoid(),
            iconName: 'archive',
            name: 'CANCEL_DELETION',
            onClick: () =>
                onDropdownAction(() => onDeleteCourseCancel('inactive')),
            disabled: remaining,
        },
        {
            id: nanoid(),
            iconName: 'trash',
            name: 'FORCIBLY_DELETE',
            onClick: () => onDropdownAction(onDeleteCourse),
        },
    ],
});

export { actions };
