import { Search } from '@akhter-studios/classlytics-front-ui-components';
import HeaderLogo from './HeaderLogo';
import HeaderHelper from './HeaderHelper';
import './Header.scss';
import SavingStatus from 'components/common/Header/SavingStatus';
import { useSavingStatus } from 'components/layout/SavingStatusContext';
import HeaderBreadcrumbs from 'components/common/Header/HeaderBreadcrumbs';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetCourseByNameMutation } from 'api/services';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'helpers/hooks';

const Header = ({ hiddenSearch, hiddenBreadCrumbs, isMobileHeader }) => {
    const { t } = useTranslation('common');
    const { status } = useSavingStatus();
    const { search: courseSearch } = useSelector((state) => state.foundCourses);
    const [searchInput, setSearchInput] = useState(courseSearch);
    const debouncedSearchInput = useDebounce(searchInput, 800);
    const [searchCourse] = useGetCourseByNameMutation();
    const { userSpace } = useSelector((state) => state.space);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const [searchParams] = useSearchParams();
    const courseStatus = useMemo(
        () => searchParams.get('scope') || 'active',
        [searchParams]
    );

    useEffect(() => {
        searchCourse({
            space_id: userSpace?.id,
            search: debouncedSearchInput,
            status: courseStatus,
        });
    }, [debouncedSearchInput, courseStatus]);

    const onSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    if (isMobileHeader) {
        return <header className="header">{isMobileHeader}</header>;
    }
    return (
        <header
            className="header"
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <HeaderLogo />
            {!hiddenBreadCrumbs && <HeaderBreadcrumbs />}
            {status && <SavingStatus text={status} />}
            {!hiddenSearch && (
                <Search
                    groupClassName={'header-search'}
                    fullWidth
                    placeholder={t('SEARCH_PLACEHOLDER')}
                    value={searchInput}
                    onChange={onSearchChange}
                />
            )}
            <HeaderHelper />
        </header>
    );
};

export default Header;
