import { memo } from 'react';
import { Link } from 'react-router-dom';
import { AUTH_FORGOT_ROUTE } from 'utils/consts';
import { Typography } from '@akhter-studios/classlytics-front-ui-components';
import './LoginLink.scss';
import { useTranslation } from 'react-i18next';

const LoginLink = () => {
    const { t } = useTranslation('common');
    return (
        <Typography className={'login-link'} variant={'body4'}>
            <Link to={AUTH_FORGOT_ROUTE}>{t('FORGOT_PASSWORD')}</Link>
        </Typography>
    );
};

export default memo(LoginLink);
