import {
    InviteMentorModal,
    InviteStudentModal,
    AddGroupModal,
} from 'pages/users/common';

const userData = {
    title: {
        '': 'STUDENTS',
        users: 'STUDENTS',
        students: 'STUDENTS',
        admins: 'ADMINS',
        groups: 'GROUPS',
    },
    btnText: {
        '': 'INVITE_STUDENTS',
        users: 'INVITE_STUDENTS',
        students: 'INVITE_STUDENTS',
        admins: 'INVITE_ADMIN',
        groups: 'CREATE_GROUP',
    },
    mobileBtnText: {
        '': 'INVITE',
        users: 'INVITE',
        students: 'INVITE',
        admins: 'INVITE',
        groups: 'CREATE',
    },
    modal: {
        '': (props) =>
            props.isOpen ? <InviteStudentModal {...props} /> : null,
        users: (props) =>
            props.isOpen ? <InviteStudentModal {...props} /> : null,
        students: (props) =>
            props.isOpen ? <InviteStudentModal {...props} /> : null,
        admins: (props) =>
            props.isOpen ? <InviteMentorModal {...props} /> : null,
        groups: (props) => (props.isOpen ? <AddGroupModal {...props} /> : null),
    },
};

export { userData };
