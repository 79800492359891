import dayjs from 'dayjs';
import defaultAvatar from 'assets/img/png/img.png';
export const touchedHelper = (touched, name) => {
    return touched[name];
};

export const createQuery = (filters) => {
    return (
        '?' +
        Array.from(filters)
            .filter((item) => Object.entries(item)[0][1])
            .map((item) => Object.entries(item)[0].join('='))
            .join('&')
    );
};

export function removeDuplicateObjects(arr, key) {
    return arr.filter((obj, index) => {
        return index === arr.findIndex((o) => o[key] === obj[key]);
    });
}

export const reorderArray = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const downloadBlob = (blob, name) => {
    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.download = name || 'file';
    link.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
};

export const testStateField = (x, isStudent) => {
    const existKey = x.hasOwnProperty('correctChoice');
    return {
        error: isStudent && existKey && !x.correctChoice && x.choice,
        success: isStudent && existKey && x.correctChoice && x.choice,
    };
};

export const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const availabilityFilter = (obj) => Object.values(obj).find((x) => x);

export const onlyCenterMinHeight = (minHeight) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: `${minHeight}vh`,
});

export const formatDate = (date, customFormat) =>
    dayjs(date).format(customFormat || 'YYYY-MM-DD hh:ss');

export const checkTestPercent = (requestPercent, resultPercent) => {
    if (resultPercent && !requestPercent) {
        return true;
    }
    if (
        typeof requestPercent === 'number' &&
        typeof resultPercent === 'number'
    ) {
        if (resultPercent >= requestPercent) {
            return true;
        }
    }
    return false;
};

export const banToNegativeNumber = (value, accessToZero) => {
    if (!value.length) return value;
    if (!accessToZero && value[0] === '0') return '';
    value = value.replace(/\D/g, '').replaceAll('-', '');
    if (accessToZero) {
        if (value.length > 1 && value[0] === '0') {
            value = value.replace('0', '');
        }
    }
    return value;
};

export const setSessionStorage = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
};

export const getSessionStorageData = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
};

export const checkIsCuteFrame = (item, customClass) => {
    const elem = item.children[0];
    const innerHeight = window.innerHeight;
    const check = (innerHeight / 100) * 10;
    const blockPosHeight = elem.getBoundingClientRect().y + 175 + check;
    if (blockPosHeight > innerHeight) {
        return elem.classList.add(customClass);
    }
};

export const onErrorImg = (e) => (e.currentTarget.src = defaultAvatar);

export const fileExpansionsNewTab = {
    rar: true,
    pptx: true,
    odt: true,
    ods: true,
    cda: true,
    aac: true,
    '7z': true,
};

export const removeKeysIsEmpty = (obj) => {
    let newObj = { ...obj };
    for (let key in newObj) {
        if (!newObj[key]) {
            delete newObj[key];
        }
    }
    return newObj;
};
