import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useCreateCourseMutation,
    useDeleteCourseAfterMutation,
} from 'api/services';
import { useSelector } from 'react-redux';
import { Divider } from '@akhter-studios/classlytics-front-ui-components';
import CoursesTitle from './CoursesTitle';
import CoursesHeading from './CoursesHeading';
import CoursesList from './CoursesList';
import CoursesDeleteModal from './CoursesDeleteModal';
import './Courses.scss';
import { Header, Sidebar } from 'components/common';
import FoundCoursesEmpty from 'pages/courses/FoundCoursesEmpty';

const Courses = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [removalElement, setRemovalElement] = useState(null);
    const { userSpace } = useSelector((state) => state.space);
    const { courses: foundCourses, search } = useSelector(
        (state) => state.foundCourses
    );
    const [createCourse, { isLoading }] = useCreateCourseMutation();
    const onShowDeleteModal = (id) => {
        setRemovalElement(id);
        setIsOpenDeleteModal(true);
    };
    const onCreateCourse = async () => {
        const { data } = await createCourse({ spaceId: userSpace.id });
        if (data) {
            navigate(`/content/courses/create/${data.id}`);
        }
    };
    const [deleteCourseAfter] = useDeleteCourseAfterMutation();
    const onDelete = () => {
        deleteCourseAfter({ courseId: removalElement, spaceId: userSpace?.id });
    };
    return (
        <>
            <Header />
            <Sidebar />
            <article className={'courses layout-main'}>
                <CoursesTitle onCreateCourse={onCreateCourse} />
                <CoursesHeading
                    onCreateCourse={onCreateCourse}
                    setPage={setPage}
                />
                <Divider className={'courses-divider'} />
                {foundCourses?.length === 0 && search?.length > 0 ? (
                    <FoundCoursesEmpty />
                ) : (
                    <CoursesList
                        isLoading={isLoading}
                        page={page}
                        setPage={setPage}
                        onShowDeleteModal={onShowDeleteModal}
                        onCreateCourse={onCreateCourse}
                    />
                )}
                <CoursesDeleteModal
                    onDelete={onDelete}
                    isOpen={isOpenDeleteModal}
                    setIsOpen={setIsOpenDeleteModal}
                />
            </article>
        </>
    );
};

export default Courses;
