import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBack from 'components/ArrowBack/ArrowBack';
import HeaderLogo from 'components/common/Header/HeaderLogo';
import HeaderHelper from 'components/common/Header/HeaderHelper';
import {
    Heading,
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import classNames from 'classnames';
import { tabs } from 'pages/profile/common/ProfileMenu/consts';
import { useSelector } from 'react-redux';
import { pickTextColorBasedOnBgColor } from 'utils/functions';

const renderComponent = {
    '': 'PROFILE_MENU_PROFILE',
    '?scope=profile': 'PROFILE_MENU_PROFILE',
    '?scope=security': 'PROFILE_MENU_SECURITY',
    '?scope=notification': 'PROFILE_MENU_NOTIFICATIONS',
    '?scope=settings': 'PROFILE_MENU_SETTINGS',
};
const HeaderProfilePage = () => {
    const { t } = useTranslation('common');
    const { search: locationSearch } = useLocation();
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);
    const [open, setOpen] = useState(false);

    const onOpen = () => setOpen((prev) => !prev);
    return (
        <header
            className={'header'}
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <ArrowBack />
            <HeaderLogo />
            <HeaderHelper />
            <div className={'header-settings-space'}>
                <Heading
                    className={'header-settings-space__title'}
                    variant={'h4'}
                    style={{
                        color: pickTextColorBasedOnBgColor(menuBackgroundColor),
                    }}
                >
                    {t(renderComponent[locationSearch])}
                </Heading>
                <Icon
                    name={open ? 'arrow-up' : 'arrow-down'}
                    onClick={onOpen}
                />
            </div>
            <BottomSheetCustom open={open} setOpen={setOpen} minHeight={250}>
                <List
                    className={'header-settings-space-menu__list'}
                    items={tabs}
                    renderItem={({ id, icon, text, search, to }) => (
                        <li
                            className={'header-settings-space-menu__item'}
                            key={id}
                        >
                            <Link
                                className={classNames(
                                    'header-settings-space-menu__link',
                                    {
                                        active:
                                            search ===
                                            (locationSearch.length > 0
                                                ? locationSearch
                                                : '?scope=profile'),
                                    }
                                )}
                                to={to}
                                onClick={() => setOpen(false)}
                            >
                                <Icon name={icon} />
                                <Typography
                                    className={
                                        'header-settings-space-menu__text'
                                    }
                                    variant={'body4'}
                                >
                                    {t(text)}
                                </Typography>
                            </Link>
                        </li>
                    )}
                />
            </BottomSheetCustom>
        </header>
    );
};

export default HeaderProfilePage;
