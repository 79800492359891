import { Fragment, useState, useEffect } from 'react';
import { getFiles } from 'pages/courses/Course/create-course/service';
import KnowledgeList from './KnowledgeList';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { store } from 'store';

const KnowledgeContainer = (props) => {
    const { data, onDataChange, title } = props;
    const [knowledge, setKnowledge] = useState(data ?? []);
    const [files, setFiles] = useState([]);
    const updateData = (newData) => {
        setKnowledge(newData);
        if (onDataChange) {
            onDataChange(newData);
        }
    };
    useEffect(() => {
        if (onDataChange && files.length) {
            onDataChange(files);
        }
    }, [files]);
    const onAddKnowledge = (e, currentKnowledge) => {
        e.stopPropagation();
        if (currentKnowledge.type === 'folder') {
            setKnowledge((prev) =>
                prev.map((k) => {
                    if (k.id === currentKnowledge.id) {
                        k.isActive = !k.isActive;
                    }
                    return k;
                })
            );
        } else {
            setFiles((prev) => [...prev, currentKnowledge]);
        }
    };

    useEffect(() => {
        getFiles(store.getState().space.userSpace?.id).then((res) => {
            setKnowledge([
                ...res.results.filter((k) => k.type === 'folder'),
                ...res.results.filter((k) => k.type !== 'folder'),
            ]);
        });
        return () => {
            setKnowledge([]);
        };
    }, []);
    return (
        <Fragment>
            <Box
                sx={{
                    backgroundColor: 'primary.white',
                    overflowY: 'scroll',
                    height: '600px',
                    padding: '16px',
                }}
            >
                <Typography variant="h3" component="h3" textAlign={'center'}>
                    {title}
                </Typography>
                {knowledge.length > 0 && (
                    <KnowledgeList
                        knowledge={knowledge}
                        onAddKnowledge={onAddKnowledge}
                    />
                )}
            </Box>
        </Fragment>
    );
};

export default KnowledgeContainer;
