import {Tag} from "@akhter-studios/classlytics-front-ui-components";
import "./CustomMultiValue.scss";

const CustomMultiValue = ({children, ...props}) => {
    const {data, removeProps} = props;
    return (
        <Tag
            className={"custom-multi-value"}
            text={data.label}
            onClick={removeProps.onClick}
        />
    );
};

export default CustomMultiValue;
