import AttachesTool from '@editorjs/attaches';
import { IconSave } from '@codexteam/icons';
import { make } from 'components/common/Editor/tools/utils';
import { saveAs } from 'file-saver';

export default class CustomAttachesTool extends AttachesTool {
    showFileData() {
        this.nodes.wrapper.classList.add(this.CSS.wrapperWithFile);
        const { file, title } = this.data;
        this.appendFileIcon(file);
        const fileInfo = make('div', this.CSS.fileInfo);

        this.nodes.title = make('div', this.CSS.title, {
            contentEditable: this.readOnly === false,
        });

        this.nodes.title.dataset.placeholder = this.api.i18n.t('File title');
        this.nodes.title.textContent = title || '';
        fileInfo.appendChild(this.nodes.title);

        if (file.size) {
            let sizePrefix;
            let formattedSize;
            const fileSize = make('div', this.CSS.size);

            if (Math.log10(+file.size) >= 6) {
                sizePrefix = 'MiB';
                formattedSize = file.size / Math.pow(2, 20);
            } else {
                sizePrefix = 'KiB';
                formattedSize = file.size / Math.pow(2, 10);
            }

            fileSize.textContent = formattedSize.toFixed(1);
            fileSize.setAttribute('data-size', sizePrefix);
            fileInfo.appendChild(fileSize);
        }
        let downloadFile = async () => {
            const downloadFile = await fetch(file.url);
            let data = await downloadFile.blob();
            saveAs(data, `${title || 'file'}.${file.url.split('.').at(-1)}`);
        };

        this.nodes.wrapper.appendChild(fileInfo);
        if (file.url !== undefined) {
            const downloadIcon = make('a', this.CSS.downloadButton, {
                innerHTML: IconSave,
            });
            downloadIcon.addEventListener('click', (e) => {
                downloadFile();
                e.stopPropagation();
            });
            if (this.readOnly) {
                this.nodes.wrapper.addEventListener('click', async function () {
                    downloadFile();
                });
            }
            this.nodes.wrapper.appendChild(downloadIcon);
        }
    }
}
