import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@akhter-studios/classlytics-front-ui-components';

const DetailCoursesStudentsMobile = ({
    body,
    headings,
    isStudent,
    handler,
}) => {
    const { t } = useTranslation('common');
    return (
        <section
            className={classNames(
                'analytics-table-mobile students-detail p-20-x',
                {
                    isStudent,
                }
            )}
        >
            <div className="analytics-table-mobile__list">
                {body.map((x) => {
                    return (
                        <div className="analytics-table-mobile__item">
                            <div className="analytics-table-mobile__item-wrap j-c-b">
                                <div
                                    className="analytics-table-mobile__item-name d-flex"
                                    onClick={() => handler && handler(x)}
                                >
                                    <Avatar
                                        className={
                                            'analytics-table-mobile__item-avatar'
                                        }
                                        src={x.img}
                                        variant={'rounded'}
                                        avatarText={`${
                                            x.firstName
                                                ?.charAt(0)
                                                .toUpperCase() || ''
                                        }${
                                            x.lastName
                                                ?.charAt(0)
                                                .toUpperCase() || ''
                                        }`}
                                    />
                                    <div>{x.name}</div>
                                </div>
                                <div className="analytics-table-mobile__item-average">
                                    <span>{t('ANALYTICS_AVERAGE_SCORE')}</span>
                                    <div>{x.average}</div>
                                </div>
                            </div>
                            <div className="analytics-table-mobile__headings">
                                {Object.keys(headings).map((el, idx) => {
                                    return (
                                        <>
                                            {idx !== 0 && idx !== 1 && (
                                                <div>
                                                    <span>
                                                        {t(headings[el])}
                                                    </span>
                                                    <div>{x[el]}</div>
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default DetailCoursesStudentsMobile;
