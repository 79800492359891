import {baseApi} from "api/xhr";

export const studentCourseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        fetchStudentCourses: build.query({
            query: ({id, ...params}) => ({
                url: `/students/${id}/courses/`,
                params
            }),
            transformResponse: (baseQueryReturnValue) => {
                return {
                    results: baseQueryReturnValue.results,
                    hasMore: !!baseQueryReturnValue.next
                }
            }
        })
    })
});

export const {
    useFetchStudentCoursesQuery,
    useLazyFetchStudentCoursesQuery,
} = studentCourseApi;