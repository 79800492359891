import classNames from 'classnames';
import {
    Avatar,
    Checkbox,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './StudentModalItem.scss';

const StudentModalItem = (props) => {
    const { user, isSelected, onSelect } = props;

    const onSelected = () => {
        onSelect({
            target: { checked: !isSelected, value: user.id },
        });
    };
    return (
        <div
            className={classNames('student-modal-item', {
                selected: isSelected,
            })}
            onClick={onSelected}
        >
            <Avatar
                className={'student-modal-item__img'}
                src={user.avatar}
                alt={'avatar'}
                variant={'rounded'}
                avatarText={`${user.firstName?.charAt(0).toUpperCase() || ''}${
                    user.lastName?.charAt(0).toUpperCase() || ''
                }`}
            />
            <div className={'student-modal-item__content'}>
                <Typography
                    className={'student-modal-item__name'}
                    variant={'body3'}
                >
                    {user.firstName} {user.lastName}
                </Typography>
                <Typography
                    className={'student-modal-item__email'}
                    variant={'body4'}
                >
                    {user.email}
                </Typography>
            </div>
            <Checkbox
                value={user.id}
                checked={isSelected}
                className={'student-modal-item__checkbox'}
                onChange={onSelect}
            />
        </div>
    );
};

export default StudentModalItem;
