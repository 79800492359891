const LOGIN_URL = '/login';
const REGISTER_URL = '/users/register/';
const REGISTER_BY_INVITATION = '/users/register-by-invitation/';
const RESENDING_MESSAGE_URL = '/users/resending-message/';
const CHECK_DOMAIN_URL = '/learning-space/check/domain/';
const RESET_PASSWORD_URL = '/users/reset-password/';
const REFRESH_TOKEN_URL = '/refresh';
const CHECK_EMAIL_URL = '/users/check-email/';
const FETCH_SPACES_URL = '/learning-space/get-spaces/';
const FETCH_SETTINGS_BY_DOMAIN_URL = 'learning-space/get-spaces/by-ori/';
const CREATE_SPACE_URL = '/learning-space/';
const USER_CHANGE_STATUS_URL = '/users/change/status/';
const GENERATE_TOKEN_URL = '/generate-token';
const GET_ACCESS_TOKEN_URL = '/get-access-token';

export {
    LOGIN_URL,
    REGISTER_URL,
    RESENDING_MESSAGE_URL,
    CHECK_DOMAIN_URL,
    RESET_PASSWORD_URL,
    CHECK_EMAIL_URL,
    FETCH_SPACES_URL,
    REGISTER_BY_INVITATION,
    REFRESH_TOKEN_URL,
    USER_CHANGE_STATUS_URL,
    GENERATE_TOKEN_URL,
    GET_ACCESS_TOKEN_URL,
    CREATE_SPACE_URL,
    FETCH_SETTINGS_BY_DOMAIN_URL,
};
