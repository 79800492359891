import React from 'react';
import {
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import './AnalyticsTable.scss';
import TableHeads from 'pages/analytics/common/AnalyticsTable/TableHead';
import TableBodyComponent from 'pages/analytics/common/AnalyticsTable/TableBody';
import cn from 'classnames';
import useIsMobile from 'helpers/hooks/useIsMobile';
import AnalyticsTableMobile from 'pages/analytics/common/AnalyticsTable/AnalyticsTableMobile';
const AnalyticsTable = ({
    headings,
    body,
    withoutBorder,
    withImgColumn,
    withoutHead,
    isStudent,
    isClickable,
    courseDetailStudents,
    handler,
    testMobileTable,
    page,
    onChangePage,
    total,
    withoutPagination,
}) => {
    console.log(!withoutPagination && total && body.length > 0);
    const isMobile = useIsMobile();
    if (isMobile) {
        return (
            <>
                <AnalyticsTableMobile
                    body={body}
                    testMobileTable={testMobileTable}
                    isStudent={isStudent}
                    courseDetailStudents={courseDetailStudents}
                    headings={headings}
                    handler={handler}
                />
                {!withoutPagination && total && body.length > 0 && (
                    <Pagination
                        count={Math.ceil(total / 10)}
                        page={page}
                        onChange={(_, value) => onChangePage(value)}
                        color="primary"
                    />
                )}
            </>
        );
    }
    return (
        <TableContainer
            className={cn('analytics-table', {
                withoutHead,
                withoutBorder,
                isClickable,
            })}
        >
            <Table>
                {!withoutHead && <TableHeads headings={headings} />}
                <TableBodyComponent
                    withImgColumn={withImgColumn}
                    body={body}
                    handler={handler}
                    isStudent={isStudent}
                    headings={headings}
                />
            </Table>
            {!withoutPagination && total && body.length > 0 && (
                <Pagination
                    count={Math.ceil(total / 10)}
                    page={page}
                    onChange={(_, value) => onChangePage(value)}
                    color="primary"
                />
            )}
        </TableContainer>
    );
};

export default AnalyticsTable;
