import {
    Heading,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import defaultAvatar from 'assets/img/png/space.png';
import './SpaceCard.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
const SpaceCard = ({ companyName, role, logo, courseQty, onClick }) => {
    const { t } = useTranslation('common');
    return (
        <li className={'space-card'} onClick={onClick}>
            <Avatar
                className={classNames('space-card__img', {
                    isSquare: logo,
                })}
                src={logo}
                sx={{ bgcolor: logo ? '' : deepOrange[500] }}
            >
                {companyName.slice(0, 1)}
            </Avatar>
            <div className={'space-card__content'}>
                <Heading variant={'h4'}>{companyName}</Heading>
                <Typography variant={'body4'}>
                    {role} •{' '}
                    {courseQty > 0
                        ? t('COURSES_WITH_COUNT', { count: courseQty })
                        : t('NO_COURSES')}
                </Typography>
            </div>
        </li>
    );
};

export default SpaceCard;
