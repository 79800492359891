import {
    Heading,
    Icon,
    MoreButton,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './CoursesTitle.scss';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'helpers/hooks';

const CoursesTitle = ({ onCreateCourse }) => {
    const { width } = useWindowSize();
    const { t } = useTranslation('common');
    return (
        <section className={'courses-title'}>
            <Heading className={'courses-title__name'} variant={'h1'}>
                {t('COURSES')}
            </Heading>
            {width < 476 ? (
                <MoreButton
                    className={'courses-title__more'}
                    onClick={onCreateCourse}
                >
                    <Typography variant={'body4'}>{t('NEW_COURSE')}</Typography>
                    <Icon name={'plus'} />
                </MoreButton>
            ) : null}
        </section>
    );
};

export default CoursesTitle;
