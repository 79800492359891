import React, { useEffect, useState } from 'react';
import './CustomSelect.scss';
import useOutsideClick from 'helpers/hooks/useOutsideClick';
import Icon from 'components/common/Icon/Icon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
const CustomSelect = ({ list, value, className = '', handler, name }) => {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    const [selectId, setSelectId] = useState(null);
    const handleClick = (item) => {
        handler(name, item);
        setSelectId(item.id);
        setOpen(false);
    };
    const ref = useOutsideClick(() => setOpen(false));
    return (
        <div className={`customSelect ${className}`} ref={ref}>
            <div
                onClick={() => setOpen(!open)}
                className={classNames('customSelect__view d-flex', {
                    isOpen: open,
                })}
            >
                <div className="customSelect__value">{t(value.title)}</div>
                <Icon name="toggle-arrow" />
            </div>
            {open && (
                <ul className="customSelect__list">
                    {list.map((x, idx) => (
                        <li
                            key={idx}
                            onClick={() => handleClick(x)}
                            className={classNames({
                                active: selectId === x.id,
                            })}
                        >
                            {t(x.title)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;
