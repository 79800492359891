import { memo } from 'react';
import { Typography } from '@akhter-studios/classlytics-front-ui-components';
import './ConfirmDesc.scss';
import { useTranslation } from 'react-i18next';

const ConfirmDesc = ({ email }) => {
    const { t } = useTranslation('common');
    return (
        <>
            <Typography
                className={'confirm-desc'}
                variant={'body3'}
                dangerouslySetInnerHTML={{
                    __html: t('AUTH_CONFIRM_DESC_FIRST', {
                        email,
                    }),
                }}
            ></Typography>
            <Typography
                className={'confirm-desc confirm-desc--secondary'}
                variant={'body3'}
            >
                {t('AUTH_CONFIRM_DESC_SECOND')}
            </Typography>
        </>
    );
};

export default memo(ConfirmDesc);
