import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
    baseComponents,
    baseStyles,
} from 'components/custom-react-select/consts';
import { useTranslation } from 'react-i18next';

const CustomAsyncCreatableSelect = (props) => {
    const { components, styles } = props;
    const { t } = useTranslation('common');
    return (
        <AsyncCreatableSelect
            ref={props.customRef}
            {...props}
            loadingMessage={({ inputValue }) =>
                inputValue.length > 0 ? t('GENERAL_LOADING') : null
            }
            noOptionsMessage={({ inputValue }) =>
                inputValue.length > 0 ? t('GENERAL_NOT_FOUND') : null
            }
            components={{
                ...baseComponents,
                ...components,
            }}
            styles={{
                ...baseStyles({ isError: props.isError }),
                ...styles,
            }}
        />
    );
};

export default CustomAsyncCreatableSelect;
