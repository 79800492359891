import { nanoid } from 'nanoid';

const actions = ({
    id,
    onNavigateToGroupDetails,
    onOpenChangeTitle,
    onOpenManageGroupUser,
    onRemove,
}) => [
    {
        id: nanoid(),
        text: 'GROUP_CHECK_DETAILS',
        onClick: () => onNavigateToGroupDetails(),
    },
    {
        id: nanoid(),
        text: 'GROUP_CHANGE_NAME',
        onClick: onOpenChangeTitle,
    },
    {
        id: nanoid(),
        text: 'GROUP_HANDLE_PARTICIPANTS',
        border: true,
        onClick: onOpenManageGroupUser,
    },
    {
        id: nanoid(),
        text: 'GROUP_DELETE',
        onClick: () => onRemove(id),
    },
];

export { actions };
