import React from 'react';
import { testAnswerStates } from 'pages/analytics/views/students/consts';
import { Icon } from 'components/common';
const type = {
    CORRECT: 'success-state',
    AVERAGE: 'average-state',
    WRONG: 'error-state',
};
const TestAnswerState = ({ data }) => {
    return (
        <ul className="test-statistic__answerState">
            {data.map((x, idx) => (
                <li key={x.id} className="j-c-b">
                    <span>
                        {idx + 1}.{x.question.name}
                    </span>
                    <Icon name={type[x.status]} />
                </li>
            ))}
        </ul>
    );
};

export default TestAnswerState;
