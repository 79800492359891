export const ModalContentsTest = ({
    percent,
    viewResult,
    successPercent,
    finishedFn,
    type,
    handler,
    t,
}) => {
    const modalObj = {
        timeSpentOne: {
            title: t('STUDENT_TEST_ALERT_TITLE_END'),
            description: (
                <>
                    <div>
                        {t('STUDENT_TEST_ALERT_TITLE_SCORE_LITTLE')}
                        <strong style={{ color: '#F55C45' }}>
                            {' '}
                            {percent}%
                        </strong>
                    </div>
                    <div>
                        {t('STUDENT_TEST_ALERT_SCORE')} -{' '}
                        <strong> {successPercent}%</strong>
                    </div>
                    <div>{t('STUDENT_TEST_ALERT_AGAIN_PASS')}</div>
                </>
            ),
            btnTitle: t('STUDENT_TEST_ALERT_BUTTON_FAILED'),
        },
        timeSpentTwo: {
            title: t('STUDENT_TEST_ALERT_TITLE_END'),
            description: (
                <>
                    <div>
                        {t('STUDENT_TEST_ALERT_TITLE_SUCCESS', { percent })}
                    </div>
                </>
            ),
            btnTitle: t('STUDENT_TEST_ALERT_BUTTON_FAILED'),
        },
        timeSpentThree: {
            title: t('STUDENT_TEST_ALERT_TITLE_END'),
            description: (
                <>
                    <div>
                        {t('STUDENT_TEST_ALERT_TITLE_SCORE_LITTLE')}
                        <strong style={{ color: '#F55C45' }}>
                            {' '}
                            {percent}%
                        </strong>
                    </div>
                    <div>
                        {t('STUDENT_TEST_ALERT_SCORE')} -{' '}
                        <strong> {successPercent}%</strong>
                    </div>
                    <div>{t('STUDENT_TEST_ALERT_ANYMORE')}</div>
                </>
            ),
            btnTitle: t('STUDENT_TEST_ALERT_BUTTON_FAILED'),
        },
        success: {
            title: t('STUDENT_TEST_ALERT_TITLE_SUCCESS', { percent }),
            description: t('STUDENT_TEST_ALERT_DESCRIPTION_SUCCESS'),
            btnTitle: t('STUDENT_TEST_ALERT_BUTTON_SUCCESS'),
            action: handler,
            otherBtn: t('STUDENT_TEST_ALERT_SECONDARY_BUTTON_SUCCESS'),
            otherAction: viewResult,
        },
        failed: {
            title: t('STUDENT_TEST_ALERT_TITLE_FAILED', { percent }) + '%',
            description: (
                <>
                    <div>
                        {t('STUDENT_TEST_ALERT_DESCRIPTION_FAILED', {
                            successPercent,
                        })}
                        %
                    </div>
                    <div>{t('STUDENT_TEST_ALERT_DESCRIPTION_FAILED-INFO')}</div>
                </>
            ),
            btnTitle: t('STUDENT_TEST_ALERT_BUTTON_FAILED'),
            action: handler,
        },
        completed: {
            title: t('STUDENT_TEST_ALERT_TITLE_COMPLETED'),
            description: t('STUDENT_TEST_ALERT_DESCRIPTION_COMPLETED'),
            btnTitle: t('STUDENT_TEST_ALERT_BUTTON_COMPLETED'),
            action: finishedFn,
        },
    };
    return modalObj[type];
};

export const modalTestBtns = ({ type, next, finishCourseClick, action }) => {
    sessionStorage.clear();
    switch (type) {
        case 'failed':
            return action();
        case 'completed':
            return finishCourseClick();
        default:
            return next();
    }
};

export const modalTitlesBtns = ({
    reTakeTheTest,
    handler,
    btnTitle,
    toggleModal,
    t,
}) => {
    return {
        success: {
            subActionTitle: t('STUDENT_TEST_CHECK_RESULTS'),
            subAction: toggleModal,
        },
        failed: {
            subActionTitle: t('STUDENT_TEST_CHECK_RESULTS'),
            subAction: toggleModal,
        },
        timeSpentOne: {
            mainAction: () => {
                reTakeTheTest(true);
                toggleModal();
            },
            mainActionTitle: t('STUDENT_TEST_ALERT_BUTTON_FAILED'),
            subAction: () => {
                handler();
                toggleModal();
            },
            subActionTitle: btnTitle,
        },
        timeSpentTwo: {
            mainAction: () => toggleModal(),
            mainActionTitle: t('STUDENT_TEST_ALERT_SEE_ANSWERS'),
            subAction: () => {
                handler();
                toggleModal();
            },
            subActionTitle: btnTitle,
        },
        timeSpentThree: {
            mainAction: () => {
                handler();
                toggleModal();
            },
            mainActionTitle: btnTitle,
            subAction: toggleModal,
            subActionTitle: t('STUDENT_TEST_CHECK_RESULTS'),
        },
    };
};
