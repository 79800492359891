import { StudentCoursesSwiper } from '../index';
import { useSelector } from 'react-redux';
import { useLazyFetchStudentCoursesQuery } from 'api/services';
import { useState, useEffect } from 'react';

const StudentCoursesSwiperContainer = () => {
    const { userSpace } = useSelector((state) => state.space);
    const [trigger] = useLazyFetchStudentCoursesQuery();
    const [progressCourses, setProgressCourses] = useState(null);
    const [page, setPage] = useState(1);
    const pageSize = 50;
    const filter = 'in_progress';

    const loadMoreProgressCourses = async () => {
        if (!progressCourses.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            id: userSpace?.id,
            page: page + 1,
            page_size: pageSize,
            filter,
        });
        const modifiedResults = [...progressCourses.results, ...data.results];
        setProgressCourses({ results: modifiedResults, hasMore: data.hasMore });
    };

    useEffect(
        () =>
            userSpace &&
            trigger({
                id: userSpace?.id,
                page,
                page_size: pageSize,
                filter,
            }).then(({ data }) => setProgressCourses(data)),
        [userSpace]
    );
    return (
        progressCourses?.results.length > 0 && (
            <StudentCoursesSwiper
                courses={progressCourses.results}
                hasMore={progressCourses.hasMore}
                loadMore={loadMoreProgressCourses}
            />
        )
    );
};

export default StudentCoursesSwiperContainer;
