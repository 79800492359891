import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    useRemoveUserGroupStudentsMutation,
    useAddUserGroupStudentsMutation,
    useLazySearchUsersQuery,
    useLazyFetchUserGroupManageStudentsQuery,
} from 'api/services';
import {
    Avatar,
    Button,
    Typography,
    Icon,
    Modal,
    Heading,
} from '@akhter-studios/classlytics-front-ui-components';
import { CustomAsyncSelect } from 'components/custom-react-select';
import { FormatOptionLabelStudent } from 'pages/users/select-formats';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import './ManageGroupStudentsModal.scss';
import { debounce } from 'utils/functions/helpers';

const ManageGroupStudentsModal = ({
    isOpen,
    setIsOpen,
    groupTitle,
    groupId,
}) => {
    const { userSpace } = useSelector((state) => state.space);
    const [trigger] = useLazyFetchUserGroupManageStudentsQuery();
    const [searchTrigger] = useLazySearchUsersQuery();
    const [removeStudent] = useRemoveUserGroupStudentsMutation();
    const [addStudent] = useAddUserGroupStudentsMutation();
    const [students, setStudents] = useState(null);
    const [searchStudents, setSearchStudents] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const { t } = useTranslation('common');
    const selectRef = useRef(null);

    const onSubmit = () => {
        setIsOpen(false);
    };

    const onRemove = async (id) => {
        const { error } = await removeStudent({
            spaceId: userSpace.id,
            groupId,
            users: [id],
        });
        if (!error) {
            setStudents({
                ...students,
                results: students.results.filter((s) => s.user.id !== id),
            });
        }
    };

    const onAdd = async (id, selectedOption) => {
        const { error } = await addStudent({
            spaceId: userSpace.id,
            groupId,
            users: [id],
        });
        if (!error) {
            setStudents({
                ...students,
                results: [selectedOption, ...students.results],
            });
        }
        selectRef.current.clearValue();
    };

    const loadMoreStudents = async () => {
        if (!students?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            spaceId: userSpace.id,
            groupId,
            page: page + 1,
            page_size: pageSize,
        });
        const modifiedResults = [...students.results, ...data.results];
        setStudents({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    const loadEmails = async (searchValue, callback) => {
        if (searchValue) {
            const res =
                userSpace &&
                (await searchTrigger({
                    space_id: userSpace.id,
                    search: searchValue,
                    unique: false,
                    role: 'student',
                }));
            const newUsers = res.data.results.map(
                ({ id, email, avatar = null, firstName, lastName }) => ({
                    label: email,
                    value: id,
                    image: avatar,
                    user: {
                        avatar,
                        email,
                        firstName,
                        lastName,
                    },
                })
            );
            callback(newUsers);
        } else {
            callback([]);
        }
    };

    const debounceLoadEmails = debounce(loadEmails, 700);

    const onChangeEmails = async (selectedOption) => {
        setSearchStudents(selectedOption);
        await onAdd(selectedOption.value, selectedOption);
    };

    useEffect(() => {
        if (userSpace) {
            trigger({
                spaceId: userSpace.id,
                groupId,
                page,
                page_size: pageSize,
            }).then(({ data }) => setStudents(data));
        }
    }, [userSpace]);
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className={'manage-group-students-modal'}
        >
            <Heading
                className={'manage-group-students-modal__title'}
                variant={'h2'}
            >
                {t('GROUP_STUDENTS_COUNT', { groupTitle })}
            </Heading>
            <CustomAsyncSelect
                className={'manage-group-students-modal__input'}
                name={'emails'}
                placeholder={t('ADD_STUDENT')}
                iconName={'search'}
                defaultOptions
                isClearable={false}
                formatOptionLabel={FormatOptionLabelStudent}
                loadOptions={debounceLoadEmails}
                value={searchStudents}
                onChange={onChangeEmails}
                ref={selectRef}
            />
            {students ? (
                <InfiniteScroll
                    className={'manage-group-students-modal__list'}
                    dataLength={students.results.length}
                    next={loadMoreStudents}
                    hasMore={students.hasMore}
                    loader={null}
                    height={430}
                >
                    {students.results.map(({ user }) => (
                        <div
                            className={classNames(
                                'manage-group-students-modal__item'
                            )}
                            key={user.id}
                        >
                            <Avatar
                                className={'item__img'}
                                alt={'avatar'}
                                variant={'rounded'}
                                avatarText={`${
                                    user.firstName?.charAt(0).toUpperCase() ||
                                    ''
                                }${
                                    user.lastName?.charAt(0).toUpperCase() || ''
                                }`}
                            />
                            <div className={'item__content'}>
                                <Typography
                                    className={'item__name'}
                                    variant={'body3'}
                                >
                                    {user.firstName} {user.lastName}
                                </Typography>
                                <Typography
                                    className={'item__email'}
                                    variant={'body4'}
                                >
                                    {user.email}
                                </Typography>
                            </div>
                            <Icon
                                name={'close'}
                                className={'item__close'}
                                onClick={() => onRemove(user.id)}
                            />
                        </div>
                    ))}
                </InfiniteScroll>
            ) : null}
            <div className={'manage-group-students-modal__btn'}>
                <Button
                    variant={'primary'}
                    size={'medium'}
                    loading={false}
                    disabled={false}
                    fullWidth
                    onClick={onSubmit}
                >
                    {t('SAVE')}
                </Button>
            </div>
        </Modal>
    );
};

export default ManageGroupStudentsModal;
