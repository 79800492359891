import {Navigate, Outlet} from "react-router-dom";

const ProtectedRoute = ({condition, redirectTo="/auth/login", ...rest}) => {
    return (
        condition
            ? <Outlet/>
            : <Navigate to={redirectTo} {...rest}/>
    );
};

export default ProtectedRoute;
