import {Icon, IconButton} from "@akhter-studios/classlytics-front-ui-components";

const SwiperButtonNext = ({onClick}) => {
    return (
        <IconButton onClick={onClick}>
            <Icon name={"arrow-right"}/>
        </IconButton>
    );
};

export default SwiperButtonNext;