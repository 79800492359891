import AttachesTool from '@editorjs/attaches';
import { getUrlExtension, make } from 'components/common/Editor/tools/utils';
import { saveRemovingBlocks } from 'utils/functions/localStorage';

export default class AudioTool extends AttachesTool {
    makeAudio(file) {
        const audio = make('audio', '', {
            controls: 'controls',
        });
        const source = make('source', '', {
            src: file.url,
            type: `audio/${getUrlExtension(file.url)}`,
        });
        audio.appendChild(source);
        const link = make('a', '', { href: file.url });
        link.html = 'Скачайте аудио';
        audio.appendChild(link);
        return audio;
    }
    showFileData() {
        this.nodes.wrapper.classList.add('cdx-attaches--with-audio');

        const { file, title } = this.data;

        const fileInfo = make('div', this.CSS.fileInfo);

        const audio = this.makeAudio(file);

        this.nodes.title = make('div', this.CSS.title, {
            contentEditable: this.readOnly === false,
        });

        this.nodes.title.dataset.placeholder = this.api.i18n.t('File title');
        this.nodes.title.textContent = title || '';
        fileInfo.appendChild(this.nodes.title);

        if (file.size) {
            let sizePrefix;
            let formattedSize;
            const fileSize = make('div', this.CSS.size);

            if (Math.log10(+file.size) >= 6) {
                sizePrefix = 'MiB';
                formattedSize = file.size / Math.pow(2, 20);
            } else {
                sizePrefix = 'KiB';
                formattedSize = file.size / Math.pow(2, 10);
            }

            fileSize.textContent = formattedSize.toFixed(1);
            fileSize.setAttribute('data-size', sizePrefix);
            fileInfo.appendChild(fileSize);
        }
        this.nodes.wrapper.appendChild(audio);
        this.nodes.wrapper.appendChild(fileInfo);
    }
    removed() {
        // saveRemovingBlocks(this._data?.file?.id);
    }
}
