import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import UsersHeadingTabs from 'components/common/UsersHeadingTabs/UsersHeadingTabs';
import { tabs } from '../consts';
import { useFetchStudentDetailQuery } from 'api/services/AnalyticsService/AnalyticsService';
import StudentDetailProfile from 'pages/analytics/views/students/StudentDetail/StudentDetailProfile';
import { useSelector } from 'react-redux';
const StudentDetail = () => {
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);

    const { data } = useFetchStudentDetailQuery({ spaceId: userSpace?.id, id });
    return (
        <section className="analytics-study-detail layout-main">
            <div className="students">
                <StudentDetailProfile
                    data={data?.Student || {}}
                    courseQty={data?.total_courses}
                    groupQty={data?.group_qty}
                />
                <UsersHeadingTabs tabs={tabs()} />
                <Outlet
                    context={{
                        totalCourses: data?.total_courses,
                        totalTests: data?.total_tests,
                    }}
                />
            </div>
        </section>
    );
};

export default StudentDetail;
