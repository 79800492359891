import React from 'react';
import './SavingStatus.scss';

const SavingStatus = ({ text = 'Сохранение' }) => {
    return (
        <div className={'saving-status'}>
            <div className={'saving-status__spinner'} />
            <div className={'saving-status__text'}>{text}</div>
        </div>
    );
};

export default SavingStatus;
