import React from 'react';
import { CustomAsyncCreatableSelect } from 'components/custom-react-select';
import './OptionOutsideAsyncCreatableSelect.scss';
import { List, Tag } from '@akhter-studios/classlytics-front-ui-components';
const OptionOutsideAsyncCreatableSelect = (props) => {
    const { isMulti, value, onChange } = props;
    const onRemove = (currentValue) => {
        if (!onChange) return;
        const removedValue = value.find((val) => val.value === currentValue);
        if (!removedValue) return;
        onChange(
            value.filter((val) => val.value !== currentValue),
            { name: currentValue, action: 'remove-value', removedValue }
        );
    };
    return (
        <div className={'option-outside-async-creatable-select'}>
            <CustomAsyncCreatableSelect
                {...props}
                controlShouldRenderValue={!isMulti}
            />
            {isMulti ? (
                <List
                    className={'option-outside-async-creatable-select__list'}
                    items={value}
                    renderItem={({ value, label }) => (
                        <li
                            key={value}
                            className={
                                'option-outside-async-creatable-select__item'
                            }
                        >
                            <Tag
                                name={value}
                                className={'custom-multi-value'}
                                text={label}
                                onRemove={() => onRemove(value)}
                            />
                        </li>
                    )}
                />
            ) : null}
        </div>
    );
};

export default OptionOutsideAsyncCreatableSelect;
