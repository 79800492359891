import {
    Icon,
    IconButton,
} from '@akhter-studios/classlytics-front-ui-components';
import './HeaderSettings.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HeaderSettings = ({onNavigate, disabled}) => {
    return (
        <IconButton
            className={'header-settings'}
            onClick={onNavigate}
            disabled={disabled}
        >
            <Icon name={'settings'} />
        </IconButton>
    );
};

export default HeaderSettings;
