export const applyDrag = (arr, dragResult) => {
    const { item, currentItem } = dragResult;
    let result = [...arr];
    if (!item.files && !currentItem.files) {
        result[item.idx] = currentItem;
        result[currentItem.idx] = item;
        return result;
    }
    if (currentItem.files) {
        result[item.idx] = currentItem;
        result[currentItem.idx] = item;
    } else {
        if (!result[item.idx].files?.some((x) => x.id === currentItem.id)) {
            result[item.idx].files = [...result[item.idx].files, currentItem];
            result = result.filter((x) => x.id !== currentItem.id);
        }
    }
    return result;
};

export const applyDragChild = (arr, dragResult) => {
    const { item, currentItem } = dragResult;
    let result = [...arr];
    if (result[item.idx].files) {
        result[currentItem.idx].files = result[currentItem.idx].files.filter(
            (x) => x.id !== currentItem.id
        );
        result[item.idx].files = [...result[item.idx].files, currentItem];
        return result;
    }
    return result;
};

export const applyDragMainList = (arr, currentItem, currentIndex) => {
    let result = [...arr];
    if (currentItem.isChild) {
        result[currentItem.idx].files = result[currentItem.idx].files.filter(
            (idx) => idx !== currentItem.indexChild
        );
        if (currentIndex) {
            result = result.splice(currentIndex, 0, currentItem);
        } else {
            result = [...result, currentItem];
        }
    }

    return result;
};

export const moveToMainList = (item, arr, currentIndex) => {
    let result = [...arr];
    let file = arr[item.parentIndex].files[item.indexChild];
    if (item.isChild) {
        result[item.parentIndex].files = result[item.parentIndex].files.filter(
            (_, idx) => idx !== item.indexChild
        );
        if (currentIndex) {
            let item = result[currentIndex];
            result.splice(currentIndex, 0, file);
        } else {
            result = [file, ...result];
        }
    }
    return result;
};
