import {memo} from "react";
import {isDisabledForm} from "utils/functions";
import {Button} from "@akhter-studios/classlytics-front-ui-components";
import "./AuthButton.scss";

const AuthButton = (props) => {
    const {isLoading, isValid, text=""} = props;
    return (
        <Button
            className={"auth-button"}
            type={"submit"}
            disabled={isDisabledForm({isValid, isLoading})}
            variant={"primary"}
            size={"large"}
            loading={isLoading}
            fullWidth
        >
            {text}
        </Button>
    );
};

export default memo(AuthButton);
