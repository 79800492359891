import {
    Button,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { BottomModal } from 'pages/users/common';
import './BottomModalWrapper.scss';
import { useTranslation } from 'react-i18next';

const BottomModalWrapper = (props) => {
    const { t } = useTranslation('common');
    const { children, count, maxCount, onClear } = props;
    return (
        <BottomModal className={'bottom-modal-wrapper'}>
            <div className={'bottom-modal-wrapper__left'}>
                <Typography
                    variant={'body4'}
                    className={'bottom-modal-wrapper__clearText'}
                    dangerouslySetInnerHTML={{
                        __html: `
                            ${t('SELECTED')}:
                            ${t('USERS_BOTTOM_MODAL_TOTAL', {
                                count,
                                maxCount,
                            })}
                        `,
                    }}
                ></Typography>
                <Button
                    className={'bottom-modal-wrapper__clear'}
                    variant={'secondary'}
                    size={'small'}
                    onClick={onClear}
                >
                    {t('CLEAR')}
                </Button>
            </div>
            <div className={'bottom-modal-wrapper__right'}>{children}</div>
        </BottomModal>
    );
};

export default BottomModalWrapper;
