import { userData } from './data';

const userRender = ({ search, node }) => {
    if (search) {
        const newPathname = search.toString().split('/');
        const pathnameArr = newPathname[newPathname.length - 1];
        return userData[node][pathnameArr];
    } else {
        return userData[node][''];
    }
};

const loadMore = async ({
    items,
    setItems,
    setPage,
    request,
    requestData,
    hasMore,
}) => {
    if (!hasMore) {
        return;
    }
    setPage((page) => page + 1);
    const { data } = await request(requestData);
    const modifiedResults = [...items, ...data.results];
    setItems({
        results: modifiedResults,
        hasMore: data.hasMore,
        count: data.count,
    });
};

export { userRender, loadMore };
