import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useCheckDomainMutation } from 'api/services';
import { registerSchema } from 'utils/schemas';
import {
    AUTH_REGISTER_ROUTE,
    CONFIRMATION_ROUTE,
    ONLY_LATIN,
} from 'utils/consts';
import { AuthButton, AuthDesc } from 'components/feature';
import 'pages/auth/Auth.scss';
import { useSelector } from 'react-redux';
import { useAction } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';
import { Input } from '@akhter-studios/classlytics-front-ui-components';
import { useEffect, useState } from 'react';
import { getDomainPlaceholder } from 'utils/functions';

const AboutCompanyForm = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { registerFormValues } = useSelector((state) => state.auth);
    const [checkDomain, { isLoading, error }] = useCheckDomainMutation();
    const { setRegisterFormValues } = useAction();
    const [domainError, setDomainError] = useState(error?.data['domain'] || '');

    useEffect(
        () => setDomainError(error?.data['domain']),
        [error?.data['domain']]
    );

    const onSubmit = async (values) => {
        const { domain, company_name } = values;
        const { error } = await checkDomain({ domain, company_name });
        if (!error) {
            setRegisterFormValues({ ...registerFormValues, ...values });
            navigate(`${AUTH_REGISTER_ROUTE}${CONFIRMATION_ROUTE}`);
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        isValid,
    } = useFormik({
        initialValues: {
            company_name: registerFormValues?.company_name ?? '',
            domain: registerFormValues?.domain ?? '',
        },
        validationSchema: registerSchema(t)[pathname],
        onSubmit,
    });

    const handleChangeDomain = (e) => {
        const { value } = e.target;
        const regex = new RegExp(ONLY_LATIN);
        const isValid = regex.test(value);
        if (!isValid) {
            setDomainError('');
            setFieldValue('domain', value.toString().toLowerCase());
        }
    };
    return (
        <>
            <AuthDesc text={t('AUTH_ABOUT_COMPANY')} />
            <form className={'auth-form'} onSubmit={handleSubmit}>
                <Input
                    id={'aboutCompanyName'}
                    name={'company_name'}
                    label={t('SETTINGS_COMPANY_NAME')}
                    value={values.company_name}
                    helperText={
                        touched['company_name'] && errors['company_name']
                    }
                    error={touched['company_name'] && errors['company_name']}
                    onChange={(e) => {
                        handleChange(e);
                        handleChangeDomain(e);
                    }}
                    onBlur={handleBlur}
                />
                <Input
                    id={'aboutCompanyDomain'}
                    name={'domain'}
                    label={t('SETTINGS_DOMAIN_NAME')}
                    // domain={getDomainPlaceholder()}
                    value={values.domain}
                    helperText={
                        (touched['domain'] && errors['domain']) ||
                        (Boolean(domainError) && t(domainError))
                    }
                    error={
                        (touched['domain'] &&
                            errors['domain'] &&
                            Boolean(errors)) ||
                        Boolean(domainError)
                    }
                    onChange={(e) => {
                        setDomainError('');
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                />
                <AuthButton
                    isLoading={isLoading}
                    isValid={isValid}
                    text={t('NEXT')}
                />
            </form>
        </>
    );
};

export default AboutCompanyForm;
