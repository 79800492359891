import {
    Heading,
    Modal,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './UsersModalWrapper.scss';

const UsersModalWrapper = (props) => {
    const {
        children,
        className,
        isOpen,
        setIsOpen,
        title,
        isSuccess,
        btnFixed = false,
    } = props;
    return (
        <Modal
            className={classNames('users-modal-wrapper', className, {
                fixed: btnFixed,
                isSuccess,
            })}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <Heading className={'users-modal-wrapper__title'} variant={'h2'}>
                {title}
            </Heading>
            {children}
        </Modal>
    );
};

export default UsersModalWrapper;
