import React, { useState } from 'react';
import TestStatisticTabs from 'pages/analytics/views/students/StudentDetail/TestStatistic/TestStatisticTabs';
import DetailCourseStatisticsItem from 'pages/analytics/views/courses/DetailCourses/DetailCourseStatisticsItem';
import TestAnswerState from 'pages/analytics/views/students/StudentDetail/TestStatistic/TestAnswerState';
import { useFetchStudentDetailTestStatisticsQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TestStatistic = ({ selectedCourse }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { t } = useTranslation('common');
    const { data, isLoading } = useFetchStudentDetailTestStatisticsQuery({
        spaceId: userSpace?.id,
        id: selectedCourse.id,
        studentId: id,
    });
    const dataInfo = data?.[activeTab]?.Attempt || {};
    if (isLoading) return <div>{t('GENERAL_LOADING')}</div>;
    return (
        <div className="test-statistic p-20-x">
            <div className="test-statistic__title">{selectedCourse.name}</div>

            <TestStatisticTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                data={data || []}
            />
            <div className="test-statistic__info d-flex">
                <DetailCourseStatisticsItem
                    title={t('ANALYTICS_AVERAGE_SCORE')}
                    value={dataInfo.average_score}
                />
                <DetailCourseStatisticsItem
                    title={t('ANALYTICS_TIME_SPENT')}
                    value={`${dataInfo.time_numbers} ${t(
                        'MENTOR_TEST_TIME_MINUTES'
                    )}`}
                />
                <DetailCourseStatisticsItem
                    title={t('PREVIEW_PASSED')}
                    value={dayjs(dataInfo.date_passage).format('DD.MM.YYYY')}
                />
            </div>
            <TestAnswerState data={dataInfo.answers || []} />
        </div>
    );
};

export default TestStatistic;
