import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useRegisterMutation } from 'api/services';
import { registerSchema } from 'utils/schemas';
import { confirmationForm } from '../consts';
import {
    AuthButton,
    AuthFields,
    AuthDesc,
    AuthError,
} from 'components/feature';
import { AUTH_CONFIRM_ROUTE } from 'utils/consts';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';
import { getFirebaseToken } from '../../../../../firebase';

const ConfirmationForm = () => {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { registerFormValues } = useSelector((state) => state.auth);
    const [register, { isLoading, isError, error }] = useRegisterMutation();

    // const fcm_id = getFirebaseToken();
    const onSubmit = async (values) => {
        const { password } = values;
        const newValues = {
            ...registerFormValues,
            password,
            // fcm_id: fcm_id || '',
            ori_domain: window.location.hostname,
            lang: i18n.language,
        };
        const {
            data: { email, domain },
        } = await register(newValues);
        if (email) {
            sessionStorage.removeItem('confirmTime');
            navigate({
                pathname: AUTH_CONFIRM_ROUTE,
                search: `?email=${email}&domain=${domain}`,
            });
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
    } = useFormik({
        initialValues: {
            password: registerFormValues?.password ?? '',
            second_password: registerFormValues?.second_password ?? '',
        },
        validationSchema: registerSchema(t)[pathname],
        onSubmit,
    });
    return (
        <>
            {isError && <AuthError error={error} />}
            <AuthDesc text={t('AUTH_PASSWORD_TITLE')} />
            <form className={'auth-form'} onSubmit={handleSubmit}>
                <AuthFields
                    fields={confirmationForm}
                    values={values}
                    errors={errors}
                    touched={touched}
                    error={error}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                />
                <AuthButton
                    isLoading={isLoading}
                    isValid={isValid}
                    text={t('AUTH_PASSWORD_CREATE_ACCOUNT')}
                />
            </form>
        </>
    );
};

export default ConfirmationForm;
