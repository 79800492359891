import React from 'react';
import { Progress } from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';

const TestStatisticsItem = ({
    success,
    subDescription,
    responseQty,
    percent,
    title,
}) => {
    return (
        <div
            className={classNames('analytics-test-statistics__item', {
                success,
            })}
        >
            <div className="analytics-test-statistics__item-title">
                <div>{title}</div>
                {subDescription && <span>{subDescription}</span>}
            </div>
            <div className="analytics-test-statistics__item-progress d-flex">
                <Progress
                    className={'cards-item__progress'}
                    value={percent}
                    max={100}
                />
                <div
                    style={{ whiteSpace: 'nowrap' }}
                    className="analytics-test-statistics__item-percent"
                >
                    {percent}% ({responseQty})
                </div>
            </div>
        </div>
    );
};

export default TestStatisticsItem;
