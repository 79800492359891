import '../../students/Students.scss';
import { useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useLazyFetchUserGroupStudentsQuery,
    useRemoveUserGroupStudentsMutation,
} from 'api/services';
import { useAction, useDebounce } from 'helpers/hooks';
import {
    AddCoursesModal,
    GroupDetailStudentsBottomModal,
    StudentItem,
    UserEmpty,
    UserSearchEmpty,
} from 'pages/users/common';
import { UsersTableList } from 'pages/users/common/UsersTable/UsersTableList';
import classNames from 'classnames';
import { UsersTableFilter } from 'pages/users/common/UsersTable/UsersTableFilter';
import { useTranslation } from 'react-i18next';

const Students = () => {
    const { id: groupId } = useParams();
    const [isOpen, setIsOpen] = useOutletContext();
    const [search, setSearch] = useState('');
    const { userSpace } = useSelector((state) => state.space);
    const { groupStudents: students } = useSelector((state) => state.userGroup);
    const { setUserGroupStudents } = useAction();
    const [trigger, { isLoading }] = useLazyFetchUserGroupStudentsQuery();
    const [removeStudent] = useRemoveUserGroupStudentsMutation();
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [isOpenAddCoursesModal, setIsOpenAddCoursesModal] = useState(false);
    const debouncedSearch = useDebounce(search, 700);
    const [page, setPage] = useState(1);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const pageSize = 15;
    const { t } = useTranslation('common');

    const onSearch = (e) => setSearch(e.target.value);
    const onSelectedStudent = (e, userId) => {
        const { checked } = e.target;
        const numberValue = +userId;
        let updatedList = [...selectedStudents];
        if (checked) {
            updatedList = [...updatedList, numberValue];
        } else {
            updatedList = updatedList.filter((p) => p !== numberValue);
        }
        setSelectedStudents(updatedList);
    };
    const onSelectedAllStudents = (e) => {
        const { checked } = e.target;
        const updatedList = students.results.map((p) => p.user.id);
        if (checked) {
            setIsCheckedAll(true);
            setSelectedStudents(updatedList);
        } else {
            setIsCheckedAll(false);
            setSelectedStudents([]);
        }
    };
    const onClearSelectedStudents = () => {
        setIsCheckedAll(false);
        setSelectedStudents([]);
    };
    const onOpenAddCoursesModal = (studentId) => {
        setIsOpenAddCoursesModal(true);
        setSelectedStudentId(studentId);
    };

    const onRemoveStudent = async (id, userId) =>
        await removeStudent({
            spaceId: userSpace.id,
            groupId,
            users: [userId],
        });
    const onRemoveSelectedMentors = async () =>
        await removeStudent({
            spaceId: userSpace.id,
            groupId,
            users: selectedStudents,
        }).then(({ data }) => {
            if (data.length === 0) {
                onClearSelectedStudents();
            }
        });

    const loadMoreStudents = async () => {
        if (!students.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            space_id: userSpace?.id,
            group_id: groupId,
            search: debouncedSearch,
            page: page + 1,
            page_size: pageSize,
        });
        const modifiedResults = [...students.results, ...data.results];
        setUserGroupStudents({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        if (userSpace) {
            trigger({
                space_id: userSpace?.id,
                group_id: groupId,
                search: debouncedSearch,
                page,
                page_size: pageSize,
            });
        }
    }, [userSpace]);

    useEffect(() => {
        if (userSpace) {
            setPage(1);
            trigger({
                space_id: userSpace?.id,
                group_id: groupId,
                search: debouncedSearch,
                page: 1,
                page_size: pageSize,
            });
        }
    }, [debouncedSearch]);

    const renderTable = () => {
        if (isLoading) {
            return null;
        } else if (search.length > 0 && !students?.results.length) {
            return <UserSearchEmpty />;
        } else if (students?.results.length === 0) {
            return (
                <UserEmpty
                    title={t('LIST_EMPTY')}
                    desc={t('ADD_STUDENTS_DESCRIPTION')}
                    btnText={t('STUDENTS_ADD')}
                    onClick={() => setIsOpen(true)}
                />
            );
        } else {
            return students?.results.length > 0 ? (
                <UsersTableList
                    count={students?.count}
                    pageSize={pageSize}
                    items={students.results}
                    loadMore={loadMoreStudents}
                    hasMore={students.hasMore}
                    renderItem={(student) => (
                        <StudentItem
                            key={student.id}
                            isSelected={selectedStudents.includes(
                                student.profile.id
                            )}
                            onSelectedStudent={onSelectedStudent}
                            onRemove={onRemoveStudent}
                            onOpenAddCoursesModal={onOpenAddCoursesModal}
                            qtyCourses={student.coursesQty}
                            qtyGroups={student.groupsQty}
                            navigateId={student.profile.id}
                            {...student}
                        />
                    )}
                />
            ) : null;
        }
    };
    return (
        <div className={'students-wrapper'}>
            <div
                className={classNames('students', {
                    shift: selectedStudents.length > 0,
                })}
            >
                <UsersTableFilter
                    count={students?.count}
                    search={search}
                    searchPlaceholder={t('SEARCH_STUDENTS')}
                    isFilter={false}
                    isChecked={isCheckedAll}
                    isDetailPage={true}
                    onSearch={onSearch}
                    onSelectedAll={onSelectedAllStudents}
                />
                {renderTable()}
            </div>
            {selectedStudents.length > 0 ? (
                <GroupDetailStudentsBottomModal
                    count={selectedStudents.length}
                    maxCount={students?.count}
                    onClear={onClearSelectedStudents}
                    onRemove={onRemoveSelectedMentors}
                />
            ) : null}
            {isOpenAddCoursesModal ? (
                <AddCoursesModal
                    isOpen={isOpenAddCoursesModal}
                    setIsOpen={setIsOpenAddCoursesModal}
                    selectedStudentId={selectedStudentId}
                />
            ) : null}
        </div>
    );
};

export default Students;
