import React from 'react';
import TestStatisticsItem from 'pages/analytics/views/courses/DetailCourses/views/test/TestStatisticsItem';
import { useTranslation } from 'react-i18next';
const TestStatisticsList = ({ data }) => {
    const { t } = useTranslation('common');
    return (
        <div className="">
            {(data || []).map((x, index) => {
                console.log({ x });
                return (
                    <div className="analytics-test-statistics__itemWrap">
                        <div className="analytics-test-statistics__mainTitle">
                            {x.name}
                        </div>
                        <div>
                            <div className="TestStatisticsList__list-title">
                                {index + 1}.{x.Question.name}
                            </div>
                            <TestStatisticsItem
                                title={t('ANALYTICS_WRONG_ANSWERS')}
                                {...x}
                                percent={x.wrong_percent}
                                responseQty={x.wrong_responses_qty}
                            />
                            <TestStatisticsItem
                                success={true}
                                title={t('ANALYTICS_CORRECT_ANSWERS')}
                                {...x}
                                percent={x.correct_percent}
                                responseQty={x.correct_responses_qty}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TestStatisticsList;
