import { baseApi, baseQuery } from 'api/xhr';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { store } from 'store';
import { BASE_URL_NOTIFICATION_SERVICE } from '../../../configs';

console.log({ BASE_URL_NOTIFICATION_SERVICE });
export const notificationApi = createApi({
    tagTypes: ['Notifications'],
    reducerPath: 'notificationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL_NOTIFICATION_SERVICE,
        prepareHeaders: (headers) => {
            headers.set(
                'Authorization',
                `Bearer ${store.getState().auth.token}`
            );
            return headers;
        },
    }),
    endpoints: (build) => ({
        getNotifications: build.query({
            query: () => ({
                url: '/my/notify-info',
            }),
        }),
        getNotificationDetail: build.query({
            query: ({ notifyId }) => ({
                url: `/my/notify-detail/info:${notifyId}`,
            }),
        }),
    }),
});

export const { useGetNotificationsQuery, useGetNotificationDetailQuery } =
    notificationApi;
