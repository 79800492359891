import { authRequest } from 'api/xhr';
import { CONTENT_COURSES_ROUTE, GET_ACCESS_TOKEN_URL } from 'utils/consts';
import { useEffect } from 'react';
import { useAction } from 'helpers/hooks';
import { useLazyFetchSpaceQuery, useMeQuery } from 'api/services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from 'components/common/Spinner/Spinner';

const WithCheckDomain = ({ children, noNavigation }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { setToken, setRefreshToken } = useAction();
    const [trigger] = useLazyFetchSpaceQuery();
    const hostName = window.location.hostname.toLowerCase();
    const domain = window.location.hostname.toLowerCase().split('.')[0];
    const navigate = useNavigate();
    const { userSpace } = useSelector((state) => state.space);
    const { data: profile } = useMeQuery(
        { id: userSpace?.id },
        { skip: !userSpace }
    );

    const findDomain = ({ domain }) => hostName.match(domain);

    const getAccessToken = async ({ token }) => {
        const { token: accessToken, refresh } = await authRequest.post(
            GET_ACCESS_TOKEN_URL,
            { token, domain }
        );
        setToken({ token: accessToken });
        setRefreshToken({ token: refresh });
        if (accessToken) {
            await trigger({ domain });
            if (noNavigation) {
                searchParams.delete('oauth');
                searchParams.delete('refresh_token');
                setSearchParams(searchParams);
            } else {
                navigate(CONTENT_COURSES_ROUTE, { replace: true });
            }
        }
    };

    const checkDomain = async () => {
        const search = window.location.search;
        const searchWithOutRefreshToken = search.split('&refresh_token=')[0];
        const currentToken = searchWithOutRefreshToken.split('=')[1];
        const searchKey = searchWithOutRefreshToken.split('=')[0];

        if (searchKey === '?oauth') {
            const refreshToken = search.split('&refresh_token=')[1];

            if (refreshToken) {
                setRefreshToken({ token: refreshToken });
            }
            if (!findDomain({ domain: 'app' })) {
                if (findDomain({ domain: 'localhost' })) {
                    if (hostName.split('.').length > 1) {
                        await getAccessToken({ token: currentToken });
                    }
                } else if (findDomain({ domain: 'classlytics' })) {
                    if (findDomain({ domain: 'classlytics' })['index'] !== 0) {
                        await getAccessToken({ token: currentToken });
                    }
                } else {
                    if (hostName.split('.').length > 2) {
                        await getAccessToken({ token: currentToken });
                    }
                }
            }
        } else {
            trigger({ domain });
        }
    };

    useEffect(() => {
        checkDomain();
    }, []);

    return profile ? children : <Spinner />;
};

export default WithCheckDomain;
