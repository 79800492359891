import {
    Button,
    Heading,
    Icon,
    List,
    Progress,
    Title,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import { coursesMock } from 'pages/courses/student-courses/data';
import defaultAvatar from 'assets/img/png/img.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFetchStudentCoursesQuery } from 'api/services';
import 'pages/courses/student-courses/common/StudentCoursesAccess/StudentCoursesAccess.scss';
import { useTranslation } from 'react-i18next';

const StudentCoursesProcess = () => {
    const navigate = useNavigate();
    const { userSpace } = useSelector((state) => state.space);
    const { data: courses } = useFetchStudentCoursesQuery(
        { id: userSpace?.id },
        { skip: !userSpace }
    );
    const { t } = useTranslation('common');
    const onDetailed = (id) =>
        navigate(`content/courses/detailed/${userSpace?.id}/${id}`);
    return (
        <article>
            <Heading className={'student-courses-access__title'} variant={'h1'}>
                {t('STUDENT_COURSES_IN_PROGRESS')}
            </Heading>
            <List
                className={'student-courses-access__list'}
                items={coursesMock}
                renderItem={({
                    id,
                    image,
                    isFavorite,
                    name,
                    subjectCount,
                    percentage,
                }) => (
                    <li key={id} className={'student-courses-access__item'}>
                        <img
                            className={'item__img'}
                            src={image || defaultAvatar}
                            alt={name}
                            onClick={() => onDetailed(id)}
                        />
                        <div
                            className={classNames('item__fav', {
                                isWhite: !isFavorite,
                            })}
                        >
                            <Icon
                                name={isFavorite ? 'heart-favorite' : 'heart'}
                            />
                        </div>
                        <Heading className={'item__name'} variant={'h5'}>
                            {name || '-'}
                        </Heading>
                        <div className={'item__percentage'}>
                            <Typography variant={'body4'}>
                                {subjectCount}
                            </Typography>
                            <Title
                                className={'item__percent'}
                                variant={'title3'}
                            >
                                {percentage}%
                            </Title>
                        </div>
                        <Progress
                            className={'item__progress'}
                            value={percentage}
                            max={100}
                        />
                        <Button
                            className={'item__btn'}
                            variant={'secondary'}
                            fullWidth
                            disabled={true}
                        >
                            {t('STUDENT_COURSE_CONTINUE')}
                        </Button>
                    </li>
                )}
            />
        </article>
    );
};

export default StudentCoursesProcess;
