import { headerProfileNavs } from 'components/common/Header/consts';
import {
    Icon,
    Typography,
    List,
} from '@akhter-studios/classlytics-front-ui-components';
import { useAction } from 'helpers/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './ProfileMenuList.scss';
import { useSelector } from 'react-redux';

const ProfileMenuList = ({ setIsShow }) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { logout } = useAction();
    const { profile } = useSelector((state) => state.profile);

    const onNavigate = ({ to, onClick }) => {
        setIsShow(false);
        if (to) {
            navigate(to);
        } else {
            onClick();
        }
    };
    return (
        <List
            className={'profile-menu-list'}
            items={headerProfileNavs({ logout, role: profile.role })}
            renderItem={({ id, icon, name, to, isDivider, isShow, onClick }) =>
                isShow ? (
                    <li key={id} className={'profile-menu-list__item'}>
                        <div
                            className={classNames('profile-menu-list__nav', {
                                border: isDivider,
                                active: to === pathname,
                            })}
                            onClick={() => onNavigate({ to, onClick })}
                        >
                            <Icon className={'nav__icon'} name={icon} />
                            <Typography
                                className={'nav__name'}
                                variant={'body3'}
                            >
                                {t(name)}
                            </Typography>
                        </div>
                    </li>
                ) : null
            }
        />
    );
};

export default ProfileMenuList;
