import { AudioTool } from 'components/common/Editor/tools';
import { make } from 'components/common/Editor/tools/utils';

export default class EmbedDOC extends AudioTool {
    makePDFEmbed(file) {
        return make('iframe', '', {
            src: `https://view.officeapps.live.com/op/embed.aspx?src=${file.url}`,
            width: '100%',
            height: '500px',
        });
    }
    showFileData() {
        this.nodes.wrapper.classList.add('cdx-attaches--doc');
        const { file, title } = this.data;
        const fileInfo = make('div', this.CSS.fileInfo);
        this.nodes.title = make('div', this.CSS.title);
        fileInfo.appendChild(this.nodes.title);
        this.nodes.title.dataset.placeholder = this.api.i18n.t('File title');
        this.nodes.title.textContent = title || '';
        const pdf = this.makePDFEmbed(file);
        this.nodes.wrapper.appendChild(pdf);
        this.nodes.wrapper.appendChild(fileInfo);
    }
}
