import React from 'react';
import CDropdown from 'pages/courses/CDropdown/CDropdown';

const CoursesContentDropdown = ({ show, setShow, items, icon = 'plus' }) => {
    return (
        <CDropdown
            icon={icon}
            show={show}
            setShow={setShow}
            onShowMoreClick={() => setShow(!show)}
            items={items}
            bottomSheetTitle={'Содержание курса'}
            bottomSheetMinHeight={252}
        />
    );
};

export default CoursesContentDropdown;
