import React, { memo, useRef } from 'react';
import { Icon } from 'components/common';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

const BaseAddFolder = ({ addFolder, onUploadFile }) => {
    const { t } = useTranslation('common');
    const uploadFileRef = useRef();
    return (
        <div className="knowledge-base__actions">
            <div className="knowledge-base__actions-item">
                <label htmlFor="base-upload-file">
                    <span>{t('UPLOAD_FILE')}</span>
                    <Icon name="document-upload-file" />
                    <input
                        ref={uploadFileRef}
                        onChange={(e) => {
                            onUploadFile(
                                Array.prototype.slice.call(e.target.files),
                                null,
                                nanoid(),
                                uploadFileRef.current
                            );
                        }}
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        id="base-upload-file"
                        name="base-upload-file"
                    />
                </label>
            </div>
            <div className="knowledge-base__actions-item" onClick={addFolder}>
                <span>{t('CREATE_FOLDER')}</span>
                <Icon name="document-create-folder" />
            </div>
        </div>
    );
};

export default memo(BaseAddFolder);
