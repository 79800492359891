import {
    Button,
    Heading,
    Typography,
    Icon,
} from '@akhter-studios/classlytics-front-ui-components';
import './SettingsItem.scss';
import { useTranslation } from 'react-i18next';

const SettingsItem = ({
    children,
    title,
    description,
    btnSecondaryText = 'APPLY',
    isDefaultBtn = true,
    isApplyBtn = true,
    isInfo = true,
    onReset,
    onApply,
}) => {
    const { t } = useTranslation('common');
    return (
        <div className={'settings-item settings-colors__field'}>
            <div className={'settings-item__wrapper'}>
                <div className={'settings-item__heading'}>
                    <Heading className={'settings-item__title'} variant={'h4'}>
                        {title}
                    </Heading>
                    {description ? (
                        <Typography
                            className={'settings-item__desc'}
                            variant={'body4'}
                        >
                            {description}
                        </Typography>
                    ) : null}
                </div>
                {/*{isInfo ? <Icon name={'info'} /> : null}*/}
            </div>
            <div className={'settings-item__content'}>
                {children}
                <div className={'settings-item__buttons'}>
                    {isApplyBtn ? (
                        <Button
                            className={'tertiary'}
                            size={'small'}
                            onClick={onReset}
                        >
                            {t('DEFAULT')}
                        </Button>
                    ) : null}
                    {isDefaultBtn ? (
                        <Button
                            variant={'secondary'}
                            size={'small'}
                            onClick={onApply}
                        >
                            {t(btnSecondaryText)}
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SettingsItem;
