import { Button, Icon } from '@akhter-studios/classlytics-front-ui-components';
import './HeaderUploadCourseButton.scss';

const HeaderUploadCourseButton = ({ onClick, buttonText, icon = 'upload' }) => {
    return (
        <Button
            className={'headerUploadCourseBtn'}
            onClick={onClick}
            variant={'secondary'}
            size={'small'}
        >
            {buttonText}
            <Icon name={icon} />
        </Button>
    );
};

export default HeaderUploadCourseButton;
