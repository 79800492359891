import React, { useState } from 'react';
import AnalyticsSearchFilter from 'pages/analytics/common/AnalyticsSearchFilter/AnalyticsSearchFilter';
import AnalyticsTable from 'pages/analytics/common/AnalyticsTable/AnalyticsTable';
import {
    groupsTable,
    groupsTableHeadings,
    modalType,
} from 'pages/analytics/common/consts';
import defaultGroupImg from 'assets/img/png/group.png';
import DialogModalCustom from 'components/common/DialogModalCustom/DialogModalCustom';
import GroupsStatistics from 'pages/analytics/views/groups/GroupsStatistics';
import { useFetchGroupsQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { Modal } from '@akhter-studios/classlytics-front-ui-components';
import './Groups.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'helpers/hooks';
const Groups = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const debouncedSearchInput = useDebounce(search, 800);
    const { data } = useFetchGroupsQuery({
        spaceId: userSpace?.id,
        page: page - 1,
        search: debouncedSearchInput,
    });
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalName, setModalName] = useState('');
    const handleToggleModal = (e, name, item) => {
        if (e) {
            setModalName(name);
            setSelectedItem(item);
            e.preventDefault();
            e.stopPropagation();
        }
        setModal((prev) => !prev);
    };

    const onSearch = (e) => setSearch(e.target.value);

    const handleClick = (item) => {
        setSelectedItem(item);
        setOpen(true);
    };
    return (
        <section className="students analytics-courses analytics-groups">
            <AnalyticsSearchFilter
                searchPlaceholder={t('USERS_GROUPS_SEARCH')}
                totalCount={data?.total_qty}
                onSearch={onSearch}
            />
            <AnalyticsTable
                isStudent
                handler={handleClick}
                headings={groupsTableHeadings}
                body={groupsTable(
                    { data: data?.items, handleToggleModal },
                    defaultGroupImg
                )}
                withImgColumn
                withoutBorder
                page={page}
                onChangePage={setPage}
                withoutPagination
                total={data?.total_qty}
            />
            <DialogModalCustom
                open={open}
                setOpen={setOpen}
                customHeight={window.innerHeight - 100}
            >
                {open && <GroupsStatistics selectedItem={selectedItem} />}
            </DialogModalCustom>
            <Modal
                className="access-course-modal full-modal-mobile"
                isOpen={modal}
                setIsOpen={() => handleToggleModal()}
            >
                {modal && modalType(selectedItem, handleToggleModal)[modalName]}
            </Modal>
        </section>
    );
};

export default Groups;
