import './SettingsCompanySquareLogoModal.scss';
import {
    Button,
    Heading,
    Modal,
} from '@akhter-studios/classlytics-front-ui-components';
import AvatarEditor from 'react-avatar-editor';
import { useState } from 'react';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { ranges } from 'pages/courses/Course/create-course/CreateCourseCoverModal/consts';
import { dataURLtoFile } from 'utils/functions';
import { useUpdateSpaceAssetsMutation } from 'api/services';
import { useSelector } from 'react-redux';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import { useTranslation } from 'react-i18next';
const SettingsCompanySquareLogoModal = ({
    isOpen,
    setIsOpen,
    file,
    setFile,
    setCurrentSquareLogo,
}) => {
    const { t } = useTranslation('common');
    const isMobile = useIsMobile();
    const [editor, setEditor] = useState(null);
    const [value, setValue] = useState({
        scale: 1,
        rotate: 0,
    });
    const { userSpace } = useSelector((state) => state.space);
    const [updateSpaceAssets, { isLoading }] = useUpdateSpaceAssetsMutation();
    const setEditorRef = (editor) => setEditor(editor);
    const onValueChange = (e) => {
        const { name, value } = e.target;
        setValue((prev) => ({ ...prev, [name]: parseFloat(value) }));
    };
    const onUploadOpen = () =>
        document.getElementById('squareLogoChangeBtn').click();
    const onChangeFile = (e) => {
        const file = e.target.files[0];
        setFile(file);
        setValue({
            scale: 1,
            rotate: 0,
        });
    };
    const onSave = async () => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();

            const file = dataURLtoFile(croppedImg);
            const formData = new FormData();
            formData.append('square_logo', file);
            const { data } = await updateSpaceAssets({
                id: userSpace.id,
                formData,
            });
            if (data) {
                setCurrentSquareLogo(URL.createObjectURL(file));
                setIsOpen(false);
                setValue({ scale: 1, rotate: 0 });
            }
        }
    };

    const getContent = () => (
        <>
            <Heading
                className={'settings-company-square-logo-modal__title'}
                variant={'h2'}
            >
                {t('SETTINGS_SQUARE_MODAL_TITLE')}
            </Heading>
            <div className={'settings-company-square-logo-modal__cropper'}>
                <AvatarEditor
                    ref={setEditorRef}
                    image={file}
                    width={isMobile ? 320 : 412}
                    height={isMobile ? 320 : 412}
                    border={0}
                    color={[255, 255, 255, 0.6]}
                    scale={value.scale}
                    rotate={value.rotate}
                    crossOrigin={'anonymous'}
                />
            </div>
            <div className={'settings-company-square-logo-modal__ranges'}>
                {ranges.map(({ id, text, name, min, max, step }) => (
                    <div
                        key={id}
                        className={'settings-company-square-logo-modal__range'}
                    >
                        <div>
                            <p>{t(text)}</p>
                            <p>{value[name]}</p>
                        </div>
                        <input
                            type={'range'}
                            name={name}
                            min={min}
                            max={max}
                            step={step}
                            value={value[name]}
                            onChange={onValueChange}
                        />
                    </div>
                ))}
            </div>
            <div className={'settings-company-square-logo-modal__actions'}>
                <Button
                    variant={'secondary'}
                    size={'small'}
                    onClick={onUploadOpen}
                >
                    {t('SETTINGS_SQUARE_MODAL_REPLACE')}
                </Button>
                <Button
                    variant={'primary'}
                    size={'small'}
                    onClick={onSave}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    {t('SAVE')}
                </Button>
            </div>
            <input
                className={'settings-company-square-logo-modal__upload'}
                type="file"
                id="squareLogoChangeBtn"
                accept={'image/png, image/svg image/jpg image/jpeg image/ico'}
                onChange={onChangeFile}
                onClick={(e) => {
                    e.target.value = null;
                }}
            />
        </>
    );
    return (
        <>
            {isMobile ? (
                <BottomSheetCustom
                    open={isOpen}
                    setOpen={setIsOpen}
                    minHeight={525}
                >
                    {getContent()}
                </BottomSheetCustom>
            ) : (
                <Modal
                    className={'settings-company-square-logo-modal'}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                >
                    {getContent()}
                </Modal>
            )}
        </>
    );
};

export default SettingsCompanySquareLogoModal;
