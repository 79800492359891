import { AddCoursesToGroupModal, AddStudentsModal } from 'pages/users/common';

const renderModal = (props) => ({
    '': props.isOpen ? <AddStudentsModal {...props} /> : null,
    students: props.isOpen ? <AddStudentsModal {...props} /> : null,
    courses: props.isOpen ? <AddCoursesToGroupModal {...props} /> : null,
});

const renderText = {
    '': 'STUDENTS_ADD',
    students: 'STUDENTS_ADD',
    courses: 'ADD_COURSE',
};

export { renderText, renderModal };
