import React, { createContext, useContext } from 'react';
import useMaterialMove from 'helpers/hooks/useMaterialMove';

const MaterialsContext = createContext();
export const useMaterialsContext = () => useContext(MaterialsContext);

export const MaterialsContextProvider = ({
    children,
    materials,
    isReadOnly,
}) => {
    const props = useMaterialMove({
        materials,
        isReadOnly,
    });
    return (
        <MaterialsContext.Provider value={props}>
            {children}
        </MaterialsContext.Provider>
    );
};
