import React from 'react';
import { Icon } from '@akhter-studios/classlytics-front-ui-components';
import './AnalyticsHeaderMobile.scss';
const AnalyticsHeaderMobile = ({ title, close }) => {
    return (
        <div className="analytics-header-mobile">
            <Icon name="arrow-back" onClick={close} />
            <div className="analytics-header-mobile__title">{title}</div>
            <Icon name="close" onClick={close} />
        </div>
    );
};

export default AnalyticsHeaderMobile;
