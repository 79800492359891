import SettingsCard from 'pages/settings/common/SettingsCard';
import { useSelector } from 'react-redux';
import DefaultLogo from 'assets/img/svg/default-logo.svg';
import { useState } from 'react';
import './SettingsCompanySquareLogo.scss';
import { useTranslation } from 'react-i18next';
import { useDeleteSpaceImageMutation } from 'api/services';
import SettingsCompanySquareLogoModal from 'pages/settings/common/SettingsCompany/SettingsCompanySquareLogo/SettingsCompanySquareLogoModal';
const SettingsCompanySquareLogo = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [currentSquareLogo, setCurrentSquareLogo] = useState(
        userSpace?.squareLogo ?? DefaultLogo
    );
    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [deleteSpaceImage] = useDeleteSpaceImageMutation();
    const handleUpload = (e) => {
        const file = e.target.files[0];
        setFile(file);
        setIsOpen(true);
    };
    const onUpload = () => {
        document.getElementById('squareLogoUploadBtn').click();
    };

    const onRemove = async () => {
        setCurrentSquareLogo(DefaultLogo);
        await deleteSpaceImage({
            id: userSpace.id,
            image_type: 'square_logo',
        });
    };
    return (
        <>
            <input
                className={'settings-company-square-logo__upload'}
                type="file"
                id="squareLogoUploadBtn"
                accept={'image/png, image/svg image/jpg image/jpeg image/ico'}
                onChange={handleUpload}
                onClick={(e) => {
                    e.target.value = null;
                }}
            />
            <SettingsCard
                title={t('SETTINGS_SQUARE_LOGO_TITLE')}
                description={t('SETTINGS_SQUARE_LOGO_DESC')}
                onUpload={onUpload}
                onRemove={onRemove}
            >
                <img
                    className={'settings-company-square-logo__img'}
                    src={currentSquareLogo}
                    alt="logo"
                />
            </SettingsCard>
            <SettingsCompanySquareLogoModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                file={file}
                setFile={setFile}
                setCurrentSquareLogo={setCurrentSquareLogo}
            />
        </>
    );
};

export default SettingsCompanySquareLogo;
