import React, { useState } from 'react';
import {
    Dropdown,
    Icon,
    List,
    Search,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import { actions } from 'pages/users/common/UsersTable/UsersTableFilter/consts';
import { useTranslation } from 'react-i18next';
import UsersFilterSearch from 'components/common/UsersFilterSearch/UsersFilterSearch';

const AnalyticsSearchFilter = ({
    searchPlaceholder,
    onSearch,
    search,
    isFilter,
    onFilter,
    onShow,
    totalCount,
}) => {
    const [isShow, setIsShow] = useState(false);
    const [selectedAction, setSelectedAction] = useState({
        text: 'USERS_SHOW_ALL',
        type: null,
    });
    return (
        <UsersFilterSearch
            searchPlaceholder={searchPlaceholder}
            search={search}
            onSearch={onSearch}
            isFilter={isFilter}
            setIsShow={setIsShow}
            selectedAction={selectedAction}
            onShow={onShow}
            isShow={isShow}
            onFilter={onFilter}
            totalCount={totalCount}
        />
    );
};

export default AnalyticsSearchFilter;
