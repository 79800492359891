import React from 'react';
import './SortList.scss';
import { Icon } from 'components/common';
const SortList = ({ data, onFilter }) => {
    return (
        <div className="sort-list desktop-hidden">
            <div>Сортировать по:</div>
            <ul>
                {data.map((el) => (
                    <li onClick={() => onFilter(el.value)}>
                        <span>{el.title}</span>
                        <Icon name="sort-icon" />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SortList;
