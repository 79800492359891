import { Button, Input } from '@akhter-studios/classlytics-front-ui-components';
import { useFormik } from 'formik';
import { useUpdateUserGroupMutation } from 'api/services';
import { useSelector } from 'react-redux';
import { isDisabledForm, notify } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import { InvitedModal } from 'pages/users/common/UsersModalWrapper/InvitedModal';
import { changeGroupTitleSchema } from 'utils/schemas';

const ChangeGroupTitleModal = ({ isOpen, setIsOpen, groupId }) => {
    const { userSpace } = useSelector((state) => state.space);
    const [updateUserGroup, { isLoading }] = useUpdateUserGroupMutation();
    const { t } = useTranslation('common');

    const onSubmit = async (values, { resetForm }) => {
        const { title } = values;
        const { error } = await updateUserGroup({
            spaceId: userSpace.id,
            groupId,
            title,
        });
        if (!error) {
            resetForm({ values: '' });
            notify('success', t('NOTIFY_SUCCESSFULLY_GROUP_RENAMED'));
            setIsOpen(false);
        }
    };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        isValid,
    } = useFormik({
        initialValues: {
            title: '',
        },
        validationSchema: changeGroupTitleSchema,
        onSubmit,
    });
    return (
        <InvitedModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('GROUP_CHANGE_NAME_TITLE')}
        >
            <form onSubmit={handleSubmit}>
                <Input
                    id={'change-group-title'}
                    name={'title'}
                    label={t('NAME_GROUP')}
                    placeholder={t('GROUP_NAME')}
                    value={values.title}
                    helperText={touched['title'] && t(errors['title'])}
                    error={touched['title'] && errors['title']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Button
                    type={'submit'}
                    variant={'primary'}
                    size={'medium'}
                    loading={isLoading}
                    disabled={isDisabledForm({ isValid, isLoading })}
                    fullWidth
                >
                    {t('SAVE')}
                </Button>
            </form>
        </InvitedModal>
    );
};

export default ChangeGroupTitleModal;
