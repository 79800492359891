import { useState } from 'react';
import { useWindowSize } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actions } from './consts';
import {
    Checkbox,
    Icon,
    List,
    Typography,
    Avatar,
    Dropdown,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './StudentItem.scss';

const StudentItem = (props) => {
    const {
        id,
        qtyCourses = 0,
        qtyGroups = 0,
        user,
        onRemove,
        onSelectedStudent,
        onOpenAddCoursesModal,
        isSelected = false,
        navigateId,
    } = props;
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { width } = useWindowSize();

    const {
        id: userId,
        avatar,
        email,
        firstName,
        lastName,
        isActive = true,
    } = user;
    const [isShow, setIsShow] = useState(false);

    const onShow = (e) => {
        e.stopPropagation();
        setIsShow((prev) => !prev);
    };
    const onCopy = () => {
        toast(t('EMAIL_COPIED'), {
            position: 'top-right',
            theme: 'colored',
        });
        return navigator.clipboard.writeText(email);
    };
    const onNavigateToUserDetails = () => {
        navigate(`/content/users/students/${navigateId || id}/courses`);
    };
    const onSelected = () => {
        if (width < 769) {
            onNavigateToUserDetails();
        } else {
            onSelectedStudent(
                {
                    target: { checked: !isSelected, value: navigateId || id },
                },
                userId
            );
        }
    };

    const onContainerOpenAddCoursesModal = () => {
        onOpenAddCoursesModal(id);
        setIsShow(false);
    };
    return (
        <div
            className={classNames('student-item', {
                selected: isSelected,
                invited: !isActive,
            })}
            onClick={onSelected}
        >
            <Checkbox
                value={navigateId || id}
                checked={isSelected}
                onChange={(e) => onSelectedStudent(e, userId)}
            />
            {!isActive ? (
                <div
                    className={'student-item__circle'}
                    onClick={onNavigateToUserDetails}
                />
            ) : (
                <Avatar
                    className={'student-item__avatar'}
                    src={avatar}
                    alt={'avatar'}
                    variant={'rounded'}
                    avatarText={`${firstName?.charAt(0).toUpperCase() || ''}${
                        lastName?.charAt(0).toUpperCase() || ''
                    }`}
                    onClick={onNavigateToUserDetails}
                />
            )}
            <div className={'student-item__content'}>
                <div>
                    <Typography
                        className={'student-item__name'}
                        variant={'body4'}
                    >
                        {firstName} {lastName}
                    </Typography>
                    <div>
                        <Typography
                            className={'student-item__email'}
                            variant={'body4'}
                        >
                            {email}
                        </Typography>
                        {!isActive ? (
                            <div className={'student-item__invited'}>
                                <Typography variant={'body5'}>
                                    {t('INVITED')}
                                </Typography>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={'student-item__qty'}>
                    <Typography variant={'body4'}>
                        {t('INVITED_COURSES')}
                    </Typography>
                    <Typography variant={'body4'}>{qtyCourses}</Typography>
                </div>
                <div className={'student-item__qty'}>
                    <Typography variant={'body4'}>
                        {t('INVITED_GROUPS')}
                    </Typography>
                    <Typography variant={'body4'}>{qtyGroups}</Typography>
                </div>
            </div>
            <Dropdown
                className={'student-item__dropdown'}
                isShow={isShow}
                setIsShow={setIsShow}
            >
                <Dropdown.Header>
                    <Icon name={'more'} onClick={onShow} />
                </Dropdown.Header>
                <Dropdown.Body>
                    <List
                        className={'student-item__actions'}
                        items={actions({
                            id,
                            userId,
                            isActive,
                            onNavigateToUserDetails,
                            onCopy,
                            onRemove,
                            onContainerOpenAddCoursesModal,
                        })}
                        renderItem={({ id, text, border, isShow, onClick }) =>
                            isShow ? (
                                <li
                                    key={id}
                                    className={classNames(
                                        'student-item__action',
                                        { border }
                                    )}
                                    onClick={onClick}
                                >
                                    <Typography variant={'body4'}>
                                        {t(text)}
                                    </Typography>
                                </li>
                            ) : null
                        }
                    />
                </Dropdown.Body>
            </Dropdown>
            {width < 769 ? (
                <Icon
                    className={'student-item__arrow'}
                    name={'arrow-right'}
                    onClick={onNavigateToUserDetails}
                />
            ) : null}
        </div>
    );
};

export default StudentItem;
