import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpenSettings: false,
    timer: '',
    resultPercent: '',
    timeIsOver: false,
};

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        toggleOpenningSettingsAction: (state) => ({
            isOpenSettings: !state.isOpenSettings,
        }),
        setCheckTimeIsOver: (state, { payload }) => ({
            timeIsOver: payload || true,
        }),
        setTimerAction: (state, { payload }) => ({
            timer: payload,
        }),
        setResultPercentAction: (state, { payload }) => ({
            resultPercent: payload,
        }),
    },
});

const testActions = testSlice.actions;
export { testActions };

export default testSlice.reducer;
