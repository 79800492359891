import React from 'react';
import { components } from 'react-select';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    const shouldFloatLabel = props.selectProps.menuIsOpen || props.hasValue;

    return (
        <ValueContainer {...props}>
            <div
                style={{ overflowY: 'auto', maxHeight: '300px', width: '100%' }}
            >
                <Placeholder {...props} isFocused={shouldFloatLabel}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, (child) =>
                    child && child.type !== Placeholder ? child : null
                )}
            </div>
        </ValueContainer>
    );
};

export default CustomValueContainer;
