import { createSlice } from '@reduxjs/toolkit';
import { profileApi } from 'api/services';

export const profileSlice = createSlice({
    name: 'profile',
    initialState: { profile: null },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            profileApi.endpoints.me.matchFulfilled,
            (state, { payload }) => {
                state.profile = {
                    ...payload,
                    role: payload.role.toLowerCase(),
                };
            }
        );
    },
});

export default profileSlice.reducer;
