import { nanoid } from 'nanoid';

const tabs = [
    {
        id: nanoid(),
        to: 'students',
        icon: 'student',
        text: 'STUDENTS',
        search: 'students',
    },
    {
        id: nanoid(),
        to: 'courses',
        icon: 'courses',
        text: 'COURSES',
        search: 'courses',
    },
];

export { tabs };
