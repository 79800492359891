const personalDataForm = [
    {
        id: 'personalFirstName',
        name: 'first_name',
        label: 'PROFILE_NAME',
    },
    {
        id: 'personalLastName',
        name: 'last_name',
        label: 'PROFILE_LAST_NAME',
    },
];

const confirmationForm = [
    {
        id: 'confirmationPassword',
        type: 'password',
        name: 'password',
        label: 'PASSWORD',
        isError: true,
    },
    {
        id: 'confirmationSecondPassword',
        type: 'password',
        name: 'second_password',
        label: 'PROFILE_REENTER_PASSWORD',
    },
];

export { personalDataForm, confirmationForm };
