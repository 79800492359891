import { useSearchParams } from 'react-router-dom';
import { useTimer } from 'helpers/hooks';
import { useResendingMessageMutation } from 'api/services';
import {
    Button,
    Heading,
} from '@akhter-studios/classlytics-front-ui-components';
import ConfirmDesc from './ConfirmDesc';
import ConfirmLottie from './ConfirmLottie';
import 'pages/auth/Auth.scss';
import './Confirm.scss';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Confirm = () => {
    const { t, i18n } = useTranslation('common');
    const [resendingMessage, { isLoading }] = useResendingMessageMutation();
    const { time, start } = useTimer({
        seconds: 60,
        sessionKey: 'confirmTime',
    });
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const domain = searchParams.get('domain');
    const { hostname } = window.location;

    const onResendMessage = async () => {
        if (email) {
            await resendingMessage({
                email,
                redirectTo: 'register',
                lang: i18n.language,
                domain,
                ori_domain: hostname,
            });
            await start();
        }
    };

    useEffect(() => {
        return () => {
            sessionStorage.removeItem('confirmTime');
        };
    }, []);
    return (
        <>
            <ConfirmLottie />
            <Heading className={'confirm-title'} variant={'h1'}>
                {t('AUTH_CONFIRM_TITLE')}
            </Heading>
            <ConfirmDesc email={email} />
            <Button
                className={'confirm-btn'}
                variant={'secondary'}
                loading={isLoading}
                onClick={onResendMessage}
                disabled={time > 0}
            >
                {time > 0 ? t('SEND_FORWARD', { time }) : t('SEND_EMAIL_AGAIN')}
            </Button>
        </>
    );
};

export default Confirm;
