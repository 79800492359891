import { UsersModalWrapper } from 'pages/users/common';
import './InvitedModal.scss';
import { Icon } from 'components/common';

const InvitedModal = (props) => {
    const { children, isOpen, setIsOpen, isSuccess, ...rest } = props;
    return (
        <UsersModalWrapper
            className={'invited-modal'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isSuccess={isSuccess}
            {...rest}
        >
            {isSuccess ? (
                <div className={'invited-modal__success'}>
                    <Icon name="success-icon" />
                </div>
            ) : (
                children
            )}
        </UsersModalWrapper>
    );
};

export default InvitedModal;
