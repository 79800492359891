import { useSelector } from 'react-redux';
import { useWindowSize } from 'helpers/hooks';
import classNames from 'classnames';
import '../Sidebar.scss';

const SidebarWrapper = ({ children }) => {
    const { isSidebarShow } = useSelector((state) => state.util);
    const { width } = useWindowSize();

    return (
        <aside
            className={classNames('sidebar', {
                'sidebar-small': isSidebarShow && width > 768,
            })}
        >
            <nav>{children}</nav>
        </aside>
    );
};

export default SidebarWrapper;
