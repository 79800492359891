import { Link, useLocation } from 'react-router-dom';
import { userRender } from 'pages/users/functions';
import { tabs } from './consts';
import {
    Icon,
    SegmentButton,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import classNames from 'classnames';
import './UsersHeading.scss';
import { useTranslation } from 'react-i18next';
import { useLocationScope } from 'helpers/hooks';

const UsersHeading = ({ isOpen, setIsOpen }) => {
    const scope = useLocationScope();
    const { t } = useTranslation('common');
    const onOpen = () => setIsOpen(true);
    return (
        <section className={'users-heading'}>
            <List
                className={'users-heading__tabs'}
                items={tabs}
                renderItem={({ id, icon, text, to, search }) => (
                    <li key={id} className={'users-heading__tab'}>
                        <Link
                            className={classNames('users-heading__link', {
                                active:
                                    search ===
                                    (scope.length > 0 && scope !== 'users'
                                        ? scope
                                        : 'students'),
                            })}
                            to={to}
                        >
                            <Icon name={icon} />
                            <Typography variant={'body4'}>{t(text)}</Typography>
                        </Link>
                    </li>
                )}
            />
            <SegmentButton
                className={'users-heading__btn'}
                size={'small'}
                onClick={onOpen}
            >
                <Icon name={'plus'} />
                <Typography variant={'body4'}>
                    {t(userRender({ search: scope, node: 'btnText' }))}
                </Typography>
            </SegmentButton>
            {userRender({ search: scope, node: 'modal' })({
                isOpen,
                setIsOpen,
            })}
        </section>
    );
};

export default UsersHeading;
