import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Heading, Icon } from '@akhter-studios/classlytics-front-ui-components';
import HeaderLogo from 'components/common/Header/HeaderLogo';
import HeaderHelper from 'components/common/Header/HeaderHelper';
import ArrowBack from 'components/ArrowBack/ArrowBack';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';
import HeaderSettingsSpaceMenu from 'components/common/Header/HeaderSettingsSpace/HeaderSettingsSpaceMenu';
import './HeaderSettingsSpace.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pickTextColorBasedOnBgColor } from 'utils/functions';

const renderComponent = {
    '': 'SETTINGS_COMPANY',
    '?scope=company': 'SETTINGS_COMPANY',
    '?scope=colors': 'SETTINGS_COLORS',
};
const HeaderSettingsSpace = () => {
    const { t } = useTranslation('common');
    const { search } = useLocation();
    const [open, setOpen] = useState(false);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);

    const onOpen = () => setOpen((prev) => !prev);
    return (
        <header
            className={'header'}
            style={{ backgroundColor: menuBackgroundColor }}
        >
            <ArrowBack />
            <HeaderLogo />
            <HeaderHelper />
            <div className={'header-settings-space'}>
                <Heading
                    className={'header-settings-space__title'}
                    variant={'h4'}
                    style={{
                        color: pickTextColorBasedOnBgColor(menuBackgroundColor),
                    }}
                >
                    {t(renderComponent[search])}
                </Heading>
                <Icon
                    name={open ? 'arrow-up' : 'arrow-down'}
                    onClick={onOpen}
                />
            </div>
            <BottomSheetCustom open={open} setOpen={setOpen} minHeight={170}>
                <HeaderSettingsSpaceMenu setOpen={setOpen} />
            </BottomSheetCustom>
        </header>
    );
};

export default HeaderSettingsSpace;
