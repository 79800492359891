import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useChangePasswordMutation } from 'api/services';
import { useAction } from 'helpers/hooks';
import { passwordSchema } from 'utils/schemas';
import { notify } from 'utils/functions';
import { SPACES_ROUTE } from 'utils/consts';
import { fields } from '../consts';
import { AuthButton, AuthError, AuthFields } from 'components/feature';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';

const PasswordForm = () => {
    const navigate = useNavigate();
    const { setToken } = useAction();
    const [searchParams] = useSearchParams();
    const [changePassword, { isLoading, isError, error }] =
        useChangePasswordMutation();
    const { t } = useTranslation('common');
    const { hostname } = window.location;
    const onSubmit = async (data) => {
        const token = searchParams.get('token');
        const { error } = await changePassword({ ...data, token }, hostname);
        if (!error) {
            setToken({ token });
            notify('success', t('NOTIFY_SUCCESSFULLY_RESTORED_PROFILE'));
            navigate(SPACES_ROUTE);
            // window.location.href = SPACES_ROUTE;
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
    } = useFormik({
        initialValues: {
            password: '',
            second_password: '',
        },
        validationSchema: passwordSchema(t),
        onSubmit,
    });
    return (
        <>
            {isError && <AuthError error={error.data.nonFieldErrors} />}
            <form className={'auth-form'} onSubmit={handleSubmit}>
                <AuthFields
                    fields={fields}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                />
                <AuthButton
                    isLoading={isLoading}
                    isValid={isValid}
                    text={t('SAVE_LOGIN')}
                />
            </form>
        </>
    );
};

export default PasswordForm;
