import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useRegisterByInvitationMutation } from 'api/services';
import { inviteSchema } from 'utils/schemas';
import { CONTENT_COURSES_ROUTE, GENERATE_TOKEN_URL } from 'utils/consts';
import { findDomainToRedirect } from 'utils/functions';
import { confirmationForm } from '../consts';
import { Typography } from '@akhter-studios/classlytics-front-ui-components';
import { AuthButton, AuthError, AuthFields } from 'components/feature';
import { authRequest } from 'api/xhr';
import 'pages/auth/Auth.scss';
import { useTranslation } from 'react-i18next';
import { getFirebaseToken } from '../../../../../firebase';

const ConfirmationForm = () => {
    const { t, i18n } = useTranslation('common');
    const { pathname } = useLocation();
    const { registerFormValues } = useSelector((state) => state.auth);
    const [registerByInvitation, { isLoading, isError, error }] =
        useRegisterByInvitationMutation();

    const onSubmit = async (values) => {
        const { password } = values;
        const { first_name, last_name, email, data, domain } =
            registerFormValues;
        // const fcm_id = await getFirebaseToken();
        const { error, data: resData } = await registerByInvitation({
            first_name,
            last_name,
            email,
            data,
            password,
            // fcm_id,
            lang: i18n.language,
        });
        if (!error) {
            const { token } = resData;
            authRequest
                .post(GENERATE_TOKEN_URL, { token, domain })
                .then(({ token: generateToken }) => {
                    if (generateToken) {
                        localStorage.removeItem('access_token');
                        window.location.href = `${
                            window.location.protocol
                        }//${domain}.${findDomainToRedirect()}${CONTENT_COURSES_ROUTE}?oauth=${generateToken}`;
                    }
                });
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
    } = useFormik({
        initialValues: {
            password: registerFormValues?.password ?? '',
            second_password: registerFormValues?.second_password ?? '',
        },
        validationSchema: inviteSchema(t)[pathname],
        onSubmit,
    });
    return (
        <>
            {isError && <AuthError error={error.data.email} />}
            <Typography
                className={'auth-desc'}
                variant={'body3'}
                dangerouslySetInnerHTML={{
                    __html: t('AUTH_INVITE_CONFIRM', {
                        email: registerFormValues.email ?? '',
                    }),
                }}
            ></Typography>
            <form className={'auth-form'} onSubmit={handleSubmit}>
                <AuthFields
                    fields={confirmationForm}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                />
                <AuthButton
                    isLoading={isLoading}
                    isValid={isValid}
                    text={t('NEXT')}
                />
            </form>
        </>
    );
};

export default ConfirmationForm;
