import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUploadCourseCoverMutation } from 'api/services';
import { dataURLtoFile } from 'utils/functions';
import { ranges, buttons } from './consts';
import {
    Button,
    Heading,
    Modal,
} from '@akhter-studios/classlytics-front-ui-components';
import AvatarEditor from 'react-avatar-editor';
import './CreateCourseCoverModal.scss';
import { useWindowSize } from 'helpers/hooks';
import useIsMobile from 'helpers/hooks/useIsMobile';
import CreateCourseCoverBottomSheet from 'pages/courses/Course/create-course/CreateCourseCoverBottomSheet';
import { useTranslation } from 'react-i18next';

const CreateCourseCoverModal = (props) => {
    const {
        isOpen,
        setIsOpen,
        cover,
        coverPreview,
        onUploadOpen,
        setCoverImg,
    } = props;

    const { t } = useTranslation('common');
    const { width } = useWindowSize();
    const isMobile = useIsMobile();
    const { userSpace } = useSelector((state) => state.space);
    const { courseId } = useParams();
    const [uploadCourseCover, { isLoading }] = useUploadCourseCoverMutation();
    const [editor, setEditor] = useState(null);
    const [value, setValue] = useState({
        scale: 1,
        rotate: 0,
    });

    const setEditorRef = (editor) => setEditor(editor);
    const onValueChange = (e) => {
        const { name, value } = e.target;
        setValue((prev) => ({ ...prev, [name]: parseFloat(value) }));
    };

    const onSave = async () => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();

            const file = dataURLtoFile(croppedImg);
            const { data } = await uploadCourseCover({
                spaceId: userSpace.id,
                courseId,
                file,
            });
            if (data) {
                setIsOpen(false);
                setCoverImg(data?.image);
                setValue({ scale: 1, rotate: 0 });
            }
        }
    };
    return isMobile ? (
        <CreateCourseCoverBottomSheet
            open={isOpen}
            setOpen={setIsOpen}
            setEditorRef={setEditorRef}
            coverPreview={coverPreview}
            cover={cover}
            value={value}
            onValueChange={onValueChange}
            onUploadOpen={onUploadOpen}
            onSave={onSave}
            isLoading={isLoading}
        />
    ) : (
        <Modal
            className={'create-course-cover-modal'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <Heading variant={'h2'}>{t('UPDATE_COVER')}</Heading>
            <div className={'create-course-cover-modal__cropper'}>
                <AvatarEditor
                    ref={setEditorRef}
                    image={coverPreview ?? cover}
                    width={width < 1025 ? 600 : 734}
                    height={412}
                    border={12}
                    color={[255, 255, 255, 0.6]}
                    scale={value.scale}
                    rotate={value.rotate}
                    crossOrigin={'anonymous'}
                />
            </div>
            <div className={'create-course-cover-modal__ranges'}>
                {ranges.map(({ id, text, name, min, max, step }) => (
                    <div
                        key={id}
                        className={'create-course-cover-modal__range'}
                    >
                        <div>
                            <p>{t(text)}</p>
                            <p>{value[name]}</p>
                        </div>
                        <input
                            type={'range'}
                            name={name}
                            min={min}
                            max={max}
                            step={step}
                            value={value[name]}
                            onChange={onValueChange}
                        />
                    </div>
                ))}
            </div>
            <div className={'create-course-cover-modal__buttons'}>
                {buttons({ onUploadOpen, onSave, isLoading }).map(
                    ({ id, variant, text, onClick, isLoading = false }) => (
                        <Button
                            key={id}
                            variant={variant}
                            size={'small'}
                            loading={isLoading}
                            onClick={onClick}
                        >
                            {t(text)}
                        </Button>
                    )
                )}
            </div>
        </Modal>
    );
};

export default CreateCourseCoverModal;
