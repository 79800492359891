import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List } from '@akhter-studios/classlytics-front-ui-components';
import { tabs } from './consts';
import classNames from 'classnames';
import { ActiveLink } from 'components/common';
import './SettingsMenu.scss';
const SettingsMenu = () => {
    const { search: locationSearch } = useLocation();
    const { t } = useTranslation('common');
    return (
        <nav className={'settings-menu'}>
            <List
                className={'settings-menu__list'}
                items={tabs}
                renderItem={({ id, icon, text, search, to, isDivider }) => (
                    <li
                        className={classNames('settings-menu__item', {
                            divider: isDivider,
                        })}
                        key={id}
                    >
                        <ActiveLink
                            icon={icon}
                            text={t(text)}
                            active={
                                search ===
                                (locationSearch.length > 0
                                    ? locationSearch
                                    : '?scope=company')
                            }
                            to={to}
                        />
                    </li>
                )}
            />
        </nav>
    );
};

export default SettingsMenu;
