import { nanoid } from 'nanoid';

const actions = ({ id, onNavigateToGroup, onRemove, onCancel }) => [
    {
        id: nanoid(),
        text: 'VIEW_GROUP',
        onClick: () => onNavigateToGroup(),
    },
    {
        id: nanoid(),
        text: 'GROUP_DELETE',
        border: true,
        onClick: () => onRemove(id),
    },
    {
        id: nanoid(),
        text: 'CANCEL',
        onClick: () => onCancel(),
    },
];

export { actions };
