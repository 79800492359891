import React from 'react';
import { Icon } from 'components/common';
import { useNavigate } from 'react-router-dom';

const ArrowBack = () => {
    const navigate = useNavigate();
    return (
        <div className="desktop-hidden arrow-back" onClick={() => navigate(-1)}>
            <Icon name="arrow-back" />
        </div>
    );
};

export default ArrowBack;
