import React, { useState } from 'react';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import {
    Dropdown,
    Icon,
    List,
    Typography,
    Heading,
} from '@akhter-studios/classlytics-front-ui-components';
import { useFindDropdownPosition } from 'helpers/hooks';
import classNames from 'classnames';
import './index.scss';
import BottomSheetCustom from 'components/common/BottomSheetCustom/BottomSheetCustom';

const MaterialOptionsDropdown = ({
    icon = 'move',
    items,
    moverClassnames,
    bottomSheetTitle,
}) => {
    const [moduleOptionsOpen, setModuleOptionsOpen] = useState(false);
    const { t } = useTranslation('common');
    const isMobile = useIsMobile();
    const { dropdownPosition, findDropdownPosition } =
        useFindDropdownPosition();
    return isMobile ? (
        <>
            <div className={moverClassnames}>
                <Icon
                    onClick={() => {
                        setModuleOptionsOpen((prev) => !prev);
                    }}
                    name="move"
                />
            </div>
            <BottomSheetCustom
                open={moduleOptionsOpen}
                setOpen={setModuleOptionsOpen}
                minHeight={252}
            >
                {bottomSheetTitle ? (
                    <Heading
                        className={'material-options__title'}
                        variant={'h4'}
                    >
                        {bottomSheetTitle}
                    </Heading>
                ) : null}
                <List
                    className={'material-options__item'}
                    items={items}
                    renderItem={(action) =>
                        !action.disabled ? (
                            <li
                                className={classNames(
                                    'material-options__item',
                                    {
                                        divider: action.isDivider,
                                    }
                                )}
                                key={action.id}
                                onClick={action.onClick}
                            >
                                <Icon
                                    className={'material-options__icon'}
                                    name={action.iconName}
                                />
                                <Typography
                                    variant={'body4'}
                                    className={'material-options__name'}
                                >
                                    {t(action.name)}
                                </Typography>
                            </li>
                        ) : null
                    }
                />
            </BottomSheetCustom>
        </>
    ) : (
        <>
            <Dropdown
                isShow={moduleOptionsOpen}
                setIsShow={setModuleOptionsOpen}
            >
                <Dropdown.Header>
                    <div className={moverClassnames}>
                        <Icon
                            onClick={(e) => {
                                findDropdownPosition(
                                    (e.clientY * 100) / window.innerHeight
                                );
                                setModuleOptionsOpen((prev) => !prev);
                            }}
                            name="move"
                        />
                    </div>
                </Dropdown.Header>
                <Dropdown.Body
                    className={classNames(
                        'material-options__body',
                        dropdownPosition
                    )}
                >
                    <List
                        items={items}
                        renderItem={(action) =>
                            !action.disabled ? (
                                <li
                                    className={classNames(
                                        'material-options__item',
                                        {
                                            divider: action.isDivider,
                                        }
                                    )}
                                    key={action.id}
                                    onClick={action.onClick}
                                >
                                    <Icon
                                        className={'material-options__icon'}
                                        name={action.iconName}
                                    />
                                    <Typography
                                        variant={'body4'}
                                        className={'material-options__name'}
                                    >
                                        {t(action.name)}
                                    </Typography>
                                </li>
                            ) : null
                        }
                    />
                </Dropdown.Body>
            </Dropdown>
        </>
    );
};

export default MaterialOptionsDropdown;
