import { axiosRequest } from 'api/xhr/axios/axios';
import axios, { CancelToken, isCancel } from 'axios';

export const fetchKnowledgeBase = (spaceId, filter) =>
    axiosRequest.get(`/knowledge-of-space/${spaceId}/knowledge-base/${filter}`);

export const fetchAllSpaces = () =>
    axiosRequest.get('/learning-space/get-spaces');

export const addFolderApi = (spaceId, body) => {
    return axiosRequest.post(
        `knowledge-of-space/${spaceId}/knowledge-base/`,
        body
    );
};

export const createFIle = (
    spaceId,
    body,
    setUploadPercent,
    uploadId,
    tempFile,
    uploadFileCancel
) =>
    axiosRequest.post(`knowledge-of-space/${spaceId}/knowledge-base/`, body, {
        onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadPercent((prev) => ({
                ...prev,
                [uploadId]: {
                    ...prev[uploadId],
                    ...tempFile,
                    value: percentCompleted,
                },
            }));
        },
        cancelToken: new CancelToken((cancel) =>
            uploadFileCancel(cancel, uploadId)
        ),
    });

export const changeFileName = (spaceId, id, name) =>
    axiosRequest.patch(`/knowledge-of-space/${spaceId}/knowledge-base/${id}/`, {
        name,
    });

export const moveFileApi = (spaceId, body) =>
    axiosRequest.patch(`/knowledge-of-space/${spaceId}/knowledge-base/move/`, {
        results: body,
    });

export const removeFileApi = (spaceId, id) =>
    axiosRequest.delete(`/knowledge-of-space/${spaceId}/knowledge-base/${id}/`);

export const removeFileAndFolderApi = (spaceId, id) =>
    axiosRequest.delete(
        `/knowledge-of-space/${spaceId}/knowledge-base/${id}/delete-folder-and-files/`
    );

export const massDeleteAPi = (spaceId, ids) => {
    return axiosRequest.post(
        `/knowledge-of-space/${spaceId}/knowledge-base/mass-delete/`,
        { ids }
    );
};

export const fetchDetailFile = (id, spaceId) =>
    axiosRequest.get(`/knowledge-of-space/${spaceId}/knowledge-base/${id}/`);

export const fetchCourses = (spaceId, search, page) => {
    return axiosRequest
        .get(
            `/search/courses/?page=${
                search ? 1 : page
            }&page_size=15&search=${search}&space_id=${spaceId}`
        )
        .then((result) => result);
};
export const sendFileToCourseApi = (spaceId, file_ids, course_ids) => {
    return axiosRequest.post(
        `/knowledge-of-space/${spaceId}/knowledge-base/mass-send/`,
        {
            file_ids,
            course_ids,
        }
    );
};
export const createQueryBase = (sorted, media, value) => {
    let obj = {
        asc: 'asc',
        desc: 'desc',
    };
    let str = '?temp=temp';

    if (media !== 'TEXT') {
        str += `&search-filetype=${media}`;
    }

    if (value) {
        str += `&search-name=${value}`;
    }
    if (obj[sorted]) {
        str += `&sort-date=${obj[sorted]}`;
    }
    if (sorted === 'not') {
        str += '&sort-alphabet=true';
    }
    if (sorted === 'exist') {
        str += '&sort-alphabet=false';
    }

    return str;
};
