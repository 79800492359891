const fields = [
    {
        id: 'loginEmail',
        type: 'email',
        name: 'email',
        label: 'ENTER_EMAIL',
        placeholder: 'login@mail.com',
        isError: true,
    },
    {
        id: 'loginPassword',
        type: 'password',
        name: 'password',
        label: 'PASSWORD',
        isInputProps: true,
        isError: true,
    },
];

export { fields };
