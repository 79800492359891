import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
    useInvitationUserMentorMutation,
    useLazyFetchUserSearchStudentsQuery,
    useLazySearchCoursesQuery,
    useLazySearchUsersQuery,
} from 'api/services';
import { isDisabledForm, notify } from 'utils/functions';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { CoursesModal } from 'pages/users/common/InviteStudentModal/CoursesModal';
import DefaultMultiValue from 'components/custom-react-select/CustomMultiValue/DefaultMultiValue';
import {
    CustomAsyncCreatableSelect,
    CustomAsyncSelect,
} from 'components/custom-react-select';
import { InvitedModal } from 'pages/users/common/UsersModalWrapper/InvitedModal';
import {
    FormatOptionLabelCourse,
    FormatOptionLabelUser,
} from 'pages/users/select-formats';
import { userStudentInvitationSchema } from 'utils/schemas';
import { debounce, removeDuplicateObjects } from 'utils/functions/helpers';
import usePaste from 'helpers/hooks/usePaste';

const InviteStudentModal = ({ isOpen, setIsOpen }) => {
    const { t, i18n } = useTranslation('common');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFocusEmail, setIsFocusEmail] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const [trigger] = useLazySearchUsersQuery();
    const [courseTrigger] = useLazySearchCoursesQuery();
    const [invitationUserMentor, { isLoading }] =
        useInvitationUserMentorMutation();
    const [studentsTrigger] = useLazyFetchUserSearchStudentsQuery();
    const [isOpenCoursesModal, setIsOpenCoursesModal] = useState(false);
    const fieldEmailRef = useRef(null);
    const onSubmit = async (values, { resetForm }) => {
        const { type, emails, courses } = values;
        const newEmail = emails.map((e) => e.value.toLowerCase());
        const newCourses = courses.map((e) => e.value);
        const { error } = await invitationUserMentor({
            spaceId: userSpace.id,
            emails: newEmail,
            courses: newCourses,
            type,
            target: 'invite',
            lang: i18n.language,
        });

        if (!error) {
            resetForm({ values: '' });
            setIsSuccess(true);
            studentsTrigger({
                space_id: userSpace?.id,
                page: 1,
                page_size: 15,
                target: 'add_group',
                unique: false,
                role: 'student',
            });
        }
    };

    const {
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: {
            emails: [],
            courses: [],
            type: 'student',
        },
        validationSchema: userStudentInvitationSchema,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit,
    });

    usePaste({
        isFocus: isFocusEmail,
        fieldRef: fieldEmailRef,
        setFieldValue,
        values,
    });
    const loadEmails = async (searchValue, callback) => {
        if (searchValue) {
            const res =
                userSpace &&
                (await trigger({
                    space_id: userSpace.id,
                    search: searchValue.toLowerCase(),
                    unique: true,
                    target: 'invite',
                }));
            if (res?.error?.data) {
                notify('error', res.error.data.msg);
            }
            const newUsers = res.data.results.map(({ email, avatar }) => ({
                label: email,
                value: email,
                image: avatar?.length > 0 ? avatar : null,
            }));
            callback(newUsers);
        } else {
            callback([]);
        }
    };
    const loadCourses = async (searchValue, callback) => {
        if (searchValue) {
            const res =
                userSpace &&
                (await courseTrigger({
                    space_id: userSpace.id,
                    status: 'active',
                    search: searchValue,
                }));
            const newCourses = res.data.results.map(({ id, name, image }) => ({
                label: name,
                value: id,
                image,
            }));
            callback(newCourses);
        } else {
            callback([]);
        }
    };

    const debounceLoadEmails = debounce(loadEmails, 700);
    const debounceLoadCourses = debounce(loadCourses, 700);

    const onNext = () => setIsOpenCoursesModal(true);
    const onBack = () => setIsOpenCoursesModal(false);
    const onChangeEmails = (selectedOptions) =>
        setFieldValue('emails', selectedOptions);
    const onChangeCourses = (selectedOptions) =>
        setFieldValue('courses', selectedOptions);
    // console.log('values.emails', values.emails);
    return isOpenCoursesModal ? (
        <CoursesModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentCourses={values.courses}
            setFieldValue={setFieldValue}
            onBack={onBack}
        />
    ) : (
        <InvitedModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t(
                isSuccess ? 'INVITATION_SENT_SUCCESSFULLY' : 'INVITE_STUDENTS'
            )}
            isSuccess={isSuccess}
        >
            <form onSubmit={handleSubmit}>
                <CustomAsyncCreatableSelect
                    className={'field'}
                    name={'emails'}
                    placeholder={t('ADD_STUDENT')}
                    iconName={'search'}
                    defaultOptions
                    onFocus={() => {
                        setIsFocusEmail(true);
                    }}
                    customRef={fieldEmailRef}
                    isMulti
                    isClearable={false}
                    isError={!!(touched['emails'] && errors['emails'])}
                    helperText={t(errors['emails'])}
                    formatOptionLabel={FormatOptionLabelUser}
                    loadOptions={debounceLoadEmails}
                    value={values.emails}
                    onChange={onChangeEmails}
                    onBlur={() => {
                        handleBlur({ target: { name: 'emails' } });
                        setIsFocusEmail(false);
                    }}
                />
                <CustomAsyncSelect
                    className={'field'}
                    name={'courses'}
                    placeholder={t('GIVE_ACCESS_TO_COURSES')}
                    iconName={'arrow-right'}
                    loadOptions={debounceLoadCourses}
                    defaultOptions
                    isMulti
                    isClearable={false}
                    isError={!!(touched['courses'] && errors['courses'])}
                    helperText={t(errors['courses'])}
                    formatOptionLabel={(props) => (
                        <FormatOptionLabelCourse t={t} {...props} />
                    )}
                    value={values.courses}
                    onChange={onChangeCourses}
                    onBlur={() => {
                        handleBlur({ target: { name: 'courses' } });
                    }}
                    onNext={onNext}
                    components={{
                        MultiValue: DefaultMultiValue,
                    }}
                />
                <Button
                    variant={'primary'}
                    size={'medium'}
                    loading={isLoading}
                    disabled={isDisabledForm({ isValid, isLoading })}
                    fullWidth
                >
                    {t('INVITE')}
                </Button>
            </form>
        </InvitedModal>
    );
};

export default InviteStudentModal;
