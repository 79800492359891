import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = (custom) => {
    return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '12px',
        boxShadow: 24,
        outline: 'none',
        p: 4,
        ...custom,
    };
};

export default function ModalWrapper({
    open,
    customStyle,
    toggle,
    children,
    className,
    title,
}) {
    return (
        <div>
            <Modal
                className={className || ''}
                open={open}
                onClose={toggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={() => style(customStyle)}>{children}</Box>
            </Modal>
        </div>
    );
}
