import React from 'react';
import { courseStatisticsList } from 'pages/analytics/views/students/consts';
import DetailCourseStatisticsItem from 'pages/analytics/views/courses/DetailCourses/DetailCourseStatisticsItem';
import useFormatDate from 'helpers/hooks/useFormatDate';
import { useTranslation } from 'react-i18next';

const List = ({ data, selectedCourse }) => {
    const { t } = useTranslation('common');
    const formatDate = useFormatDate;
    return (
        <ul className="study-details-statistic__list">
            {courseStatisticsList({ data, selectedCourse, formatDate }).map(
                ({ title, value, id }) => (
                    <React.Fragment key={id}>
                        <DetailCourseStatisticsItem
                            title={t(title)}
                            value={value}
                        />
                    </React.Fragment>
                )
            )}
        </ul>
    );
};

export default List;
