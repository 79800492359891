import { useEffect, useState } from 'react';
import {
    useFetchUserStudentQuery,
    useLazyFetchUserStudentCoursesQuery,
    useRemoveUserStudentCoursesMutation,
} from 'api/services';
import { useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import { useAction, useDebounce, useSearch } from 'helpers/hooks';
import { UsersTableFilter } from 'pages/users/common/UsersTable/UsersTableFilter';
import { UsersTableList } from 'pages/users/common/UsersTable/UsersTableList';
import {
    StudentDetailCourseBottomModal,
    StudentDetailCourseItem,
    UserEmpty,
    UserSearchEmpty,
} from 'pages/users/common';
import './Courses.scss';
import { useTranslation } from 'react-i18next';

const Courses = () => {
    const { t } = useTranslation('common');
    const { id: studentId } = useParams();
    const [isOpen, setIsOpen] = useOutletContext();
    const { search, onSearch } = useSearch();
    const debouncedSearch = useDebounce(search, 700);
    const { userSpace } = useSelector((state) => state.space);
    const { isUpdate, pageSize } = useSelector((state) => state.userStudent);
    const { setIsUpdate } = useAction();
    const { studentCourses: courses } = useSelector(
        (state) => state.userStudent
    );
    const { setUserStudentCourses } = useAction();
    const [trigger, { isLoading }] = useLazyFetchUserStudentCoursesQuery();
    const [removeCourse] = useRemoveUserStudentCoursesMutation();
    const { data: student } = useFetchUserStudentQuery(
        { spaceId: userSpace?.id, studentId },
        { skip: !userSpace || !studentId }
    );
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [page, setPage] = useState(1);
    const [isCheckedAll, setIsCheckedAll] = useState(false);

    const onSelectedCourse = (e) => {
        const { checked, value } = e.target;
        let updatedList = [...selectedCourses];
        if (checked) {
            updatedList = [...updatedList, value];
        } else {
            updatedList = updatedList.filter((p) => p !== value);
        }
        setSelectedCourses(updatedList);
    };
    const onSelectedAllCourses = (e) => {
        const { checked } = e.target;
        const updatedList = courses.results.map((p) => p.id);
        if (checked) {
            setIsCheckedAll(true);
            setSelectedCourses(updatedList);
        } else {
            setIsCheckedAll(false);
            setSelectedCourses([]);
        }
    };
    const onClearSelectedCourses = () => {
        setIsCheckedAll(false);
        setSelectedCourses([]);
    };

    const onRemoveCourse = async (id) =>
        await removeCourse({
            spaceId: userSpace.id,
            userId: student.user.id,
            courses: [id],
        });
    const onRemoveCourses = async () =>
        await removeCourse({
            spaceId: userSpace.id,
            userId: student.user.id,
            courses: selectedCourses,
        }).then(({ data }) => {
            if (data.length === 0) {
                onClearSelectedCourses();
            }
        });

    const loadMoreStudents = async () => {
        if (!courses.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            space_id: userSpace.id,
            student_id: studentId,
            search: debouncedSearch,
            page: page + 1,
            page_size: pageSize,
            status: 'active',
        });
        const modifiedResults = [...courses.results, ...data.results];
        setUserStudentCourses({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(() => {
        if (userSpace) {
            setPage(1);
            trigger({
                space_id: userSpace.id,
                student_id: studentId,
                search: debouncedSearch,
                page: 1,
                page_size: pageSize,
                status: 'active',
            });
        }
    }, [debouncedSearch]);

    useEffect(() => {
        if (userSpace) {
            trigger({
                space_id: userSpace.id,
                student_id: studentId,
                page,
                page_size: pageSize,
                status: 'active',
            });
        }
    }, [userSpace]);

    useEffect(() => {
        if (isUpdate) {
            trigger({
                space_id: userSpace.id,
                student_id: studentId,
                page: 1,
                page_size: pageSize,
                status: 'active',
            }).then(() => {
                setIsUpdate(false);
                setPage(1);
            });
        }
        return () => {
            setIsUpdate(false);
        };
    }, [isUpdate]);
    const renderTable = () => {
        if (isLoading) {
            return null;
        } else if (search.length > 0 && !courses?.results.length) {
            return <UserSearchEmpty />;
        } else if (courses?.results.length === 0) {
            return (
                <UserEmpty
                    title={t('LIST_EMPTY')}
                    desc={t('ADD_STUDENTS_DESCRIPTION')}
                    btnText={t('ADD_COURSE')}
                    onClick={() => setIsOpen(true)}
                />
            );
        } else {
            return courses?.results.length > 0 ? (
                <UsersTableList
                    count={courses.count}
                    items={courses.results}
                    loadMore={loadMoreStudents}
                    pageSize={pageSize}
                    hasMore={courses.hasMore}
                    renderItem={(course) => (
                        <StudentDetailCourseItem
                            key={course.id}
                            isSelected={selectedCourses.includes(course.id)}
                            onSelected={onSelectedCourse}
                            onRemove={onRemoveCourse}
                            {...course}
                        />
                    )}
                />
            ) : null;
        }
    };
    return (
        <div className={'student-detail-courses'}>
            <UsersTableFilter
                count={courses?.count}
                searchPlaceholder={t('SEARCH_COURSES')}
                isFilter={false}
                isChecked={isCheckedAll}
                isDetailPage={true}
                search={search}
                onSearch={onSearch}
                onSelectedAll={onSelectedAllCourses}
            />
            {renderTable()}
            {selectedCourses.length > 0 ? (
                <StudentDetailCourseBottomModal
                    count={selectedCourses.length}
                    maxCount={courses.count}
                    onRemove={onRemoveCourses}
                    onClear={onClearSelectedCourses}
                />
            ) : null}
        </div>
    );
};

export default Courses;
