import React, { useCallback, useEffect, useState } from 'react';
import NotificationWrapper from 'pages/notification/NotificationWrapper';
import './Notification.scss';
import NotificationList from 'pages/notification/NotificationList';
import { useSelector } from 'react-redux';
import { useAction } from 'helpers/hooks';
import useNotification from 'helpers/hooks/useNotification';
import {
    useGetNotificationDetailQuery,
    useGetNotificationsQuery,
} from 'api/services/NotificationService';
import useChangeTab from 'helpers/hooks/useChangeTab';
import NotificationDetail from 'pages/notification/NotificationDetail';
import { useTranslation } from 'react-i18next';

const Notification = () => {
    const { t } = useTranslation('common');
    const { isOpen, notifications } = useSelector(
        (state) => state.notification
    );
    const { refetch } = useGetNotificationsQuery();
    const { notificationToggle, updateNotifications, readNotification } =
        useAction();
    const [notifyItem, setNotifyItem] = useState(null);
    const notifyDetail = useGetNotificationDetailQuery(
        { notifyId: notifyItem?.id },
        { skip: !notifyItem }
    );
    useEffect(() => {
        if (notifyItem) {
            refetch();
        }
    }, [notifyItem]);

    useChangeTab(refetch);
    useNotification();

    const readNotify = useCallback(
        (item, index) => {
            readNotification(index);
            setNotifyItem(item);
        },
        [notifyItem]
    );

    const displayContent = () => {
        if (notifyItem) {
            return (
                <NotificationDetail
                    goBack={setNotifyItem}
                    {...notifyDetail.data}
                />
            );
        }
        return (
            <div className="notifications__wrapper">
                <h5 className="notifications__title">{t('NOTIFICATION')}</h5>
                <NotificationList
                    setNotifyItem={readNotify}
                    notifications={notifications || []}
                />
            </div>
        );
    };
    return (
        <NotificationWrapper isOpen={isOpen} close={notificationToggle}>
            {displayContent()}
        </NotificationWrapper>
    );
};

export default Notification;
