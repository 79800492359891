export const courseModalSort = [
    {
        title: 'Среднему баллу',
        value: '',
    },
    {
        title: 'Прогрессу',
        value: '',
    },
];

export const tableHeadingStudents = {
    name: 'PROFILE_NAME',
    average: 'ANALYTICS_AVERAGE_SCORE_MIN',
    progress: 'ANALYTICS_PROGRESS',
};
