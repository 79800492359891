import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { actions } from './consts';
import {
    Avatar,
    Checkbox,
    Dropdown,
    Icon,
    List,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import {
    ChangeGroupTitleModal,
    ManageGroupStudentsModal,
} from 'pages/users/common';
import GroupImg from 'assets/img/png/group.png';
import classNames from 'classnames';
import './GroupItem.scss';
import { useWindowSize } from 'helpers/hooks';

const GroupItem = (props) => {
    const {
        id,
        title,
        accessCourses = 0,
        qtyParticipants = 0,
        isSelected,
        onSelectedGroup,
        onRemove,
    } = props;
    const { t } = useTranslation('common');
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState(false);
    const [isOpenChangeTitleModal, setIsOpenChangeTitleModal] = useState(false);
    const [isOpenManageGroupUserModal, setIsOpenManageGroupUserModal] =
        useState(false);

    const onShow = (e) => {
        e.stopPropagation();
        setIsShow((prev) => !prev);
    };
    const onOpenChangeTitle = () => {
        setIsOpenChangeTitleModal(true);
        setIsShow(false);
    };
    const onOpenManageGroupUser = () => {
        setIsOpenManageGroupUserModal(true);
        setIsShow(false);
    };
    const onNavigateToGroupDetails = () => {
        navigate(`/content/users/groups/${id}/students`);
    };
    const onSelected = () => {
        if (width < 769) {
            onNavigateToGroupDetails();
        } else {
            onSelectedGroup({
                target: { checked: !isSelected, value: id },
            });
        }
    };

    return (
        <>
            <div
                className={classNames('group-item', {
                    selected: isSelected,
                })}
                onClick={onSelected}
            >
                <Checkbox
                    className={'group-item__checkbox'}
                    value={id}
                    checked={isSelected}
                    onChange={onSelectedGroup}
                />
                <Avatar
                    className={'group-item__avatar'}
                    src={GroupImg}
                    variant={'rounded'}
                    alt={'avatar'}
                    onClick={onNavigateToGroupDetails}
                />
                <div className={'group-item__content'}>
                    <div>
                        <Typography
                            className={'group-item__name'}
                            variant={'body4'}
                        >
                            {title}
                        </Typography>
                    </div>
                    <div className={'group-item__qty'}>
                        <Typography variant={'body5'}>
                            {t('GROUPS_PARTICIPANTS')}
                        </Typography>
                        <Typography variant={'body4'}>
                            {qtyParticipants}
                        </Typography>
                    </div>
                    <div className={'group-item__qty'}>
                        <Typography variant={'body5'}>
                            {t('GROUPS_ACCESS_TO_COURSES')}
                        </Typography>
                        <Typography variant={'body4'}>
                            {accessCourses}
                        </Typography>
                    </div>
                </div>
                <Dropdown
                    className={'group-item__dropdown'}
                    isShow={isShow}
                    setIsShow={setIsShow}
                >
                    <Dropdown.Header>
                        <Icon name={'more'} onClick={onShow} />
                    </Dropdown.Header>
                    <Dropdown.Body>
                        <List
                            className={'group-item__actions'}
                            items={actions({
                                id,
                                onNavigateToGroupDetails,
                                onOpenChangeTitle,
                                onOpenManageGroupUser,
                                onRemove,
                            })}
                            renderItem={({ id, text, border, onClick }) => (
                                <li
                                    key={id}
                                    className={classNames(
                                        'group-item__action',
                                        {
                                            border,
                                        }
                                    )}
                                    onClick={onClick}
                                >
                                    <Typography variant={'body4'}>
                                        {t(text)}
                                    </Typography>
                                </li>
                            )}
                        />
                    </Dropdown.Body>
                </Dropdown>
                {width < 769 ? (
                    <Icon
                        className={'group-item__arrow'}
                        name={'arrow-right'}
                        onClick={onNavigateToGroupDetails}
                    />
                ) : null}
            </div>
            {isOpenChangeTitleModal ? (
                <ChangeGroupTitleModal
                    isOpen={isOpenChangeTitleModal}
                    setIsOpen={setIsOpenChangeTitleModal}
                    groupId={id}
                />
            ) : null}
            {isOpenManageGroupUserModal ? (
                <ManageGroupStudentsModal
                    isOpen={isOpenManageGroupUserModal}
                    setIsOpen={setIsOpenManageGroupUserModal}
                    groupTitle={title}
                    groupId={id}
                />
            ) : null}
        </>
    );
};

export default GroupItem;
