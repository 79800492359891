import React from 'react';
import List from 'pages/analytics/views/students/StudentDetail/CourseStatistic/List';
import { useFetchStudentDetailCoursesStatisticsQuery } from 'api/services/AnalyticsService/AnalyticsService';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
const CourseStatistic = ({ selectedCourse }) => {
    const { id } = useParams();
    const { userSpace } = useSelector((state) => state.space);
    const { data } = useFetchStudentDetailCoursesStatisticsQuery({
        spaceId: userSpace?.id,
        studentId: id,
        courseId: selectedCourse?.id,
    });
    return (
        <div className="study-details-statistic">
            <div className="study-details-statistic__title">
                {selectedCourse.name}
            </div>
            <List data={data} selectedCourse={selectedCourse} />
        </div>
    );
};

export default CourseStatistic;
