import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { BottomModalWrapper } from 'pages/users/common';
import { useTranslation } from 'react-i18next';

const MentorBottomModal = ({ count, maxCount, onClear, onRemove }) => {
    const { t } = useTranslation('common');
    return (
        <BottomModalWrapper count={count} maxCount={maxCount} onClear={onClear}>
            <Button variant={'tertiary'} size={'small'} onClick={onRemove}>
                {t('ADMINS_DELETE')}
            </Button>
        </BottomModalWrapper>
    );
};

export default MentorBottomModal;
