import { nanoid } from 'nanoid';

const actions = [
    {
        id: nanoid(),
        text: 'USERS_ACTIVE',
        type: 'active',
    },
    {
        id: nanoid(),
        text: 'USERS_PENDING',
        type: 'pending',
        border: true,
    },
    {
        id: nanoid(),
        text: 'USERS_SHOW_ALL',
        type: null,
    },
];

export { actions };
