import { useState } from 'react';
import { useDebounce } from 'helpers/hooks';
import { Input } from '@akhter-studios/classlytics-front-ui-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useUpdateSpaceEffect from 'pages/settings/useUpdateSpaceEffect';
const SettingsCompanyName = () => {
    const { t } = useTranslation('common');
    const { userSpace } = useSelector((state) => state.space);
    const [companyName, setCompanyName] = useState(userSpace.companyName);
    const debouncedCompanyName = useDebounce(companyName, 700);
    const onChangeCompanyName = (e) => setCompanyName(e.target.value);

    const { isError, error } = useUpdateSpaceEffect({
        field: companyName,
        fieldName: 'companyName',
        fieldRequestName: 'company_name',
        debouncedField: debouncedCompanyName,
    });

    return (
        <div className={'settings-company__field'}>
            <Input
                id={'settingsCompanyName'}
                label={t('SETTINGS_COMPANY_NAME')}
                value={companyName}
                error={isError}
                helperText={error?.data['companyName']}
                onChange={onChangeCompanyName}
                fullWidth
            />
        </div>
    );
};

export default SettingsCompanyName;
