const fields = [
    {
        id: 'forgotPassword',
        type: 'password',
        name: 'password',
        label: 'ENTER_PASSWORD',
    },
    {
        id: 'forgotSecondPassword',
        type: 'password',
        name: 'second_password',
        label: 'CONFIRM_PASSWORD',
    },
];

export { fields };
