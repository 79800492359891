import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
    useAddUserStudentGroupsManyMutation,
    useLazyFetchUserGroupsQuery,
} from 'api/services';
import { notify } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import SelectableModal from 'pages/users/common/UsersModalWrapper/SelectableModal';
import { useEffect } from 'react';
import { useDebounce } from 'helpers/hooks';
import AddGroupsModalItem from 'pages/users/common/AddGroupsModal/AddGroupsModalItem';

const AddGroupsManyModal = ({ isOpen, setIsOpen, count, students }) => {
    const { userSpace } = useSelector((state) => state.space);
    const { t } = useTranslation('common');
    const [trigger] = useLazyFetchUserGroupsQuery();
    const [addUserStudentGroupsMany, { isLoading }] =
        useAddUserStudentGroupsManyMutation();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [groups, setGroups] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 10;

    const onAddGroups = async () => {
        const { error } = await addUserStudentGroupsMany({
            spaceId: userSpace.id,
            groups: selectedGroups,
            students,
        });
        if (!error) {
            notify('success', t('NOTIFY_SUCCESSFULLY_INVITED_TO_GROUP'));
            setIsOpen(false);
            setSelectedGroups([]);
        } else {
            notify('error', error.data.error);
        }
    };

    const onSearch = (e) => setSearch(e.target.value);
    const onSelect = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedGroups([...selectedGroups, value]);
        } else {
            setSelectedGroups(selectedGroups.filter((s) => s !== value));
        }
    };
    const onSelectAll = () => {
        if (isSelectedAll) {
            setIsSelectedAll(false);
            setSelectedGroups([]);
        } else {
            setIsSelectedAll(true);
            setSelectedGroups(groups.results.map((c) => c.id));
        }
    };

    const loadMoreGroups = async () => {
        if (!groups?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            id: userSpace.id,
            page: page + 1,
            page_size: pageSize,
            search: debouncedSearch,
        });
        const modifiedResults = [...groups.results, ...data.results];
        setGroups({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(
        () =>
            userSpace &&
            trigger({
                id: userSpace.id,
                page,
                page_size: pageSize,
            }).then(({ data }) => setGroups(data)),
        [userSpace]
    );
    useEffect(
        () =>
            debouncedSearch &&
            trigger({
                id: userSpace.id,
                page: 1,
                page_size: pageSize,
                search: debouncedSearch,
            }).then(({ data }) => {
                setPage(1);
                setGroups(data);
            }),
        [debouncedSearch]
    );
    return (
        <SelectableModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('ADD_GROUPS_TO_STUDENTS', { count })}
            btnText={t('SAVE')}
            searchPlaceholder={t('USERS_GROUPS_SEARCH')}
            isSearchable={true}
            isSelectedAll={isSelectedAll}
            search={search}
            btnFixed={true}
            btnLoading={isLoading}
            btnDisabled={isLoading || selectedGroups.length === 0}
            currentCount={groups?.results.length}
            maxCount={groups?.count}
            hasMore={groups?.hasMore}
            items={groups?.results}
            onSearch={onSearch}
            onSubmit={onAddGroups}
            onLoadMore={loadMoreGroups}
            onSelectAll={onSelectAll}
            renderItem={(course) => (
                <AddGroupsModalItem
                    key={course.id}
                    isSelected={selectedGroups.includes(course.id)}
                    onSelect={onSelect}
                    {...course}
                />
            )}
        />
    );
};

export default AddGroupsManyModal;
