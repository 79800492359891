import React, { useEffect, useState } from 'react';

const useOnSaveInput = (defaultValue) => {
    const [value, setValue] = useState();
    useEffect(() => {
        if (defaultValue?.value) {
            setValue(defaultValue);
        }
    }, [defaultValue?.value]);
    return { value, setValue };
};

export default useOnSaveInput;
