import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';
import {
    useAddUserStudentCoursesManyMutation,
    useLazyFetchCoursesQuery,
} from 'api/services';
import { notify } from 'utils/functions';
import { CourseItem } from 'pages/users/common';
import SelectableModal from 'pages/users/common/UsersModalWrapper/SelectableModal';

const AddCoursesManyModal = ({ isOpen, setIsOpen, students, count }) => {
    const { userSpace } = useSelector((state) => state.space);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 700);
    const [trigger] = useLazyFetchCoursesQuery();
    const [addUserStudentCoursesMany, { isLoading }] =
        useAddUserStudentCoursesManyMutation();
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [courses, setCourses] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const status = 'active';
    const { t } = useTranslation('common');

    const onAddCourses = async () => {
        const { error } = await addUserStudentCoursesMany({
            spaceId: userSpace.id,
            courses: selectedCourses,
            students,
        });
        if (!error) {
            notify('success', t('NOTIFY_SUCCESSFULLY_INVITED_TO_COURSE'));
            setIsOpen(false);
            setSearch('');
            setSelectedCourses([]);
        } else {
            notify('error', error.data.error);
        }
    };

    const onSearch = (e) => setSearch(e.target.value);
    const onSelect = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedCourses([...selectedCourses, value]);
        } else {
            setSelectedCourses(selectedCourses.filter((s) => s !== value));
        }
    };
    const onSelectAll = () => {
        if (isSelectedAll) {
            setIsSelectedAll(false);
            setSelectedCourses([]);
        } else {
            setIsSelectedAll(true);
            setSelectedCourses(courses.results.map((c) => c.id));
        }
    };

    const loadMoreCourses = async () => {
        if (!courses?.hasMore) {
            return;
        }
        setPage((page) => page + 1);
        const { data } = await trigger({
            spaceId: userSpace.id,
            status,
            page: page + 1,
            page_size: pageSize,
            search: debouncedSearch,
        });
        const modifiedResults = [...courses.results, ...data.results];
        setCourses({
            results: modifiedResults,
            hasMore: data.hasMore,
            count: data.count,
        });
    };

    useEffect(
        () =>
            userSpace &&
            trigger({
                spaceId: userSpace.id,
                status,
                page,
                page_size: pageSize,
            }).then(({ data }) => setCourses(data)),
        [userSpace]
    );
    useEffect(
        () =>
            debouncedSearch &&
            trigger({
                spaceId: userSpace.id,
                status,
                page: 1,
                page_size: pageSize,
                search: debouncedSearch,
            }).then(({ data }) => {
                setPage(1);
                setCourses(data);
            }),
        [debouncedSearch]
    );
    return (
        <SelectableModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t('ADD_COURSES_TO_STUDENTS', { count })}
            btnText={t('SAVE')}
            searchPlaceholder={t('SEARCH_COURSES')}
            isSearchable={true}
            isSelectedAll={isSelectedAll}
            search={search}
            btnFixed={true}
            btnLoading={isLoading}
            btnDisabled={isLoading || selectedCourses.length === 0}
            currentCount={courses?.results.length}
            maxCount={courses?.count}
            hasMore={courses?.hasMore}
            items={courses?.results}
            onSearch={onSearch}
            onSubmit={onAddCourses}
            onLoadMore={loadMoreCourses}
            onSelectAll={onSelectAll}
            renderItem={(course) => (
                <CourseItem
                    key={course.id}
                    isSelected={selectedCourses.includes(course.id)}
                    onSelect={onSelect}
                    {...course}
                />
            )}
        />
    );
};

export default AddCoursesManyModal;
