import { baseApi } from 'api/xhr';

export const profileApi = baseApi.injectEndpoints({
    tagTypes: ['Profile', 'ProfileUpdate'],
    endpoints: (build) => ({
        me: build.query({
            query: ({ id }) => ({
                url: `/users/${id}/me/`,
            }),
            providesTags: ['Profile', 'ProfileUpdate'],
        }),
        updateProfile: build.mutation({
            query: ({ id, body }) => ({
                method: 'PATCH',
                url: `/users/${id}/me/`,
                body,
            }),
            invalidatesTags: ['ProfileUpdate'],
            transformErrorResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue.data;
            },
        }),
    }),
});

export const { useMeQuery, useUpdateProfileMutation } = profileApi;
