import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    useInvitationUserMentorMutation,
    useLazySearchUsersQuery,
} from 'api/services';
import { isDisabledForm, notify } from 'utils/functions';
import { Button } from '@akhter-studios/classlytics-front-ui-components';
import { CustomAsyncCreatableSelect } from 'components/custom-react-select';
import { InvitedModal } from 'pages/users/common/UsersModalWrapper/InvitedModal';
import { FormatOptionLabelUser } from 'pages/users/select-formats';
import { userMentorInvitationSchema } from 'utils/schemas';
import { useEffect, useRef, useState } from 'react';
import { debounce, removeDuplicateObjects } from 'utils/functions/helpers';
import defaultAvatar from 'assets/img/png/img.png';
import usePaste from 'helpers/hooks/usePaste';
const InviteMentorModal = ({ isOpen, setIsOpen }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const { userSpace } = useSelector((state) => state.space);
    const [isFocusEmail, setIsFocusEmail] = useState(false);
    const [trigger] = useLazySearchUsersQuery();
    const [invitationUserMentor, { isLoading }] =
        useInvitationUserMentorMutation();
    const { t, i18n } = useTranslation('common');
    const fieldEmailRef = useRef(null);

    const onSubmit = async (values, { resetForm }) => {
        const { type, emails } = values;
        const newEmail = emails.map((e) => e.value.toLowerCase());
        const { error } = await invitationUserMentor({
            spaceId: userSpace.id,
            emails: newEmail,
            type,
            target: 'invite',
            lang: i18n.language,
        });
        if (!error) {
            resetForm({ values: '' });
            setIsSuccess(true);
        }
    };

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
        isValid,
    } = useFormik({
        initialValues: {
            emails: [],
            type: 'mentor',
        },
        validationSchema: userMentorInvitationSchema,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit,
    });

    usePaste({
        isFocus: isFocusEmail,
        fieldRef: fieldEmailRef,
        setFieldValue,
        values,
    });
    const loadOptions = async (searchValue, callback) => {
        if (searchValue) {
            const res = await trigger({
                space_id: userSpace.id,
                search: searchValue.toLowerCase(),
                unique: true,
                target: 'invite',
            });
            if (res?.error?.data) {
                notify('error', res.error.data.msg);
            }
            const newUsers = res.data.results.map(({ email, avatar }) => ({
                label: email,
                value: email,
                image: avatar?.length > 0 ? avatar : null,
            }));
            callback(newUsers);
        } else {
            callback([]);
        }
    };

    const debounceLoadEmails = debounce(loadOptions, 700);

    const onChange = (selectedOptions) =>
        setFieldValue('emails', selectedOptions);
    return (
        <InvitedModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t(
                isSuccess ? 'INVITATION_SENT_SUCCESSFULLY' : 'INVITE_ADMIN'
            )}
            isSuccess={isSuccess}
        >
            <form onSubmit={handleSubmit}>
                <CustomAsyncCreatableSelect
                    className={'field'}
                    name={'emails'}
                    customRef={fieldEmailRef}
                    placeholder={t('ENTER_EMAILS')}
                    iconName={'search'}
                    onFocus={() => {
                        setIsFocusEmail(true);
                    }}
                    loadOptions={debounceLoadEmails}
                    defaultOptions
                    isMulti
                    formatOptionLabel={FormatOptionLabelUser}
                    isClearable={false}
                    isError={!!(touched['emails'] && errors['emails'])}
                    helperText={t(errors['emails'])}
                    value={values.emails}
                    onChange={onChange}
                    onBlur={() => {
                        handleBlur({ target: { name: 'emails' } });
                        setIsFocusEmail(false);
                    }}
                />
                <Button
                    className={'invite-mentor-modal__btn'}
                    variant={'primary'}
                    size={'medium'}
                    loading={isLoading}
                    disabled={isDisabledForm({ isValid, isLoading })}
                    fullWidth
                >
                    {t('INVITE')}
                </Button>
            </form>
        </InvitedModal>
    );
};

export default InviteMentorModal;
