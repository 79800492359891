import React, { memo } from 'react';
import './Timer.scss';
import { Icon } from 'components/common';
import CountDownTimer from 'components/common/Header/HeaderStudentTest/Timer/CountDownTimer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pickTextColorBasedOnBgColor } from 'utils/functions';
const Timer = ({ isPassed }) => {
    const { timer, resultPercent } = useSelector((state) => state.test);
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);

    const isExistResult = typeof resultPercent === 'number';
    const { t } = useTranslation('common');

    return (
        <div
            className="timer d-flex"
            style={{ color: pickTextColorBasedOnBgColor(menuBackgroundColor) }}
        >
            <div className="mobile-hidden timer-title-desktop">
                {isExistResult
                    ? t('STUDENT_TEST_TEST_YOUR_RESULT')
                    : timer && t('STUDENT_TEST_TIMER')}
            </div>
            <div
                className="desktop-hidden timer-title"
                style={{
                    color: pickTextColorBasedOnBgColor(menuBackgroundColor),
                }}
            >
                {isExistResult
                    ? t('STUDENT_TEST_TEST_YOUR_RESULT')
                    : t('STUDENT_TEST_TIME_LEFT')}
            </div>
            {isExistResult ? (
                <div className="timer-result">{resultPercent}%</div>
            ) : (
                timer && (
                    <div className="timer-countdown d-flex">
                        <CountDownTimer
                            disabled={isPassed}
                            minSecs={{ minutes: timer, seconds: 0 }}
                        />
                        <Icon name="time" />
                    </div>
                )
            )}
        </div>
    );
};

export default memo(Timer);
