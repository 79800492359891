import './ColorValue.scss';
import { Typography } from '@akhter-studios/classlytics-front-ui-components';

const ColorValue = ({ color }) => {
    return (
        <span className={'color-value'}>
            <div
                className={'color-value__box'}
                style={{ backgroundColor: color }}
            />
            <Typography variant={'body4'} className={'color-value__color'}>
                {color}
            </Typography>
        </span>
    );
};

export default ColorValue;
