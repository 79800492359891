import { nanoid } from 'nanoid';
import defaultGroupImg from 'assets/img/png/group.png';
import Course from 'pages/analytics/views/groups/Modals/Course';
import Student from 'pages/analytics/views/groups/Modals/Student';

const tabs = [
    {
        id: nanoid(),
        icon: 'courses',
        text: 'REVIEW',
        search: 'students',
        to: '/content/analytics/review',
    },
    {
        id: nanoid(),
        icon: 'admin-courses',
        text: 'COURSES',
        search: 'admins',
        to: '/content/analytics/courses',
    },
    {
        id: nanoid(),
        icon: 'student',
        text: 'STUDENTS',
        search: 'groups',
        to: '/content/analytics/students',
    },
    {
        id: nanoid(),
        icon: 'user-group',
        text: 'GROUPS',
        search: 'groups',
        to: '/content/analytics/groups',
    },
];
const coursesTableHeadings = {
    name: 'NAME',
    student_qty: 'ANALYTICS_STUDENTS',
    group_qty: 'GROUPS',
    lesson_qty: 'ANALYTICS_LESSONS',
    passed_qty: 'PREVIEW_PASSED',
    average_score: 'ANALYTICS_AVERAGE_SCORE_MIN',
};

const studentsTableHeadings = {
    name: 'NAME',
    average: 'ANALYTICS_AVERAGE_SCORE_MIN',
    course_qty: 'INVITED_COURSES',
    joined: 'ANALYTICS_JOINED',
};

const groupsTableHeadings = {
    name: 'EDITOR_TOOL_WARNING_TITLE',
    members: 'MEMBERS',
    accessCourse: 'ADMINS_ACCESS_TO_COURSE',
    progress: 'ANALYTICS_OVERALL_PROGRESS',
};

const reviewTableHeadings = {
    name: 'NAME',
    student_qty: 'ANALYTICS_STUDENTS',
    group_qty: 'GROUPS',
    lesson_qty: 'ANALYTICS_LESSONS',
    passed_qty: 'PREVIEW_PASSED',
    average_score: 'ANALYTICS_AVERAGE_SCORE',
};

const reviewsEmojiData = (data) => [
    {
        count: data.total_courses,
        title: 'ANALYTICS_TOTAL_COURSES',
        emoji: '📚',
    },
    {
        count: data.active_courses,
        title: 'ANALYTICS_ACTIVE_COURSES',
        emoji: '🗒️',
    },
    {
        count: data.total_students,
        title: 'ANALYTICS_TOTAL_STUDENTS',
        emoji: '🎓',
    },
    // {
    //     count: '75%',
    //     title: 'ANALYTICS_INVOLVEMENT',
    //     emoji: '✋',
    // },
    {
        count: data.average_score,
        title: 'ANALYTICS_AVERAGE_SCORE',
        emoji: '🏆',
    },
];
const reviewTableBody = [
    {
        name: 'BBS- Bachelor of Business Studies',
        students: '20',
        link: '/content/analytics/detail/course/1',
        groups: '20',
        lessons: '12',
        completed: '150 раз',
        ball: '4,87',
        img: 'https://cdn.cloudflare.steamstatic.com/steam/apps/570/ss_86d675fdc73ba10462abb8f5ece7791c5047072c.1920x1080.jpg?t=1666237243',
    },
    {
        name: 'BBS- Bachelor of Business Studies',
        students: '20',
        link: '/content/analytics/detail/course/1',
        groups: '20',
        lessons: '12',
        completed: '150 раз',
        ball: '4,87',
        img: 'https://cdn.cloudflare.steamstatic.com/steam/apps/570/ss_86d675fdc73ba10462abb8f5ece7791c5047072c.1920x1080.jpg?t=1666237243',
    },
    {
        name: 'BBS- Bachelor of Business Studies',
        students: '20',
        link: '/content/analytics/detail/course/1',
        groups: '20',
        lessons: '12',
        completed: '150 раз',
        ball: '4,87',
        img: 'https://cdn.cloudflare.steamstatic.com/steam/apps/570/ss_86d675fdc73ba10462abb8f5ece7791c5047072c.1920x1080.jpg?t=1666237243',
    },
    {
        name: 'BBS- Bachelor of Business Studies',
        students: '20',
        link: '/content/analytics/detail/course/1',
        groups: '20',
        lessons: '12',
        completed: '150 раз',
        ball: '4,87',
        img: 'https://cdn.cloudflare.steamstatic.com/steam/apps/570/ss_86d675fdc73ba10462abb8f5ece7791c5047072c.1920x1080.jpg?t=1666237243',
    },
];
const studentsTable = [
    {
        id: 1,
        img: 'https://cdn.cloudflare.steamstatic.com/steam/apps/570/ss_86d675fdc73ba10462abb8f5ece7791c5047072c.1920x1080.jpg?t=1666237243',
        name: 'Elon Musk',
        ball: '3,25',
        coursesQty: '120',
        joined: '10 авг. 2022',
    },
    {
        img: 'https:˚//cdn.cloudflare.steamstatic.com/steam/apps/570/ss_86d675fdc73ba10462abb8f5ece7791c5047072c.1920x1080.jpg?t=1666237243',
        id: 2,
        name: 'Elon Musk',
        ball: '3,25',
        coursesQty: '120',
        joined: '10 авг. 2022',
    },
    {
        id: 3,
        img: 'https://cdn.cloudflare.steamstatic.com/steam/apps/570/ss_86d675fdc73ba10462abb8f5ece7791c5047072c.1920x1080.jpg?t=1666237243',
        name: 'Elon Musk',
        ball: '3,25',
        coursesQty: '120',
        joined: '10 авг. 2022',
    },
];

const groupsTable = ({ data, handleToggleModal }, defaultGroupImg) => {
    return (data || []).map((x) => ({
        name: x.Group.name,
        id: x.Group.id,
        members: (
            <div onClick={(e) => handleToggleModal(e, 'student', x.Group)}>
                {x.count}
            </div>
        ),
        accessCourse: (
            <div onClick={(e) => handleToggleModal(e, 'course', x.Group)}>
                {x.access_courses}
            </div>
        ),
        progress: `${x.overall_progress || 0}%`,
        img: x.Group.image || defaultGroupImg,
        ...x,
    }));
};

const modalType = (selectedCourse, handleToggleModal) => ({
    course: (
        <Course
            handleToggleModal={handleToggleModal}
            selectedCourse={selectedCourse}
        />
    ),
    student: (
        <Student
            handleToggleModal={handleToggleModal}
            selectedCourse={selectedCourse}
        />
    ),
});
export {
    tabs,
    reviewTableHeadings,
    reviewTableBody,
    coursesTableHeadings,
    reviewsEmojiData,
    studentsTableHeadings,
    studentsTable,
    groupsTableHeadings,
    groupsTable,
    modalType,
};
