import { useFormik } from 'formik';
import { registerSchema } from 'utils/schemas';
import {
    CONTENT_COURSES_ROUTE,
    DOMAIN_START_END_CHARACTERS_REGEX,
    GENERATE_TOKEN_URL,
    ONLY_LATIN,
} from 'utils/consts';
import { useCreateSpaceMutation } from 'api/services';
import { authRequest } from 'api/xhr';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button, Input } from '@akhter-studios/classlytics-front-ui-components';
import { useTranslation } from 'react-i18next';
import {
    findDomainToRedirect,
    getDomain,
    getDomainPlaceholder,
} from 'utils/functions';
import './CreateSpaceForm.scss';
import { AuthError } from 'components/feature';

const CreateSpaceForm = () => {
    const { t } = useTranslation('common');
    const { token, refreshToken } = useSelector((state) => state.auth);
    const [createSpace, { isSuccess, isLoading, error }] =
        useCreateSpaceMutation();

    const onSubmit = async (values) => await createSpace(values);

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues: {
            company_name: '',
            domain: '',
            ori_domain: window.location.hostname,
        },
        validationSchema: registerSchema(t)['/auth/register/about-company'],
        onSubmit,
    });
    const handleChangeDomain = (e) => {
        const { value } = e.target;
        const regex = new RegExp(ONLY_LATIN);
        const isValid = regex.test(value);
        if (!isValid) {
            setFieldValue('domain', value.toString().toLowerCase());
        }
    };
    const onChangeSpace = async () => {
        const { domain } = values;
        const { token: generateToken } = await authRequest.post(
            GENERATE_TOKEN_URL,
            { token, domain }
        );
        if (generateToken.length > 0) {
            window.location.href = `${
                window.location.protocol
            }//${domain}.${findDomainToRedirect()}${CONTENT_COURSES_ROUTE}?oauth=${generateToken}&refresh_token=${refreshToken}`;
        }
    };

    useEffect(async () => {
        if (isSuccess) {
            await onChangeSpace();
        }
    }, [isSuccess]);
    return (
        <>
            {error?.data?.detail && <AuthError error={error.data?.detail} />}
            <form
                className={'auth-form create-space-form'}
                onSubmit={handleSubmit}
            >
                <Input
                    id={'aboutCompanyName'}
                    name={'company_name'}
                    label={t('SETTINGS_COMPANY_NAME')}
                    value={values.company_name}
                    helperText={
                        (touched['company_name'] && errors['company_name']) ||
                        (Boolean(error) && t(error?.data['companyName']))
                    }
                    error={
                        (touched['company_name'] &&
                            errors['company_name'] &&
                            Boolean(errors)) ||
                        Boolean(error)
                    }
                    onChange={(e) => {
                        handleChangeDomain(e);
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                />
                <Input
                    id={'aboutCompanyDomain'}
                    name={'domain'}
                    label={t('SETTINGS_DOMAIN_NAME')}
                    // domain={getDomainPlaceholder()}
                    value={values.domain}
                    helperText={
                        (touched['domain'] && errors['domain']) ||
                        (Boolean(error) && t(error?.data['domain']))
                    }
                    error={
                        (touched['domain'] &&
                            errors['domain'] &&
                            Boolean(errors)) ||
                        Boolean(error)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Button
                    className={'auth-button'}
                    type={'submit'}
                    disabled={
                        Boolean(errors.company_name) ||
                        !new RegExp(DOMAIN_START_END_CHARACTERS_REGEX).test(
                            values.domain
                        )
                    }
                    variant={'primary'}
                    size={'large'}
                    loading={isLoading}
                    fullWidth
                >
                    {t('CREATE_SPACE')}
                </Button>
            </form>
        </>
    );
};

export default CreateSpaceForm;
