import React from 'react';
import { Icon } from 'components/common';
import { useNavigate } from 'react-router-dom';
import { pickTextColorBasedOnBgColor } from 'utils/functions';
import { useSelector } from 'react-redux';

const CourseDetailedHeaderMobile = ({ title }) => {
    const navigate = useNavigate();
    const { menuBackgroundColor } = useSelector((state) => state.settingSpace);

    return (
        <div className="courses-detailed-header-mobile d-flex">
            <Icon name="arrow-back" handler={() => navigate(-1)} />
            <div
                className="courses-detailed-header-mobile__title"
                style={{
                    color: pickTextColorBasedOnBgColor(menuBackgroundColor),
                }}
            >
                {title}
            </div>
        </div>
    );
};

export default CourseDetailedHeaderMobile;
