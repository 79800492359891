import DefaultCourseImg from 'assets/img/png/img.png';
import {
    Heading,
    Typography,
} from '@akhter-studios/classlytics-front-ui-components';
import './FormatOptionLabelCourse.scss';
import i18next from 'i18next';

const FormatOptionLabelCourse = ({ t = i18next.t, ...props }) => {
    const { label, image } = props;
    return (
        <div className={'format-option-label-course'}>
            <img
                className={'format-option-label-course__img'}
                src={image ?? DefaultCourseImg}
                alt={label}
            />
            <div>
                <Heading
                    className={'format-option-label-course__name'}
                    variant={'h5'}
                >
                    {label}
                </Heading>
                <Typography
                    className={'format-option-label-course__invite'}
                    variant={'body4'}
                >
                    {t('COURSE_INVITE_MESSAGE')}
                </Typography>
            </div>
        </div>
    );
};

export default FormatOptionLabelCourse;
