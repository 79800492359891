import React from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { onErrorImg } from 'utils/functions/helpers';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@akhter-studios/classlytics-front-ui-components';

const TableBodyComponent = ({
    body,
    isStudent,
    handler,
    headings,
    withImgColumn,
}) => {
    const { t } = useTranslation('common');
    return (
        <TableBody>
            {body.map((row, idx) => (
                <TableRow
                    onClick={() => handler && handler(row)}
                    key={row.id || idx}
                    sx={{
                        '&:last-child td, &:last-child th': {
                            border: 0,
                        },
                    }}
                >
                    {Object.keys(headings).map((x, index) => {
                        return (
                            <TableCell
                                key={x.id || index}
                                align={!index ? 'left' : 'right'}
                            >
                                <Link
                                    to={row.link || '#'}
                                    className="d-flex"
                                    style={{ display: 'inline-flex' }}
                                >
                                    {!index &&
                                        withImgColumn &&
                                        (isStudent ? (
                                            <Box marginRight={1}>
                                                <Avatar
                                                    src={row.img || row.image}
                                                    variant={'rounded'}
                                                    avatarText={`${
                                                        row.firstName
                                                            ?.charAt(0)
                                                            .toUpperCase() || ''
                                                    }${
                                                        row.lastName
                                                            ?.charAt(0)
                                                            .toUpperCase() || ''
                                                    }`}
                                                />
                                            </Box>
                                        ) : (
                                            <img
                                                className={classNames({
                                                    isStudent,
                                                })}
                                                src={row.img || row.image}
                                                onError={(e) => onErrorImg(e)}
                                            />
                                        ))}
                                    <span className="analytics-table__name">
                                        {row[x] || '-'}
                                    </span>
                                </Link>
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))}
        </TableBody>
    );
};

export default TableBodyComponent;
