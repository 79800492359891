import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const TestStatisticTabs = ({ data, activeTab, setActiveTab }) => {
    const { t } = useTranslation();
    return (
        <ul className="test-statistic__tabs d-flex">
            {Array.from({ length: data.length }, (_, i) => i).map((x) => (
                <li
                    onClick={() => setActiveTab(x)}
                    className={classNames('test-statistic__tabs-item', {
                        active: x === activeTab,
                    })}
                >{`Attempt ${x + 1}`}</li>
            ))}
        </ul>
    );
};

export default TestStatisticTabs;
