import { useState } from 'react';

const useOnchange = (defaultState) => {
    const [form, setForm] = useState(defaultState);

    const handleChangeBlock = (name, value) =>
        setForm({ ...form, [name]: value });

    const handleChangeField = ({ target: { value, name } }) =>
        setForm({ ...form, [name]: value });

    const resetFields = () => setForm(defaultState);
    return { form, handleChangeBlock, handleChangeField, resetFields };
};

export default useOnchange;
