import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CourseContent from 'pages/courses/CoursesDetailed/CourseContent';
import EducationFiles from 'components/common/EducationalFiles/EducationFiles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ maxWidth: '875px', margin: '0 auto' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const STabs = styled(Tabs)`
    .MuiTabs-indicator {
        display: none;
    }
`;

const STab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'capitalize !important',
        fontWeight: '500',
        fontSize: '13px',
        color: '#637D8B',
        '&:first-child': {
            paddingLeft: 0,
        },
        '&:hover': {
            color: '#061822 !important',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#061822 !important',
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    })
);

const CourseTabs = ({ data }) => {
    const { t } = useTranslation('common');
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ paddingBottom: '100px' }}>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    maxWidth: '800px',
                    margin: '0 auto',
                }}
            >
                <STabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <STab label={t('COURSE_CONTENT')} {...a11yProps(0)} />
                    {data?.educationalMaterials?.length && (
                        <STab label={t('LEARNING_FILES')} {...a11yProps(1)} />
                    )}
                </STabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box sx={{ pt: 3 }}>
                    <CourseContent data={data?.materials} />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <EducationFiles
                    files={data?.educationalMaterials}
                    disabledDownload={!data?.available}
                />
            </TabPanel>
        </Box>
    );
};

export default CourseTabs;
